import { partnerProfileConstants } from './constants';

export const partnerProfileReducers = function (state = {
    partnerProfile: null,
    partnerProfileLogs: [],
    partnerProfileDocuments: [],
    partnerProfileServices: [],
    partnerProfileService: null,
    partnerProfileServiceDocuments: [],
    partnerProfileAvailableServices: [],
    sendPartnerProfileGET: true,
    sendPartnerProfileLogsGET: true,
    sendPartnerProfileLogs: true,
    sendPartnerProfilePATCH: false,
    sendPartnerProfileDocument: false,
    sendPartnerProfileDocumentPOST: false,
    sendPartnerProfileDocumentDELETE: false,
    sendPartnerProfileDocumentsGET: true,
    sendPartnerProfileServicesGET: true,
    sendPartnerProfileServiceGET: true,
    sendPartnerProfileServiceDELETE: [],
    sendPartnerProfileAvailableServicesGET: true,
    sendPartnerProfileServicePOST: false,
    sendPartnerProfileSynchronization: []
}, action) {
    switch (action.type) {
    case partnerProfileConstants.GET_PARTNER_PROFILE_REQUEST:
        return {
            ...state,
            partnerProfile: action.update ? state.partnerProfile : null,
            send: true,
            sendPartnerProfileGET: !action.update
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SUCCESS:
        return {
            ...state,
            partnerProfile: action.payload,
            send: false,
            sendPartnerProfileGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_LOGS_REQUEST:
        return {
            ...state,
            partnerProfileLogs: [],
            send: true,
            sendPartnerProfileLogsGET: true
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_LOGS_SUCCESS:
        return {
            ...state,
            partnerProfileLogs: action.payload,
            send: false,
            sendPartnerProfileLogsGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_LOGS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileLogsGET: false
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfilePOST: true
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfilePOST: false
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfilePOST: false
        };
    case partnerProfileConstants.PATCH_PARTNER_PROFILE_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfilePATCH: true
        };
    case partnerProfileConstants.PATCH_PARTNER_PROFILE_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfilePATCH: false
        };
    case partnerProfileConstants.PATCH_PARTNER_PROFILE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfilePATCH: false
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_IMAGE_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfileImage: true
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_IMAGE_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfileImage: false
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_IMAGE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileImage: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_REQUEST:
        return {
            ...state,
            send: true
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfileDocument: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileDocument: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_REQUEST:
        return {
            ...state,
            send: true
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_SUCCESS:
        return {
            ...state,
            send: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_DOCUMENT_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfileDocumentPOST: true
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_DOCUMENT_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfileDocumentPOST: false
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_DOCUMENT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileDocumentPOST: false
        };
    case partnerProfileConstants.DELETE_PARTNER_PROFILE_DOCUMENT_REQUEST:
        return {
            ...state,
            send: true
        };
    case partnerProfileConstants.DELETE_PARTNER_PROFILE_DOCUMENT_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfileDocumentDELETE: false
        };
    case partnerProfileConstants.DELETE_PARTNER_PROFILE_DOCUMENT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileDocumentDELETE: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENTS_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfileDocumentsGET: true
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENTS_SUCCESS:
        return {
            ...state,
            partnerProfileDocuments: action.payload,
            send: false,
            sendPartnerProfileDocumentsGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENTS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileDocumentsGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SERVICES_REQUEST:
        return {
            ...state,
            partnerProfileServices: action.update ? state.partnerProfileServices : [],
            send: true,
            sendPartnerProfileServicesGET: true
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SERVICES_SUCCESS:
        return {
            ...state,
            partnerProfileServices: action.payload,
            send: false,
            sendPartnerProfileServicesGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SERVICES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileServicesGET: false
        };
    case partnerProfileConstants.PUT_PARTNER_PROFILE_SERVICE_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfileServicesPUT: true
        };
    case partnerProfileConstants.PUT_PARTNER_PROFILE_SERVICE_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfileServicesPUT: false
        };
    case partnerProfileConstants.PUT_PARTNER_PROFILE_SERVICE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileServicesPUT: false
        };
    case partnerProfileConstants.DELETE_PARTNER_PROFILE_SERVICE_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerServiceDELETE: true
        };
    case partnerProfileConstants.DELETE_PARTNER_PROFILE_SERVICE_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerServiceDELETE: false
        };
    case partnerProfileConstants.DELETE_PARTNER_PROFILE_SERVICE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerServiceDELETE: false
        };
    case partnerProfileConstants.PATCH_PARTNER_PROFILE_SERVICE_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfileServicePATCH: true
        };
    case partnerProfileConstants.PATCH_PARTNER_PROFILE_SERVICE_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfileServicePATCH: false
        };
    case partnerProfileConstants.PATCH_PARTNER_PROFILE_SERVICE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileServicePATCH: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_REQUEST:
        return {
            ...state,
            partnerProfileService: action.update ? state.partnerProfileService : null,
            send: true,
            sendPartnerProfileServiceGET: true
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_SUCCESS:
        return {
            ...state,
            partnerProfileService: action.payload,
            send: false,
            sendPartnerProfileServiceGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileServiceGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_REQUEST:
        return {
            ...state,
            partnerProfileServiceDocuments: action.update ? state.partnerProfileServiceDocuments : [],
            send: true,
            sendPartnerProfileServiceDocumentsGET: true
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_SUCCESS:
        return {
            ...state,
            partnerProfileServiceDocuments: action.payload,
            send: false,
            sendPartnerProfileServiceDocumentsGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileServiceDocumentsGET: false
        };
    case partnerProfileConstants.POST_PARTNER_SERVICE_DOCUMENT_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerServiceDocumentPOST: true
        };
    case partnerProfileConstants.POST_PARTNER_SERVICE_DOCUMENT_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerServiceDocumentPOST: false
        };
    case partnerProfileConstants.POST_PARTNER_SERVICE_DOCUMENT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerServiceDocumentPOST: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_AVAILABLE_SERVICES_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfileAvailableServicesGET: true
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_AVAILABLE_SERVICES_SUCCESS:
        return {
            ...state,
            partnerProfileAvailableServices: action.payload,
            send: false,
            sendPartnerProfileAvailableServicesGET: false
        };
    case partnerProfileConstants.GET_PARTNER_PROFILE_AVAILABLE_SERVICES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileAvailableServicesGET: false
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_SERVICE_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfileServicePOST: true
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_SERVICE_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfileServicePOST: false
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_SERVICE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileServicePOST: false
        };
    case partnerProfileConstants.POST_PARTNER_RESET_PASSWORD_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerPasswordReset: true
        };
    case partnerProfileConstants.POST_PARTNER_RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerPasswordReset: false
        };
    case partnerProfileConstants.POST_PARTNER_RESET_PASSWORD_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerPasswordReset: false
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_SYNCHRONIZATION_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerProfileSynchronization: [...state.sendPartnerProfileSynchronization, action.loading]
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_SYNCHRONIZATION_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerProfileSynchronization: state.sendPartnerProfileSynchronization.filter(e => e !== action.loading)
        };
    case partnerProfileConstants.POST_PARTNER_PROFILE_SYNCHRONIZATION_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerProfileSynchronization: state.sendPartnerProfileSynchronization.filter(e => e !== action.loading)
        };
    case partnerProfileConstants.GET_PARTNER_SERVICE_ITEMS_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerServiceItemsGET: true
        };
    case partnerProfileConstants.GET_PARTNER_SERVICE_ITEMS_SUCCESS:
        return {
            ...state,
            partnerProfileServiceItems: action.payload,
            send: false,
            sendPartnerServiceItemsGET: false
        };
    case partnerProfileConstants.GET_PARTNER_SERVICE_ITEMS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerServiceItemsGET: false
        };
    case partnerProfileConstants.PUT_PARTNER_SERVICE_ITEM_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerServiceItemPUT: true
        };
    case partnerProfileConstants.PUT_PARTNER_SERVICE_ITEM_SUCCESS:
        return {
            ...state,
            send: false,
            sendPartnerServiceItemPUT: false
        };
    case partnerProfileConstants.PUT_PARTNER_SERVICE_ITEM_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerServiceItemPUT: false
        };
    default:
        return state;
    }
};
