import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InternalClientSelectionField } from './internalClientSelectionField';
import { FormField, formFieldType, Translate } from '../../../../components';
import { fieldNames } from '../../fieldNames';
import { i18 } from '../../../../utilities';
import { JobsStatusConstants } from '../../../../api';
import { isJobStandard, isJobFixed } from '../../../jobsCreate';

class OtherSettingsFormSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    isEditableAndNotFactual() {
        return (
            this.props.isJobEditable && (this.isPreorderAndFixed() || this.isApprovedInProgressPausedAndStandardFixed())
        );
    }

    isPreorderAndFixed() {
        return (
            this.props.formValues.jobStatus === JobsStatusConstants.PREORDER &&
            isJobFixed(this.props.formValues.biddingType)
        );
    }

    isEditableAndNotResolved() {
        return this.props.isJobEditable && this.props.formValues.jobStatus !== JobsStatusConstants.RESOLVED;
    }

    isApprovedInProgressPausedAndStandardFixed() {
        return (
            this.isApprovedInProgressOrPaused() &&
            (isJobStandard(this.props.formValues.biddingType) || isJobFixed(this.props.formValues.biddingType))
        );
    }

    isApprovedInProgressOrPaused() {
        return (
            this.props.formValues.jobStatus === JobsStatusConstants.APPROVED ||
            this.props.formValues.jobStatus === JobsStatusConstants.INPROGRESS ||
            this.props.formValues.jobStatus === JobsStatusConstants.PAUSED
        );
    }

    render() {
        return (
            <>
                <Translate className="fs-16 fw_600" value={i18.Job.Titles.OtherSettings} />
                <div className="container-default mt-2">
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.ManagerPhone}
                                borderTitle
                                name={fieldNames.managerPhone}
                                component={formFieldType.input}
                                disabled
                                dimmed
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.ResponsibleManager}
                                borderTitle
                                name={fieldNames.responsibleManager}
                                component={formFieldType.input}
                                maxLength={50}
                                disabled
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.ContactNumber}
                                borderTitle
                                name={fieldNames.contactPhoneNumber}
                                component={formFieldType.input}
                                disabled
                                dimmed
                            />
                        </div>
                    </div>
                    <div className="row">
                        <InternalClientSelectionField
                            classNames="col-12"
                            nameValue={fieldNames.internalClientName}
                            nameId={fieldNames.internalClientId}
                            updateKeyValue={this.props.updateKeyValue}
                            disabled
                            dimmed={this.props.isJobEditable}
                        />
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.PartnerNameCompanyName}
                                borderTitle
                                component={formFieldType.input}
                                name={fieldNames.partnerName}
                                disabled
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.PreviewPartner}
                                borderTitle
                                component={formFieldType.clickLink}
                                name={fieldNames.partnerLink}
                                highlighted
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.JobCreationDate}
                                borderTitle
                                component={formFieldType.date}
                                name={fieldNames.creationDate}
                                disabled
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.PlannedStartDate}
                                borderTitle
                                component={formFieldType.date}
                                name={fieldNames.plannedStartDate}
                                minDate={{
                                    date: this.props.job[fieldNames.creationDate]
                                }}
                                time
                                disabled={!this.isEditableAndNotFactual()}
                                highlighted={this.isEditableAndNotFactual()}
                                dimmed={this.props.isJobEditable && !this.isEditableAndNotFactual()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.JobPeriod}
                                borderTitle
                                component={formFieldType.dateRangePicker}
                                name={fieldNames.jobDateRange}
                                disabled
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.JobDuration}
                                borderTitle
                                component={formFieldType.input}
                                name={fieldNames.jobDuration}
                                suffix={'valandos'}
                                disabled
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.AdministratorComment}
                                borderTitle
                                component={formFieldType.textarea}
                                name={fieldNames.administratorComment}
                                disabled={!this.props.isJobEditable}
                                highlighted={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.JobTrackingUrl}
                                borderTitle
                                component={formFieldType.clickLink}
                                name={fieldNames.jobTrackingUrl}
                                highlighted
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

OtherSettingsFormSection.propTypes = {
    formValues: PropTypes.object.isRequired,
    isJobEditable: PropTypes.bool.isRequired,
    jobTypes: PropTypes.array,
    job: PropTypes.object,
    updateKeyValue: PropTypes.func.isRequired,
    dispatch: PropTypes.func
};

function mapStateToProps(state) {
    const { job } = state.jobReducers;

    const { isJobEditable } = state.localJobReducers;

    return {
        isJobEditable,
        job: job?.value || {}
    };
}

const connectedOtherSettingsFormSection = connect(mapStateToProps)(OtherSettingsFormSection);
export { connectedOtherSettingsFormSection as OtherSettingsFormSection };
