import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import * as History from 'history';

import { serverErrorsMiddleware } from '../middleware';
import { rootReducerWithRouter } from './';
import { getUser } from '../../utilities';

export const history = History.createBrowserHistory();

const initialState = {
    userReducers: {
        userLogin: getUser()
    }
};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history), serverErrorsMiddleware];

const composedEnhancers = compose(
    composeWithDevTools(applyMiddleware(...middleware, loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR']
    }))),
    ...enhancers
);

export const store = createStore(
    rootReducerWithRouter(history),
    initialState,
    composedEnhancers
);
