import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Classificator, ServiceImage, Translate, TranslateStopJobs } from '../../../components';
import { formatDate, i18, i18cl, statusesBool } from '../../../utilities';

const ServiceInformation = ({ data, featureToggles }) => {
    const information = useMemo(
        () => [
            {
                label: <Translate value={i18.Service.Labels.ServiceGroup} />,
                value: (
                    <>
                        <span className="mr-5">{data.serviceGroup}</span>
                        <Classificator value={i18cl.GROUPS_SERVICE + data.serviceGroup} />
                    </>
                )
            },
            {
                label: <Translate value={i18.Service.Labels.ExternalServiceId} />,
                value: data.externalServiceID
            },
            {
                label: <Translate value={i18.Service.Labels.Priority} />,
                value: data.priority
            },
            {
                label: <Translate value={i18.Service.Labels.ValidFrom} />,
                value: formatDate(data.validFrom)
            },
            {
                label: <Translate value={i18.Service.Labels.ValidTo} />,
                value: formatDate(data.validTo)
            },
            {
                label: <Translate value={i18.Service.Labels.ShowInWeb} />,
                value: statusesBool(data.showInWeb)
            },
            {
                label: <Translate value={i18.Service.Labels.ShowInRegistrationForm} />,
                value: statusesBool(data.showInRegistrationForm)
            },
            {
                label: <Translate value={i18.Service.Labels.ValidFromWeb} />,
                value: formatDate(data.validFrom),
                visible: data.showInWeb
            },
            {
                label: <Translate value={i18.Service.Labels.ValidToWeb} />,
                value: formatDate(data.validTo),
                visible: data.showInWeb
            },
            {
                label: <Translate value={i18.Service.Labels.VatPercentage} />,
                value: data.vatName,
                visible: !featureToggles.allowVatEditingForJob
            },
            {
                label: <Translate value={i18.Service.Labels.CanReverseVatBeApplied} />,
                value: statusesBool(data.canReverseVatBeApplied)
            },
            {
                label: <Translate value={i18.Service.Labels.ServiceDetailsDescription} />,
                value: data.serviceDetails?.description
            },
            {
                label: <Translate value={i18.Service.Labels.ServiceDetailsAdditionalDescription} />,
                value: data.serviceDetails?.additionalDescription
            },
            {
                label: <Translate value={i18.Service.Labels.ServiceDetailsPrice} />,
                value: data.serviceDetails?.price
            },
            {
                label: <Translate value={i18.Service.Labels.ServiceDetailsKeywords} />,
                value: data.serviceDetails?.keywords
            },
            {
                label: <Translate value={i18.Service.Labels.ServiceDetailsHintText} />,
                value: data.serviceDetails?.hintText
            },
            {
                label: <Translate value={i18.Service.Labels.ServiceDetailsOrder} />,
                value: data.serviceDetails?.order
            },
            {
                label: <Translate value={i18.Service.Labels.ServiceDetailsStoppedJobs} />,
                value: <TranslateStopJobs jobsList={data?.serviceStoppedJobsList} />
            }
        ],
        [data, featureToggles]
    );

    const renderedInformation = useMemo(
        () =>
            information
                .filter(e => e.visible !== false)
                .map((item, index) => (
                    <div key={index} className="list_info row">
                        <div className="list_info_label col-12 col-xl-5 text-right">{item.label}</div>
                        <div className="list_info_value col-12 col-xl-7">{item.value}</div>
                    </div>
                )),
        [information]
    );

    return (
        <div className="row">
            <div className="col-auto">
                <ServiceImage />
            </div>
            <div className="col">
                <div className="list_info_wrapper">{renderedInformation}</div>
            </div>
        </div>
    );
};

ServiceInformation.propTypes = {
    data: PropTypes.object.isRequired,
    featureToggles: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const { featureToggles } = state.featureTogglesReducers;

    return {
        featureToggles
    };
}

const connectedServiceInformation = connect(mapStateToProps)(ServiceInformation);
export { connectedServiceInformation as ServiceInformation };
