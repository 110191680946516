import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

const ClientSegmentFeeMenu = props => (
    <Menu
        className="d-flex content-bottom-border"
        mode="inline"
        selectedKeys={[props.selected]}
        onSelect={props.onChange}
    >
        {props.values.map(item => (
            <Menu.Item className="flex text-center" key={item.value}>
                {item.label}
            </Menu.Item>
        ))}
    </Menu>
);

ClientSegmentFeeMenu.propTypes = {
    location: PropTypes.object,
    values: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })
    ).isRequired,
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export { ClientSegmentFeeMenu };
