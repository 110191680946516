import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Popconfirm } from 'antd';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { i18 } from '../../utilities';
import { translationActions } from '../../api';
import { ButtonWrapper, ContentHeader, Translate } from '../../components';
import { EditorLinks, TranslatesContentsFilterForm, ContentsTypeToggle } from './';
import { env } from '../../configs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const emptyEditorState = EditorState.createEmpty();

const getDefaultLanguage = languages => languages[0]?.code || env.REACT_APP_DEFAULT_LANGUAGE;

export const TranslatesContentsContainer = () => {
    const dispatch = useDispatch();
    const { publicLanguages } = useSelector(state => state.publicReducers);
    const { sendTranslationContent, sendTranslationContentPUT } = useSelector(state => state.translationReducers);
    const [content, setContent] = useState({
        value: null,
        customLinks: null,
        showNotifyPartnerButton: false
    });
    const [asPlainText, setAsPlainText] = useState(false);
    const [filters, setFilters] = useState({
        language: getDefaultLanguage(publicLanguages),
        filter: {}
    });
    const [notifyPartners, setNotifyPartners] = useState(false);

    const filterContent = useCallback(
        async values => {
            const { language, filter } = values;
            const response = await dispatch(translationActions.getContent(language, filter.title));

            const newFilters = {
                language,
                filter
            };

            const newContent = {
                value: emptyEditorState,
                customLinks: null,
                showNotifyPartnerButton: false
            };

            if (response && response.payload) {
                const { value, customLinksJson, showNotifyPartnerButton } = response.payload;

                if (value) {
                    const contentBlock = htmlToDraft(value);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, null);
                    newContent.value = EditorState.createWithContent(contentState);
                }

                if (customLinksJson) {
                    try {
                        newContent.customLinks = JSON.parse(customLinksJson);
                    } catch (e) {
                        newContent.customLinks = [
                            {
                                value: 'CountrySettings CustomLink json syntax error',
                                href: '/countries/settings'
                            }
                        ];
                    }
                }

                newContent.showNotifyPartnerButton = showNotifyPartnerButton;
            }

            setContent(newContent);
            setFilters(newFilters);
            setNotifyPartners(false);
        },
        [dispatch]
    );

    const submit = useCallback(async () => {
        const submitValue = asPlainText
            ? content.value.getCurrentContent()?.getPlainText() || ''
            : draftToHtml(convertToRaw(content.value.getCurrentContent()));

        dispatch(
            translationActions.putContent(filters.language, {
                title: filters.filter.title,
                value: submitValue,
                needNotifyPartners: notifyPartners
            })
        );
    }, [asPlainText, content, dispatch, filters, notifyPartners]);

    const handleContentTypeChange = value => {
        setAsPlainText(value);
    };

    const changeContent = value => {
        setContent(prevState => ({ ...prevState, value }));
    };

    return (
        <>
            <ContentHeader title={<Translate value={i18.Translates.Titles.Contents} />} />
            <div className="content_block">
                <TranslatesContentsFilterForm
                    filter={{
                        language: filters.language,
                        filter: filters.filter
                    }}
                    languages={publicLanguages}
                    submit={filterContent}
                    submitting={sendTranslationContent}
                />
                {content.value && (
                    <div className="content_box content_box_white p-3">
                        <div>
                            <Editor
                                editorClassName="editor"
                                editorState={content.value}
                                onEditorStateChange={changeContent}
                                toolbarCustomButtons={[
                                    <EditorLinks key="links" customLinks={content.customLinks} />,
                                    <ContentsTypeToggle
                                        key="typeToggle"
                                        toggleValue={asPlainText}
                                        onToggle={handleContentTypeChange}
                                    />
                                ]}
                            />
                        </div>
                    </div>
                )}
                {content.value && (
                    <>
                        {content.showNotifyPartnerButton && (
                            <div className="text-right">
                                <Checkbox checked={notifyPartners} onChange={() => setNotifyPartners(!notifyPartners)}>
                                    <Translate value={i18.Translates.Labels.NotifyPartners} />
                                </Checkbox>
                            </div>
                        )}
                        <div className="text-right">
                            {notifyPartners ? (
                                <Popconfirm
                                    placement="left"
                                    onConfirm={submit}
                                    title={<Translate value={i18.Translates.Labels.ConfirmationTitle} />}
                                    okText={<Translate value={i18.Translates.Labels.ConfirmationOkText} />}
                                    cancelText={<Translate value={i18.Translates.Labels.ConfirmationCancelText} />}
                                >
                                    <ButtonWrapper type="primary" htmlType="submit" loading={sendTranslationContentPUT}>
                                        <Translate value={i18.Translates.Buttons.Submit} />
                                    </ButtonWrapper>
                                </Popconfirm>
                            ) : (
                                <ButtonWrapper
                                    type="primary"
                                    htmlType="submit"
                                    onClick={submit}
                                    loading={sendTranslationContentPUT}
                                >
                                    <Translate value={i18.Translates.Buttons.Submit} />
                                </ButtonWrapper>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
