import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { EditOutlined, FileImageOutlined, LoadingOutlined } from '@ant-design/icons';
import { serialize } from 'object-to-formdata';

import { getCurrentDate, i18, translate } from '../../utilities';
import { Message } from '../';

export class Image extends React.Component {
    constructor (props) {
        super(props);

        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
    }

    validate (type) {
        if (type && type.includes('image/')) {
            return true;
        } else {
            Message({
                type: 'warning',
                message: translate(i18.Components.Cropper.FileNotValid)
            });

            return false;
        }
    }

    submit (values) {
        const data = serialize(values);

        this.props.submitImage(data);
    }

    render () {
        return (
            <div className='profile_image'>
                <ImgCrop
                    grid
                    rotate
                    modalWidth={800}
                    modalTitle={translate(i18.Components.Cropper.ProfileImage)}
                    beforeCrop={(file) => this.validate(file.type)}
                >
                    <Upload
                        accept='image/*'
                        showUploadList={false}
                        disabled={this.props.loading}
                        customRequest={({ file }) => {
                            this.submit({
                                validFrom: getCurrentDate(),
                                validTo: getCurrentDate(100000),
                                file: file
                            });
                        }}
                    >
                        <Avatar
                            size={128}
                            src={this.props.loading ? undefined : this.props.imagePath || undefined}
                            icon={this.props.loading ? (
                                <LoadingOutlined
                                    spin
                                />
                            ) : <FileImageOutlined />}
                        />
                        <div className='profile_image_edit'>
                            <EditOutlined />
                        </div>
                    </Upload>
                </ImgCrop>
            </div>
        );
    }
}

Image.propTypes = {
    imagePath: PropTypes.string,
    submitImage: PropTypes.func,
    loading: PropTypes.bool
};
