import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { i18, translate } from '../../../utilities';
import { partnerReadActions } from '../../../api';
import { ButtonWrapper, Message, Translate } from '../../../components';
import { CopyOutlined } from '@ant-design/icons';

class PartnersMarketingAgreedAndApprovedContainer extends React.Component {
    constructor (props) {
        super(props);

        this.copy = this.copy.bind(this);
    }

    componentDidMount () {
        this.props.dispatch(partnerReadActions.getMarketingAgreedAndApproved());
    }

    copy () {
        navigator.clipboard.writeText(this.props.partnerMarketingAgreedAndApproved.separatedEmails).then(() => {
            Message({
                message: translate(i18.API.Messages.PartnersWithMarketingApprovedCopied)
            });
        });
    }

    render () {
        return (
            <div className='content_block'>
                <div className='mr-4 text-right'>
                    <ButtonWrapper
                        icon={<CopyOutlined />}
                        onClick={() => this.copy()}
                    >
                        <Translate value={i18.PartnersMarketing.Buttons.Copy} />
                    </ButtonWrapper>
                </div>
                {this.props.partnerMarketingAgreedAndApproved.separatedEmails}
            </div>
        );
    }
}

PartnersMarketingAgreedAndApprovedContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    partnerMarketingAgreedAndApproved: PropTypes.any.isRequired
};

function mapStateToProps (state) {
    const {
        partnerMarketingAgreedAndApproved
    } = state.partnerReadReducers;

    return {
        partnerMarketingAgreedAndApproved
    };
}

const connectedPartnersMarketingAgreedAndApprovedContainer = connect(mapStateToProps)(PartnersMarketingAgreedAndApprovedContainer);
export { connectedPartnersMarketingAgreedAndApprovedContainer as PartnersMarketingAgreedAndApprovedContainer };
