import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker } from 'antd';
import moment from 'moment';

import { i18 } from '../../../utilities';
import { Translate } from '../../translate';
import { ButtonWrapper } from '../../buttonWrapper';

const { RangePicker } = DatePicker;

export const DateFilterField = (props, confirm) => {
    const [typeField, setTypeField] = useState(props.typeField || 'range');
    const [value, setValue] = useState(props.data ? props.data.value : undefined);
    const reset = () => {
        setValue(null);

        props.setFilter({
            name: props.name,
            operator: null,
            type: props.type,
            typeField: 'range',
            value: null,
        });
    };
    const submit = () => {
        props.setFilter(
            {
                name: props.name,
                operator: null,
                type: props.type,
                typeField: typeField,
                value: value,
            },
            true,
        );
    };

    return (
        <>
            <div className="p-2">
                <div className="filter_field_wrapper">
                    <div className="mb-2">
                        <ButtonWrapper
                            className="m-0 p-0"
                            type="link"
                            onClick={() => {
                                setValue(undefined);
                                setTypeField(typeField === 'date' ? 'range' : 'date');
                            }}
                        >
                            {typeField === 'date' ? (
                                <Translate value={i18.Filter.Buttons.DateRange} />
                            ) : (
                                <Translate value={i18.Filter.Buttons.Date} />
                            )}
                        </ButtonWrapper>
                    </div>
                    {typeField === 'range' ? (
                        <RangePicker
                            style={{ width: '100%' }}
                            value={
                                value
                                    ? Array.isArray(value)
                                        ? [moment(value[0], 'YYYY-MM-DD'), moment(value[1], 'YYYY-MM-DD')]
                                        : moment(value, 'YYYY-MM-DD')
                                    : undefined
                            }
                            onChange={moment => {
                                setValue(moment.map(m => m.format('YYYY-MM-DD')));
                            }}
                        />
                    ) : (
                        <DatePicker
                            style={{ width: '100%' }}
                            value={value ? moment(value, 'YYYY-MM-DD') : undefined}
                            onChange={moment => {
                                setValue(moment.format('YYYY-MM-DD'));
                            }}
                        />
                    )}
                </div>
            </div>
            <div className="ant-table-filter-dropdown-btns">
                <Button
                    size="small"
                    type="text"
                    onClick={() => {
                        confirm({ closeDropdown: true });
                        reset();
                    }}
                >
                    <Translate value={i18.Filter.Buttons.Reset} />
                </Button>
                <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                        confirm({ closeDropdown: true });
                        submit();
                    }}
                >
                    <Translate value={i18.Filter.Buttons.Submit} />
                </Button>
            </div>
        </>
    );
};

DateFilterField.propTypes = {
    confirm: PropTypes.func.isRequired,
    data: PropTypes.object,
    name: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    typeField: PropTypes.string,
};
