export const routerPaths = {
    countries: {
        countries: '/countries',
        languages: '/countries/languages',
        settings: '/countries/settings',
        vats: '/countries/vats',
        administrationFeeMargins: '/countries/administration-fee-margins',
        monthlyRewards: '/countries/monthly-rewards'
    },
    dashboard: '/dashboard',
    jobs: {
        index: '/jobs',
        job: '/jobs/:jobId',
        create: '/jobs-create'
    },
    public: {
        resetPassword: '/reset-password',
        resetPasswordSuccess: '/reset-password-success',
        createPassword: '/reset-password/:code'
    },
    login: '/',
    logout: '/logout',
    services: {
        service: '/services/',
        serviceWithParams: '/services/:serviceCatalogId',
        services: '/services',
        serviceItems: '/service-items',
        serviceItemsCreate: '/service-items-create'
    },
    serviceGroups: {
        serviceGroups: '/service-groups',
        serviceGroup: '/service-groups/',
        serviceGroupWithParams: '/service-groups/:serviceGroupCode'
    },
    translates: {
        classificators: '/translates/classificators',
        contents: '/translates/contents',
        errors: '/translates/errors',
        labels: '/translates/labels',
        labelsFrontend: '/translates/labels-frontend',
        translates: '/translates'
    },
    partners: {
        account: '/partners/:partnerId/account',
        adminActions: '/partners/:partnerId/admin-actions',
        create: '/partners-create',
        documents: '/partners/:partnerId/documents',
        index: '/partners',
        logs: '/partners/:partnerId/logs',
        marketing: '/partners-marketing',
        marketingAgreed: '/partners-marketing-agreed',
        partner: '/partners/:partnerId',
        profile: '/partners/:partnerId/profile',
        service: '/partners/:partnerId/service/:partnerServiceId',
        serviceItems: '/partners/:partnerId/service-items',
        services: '/partners/:partnerId/services',
        communications: '/partners/:partnerId/communications',
        devices: '/partners/:partnerId/devices'
    },
    internalClients: {
        create: '/internal-clients-create',
        index: '/internal-clients',
        client: '/internal-clients/:clientId'
    }
};
