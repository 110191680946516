import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AdditionalSettingsSection } from './additionalSettingsSection';
import { OtherSettingsFormSection } from './otherSettingsFormSection';
import { PartnerSelectionFormSection } from './partnerSelectionFormSection';
import { ObjectLocationFormSection } from './objectLocationFormSection';
import { ServiceSettingsFormSection } from './serviceSettingsFormSection';
import { SettingsFormSection } from './settingsFormSection';
import { Form } from 'react-final-form';
import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../components';
import { history, jobCreationConstants, jobCreateFormActions, JobsStatusConstants } from '../../../api';
import { i18, routerPaths } from '../../../utilities';
import { jobCreateActions } from '../../../api/services/jobCreation/actions';
import { EntryFormSection } from './entryFormSection';
import { serialize } from 'object-to-formdata';
import { fieldNames } from './fieldNames';
import { isJobAnOrder } from '..';

class JobsCreateForm extends React.Component {
    static defaultProps = {
        additionalInfoStatuses: [
            JobsStatusConstants.APPROVED,
            JobsStatusConstants.COMPLETED,
            JobsStatusConstants.INPROGRESS,
            JobsStatusConstants.PAUSED
        ]
    }

    constructor (props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount () {
        this.fetch().then(() => {
            if (this.props.history.location.state?.jobCreateFormReducers) {
                this.props.dispatch(jobCreateFormActions.setJobReducerState(this.props.history.location.state.jobCreateFormReducers));
            }
        });
    }

    componentWillUnmount () {
        this.props.dispatch(jobCreateFormActions.setJobDefaultValues());
    }

    fetch () {
        const { dispatch } = this.props;

        return dispatch(jobCreateActions.getJobCreationInputs());
    }

    mutateSubmit (values) {
        return serialize(values);
    }

    submit (values) {
        this.props.dispatch(jobCreateActions.postJob(this.mutateSubmit(values))).then(res => {
            if (res && res.type === jobCreationConstants.POST_JOB_SUCCESS) {
                this.fetch();
            }
        });
    }

    render () {
        return (
            <Form
                mutators={{
                    updateKeyValue: (args, state, utils) => {
                        utils.changeValue(state, args[0], () => args[1]);
                    }
                }}
                initialValues={this.props.history.location.state?.formValues}
                onSubmit={this.submit}
                render={({ handleSubmit, values, form }) => (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete='off'
                    >
                        <div className='text-right'>
                            <ButtonGroup>
                                <ButtonWrapper
                                    type='text'
                                    disabled={false}
                                    onClick={() => history.push(routerPaths.jobs.index)}
                                >
                                    <Translate value={i18.PartnersCreateForm.Buttons.Cancel} />
                                </ButtonWrapper>
                                <ButtonWrapper
                                    type='primary'
                                    htmlType='submit'
                                    loading={false}
                                >
                                    <Translate value={i18.PartnersCreateForm.Buttons.Submit} />
                                </ButtonWrapper>
                            </ButtonGroup>
                        </div>
                        <div className='container-default'>
                            <div className='row'>
                                <div className='col pr-4 border-right'>
                                    <EntryFormSection
                                        workStatuses={this.props.workStatuses}
                                        orderStatuses={this.props.orderStatuses}
                                        vatTypes={this.props.vatTypes}
                                        updateKeyValue={form.mutators.updateKeyValue}
                                    />
                                    <SettingsFormSection
                                        jobTypes={this.props.jobTypes}
                                        formValues={values}
                                        updateKeyValue={form.mutators.updateKeyValue}
                                    />
                                    <ServiceSettingsFormSection
                                        services={this.props.services}
                                        updateKeyValue={form.mutators.updateKeyValue}
                                    />
                                    <ObjectLocationFormSection
                                        updateKeyValue={form.mutators.updateKeyValue}
                                    />
                                </div>
                                <div className='col'>
                                    <OtherSettingsFormSection
                                        updateKeyValue={form.mutators.updateKeyValue}
                                    />
                                    {isJobAnOrder(this.props.selectedJobStatus.statusCode) && <PartnerSelectionFormSection
                                        name={fieldNames.partnerIds}
                                        updateKeyValue={form.mutators.updateKeyValue}
                                        formValues={values}
                                    />}
                                    {this.props.additionalInfoStatuses.includes(this.props.selectedJobStatus.statusCode) && (
                                        <AdditionalSettingsSection
                                            updateKeyValue={form.mutators.updateKeyValue}
                                            formValues={values}
                                        />
                                    )}
                                    <FormField
                                        component={formFieldType.upload}
                                        accept='image/jpeg, image/png, image/heic, application/pdf, .heic'
                                        name={fieldNames.files}
                                        maxFileSizeInBytes={this.props.fileUploadSettings.maxFileSizeInMb * 1024 * 1024}
                                        multipleFiles
                                        maxCount={this.props.fileUploadSettings.maxFileCount}
                                        showUploadList
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            />
        );
    }
};

JobsCreateForm.propTypes = {
    workStatuses: PropTypes.array,
    orderStatuses: PropTypes.array,
    additionalInfoStatuses: PropTypes.array.isRequired,
    vatTypes: PropTypes.array,
    services: PropTypes.array,
    selectedJobStatus: PropTypes.object,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    jobTypes: PropTypes.array,
    dispatch: PropTypes.func,
    fileUploadSettings: PropTypes.object
};

function mapStateToProps (state) {
    const {
        fileUploadSettings,
        services,
        vatTypes,
        jobTypes,
        workStatuses,
        orderStatuses
    } = state.jobCreationReducers;

    const {
        selectedJobStatus
    } = state.jobCreateFormReducers;

    return {
        fileUploadSettings,
        services,
        vatTypes,
        jobTypes,
        workStatuses,
        orderStatuses,
        selectedJobStatus
    };
}

const connectedJobsCreateForm = withRouter(connect(mapStateToProps)(JobsCreateForm));
export { connectedJobsCreateForm as JobsCreateForm };
