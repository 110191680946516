import React from 'react';

import { FormField, formFieldType } from '../../../components';
import { fieldNames } from '../fieldNames';
import { i18 } from '../../../utilities';

export const PersonClient = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.ClientFirstName}
                        borderTitle
                        name={fieldNames.client.firstName}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.ClientLastName}
                        borderTitle
                        name={fieldNames.client.lastName}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
        </>
    );
};
