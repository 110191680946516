import React from 'react';

import { i18 } from '../../utilities';
import { ContentHeader, Translate } from '../../components';
import { ServicesForm, ServicesList } from './components';

export const ServicesContainer = () => {
    return (
        <>
            <ContentHeader title={<Translate value={i18.Services.Titles.Services} />} extra={<ServicesForm />} />
            <div className="content_block">
                <ServicesList />
            </div>
        </>
    );
};
