import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { serviceSettingsActions, serviceSettingsConstants } from '../../../api';
import { classificatorArray, getCurrentDate, i18, i18cl, required } from '../../../utilities';
import { FormField, formFieldType, Translate } from '../../../components';

class ServiceSettingsForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.toggle = this.toggle.bind(this);
        this.submit = this.submit.bind(this);
    }

    toggle(state) {
        if (state) {
            this.props
                .dispatch(serviceSettingsActions.getServiceSettingsById(this.props.serviceCatalogId, 0))
                .then(res => {
                    if (res && res.type === serviceSettingsConstants.GET_SERVICE_SETTINGS_BY_ID_SUCCESS) {
                        this.setState({ visible: true });
                    }
                });
        } else {
            this.setState({ visible: false });
        }
    }

    submit(values) {
        this.props
            .dispatch(serviceSettingsActions.postServiceSettings(this.props.serviceCatalogId, values))
            .then(res => {
                if (res && res.type === serviceSettingsConstants.POST_SERVICE_SETTINGS_SUCCESS) {
                    this.toggle();
                    this.props.dispatch(serviceSettingsActions.getServiceSettings(this.props.serviceCatalogId, true));
                }
            });
    }

    render() {
        return (
            <>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    loading={this.props.sendServiceSettingId === 0}
                    disabled={this.props.sendServiceSettingGET}
                    onClick={() => this.toggle(true)}
                >
                    <Translate value={i18.ServiceSettingsForm.Buttons.Add} />
                </Button>
                <Modal
                    title={<Translate value={i18.ServiceSettingsForm.Titles.Add} />}
                    visible={this.state.visible}
                    onCancel={() => this.toggle(false)}
                    footer={null}
                    maskClosable={false}
                >
                    <Form
                        onSubmit={this.submit}
                        render={({ handleSubmit, values }) => (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <FormField
                                    component={formFieldType.select}
                                    title={i18.ServiceSettingsForm.Labels.Parameter}
                                    options={classificatorArray(
                                        i18cl.SETTINGS_SERVICE,
                                        this.props.serviceSetting.classificatorList,
                                        'classificatorCode'
                                    )}
                                    disabled={this.props.sendServiceSettingPOST}
                                    name="classificatorCode"
                                    validate={required}
                                />
                                {values.classificatorCode &&
                                this.props.serviceSetting.classificatorList.find(
                                    e => e.classificatorCode === values.classificatorCode
                                ).field === 'select' ? (
                                    <FormField
                                        component={formFieldType.select}
                                        title={i18.ServiceSettingsForm.Labels.Value}
                                        name="value"
                                        validate={required}
                                        options={classificatorArray(
                                            this.props.serviceSetting.classificatorList.find(
                                                e => e.classificatorCode === values.classificatorCode
                                            ).classificatorCodeForValues + '.',
                                            this.props.serviceSetting.classificatorList.find(
                                                e => e.classificatorCode === values.classificatorCode
                                            ).fieldValues
                                        )}
                                    />
                                ) : (
                                    <FormField
                                        component={formFieldType.input}
                                        title={i18.ServiceSettingsForm.Labels.Value}
                                        name="value"
                                        validate={required}
                                    />
                                )}
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServiceSettingsForm.Labels.ValidFrom}
                                    name="validFrom"
                                    validate={required}
                                    disabled={this.props.sendServiceSettingPOST}
                                    minDate={{
                                        date: getCurrentDate()
                                    }}
                                    maxDate={{
                                        date: values.validTo
                                    }}
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServiceSettingsForm.Labels.ValidTo}
                                    name="validTo"
                                    disabled={this.props.sendServiceSettingPOST}
                                    minDate={{
                                        date: values.validFrom || getCurrentDate()
                                    }}
                                />
                                <div className="text-right">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={this.props.sendServiceSettingPOST}
                                    >
                                        <Translate value={i18.ServiceSettingsForm.Buttons.Submit} />
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </Modal>
            </>
        );
    }
}

ServiceSettingsForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    serviceSetting: PropTypes.object,
    serviceCatalogId: PropTypes.string.isRequired,
    sendServiceSettingGET: PropTypes.bool,
    sendServiceSettingPOST: PropTypes.bool,
    sendServiceSettingId: PropTypes.number
};

function mapStateToProps(state) {
    const { serviceSetting, sendServiceSettingGET, sendServiceSettingPOST, sendServiceSettingId } =
        state.serviceSettingsReducers;

    return {
        serviceSetting,
        sendServiceSettingGET,
        sendServiceSettingPOST,
        sendServiceSettingId
    };
}

const connectedServiceSettingsForm = connect(mapStateToProps)(ServiceSettingsForm);
export { connectedServiceSettingsForm as ServiceSettingsForm };
