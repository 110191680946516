import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';

import { i18 } from '../../utilities';
import { Translate } from '../translate';

export const PopConfirmWrapper = (props) => {
    return (
        <Popconfirm
            placement='top'
            okText={<Translate value={i18.PopConfirm.Buttons.Confirm} />}
            cancelText={<Translate value={i18.PopConfirm.Buttons.Cancel} />}
            {...props}
        >
            {props.children}
        </Popconfirm>
    );
};

PopConfirmWrapper.propTypes = {
    children: PropTypes.object.isRequired,
    title: PropTypes.object.isRequired
};
