import { requestApi, apiPaths, handleResponse } from '../../config';

export const partnerReadServices = {
    getActivityLog,
    getCommunicationLog,
    getMarketingAgreed,
    getMarketingAgreedAndApproved
};

function getActivityLog (profileId) {
    return requestApi(apiPaths.partnerRead + profileId + '/adminActivityLog').then(handleResponse);
}

function getCommunicationLog (profileId) {
    return requestApi(apiPaths.partnerRead + profileId + '/communicationLog').then(handleResponse);
}

function getMarketingAgreed () {
    return requestApi(apiPaths.partnerRead + 'agreedToBeUsedForMarketingPurposes').then(handleResponse);
}

function getMarketingAgreedAndApproved () {
    return requestApi(apiPaths.partnerRead + 'agreedToBeUsedForMarketingPurposesAndApproved').then(handleResponse);
}
