import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { ConfigProvider, Layout } from 'antd';
import LoadingBar from 'react-redux-loading-bar';
import { loadReCaptcha } from 'react-recaptcha-v3';

import localeEN from 'antd/lib/locale-provider/en_GB';
import localeES from 'antd/lib/locale-provider/es_ES';
import localeLT from 'antd/lib/locale-provider/lt_LT';
import localeLV from 'antd/lib/locale-provider/lv_LV';
import localePL from 'antd/lib/locale-provider/pl_PL';
import localeRU from 'antd/lib/locale-provider/ru_RU';

import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/lt';
import 'moment/locale/lv';
import 'moment/locale/pl';
import 'moment/locale/ru';

import { getDomain, RequireAuth, routerPaths } from '../utilities';
import {
    history,
    publicActions,
    translationActions,
    systemActions,
    featureTogglesActions,
    countryVatActions
} from '../api';
import { PartHeader, PartMenu } from '../parts';
import { Notification } from '../components';
import {
    CountriesLanguagesContainer,
    CountriesSettingsContainer,
    InternalClientsCreateContainer,
    InternalClientsOdataContainer,
    DashboardContainer,
    JobContainer,
    JobsContainer,
    JobsCreateContainer,
    LoginContainer,
    LogoutContainer,
    PartnerContainer,
    PartnersCreateContainer,
    PartnersMarketingContainer,
    PartnersOdataContainer,
    ServiceContainer,
    ServicesContainer,
    ServiceGroupsContainer,
    ServiceGroupContainer,
    ServiceItemsContainer,
    TranslatesClassificatorsContainer,
    TranslatesContentsContainer,
    TranslatesErrorsContainer,
    TranslatesLabelsContainer,
    TranslatesLabelsFrontendContainer,
    InternalClientContainer,
    CountryVatContainer,
    CountryAdministrationFeeMargins,
    ServiceItemsCreateContainer,
    CountriesMonthlyRewards,
    ResetPasswordContainer,
    ResetPasswordSuccessContainer,
    CreatePasswordContainer,
    ValidateContainer
} from '../containers';
import cookie from '../utilities/cookie';
import { env } from '../configs';

const { Header, Content, Footer, Sider } = Layout;

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isUpdatedAfterLogin: false
        };

        this.handleLanguageChangeLocale = this.handleLanguageChangeLocale.bind(this);
    }

    componentDidMount() {
        this.loadRecaptcha();

        this.props.dispatch(systemActions.setCountry(getDomain()));
        this.props.dispatch(publicActions.getLanguages());
        this.props.dispatch(publicActions.getLocalization());
        this.props.dispatch(publicActions.getServiceAreas());
        this.props.dispatch(publicActions.getConfigurations());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.systemCountry !== this.props.systemCountry) {
            history.push('reload');
            history.goBack();
        }

        if (cookie.getCookie() && !this.state.isUpdatedAfterLogin) {
            this.props.dispatch(translationActions.getAdminFooter(this.props.systemLanguage));
            this.props.dispatch(publicActions.getContent());
            this.props.dispatch(featureTogglesActions.getFeatureToggles());
            this.props.dispatch(countryVatActions.getCountryVats());

            this.setState({ isUpdatedAfterLogin: true });
        }
    }

    loadRecaptcha() {
        loadReCaptcha(env.REACT_APP_RECAPTCHA, false);

        var scripts = document.getElementsByTagName('script');
        const captchaScript = [...scripts].filter(item => item.src.includes('onLoadCaptchaV3Callback'));

        captchaScript.onerror = error => {
            console.log('Captcha error occured:');
            console.log(error);

            const defaultErrorResponse = 'Please try again later';
            const displayError = typeof error === 'string' ? error : null;
            const displayErrorMessage = error && typeof error.message === 'string' ? error.message : null;

            Notification({
                type: 'error',
                message: 'ReCaptcha failed',
                description: displayError || displayErrorMessage || defaultErrorResponse
            });
        };
    }

    handleLanguageChangeLocale() {
        switch (this.props.systemLanguage) {
            case 'EN':
                return localeEN;
            case 'ES':
                return localeES;
            case 'LT':
                return localeLT;
            case 'LV':
                return localeLV;
            case 'PL':
                return localePL;
            case 'RU':
                return localeRU;
            default:
                return localeEN;
        }
    }

    render() {
        if (
            this.props.sendPublicLocalization ||
            this.props.sendPublicLanguages ||
            this.props.sendPublicServiceAreasGET
        ) {
            return null;
        }
        const isAuth = cookie.getCookie();
        return (
            <ConfigProvider locale={this.handleLanguageChangeLocale()}>
                <Switch>
                    <Route
                        exact
                        path={routerPaths.login}
                        render={props =>
                            isAuth ? (
                                <Redirect to={routerPaths.partners.index} from={props.location} />
                            ) : (
                                <LoginContainer {...props} />
                            )
                        }
                    />
                    <Route component={LogoutContainer} exact path={routerPaths.logout} />
                    <Route
                        component={ResetPasswordContainer}
                        exact
                        path={routerPaths.public.resetPassword}
                    />
                    <Route
                        component={CreatePasswordContainer}
                        exact
                        path={routerPaths.public.createPassword}
                    />
                    <Route
                        component={ResetPasswordSuccessContainer}
                        exact
                        path={routerPaths.public.resetPasswordSuccess}
                    />
                    <Route
                        component={ValidateContainer}
                        exact
                        path={[
                            routerPaths.public.validate,
                            routerPaths.public.validateWithParams
                        ]}
                    />
                    {isAuth && (
                        <IdleTimer debounce={250} element={document} onIdle={this.onIdle} timeout={900000}>
                            <Layout style={{ minHeight: '100%' }}>
                                <Sider
                                    width={280}
                                    style={{
                                        position: 'fixed',
                                        height: '100vh',
                                        left: 0,
                                        overflow: 'auto'
                                    }}
                                >
                                    <PartMenu />
                                </Sider>
                                <Layout style={{ marginLeft: 280 }}>
                                    <Header className="header">
                                        <LoadingBar
                                            showFastActions
                                            style={{
                                                position: 'absolute',
                                                zIndex: 1000,
                                                bottom: 0,
                                                left: 0
                                            }}
                                        />
                                        <PartHeader />
                                    </Header>
                                    <Content style={{ paddingTop: 64 }}>
                                        <React.Fragment>
                                            <Route
                                                exact
                                                path={routerPaths.countries.languages}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <CountriesLanguagesContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.countries.settings}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <CountriesSettingsContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.internalClients.client}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <InternalClientContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.internalClients.create}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <InternalClientsCreateContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.internalClients.index}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <InternalClientsOdataContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.dashboard}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <DashboardContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.jobs.index}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <JobsContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.jobs.create}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <JobsCreateContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.jobs.job}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <JobContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.serviceGroups.serviceGroups}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <ServiceGroupsContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.serviceGroups.serviceGroupWithParams}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <ServiceGroupContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.services.services}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <ServicesContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.services.serviceItems}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <ServiceItemsContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.services.serviceItemsCreate}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <ServiceItemsCreateContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.services.serviceWithParams}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <ServiceContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.translates.labels}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <TranslatesLabelsContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.translates.labelsFrontend}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <TranslatesLabelsFrontendContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.translates.classificators}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <TranslatesClassificatorsContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.translates.contents}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <TranslatesContentsContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.translates.errors}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <TranslatesErrorsContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.partners.index}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <PartnersOdataContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.partners.create}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <PartnersCreateContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={[
                                                    routerPaths.partners.marketing,
                                                    routerPaths.partners.marketingAgreed
                                                ]}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <PartnersMarketingContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.countries.vats}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <CountryVatContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            <Route
                                                exact
                                                path={routerPaths.countries.administrationFeeMargins}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <CountryAdministrationFeeMargins {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                            {/* TODO: uncomment when ready */}
                                            {/* <Route
                                                exact
                                                path={routerPaths.countries.monthlyRewards}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.logout} from={props.location}>
                                                        <CountriesMonthlyRewards {...props} />
                                                    </RequireAuth>
                                                )}
                                            /> */}
                                            <Route
                                                exact
                                                path={[
                                                    routerPaths.partners.partner,
                                                    routerPaths.partners.account,
                                                    routerPaths.partners.adminActions,
                                                    routerPaths.partners.communications,
                                                    routerPaths.partners.documents,
                                                    routerPaths.partners.logs,
                                                    routerPaths.partners.profile,
                                                    routerPaths.partners.service,
                                                    routerPaths.partners.services,
                                                    routerPaths.partners.serviceItems,
                                                    routerPaths.partners.devices
                                                ]}
                                                render={props => (
                                                    <RequireAuth to={routerPaths.login} from={props.location}>
                                                        <PartnerContainer {...props} />
                                                    </RequireAuth>
                                                )}
                                            />
                                        </React.Fragment>
                                    </Content>
                                    <Footer className="text-center">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.footerTranslation.value
                                            }}
                                        />
                                    </Footer>
                                </Layout>
                            </Layout>
                        </IdleTimer>
                    )}
                    <Redirect from="*" to={routerPaths.login} />
                </Switch>
            </ConfigProvider>
        );
    }
}

App.propTypes = {
    footerTranslation: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    userLogin: PropTypes.object,
    sendPublicLanguages: PropTypes.bool.isRequired,
    sendPublicLocalization: PropTypes.bool.isRequired,
    sendPublicServiceAreasGET: PropTypes.bool.isRequired,
    systemCountry: PropTypes.string.isRequired,
    systemLanguage: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    const { systemCountry, systemLanguage } = state.systemReducers;
    const { sendPublicLanguages, sendPublicLocalization, sendPublicServiceAreasGET } = state.publicReducers;
    const { userLogin } = state.userReducers;
    const { footerTranslation } = state.translationReducers;

    return {
        footerTranslation,
        sendPublicLanguages,
        sendPublicLocalization,
        sendPublicServiceAreasGET,
        systemCountry,
        systemLanguage,
        userLogin
    };
}

const connectedApp = withRouter(connect(mapStateToProps)(App));
export { connectedApp as App };
