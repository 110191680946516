import React from 'react';
import { Tag } from 'antd';

import { Classificator } from '../../components';
import { colors, i18cl } from '../';

export const statusesDocument = (status) => {
    const classificator = <Classificator value={i18cl.DOCUMENT_VALIDITY_VALUE + status} />;

    switch (status) {
    case 'NO_LONGER_VALID':
        return <Tag color={colors.light}>{classificator}</Tag>;
    case 'REQUIRED':
        return <Tag color={colors.warning}>{classificator}</Tag>;
    case 'VALID':
        return <Tag color={colors.success}>{classificator}</Tag>;
    case 'WARNING':
        return <Tag color={colors.warning}>{classificator}</Tag>;
    case 'WILL_BE_VALID':
        return <Tag color={colors.warning}>{classificator}</Tag>;
    default:
        return null;
    }
};
