import { administrationFeeMarginsServices } from './services';
import { administrationFeeMarginsConstants } from './constants';
import { Message } from '../../../components/message';
import { i18, translate } from '../../../utilities';

export const administrationFeeMarginsActions = {
    getAdministrationFeeMargins,
    putAdministrationFeeMargins
};

function getAdministrationFeeMargins ({ dataCountryId, clientSegment }) {
    return dispatch => {
        dispatch(request());

        return administrationFeeMarginsServices.getAdministrationFeeMargins({ dataCountryId, clientSegment })
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: administrationFeeMarginsConstants.GET_ADMINISTRATION_FEE_MARGINS_REQUEST };
    }

    function success (data) {
        return {
            type: administrationFeeMarginsConstants.GET_ADMINISTRATION_FEE_MARGINS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: administrationFeeMarginsConstants.GET_ADMINISTRATION_FEE_MARGINS_ERROR,
            error
        };
    }
}

function putAdministrationFeeMargins ({ dataCountryId, clientSegment }, data) {
    return dispatch => {
        dispatch(request());

        return administrationFeeMarginsServices.putAdministrationFeeMargins({ dataCountryId, clientSegment }, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: administrationFeeMarginsConstants.PUT_ADMINISTRATION_FEE_MARGINS_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.AdministrationFeeMarginsUpdated)
        });

        return {
            type: administrationFeeMarginsConstants.PUT_ADMINISTRATION_FEE_MARGINS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: administrationFeeMarginsConstants.PUT_ADMINISTRATION_FEE_MARGINS_ERROR,
            error
        };
    }
}
