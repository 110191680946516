import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Select } from 'antd';

const { Option } = Select;

export const SelectFilterField = (props, confirm) => {
    const [value, setValue] = useState(props.data ? props.data.value : null);
    const reset = () => {
        setValue(null);

        props.setFilter({
            name: props.name,
            type: props.type,
            value: null
        });
    };
    const submit = () => {
        props.setFilter({
            name: props.name,
            type: props.type,
            value: value,
            operator: null
        }, true);
    };

    return (
        <>
            <div className='p-2'>
                <div className='filter_field_wrapper'>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        optionFilterProp='children'
                        onChange={(e) => {
                            setValue(e);
                        }}
                        value={value}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {props.options.map((item, index) =>
                            <Option
                                key={index}
                                value={item.value}
                            >
                                {item.label}
                            </Option>
                        )}
                    </Select>
                </div>
            </div>
            <div className='ant-table-filter-dropdown-btns'>
                <Button
                    size='small'
                    type='text'
                    onClick={() => {
                        confirm({ closeDropdown: true });
                        reset();
                    }}
                >
                    Reset
                </Button>
                <Button
                    size='small'
                    type='primary'
                    onClick={() => {
                        confirm({ closeDropdown: true });
                        submit();
                    }}
                >
                    Ok
                </Button>
            </div>
        </>
    );
};

SelectFilterField.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    confirm: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    data: PropTypes.object
};
