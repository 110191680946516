import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { i18, routerPaths } from '../../utilities';
import { history } from '../../api';
import { ButtonLink, Translate } from '../../components';
import { CreatePasswordForm } from './components';

export const CreatePasswordContainer = (props) => {
    return (
        <div className='row public_wrapper'>
            <div className='col-12 align-self-center public_content'>
                <div className='public_inner_sm'>
                    <div className='public_title'>
                        <Translate value={i18.UserPasswordForm.Titles.Create} />
                    </div>
                    <div className='public_reset_wrapper'>
                        <div className='public_reset_form'>
                            <CreatePasswordForm {...props}/>
                        </div>
                    </div>
                    <ButtonLink
                        className='p-0'
                        onClick={() => history.replace(routerPaths.login)}
                        icon={<ArrowLeftOutlined />}
                    >
                        <Translate value={i18.Reset.Buttons.Login} />
                    </ButtonLink>
                </div>
            </div>
        </div>
    );
};

CreatePasswordContainer.propTypes = {
    match: PropTypes.object.isRequired
};
