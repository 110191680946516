import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import './index.less';

class AvatarPicker extends React.Component {
    render () {
        return (
            <div className='avatar_picker'>
                <div className='avatar_picker_icon'>
                    <Avatar
                        size={32}
                        icon={<UserOutlined />}
                    />
                </div>
                <div>
                    <div className='avatar_picker_name'>{this.props.userLogin.firstName} {this.props.userLogin.lastName}</div>
                    <div className='avatar_picker_email'>{this.props.userLogin.loginName}</div>
                </div>
            </div>
        );
    }
}

AvatarPicker.propTypes = {
    userLogin: PropTypes.object.isRequired
};

function mapStateToProps (state) {
    const {
        userLogin
    } = state.userReducers;

    return {
        userLogin
    };
}

const connectedAvatarPicker = connect(mapStateToProps)(AvatarPicker);
export { connectedAvatarPicker as AvatarPicker };
