import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { history } from '../../api';

export const ContentHeader = props => {
    return (
        <div className="content_header">
            <div className="row align-items-center">
                {props.back && (
                    <div className="col-auto">
                        <Button
                            icon={<ArrowLeftOutlined />}
                            type="link"
                            size="large"
                            onClick={() => history.push(props.back, props.backState)}
                        />
                    </div>
                )}
                {props.avatar && <div className="col-auto">{props.avatar}</div>}
                <div className="col">
                    <div className="content_header_title">{props.title}</div>
                    <div className="content_header_description">{props.description}</div>
                </div>
            </div>
            <div>{props.extra}</div>
        </div>
    );
};

ContentHeader.propTypes = {
    avatar: PropTypes.object,
    back: PropTypes.string,
    backState: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    description: PropTypes.object,
    extra: PropTypes.any
};
