import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { Error, Title } from './';
import { Translate } from '../../translate';
import { classificatorArray } from '../../../utilities';

const Option = Select.Option;

export const MultipleSelectFormField = (props) => {
    return (
        <div className={props.inline ? 'form_field_wrapper row align-items-center' : 'form_field_wrapper row'}>
            <Title
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
            />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <Select
                    allowClear
                    placeholder={props.placeholder ? <Translate value={props.placeholder} /> : null}
                    disabled={props.disabled}
                    name={props.input.name}
                    mode='multiple'
                    loading={props.loading}
                    onChange={props.input.onChange}
                    value={props.input.value ? props.input.value : []}
                    style={{ width: '100%' }}
                    size={props.size}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) > -1}
                >
                    {props.options.map((item, index) =>
                        <Option value={props.optionsValue ? item[props.optionsValue || 'value'] : item.value} key={index}>
                            {props.optionsClassificator ? (
                                classificatorArray(props.optionsClassificator + item[props.optionsLabel || 'label'])
                            ) : (
                                props.optionsArray ? item : item[props.optionsLabel || 'label']
                            )}
                        </Option>
                    )}
                </Select>
            </div>
            <Error
                inline={props.inline}
                touched={props.meta.touched}
                error={props.meta.error}
            />
        </div>
    );
};

MultipleSelectFormField.propTypes = {
    loading: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    options: PropTypes.array,
    optionsArray: PropTypes.bool,
    optionsClassificator: PropTypes.string,
    optionsLabel: PropTypes.string,
    optionsValue: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.function
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    })
};
