import { languageServices } from './services';
import { languageConstants } from './constants';
import { Message } from '../../../components/message';
import { i18, translate } from '../../../utilities';

export const languageActions = {
    getCountriesLanguages,
    getAllLanguages,
    postCountriesLanguages
};

function getCountriesLanguages () {
    return dispatch => {
        dispatch(request());

        return languageServices.getCountriesLanguages()
            .then(
                data => dispatch(success([data])),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: languageConstants.GET_LANGUAGES_REQUEST };
    }

    function success (data) {
        return {
            type: languageConstants.GET_LANGUAGES_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: languageConstants.GET_LANGUAGES_ERROR,
            error
        };
    }
}

function getAllLanguages () {
    return dispatch => {
        dispatch(request());

        return languageServices.getAllLanguages()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: languageConstants.GET_ALL_LANGUAGES_REQUEST };
    }

    function success (data) {
        return {
            type: languageConstants.GET_ALL_LANGUAGES_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: languageConstants.GET_ALL_LANGUAGES_ERROR,
            error
        };
    }
}

function postCountriesLanguages (data) {
    return dispatch => {
        dispatch(request());

        return languageServices.postCountriesLanguages(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: languageConstants.POST_LANGUAGES_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.LanguagesUpdated)
        });

        return {
            type: languageConstants.POST_LANGUAGES_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: languageConstants.POST_LANGUAGES_ERROR,
            error
        };
    }
}
