import { requestApi, apiPaths, handleResponse, methods } from '../../config';

export const administrationFeeMarginsServices = {
    getAdministrationFeeMargins,
    putAdministrationFeeMargins
};

function getAdministrationFeeMargins ({ dataCountryId, clientSegment }) {
    return requestApi(`${apiPaths.administrationFeeMargins}${dataCountryId}/${clientSegment}`).then(handleResponse);
}

function putAdministrationFeeMargins ({ dataCountryId, clientSegment }, data) {
    return requestApi(`${apiPaths.administrationFeeMargins}${dataCountryId}/${clientSegment}`, {
        method: methods.put,
        data
    }).then(handleResponse);
}
