import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch } from 'antd';

import { i18 } from '../../../../../utilities';
import { Translate } from '../../../../../components';

const getStatusTranslationKey = (active) => {
    return active
        ? i18.PartnerServiceItems.Statuses.Active
        : i18.PartnerServiceItems.Statuses.Inactive;
};

export const PartnerServiceItemsList = (props) => {
    const handleActiveChange = (item) => {
        const copy = { ...item };
        copy.enabled = !copy.enabled;
        props.handleUpdate(copy);
    };

    const columns = [
        {
            title: <Translate value={i18.PartnerServiceItems.Labels.Status} />,
            render: (item) => <Translate value={getStatusTranslationKey(item.isValidService)} />
        }, {
            title: <Translate value={i18.PartnerServiceItems.Labels.Service} />,
            render: (item) => `${item.serviceCatalogCode} ${item.serviceCatalogName}`
        }, {
            title: <Translate value={i18.PartnerServiceItems.Labels.JobTranslation} />,
            dataIndex: 'serviceItemName'
        }, {
            title: <Translate value={i18.PartnerServiceItems.Labels.UnitOfMeasure} />,
            dataIndex: 'unitOfMeasure'
        }, {
            title: <Translate value={i18.PartnerServiceItems.Labels.Price} />,
            dataIndex: 'partnerPrice'
        }, {
            title: <Translate value={i18.PartnerServiceItems.Labels.Active} />,
            render: (item) => (
                <Switch
                    checked={item.enabled}
                    onChange={() => handleActiveChange(item)}
                    disabled={props.loading}
                />
            )
        }
    ];

    return (
        <Table
            rowKey='serviceItemId'
            dataSource={props.data || []}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content' }}
            loading={props.loading}
        />
    );
};

PartnerServiceItemsList.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    handleUpdate: PropTypes.func.isRequired
};
