import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { partnerProfileActions } from '../../api';
import { i18, randomUUID } from '../../utilities';
import { Translate } from '../../components';

class FileDownload extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: false
        };

        this.blob = this.blob.bind(this);
        this.download = this.download.bind(this);
    }

    blob (res) {
        const blob = new window.Blob([res.payload], { type: 'application/octet-stream' });
        saveAs(blob, this.props.name ? this.props.name : randomUUID());
    };

    download () {
        this.setState({ loading: true });

        this.props.dispatch(partnerProfileActions.getProfileDocument(this.props.profileId, this.props.documentId)).then(res => {
            if (res && res.payload) {
                this.blob(res);
            }

            this.setState({ loading: false });
        });
    }

    preview () {
        if (!this.props.canPreview) {
            return;
        }

        this.setState({ loading: true });

        this.props.dispatch(partnerProfileActions.getProfileDocumentPresignedUrl(this.props.profileId, this.props.documentId)).then(res => {
            if (res && res.payload) {
                window.open(res.payload, '_blank');
            }

            this.setState({ loading: false });
        });
    }

    render () {
        return (
            <Button
                icon={<DownloadOutlined />}
                onClick={() => this.download()}
                onContextMenu={(event) => { this.preview(); event.preventDefault(); return false; }}
                loading={this.state.loading}
                type={this.props.type ? this.props.type : 'link'}
                block={this.props.block}
            >
                <Translate value={i18.Files.Buttons.Download} />
            </Button>
        );
    }
}

FileDownload.propTypes = {
    block: PropTypes.bool,
    canPreview: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    profileId: PropTypes.number.isRequired,
    documentId: PropTypes.number.isRequired,
    name: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'text', 'danger', 'link', 'dashed'])
};

const connectedFileDownload = connect(null)(FileDownload);
export { connectedFileDownload as FileDownload };
