import { requestApi, apiPaths, methods, handleResponse, requestApiFormData } from '../../config';

export const jobCreateServices = {
    getJobCreationInputs,
    postJob
};

function getJobCreationInputs () {
    return requestApi(apiPaths.jobs.creationInputs).then(handleResponse);
}

function postJob (data) {
    return requestApiFormData(apiPaths.jobs.index, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}
