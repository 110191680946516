import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ButtonGroup, ButtonWrapper, ContentHeader, FormField, Translate, formFieldType } from '../../components';
import {
    classificator,
    countryCodes,
    formatNumber,
    getCountry,
    i18,
    i18cl,
    required,
    routerPaths,
    translate
} from '../../utilities';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { history, languageActions, serviceItemsActions, servicesActions } from '../../api';
import { Button, Col, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useServiceItemModal } from '../../hooks/useServiceItemModal';

const ServiceItemsCreateComponent = ({ dispatch, ...props }) => {
    const defaultLanguages = useMemo(() => {
        const country = getCountry();
        return country === countryCodes.EN ? [countryCodes.EN] : [country, countryCodes.EN];
    }, []);

    const [selectedLanguages, setSelectedLanguages] = useState(defaultLanguages);
    const serviceCatalogId = props.history.location.state.serviceCatalogId;
    const service = props.services.find(service => Number(service.serviceCatalogId) === Number(serviceCatalogId));
    const serviceGroupCode = service?.serviceCatalogCode;
    const serviceName = serviceGroupCode ? classificator(i18cl.SERVICES_CATALOG + serviceGroupCode) : '';
    const title = serviceName
        ? `${translate(i18.ServiceItemsCreate.Titles.ServiceItemsCreate)} ${serviceGroupCode} ${serviceName}`
        : translate(i18.ServiceItemsCreate.Titles.ServiceItemsCreate);
    const handleShowServiceItemModal = useServiceItemModal();

    useEffect(() => {
        dispatch(languageActions.getAllLanguages());
        dispatch(servicesActions.getServices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const addNewLanguage = useCallback(() => {
        const remainingLanguages = props.allLanguages.filter(lang => !selectedLanguages.includes(lang));
        setSelectedLanguages(prevLanguages => [...prevLanguages, remainingLanguages[0]]);
    }, [props.allLanguages, selectedLanguages]);

    const updateLanguage = useCallback((index, newLang) => {
        setSelectedLanguages(prevLanguages => prevLanguages.map((lang, i) => (i === index ? newLang : lang)));
    }, []);

    const renderLanguageFields = useMemo(() => {
        return selectedLanguages.map((code, index) => {
            const isRequiredLanguage = index < defaultLanguages.length;
            const options = props.allLanguages
                .filter(c => !selectedLanguages.includes(c) || c === code)
                .map(c => ({
                    value: c,
                    label: classificator(i18cl.LANGUAGES + c)
                }));

            return (
                <React.Fragment key={index}>
                    <FormField
                        inline
                        title={i18.ServiceItemsCreate.Labels.Language}
                        name={`language-${index}`}
                        component={formFieldType.select}
                        validate={required}
                        initialValue={code}
                        disabled={isRequiredLanguage}
                        options={options}
                        onChange={newLang => updateLanguage(index, newLang)}
                    />
                    <FormField
                        inline
                        title={i18.ServiceItemsCreate.Labels.JobTitle}
                        name={`jobTitle-${index}`}
                        component={formFieldType.input}
                        validate={required}
                    />
                    <FormField
                        inline
                        title={i18.ServiceItemsCreate.Labels.JobDescription}
                        name={`description-${index}`}
                        component={formFieldType.textarea}
                        maxLength={1500}
                    />
                    <FormField
                        inline
                        title={i18.ServiceItemsCreate.Labels.MeasuringUnit}
                        name={`unitOfMeasure-${index}`}
                        component={formFieldType.input}
                        validate={required}
                    />
                </React.Fragment>
            );
        });
    }, [defaultLanguages.length, props.allLanguages, selectedLanguages, updateLanguage]);

    const remainingLanguages = useMemo(
        () => props.allLanguages.filter(lang => !selectedLanguages.includes(lang)),
        [props.allLanguages, selectedLanguages]
    );

    const canAddLanguage = remainingLanguages.length > 0;

    const handleCreateItem = useCallback(
        async values => {
            const languageArray = [];
            let requestBody = {};

            // Loop over the form values
            for (const key in values) {
                if (Object.prototype.hasOwnProperty.call(values, key)) {
                    const splitKey = key.split('-');
                    const fieldName = splitKey[0];
                    const index = splitKey[1];

                    if (index) {
                        if (!languageArray[index]) {
                            languageArray[index] = {};
                        }

                        languageArray[index] = { ...languageArray[index], [fieldName]: values[key] };
                    } else {
                        requestBody = { ...requestBody, [fieldName]: values[key] };
                    }
                }
            }

            requestBody = { ...requestBody, translations: languageArray, serviceCode: serviceGroupCode };

            try {
                const response = await dispatch(serviceItemsActions.postServiceItemsV2(requestBody));
                history.replace(routerPaths.services.serviceItems, {
                    initialServiceCatalogId: serviceCatalogId
                });
                const jobTitle = languageArray[0].jobTitle;
                const jobDescription = languageArray[0].description;

                handleShowServiceItemModal(
                    serviceCatalogId,
                    serviceName,
                    response.serviceItemId,
                    jobTitle,
                    jobDescription
                );
            } catch {}
        },
        [handleShowServiceItemModal, dispatch, serviceCatalogId, serviceName, serviceGroupCode]
    );

    return (
        <div>
            <ContentHeader
                title={title}
                back={routerPaths.services.serviceItems}
                backState={{
                    initialServiceCatalogId: serviceCatalogId
                }}
            />
            <div className="content_block">
                <Form
                    onSubmit={handleCreateItem}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <div className="py-5">
                                <Translate className="fs-18" value={i18.ServiceItemsCreate.Titles.JobDescriptions} />
                            </div>
                            {renderLanguageFields}
                            {canAddLanguage && (
                                <div className="form_field_wrapper row align-items-center">
                                    <Col span={8} />
                                    <Button icon={<PlusOutlined />} type="link" onClick={addNewLanguage}>
                                        <Translate value={i18.ServiceItemsCreate.Buttons.AddLanguage} />
                                    </Button>
                                </div>
                            )}
                            <div className="py-5">
                                <Translate className="fs-18" value={i18.ServiceItemsCreate.Titles.PriceInformation} />
                            </div>
                            <FormField
                                inline
                                title={i18.ServiceItemsCreate.Labels.PartnerPrice}
                                name="price"
                                component={formFieldType.number}
                                validate={required}
                                formatNumber={{
                                    allowNegative: false,
                                    allowLeadingZeros: false,
                                    decimalScale: 2
                                }}
                            />
                            <FormField
                                inline
                                title={i18.ServiceItemsCreate.Labels.MinimalPartnerPrice}
                                name="minimalPrice"
                                component={formFieldType.number}
                                validate={required}
                                initialValue="20"
                                formatNumber={{
                                    allowNegative: false,
                                    allowLeadingZeros: false,
                                    decimalScale: 2
                                }}
                            />
                            <FormField
                                inline
                                title={i18.ServiceItemsCreate.Labels.Margin}
                                name="margin"
                                component={formFieldType.number}
                                formatNumber={formatNumber().percentPositive}
                                validate={required}
                            />
                            <FormField
                                inline
                                title={i18.ServiceItemsCreate.Labels.Coefficient}
                                name="coefficient"
                                component={formFieldType.number}
                                formatNumber={formatNumber().floatPositiveUpTo2Decimal}
                                validate={required}
                            />
                            <FormField
                                inline
                                title={i18.ServiceItemsCreate.Labels.PriceReduction}
                                name="priceReduction"
                                component={formFieldType.number}
                                formatNumber={formatNumber().percentPositive}
                            />
                            <div className="text-right mt-5">
                                <ButtonGroup>
                                    <ButtonWrapper
                                        type="text"
                                        onClick={() => {
                                            history.replace(routerPaths.services.serviceItems, {
                                                initialServiceCatalogId: serviceCatalogId
                                            });
                                        }}
                                    >
                                        <Translate value={i18.ServiceItemsCreate.Buttons.Cancel} />
                                    </ButtonWrapper>
                                    <Popconfirm
                                        placement="left"
                                        onConfirm={handleSubmit}
                                        title={<Translate value={i18.ServiceItemsCreate.Labels.ConfirmationTitle} />}
                                        okText={<Translate value={i18.ServiceItemsCreate.Labels.ConfirmationOkText} />}
                                        cancelText={
                                            <Translate value={i18.ServiceItemsCreate.Labels.ConfirmationCancelText} />
                                        }
                                    >
                                        <ButtonWrapper
                                            type="primary"
                                            htmlType="submit"
                                            loading={props.sendServiceItemsPOSTV2}
                                        >
                                            <Translate value={i18.ServiceItemsCreate.Buttons.Submit} />
                                        </ButtonWrapper>
                                    </Popconfirm>
                                </ButtonGroup>
                            </div>
                        </form>
                    )}
                />
            </div>
        </div>
    );
};

ServiceItemsCreateComponent.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    allLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
    services: PropTypes.arrayOf(PropTypes.object),
    sendServiceItemsPOSTV2: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        allLanguages: state.languageReducers.allLanguages,
        services: state.servicesReducers.services,
        sendServiceItemsPOSTV2: state.serviceItemsReducers.sendServiceItemsPOSTV2
    };
};

export const ServiceItemsCreateContainer = connect(mapStateToProps)(ServiceItemsCreateComponent);
