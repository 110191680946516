import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { history, jobCreateFormActions, odataActions } from '../../../api';
import { FormField, formFieldType } from '../../../components';
import { i18, oDataQuery, routerPaths } from '../../../utilities';
import { PartnersSelectionGrid } from '.';

class PartnerSelectionField extends React.Component {
    static defaultProps = {
        defaultFilter: {
            filters: [],
            orders: [],
            pagination: {
                current: 1,
                pageSize: 10
            }
        }
    }

    constructor (props) {
        super(props);

        this.state = {
            showPartnersGrid: false
        };

        this.onToggleModal = this.onToggleModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onOpenProfile = this.onOpenProfile.bind(this);
        this.fetch = this.fetch.bind(this);
        this.onPartnerSelected = this.onPartnerSelected.bind(this);
    }

    componentDidUpdate (prevProps) {
        if (this.props.selectedClientSegment !== prevProps.selectedClientSegment) {
            this.props.updateKeyValue(this.props.nameId, undefined);
            this.props.updateKeyValue(this.props.nameValue, undefined);

            this.props.dispatch(jobCreateFormActions.setJobPartnerId(undefined));
        }
    }

    fetch (filters) {
        this.props.dispatch(odataActions.getJobPartnersByClientSegmentGrid(this.props.selectedClientSegment, oDataQuery(filters)));
    }

    onToggleModal (e) {
        e.preventDefault();

        this.toggleModal();
    }

    toggleModal () {
        this.setState(prevState => ({
            showPartnersGrid: !prevState.showPartnersGrid
        }));
    }

    partneNameValue (partner) {
        const companySuffix = partner.CompanyName ? `(${partner.CompanyName})` : '';
        return `${partner.FirstName} ${partner.LastName} ${companySuffix}`;
    }

    onPartnerSelected (_, partner) {
        const nameValue = partner ? this.partneNameValue(partner) : undefined;
        this.props.updateKeyValue(this.props.nameId, partner.PartnerId);
        this.props.updateKeyValue(this.props.nameValue, nameValue);

        this.props.dispatch(jobCreateFormActions.setJobPartnerId(partner.PartnerId));

        this.toggleModal();
    }

    onOpenProfile (profileId) {
        history.push(routerPaths.partners.index + '/' + profileId + '/profile', {
            prevRoute: this.props.history.location.pathname,
            jobCreateFormReducers: this.props.jobCreateFormReducers,
            formValues: this.props.formValues
        });
    }

    render () {
        return (
            <div className={this.props.classNames}>
                <FormField
                    style={{ cursor: 'pointer' }}
                    placeholder={i18.JobCreateForm.Labels.PartnerNameCompanyName}
                    name={this.props.nameValue}
                    onFocus={e => e.currentTarget.blur()}
                    onClick={this.onToggleModal}
                    component={formFieldType.input}
                    disabled={false}
                />
                <Modal
                    visible={this.state.showPartnersGrid}
                    onCancel={this.onToggleModal}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                    destroyOnClose
                    width={'90%'}
                >
                    <PartnersSelectionGrid
                        multiplePartners={false}
                        odataPartnerGrid={this.props.odataJobPartnersByClientSegmentGrid}
                        odataPartnerGridLoading={this.props.sendOdataJobPartnersByClientSegmentGrid}
                        searchAggregateName={'SearchAggregate'}
                        onOpenProfile={this.onOpenProfile}
                        fetchOdata={this.fetch}
                        onPartnersSelected={this.onPartnerSelected}
                        selectedRowKeys={this.props.jobPartnerId ? [this.props.jobPartnerId] : undefined}
                        onCloseForm={this.onToggleModal}
                        defaultFilter={this.props.defaultFilter}
                    />
                </Modal>
            </div>
        );
    }
};

PartnerSelectionField.propTypes = {
    formValues: PropTypes.object.isRequired,
    nameValue: PropTypes.string.isRequired,
    nameId: PropTypes.string.isRequired,
    updateKeyValue: PropTypes.func.isRequired,
    classNames: PropTypes.string,
    odataJobPartnersByClientSegmentGrid: PropTypes.object.isRequired,
    jobCreateFormReducers: PropTypes.object,
    jobPartnerId: PropTypes.number,
    sendOdataJobPartnersByClientSegmentGrid: PropTypes.bool,
    selectedClientSegment: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    defaultFilter: PropTypes.object.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    })
};

function mapStateToProps (state) {
    const {
        odataJobPartnersByClientSegmentGrid,
        sendOdataJobPartnersByClientSegmentGrid
    } = state.odataReducers;

    const {
        jobPartnerId,
        selectedClientSegment
    } = state.jobCreateFormReducers;

    const jobCreateFormReducers = state.jobCreateFormReducers;

    return {
        jobCreateFormReducers,
        jobPartnerId,
        odataJobPartnersByClientSegmentGrid,
        selectedClientSegment,
        sendOdataJobPartnersByClientSegmentGrid
    };
}

const connectedPartnerSelectionField = withRouter(connect(mapStateToProps)(PartnerSelectionField));
export { connectedPartnerSelectionField as PartnerSelectionField };
