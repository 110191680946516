import { translationConstants } from './constants';

export const translationReducers = function (state = {
    translationClassificationsGroups: [],
    footerTranslation: {}
}, action) {
    switch (action.type) {
    case translationConstants.GET_TRANSLATION_ADMIN_FOOTER_REQUEST:
        return {
            ...state,
            send: true
        };
    case translationConstants.GET_TRANSLATION_ADMIN_FOOTER_SUCCESS:
        return {
            ...state,
            footerTranslation: action.payload,
            send: false
        };
    case translationConstants.GET_TRANSLATION_ADMIN_FOOTER_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false
        };
    case translationConstants.GET_CLASSIFICATION_GROUPS_REQUEST:
        return {
            ...state,
            send: true,
            sendTranslationClassificationGroups: true
        };
    case translationConstants.GET_CLASSIFICATION_GROUPS_SUCCESS:
        return {
            ...state,
            translationClassificationsGroups: action.payload,
            send: false,
            sendTranslationClassificationGroups: false
        };
    case translationConstants.GET_CLASSIFICATION_GROUPS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendTranslationClassificationGroups: false
        };
    case translationConstants.GET_CLASSIFICATIONS_REQUEST:
        return {
            ...state,
            send: true,
            sendTranslationClassifications: true
        };
    case translationConstants.GET_CLASSIFICATIONS_SUCCESS:
        return {
            ...state,
            translationClassifications: action.payload,
            send: false,
            sendTranslationClassifications: false
        };
    case translationConstants.GET_CLASSIFICATIONS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendTranslationClassifications: false
        };
    case translationConstants.PUT_CLASSIFICATION_REQUEST:
        return {
            ...state,
            send: true,
            sendTranslationClassificationsPUT: true
        };
    case translationConstants.PUT_CLASSIFICATION_SUCCESS:
        return {
            ...state,
            send: false,
            sendTranslationClassificationsPUT: false
        };
    case translationConstants.PUT_CLASSIFICATION_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendTranslationClassificationsPUT: false
        };
    case translationConstants.GET_CONTENT_REQUEST:
        return {
            ...state,
            send: true,
            sendTranslationContent: true
        };
    case translationConstants.GET_CONTENT_SUCCESS:
        return {
            ...state,
            send: false,
            sendTranslationContent: false
        };
    case translationConstants.GET_CONTENT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendTranslationContent: false
        };
    case translationConstants.PUT_CONTENT_REQUEST:
        return {
            ...state,
            send: true,
            sendTranslationContentPUT: true
        };
    case translationConstants.PUT_CONTENT_SUCCESS:
        return {
            ...state,
            send: false,
            sendTranslationContentPUT: false
        };
    case translationConstants.PUT_CONTENT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendTranslationContentPUT: false
        };
    case translationConstants.GET_ERROR_MESSAGES_REQUEST:
        return {
            ...state,
            send: true,
            sendTranslationErrorMessages: true
        };
    case translationConstants.GET_ERROR_MESSAGES_SUCCESS:
        return {
            ...state,
            translationErrorMessages: action.payload,
            send: false,
            sendTranslationErrorMessages: false
        };
    case translationConstants.GET_ERROR_MESSAGES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendTranslationErrorMessages: false
        };
    case translationConstants.PUT_ERROR_MESSAGE_REQUEST:
        return {
            ...state,
            send: true,
            sendTranslationErrorMessagesPUT: true
        };
    case translationConstants.PUT_ERROR_MESSAGE_SUCCESS:
        return {
            ...state,
            send: false,
            sendTranslationErrorMessagesPUT: false
        };
    case translationConstants.PUT_ERROR_MESSAGE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendTranslationErrorMessagesPUT: false
        };
    case translationConstants.GET_LABELS_REQUEST:
        return {
            ...state,
            send: true,
            sendTranslationLabels: true
        };
    case translationConstants.GET_LABELS_SUCCESS:
        return {
            ...state,
            send: false,
            sendTranslationLabels: false
        };
    case translationConstants.GET_LABELS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendTranslationLabels: false
        };
    case translationConstants.PUT_LABEL_REQUEST:
        return {
            ...state,
            send: true,
            sendTranslationLabelPUT: true
        };
    case translationConstants.PUT_LABEL_SUCCESS:
        return {
            ...state,
            send: false,
            sendTranslationLabelPUT: false
        };
    case translationConstants.PUT_LABEL_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendTranslationLabelPUT: false
        };
    default:
        return state;
    }
};
