import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FbImageLibrary from 'react-fb-image-grid';
import { Translate } from '../../../components';
import { Image } from 'antd';

export const JobImages = ({ photos, title }) => {
    const [visible, setVisible] = useState(false);
    const [current, setCurrent] = useState(0);

    if (!photos.length) {
        return null;
    }

    return (
        <>
            <Translate className="fs-16 fw_600" value={title} />
            <div className="mb-3" />
            <FbImageLibrary
                hideOverlay
                images={photos.map(item => item.mediumSizeImageUrl)}
                onClickEach={({ index }) => {
                    setVisible(vis => !vis);
                    setCurrent(index);
                }}
            />
            <div className="d-none">
                <Image.PreviewGroup preview={{ visible, onVisibleChange: setVisible, current }}>
                    {photos.map((p, i) => (
                        <Image key={i} src={p.hugeSizeImageUrl} loading="lazy" />
                    ))}
                </Image.PreviewGroup>
            </div>
        </>
    );
};

JobImages.propTypes = {
    photos: PropTypes.array.isRequired,
    title: PropTypes.string
};
