import React from 'react';

import { i18 } from '../i18';
import { Translate } from '../../components';

export const statusesBool = (bool) => {
    if (bool) {
        return (
            <Translate
                className='text-nowrap'
                value={i18.Statuses.Bool.True}
            />
        );
    } else {
        return (
            <Translate
                className='text-nowrap'
                value={i18.Statuses.Bool.False}
            />
        );
    }
};
