if (global.window && global.window.Element) {
    global.window.Element.prototype.remove = function () {
        this.parentElement.removeChild(this);
    };
}
if (global.window && global.window.NodeList) {
    global.window.NodeList.prototype.remove = global.window.HTMLCollection.prototype.remove = function () {
        for (var i = this.length - 1; i >= 0; i--) {
            if (this[i] && this[i].parentElement) {
                this[i].parentElement.removeChild(this[i]);
            }
        }
    };
}

if (!global.window.HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(global.window.HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
            var canvas = this;
            setTimeout(function () {
                var binStr = global.window.atob(canvas.toDataURL(type, quality).split(',')[1]);
                var len = binStr.length;
                var arr = new Uint8Array(len);

                for (var i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }

                callback(new global.window.Blob([arr], { type: type || 'image/jpg' }));
            });
        }
    });
}
