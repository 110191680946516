export const JobsStatusConstants = {
    APPROVED: 'APPROVED',
    CANCELED: 'CANCELED',
    COMPLETED: 'COMPLETED',
    FINISHED: 'FINISHED',
    INPROGRESS: 'INPROGRESS',
    NEW: 'NEW',
    PAUSED: 'PAUSED',
    PREORDER: 'PREORDER',
    PAID: 'PAID',
    RESOLVED: 'RESOLVED',
    UNKNOWN: 'UNKNOWN',
    WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
    CANCELED_BY_CLIENT: 'CANCELED_BY_CLIENT',
    CANCELED_BY_PARTNER: 'CANCELED_BY_PARTNER',
    WAITING_FOR_CUSTOMER_APPROVAL: 'WAITING_FOR_CUSTOMER_APPROVAL',
    PRIMARY: 'PRIMARY',
};
