import { JobsStatusConstants, BiddingTypesConstants } from '../../../api';

export const isJobAnOrder = (jobStatusCode) => {
    const orderStatuses = [
        JobsStatusConstants.NEW
    ];

    return orderStatuses.includes(jobStatusCode);
};

export const isJobAWork = (jobStatusCode) => {
    const workStatuses = [
        JobsStatusConstants.APPROVED,
        JobsStatusConstants.INPROGRESS,
        JobsStatusConstants.PAUSED,
        JobsStatusConstants.COMPLETED
    ];

    return workStatuses.includes(jobStatusCode);
};

export const isJobFactual = (jobBiddingType) => {
    return jobBiddingType === BiddingTypesConstants.FACTUAL;
};

export const isJobStandard = (jobBiddingType) => {
    return jobBiddingType === BiddingTypesConstants.STANDARD;
};

export const isJobFixed = (jobBiddingType) => {
    return jobBiddingType === BiddingTypesConstants.FIXED;
};