import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { i18 } from '../../../../../utilities';
import { Translate } from '../../../../../components';

export const PartnerDeviceList = (props) => {
    const columns = [
        {
            title: <Translate value={i18.PartnerDevicesList.Labels.Version} />,
            render: (item) => <span>{item.appVersion}</span>
        },
        {
            title: <Translate value={i18.PartnerDevicesList.Labels.BaseOS} />,
            render: (item) => <span>{item.baseOS}</span>
        },
        {
            title: <Translate value={i18.PartnerDevicesList.Labels.DeviceId} />,
            render: (item) => <span>{item.deviceId}</span>
        },
        {
            title: <Translate value={i18.PartnerDevicesList.Labels.DeviceName} />,
            render: (item) => <span>{item.deviceName}</span>
        }
    ];

    return (
        <Table
            rowKey="profileServiceId"
            columns={columns}
            size="large"
            rowClassName="cursor_pointer"
            dataSource={props.data}
            pagination={false}
        />
    );
};

PartnerDeviceList.propTypes = {
    data: PropTypes.array.isRequired
};
