import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from '../../../translate';

export const Title = props => {
    if (!props.title) return null;

    const generateContainerStyles = () => {
        if (props.justify) return { wrapper: 'form_field_title col-9 text-left text-md-left', container: '' };
        if (props.center) return { wrapper: 'd-flex align-items-center', container: '' };
        if (props.inline) return { wrapper: 'form_field_title col-12 col-md-4 text-left text-md-right', container: '' };
        return { wrapper: 'form_field_title col-12', container: 'd-flex justify-content-between' };
    };

    const { wrapper: containerWrapperStyles, container: containerStyles } = generateContainerStyles();

    let titleUi;

    if (props.borderTitle) {
        titleUi = <TitleContent dimmed={props.dimmed} title={props.title} titleTranslated={props.titleTranslated} />;
    } else {
        titleUi = (
            <div className="overflow-hidden">
                {props.titleTranslated ? props.title : <Translate value={props.title} />}
            </div>
        );
    }

    return (
        <div className={containerWrapperStyles}>
            <div className={containerStyles}>
                {titleUi}
                {props.required && <div className="form_field_required">*</div>}
            </div>
        </div>
    );
};

const TitleContent = props => {
    const classes = ['form_field_label', 'ml-2', 'pl-1', 'pr-1', 'bg-white-shrinked', 'color-border-title'];

    if (props.dimmed) classes.push('ant-input-dimmed');

    let titleUi;

    if (props.titleTranslated) {
        titleUi = props.title;
    } else {
        titleUi = <Translate value={props.title} />;
    }

    return (
        <div className={classes.join(' ')} style={{ zIndex: 1, marginBottom: '-14px' }}>
            <small>{titleUi}</small>
        </div>
    );
};

TitleContent.propTypes = {
    dimmed: PropTypes.bool,
    title: PropTypes.string,
    titleTranslated: PropTypes.bool
};

Title.propTypes = {
    borderTitle: PropTypes.bool,
    dimmed: PropTypes.bool,
    inline: PropTypes.bool,
    required: PropTypes.bool,
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    center: PropTypes.bool,
    justify: PropTypes.bool
};
