import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

export const responsiveBreakpoint = (size) => {
    switch (size) {
    case true:
        return true;
    case 'sm':
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useMediaQuery({ query: '(max-width: 575px)' });
    case 'md':
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useMediaQuery({ query: '(max-width: 767px)' });
    case 'lg':
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useMediaQuery({ query: '(max-width: 991px)' });
    case 'xl':
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useMediaQuery({ query: '(max-width: 1199px)' });
    case 'xxl':
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useMediaQuery({ query: '(max-width: 1599px)' });
    default:
        return null;
    }
};

responsiveBreakpoint.propTypes = {
    size: PropTypes.oneOf([true, 'sm', 'md', 'lg', 'xl', 'xxl']).isRequired
};