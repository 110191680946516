import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

export const ButtonLink = (props) => {
    return (
        <Button
            type='link'
            {...props}
        >
            {props.children}
        </Button>
    );
};

ButtonLink.propTypes = {
    icon: PropTypes.object,
    children: PropTypes.object
};
