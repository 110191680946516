import { odataConstants } from './constants';

export const odataReducers = function (
    state = {
        odataInternalClientsGrid: {},
        odataJobsGrid: {},
        odataJobBiddingsGrid: {},
        odataJobPartnersGrid: {},
        odataJobReassignPartnersGrid: {},
        odataJobPartnersByClientSegmentGrid: {},
        odataPartnersGrid: {}
    },
    action
) {
    switch (action.type) {
        case odataConstants.GET_ODATA_INTERNAL_CLIENTS_GRID_REQUEST:
            return {
                ...state,
                send: true,
                sendOdataInternalClientsGrid: true
            };
        case odataConstants.GET_ODATA_INTERNAL_CLIENTS_GRID_SUCCESS:
            return {
                ...state,
                odataInternalClientsGrid: action.payload,
                send: false,
                sendOdataInternalClientsGrid: false
            };
        case odataConstants.GET_ODATA_INTERNAL_CLIENTS_GRID_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendOdataInternalClientsGrid: false
            };
        case odataConstants.GET_ODATA_JOBS_GRID_REQUEST:
            return {
                ...state,
                send: true,
                sendOdataJobsGrid: true
            };
        case odataConstants.GET_ODATA_JOBS_GRID_SUCCESS:
            return {
                ...state,
                odataJobsGrid: action.payload,
                send: false,
                sendOdataJobsGrid: false
            };
        case odataConstants.GET_ODATA_JOBS_GRID_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendOdataJobsGrid: false
            };
        case odataConstants.GET_ODATA_JOB_PARTNERS_GRID_REQUEST:
            return {
                ...state,
                send: true,
                sendOdataJobPartnersGrid: true
            };
        case odataConstants.GET_ODATA_JOB_PARTNERS_GRID_SUCCESS:
            return {
                ...state,
                odataJobPartnersGrid: action.payload,
                send: false,
                sendOdataJobPartnersGrid: false
            };
        case odataConstants.GET_ODATA_JOB_PARTNERS_GRID_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendOdataJobPartnersGrid: false
            };
        case odataConstants.GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_REQUEST:
            return {
                ...state,
                send: true,
                sendOdataJobReassignPartnersGrid: true
            };
        case odataConstants.GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_SUCCESS:
            return {
                ...state,
                odataJobReassignPartnersGrid: action.payload,
                send: false,
                sendOdataJobReassignPartnersGrid: false
            };
        case odataConstants.GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendOdataJobReassignPartnersGrid: false
            };
        case odataConstants.GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_REQUEST:
            return {
                ...state,
                send: true,
                sendOdataJobPartnersByClientSegmentGrid: true
            };
        case odataConstants.GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_SUCCESS:
            return {
                ...state,
                odataJobPartnersByClientSegmentGrid: action.payload,
                send: false,
                sendOdataJobPartnersByClientSegmentGrid: false
            };
        case odataConstants.GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendOdataJobPartnersByClientSegmentGrid: false
            };
        case odataConstants.GET_ODATA_PARTNERS_GRID_REQUEST:
            return {
                ...state,
                send: true,
                sendOdataPartnersGrid: true
            };
        case odataConstants.GET_ODATA_PARTNERS_GRID_SUCCESS:
            return {
                ...state,
                odataPartnersGrid: action.payload,
                send: false,
                sendOdataPartnersGrid: false
            };
        case odataConstants.GET_ODATA_PARTNERS_GRID_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendOdataPartnersGrid: false
            };
        case odataConstants.GET_ODATA_JOB_BIDDINGS_GRID_REQUEST:
            return {
                ...state,
                send: true,
                sendOdataJobBiddingsGrid: true
            };
        case odataConstants.GET_ODATA_JOB_BIDDINGS_GRID_SUCCESS:
            return {
                ...state,
                odataJobBiddingsGrid: action.payload,
                send: false,
                sendOdataJobBiddingsGrid: false
            };
        case odataConstants.GET_ODATA_JOB_BIDDINGS_GRID_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendOdataJobBiddingsGrid: false
            };
        default:
            return state;
    }
};
