import React from 'react';
import PropTypes from 'prop-types';
import { FileTextOutlined } from '@ant-design/icons';

import { i18 } from '../../../utilities';
import { Translate, ButtonWrapper } from '../../../components';

export const JobFiles = ({ files, title }) => {
    if (!files.length) {
        return null;
    }

    return (
        <>
            <Translate className="fs-16 fw_600" value={title} />
            <div className="list_files mt-3">
                {files.map((item, index) => (
                    <div key={index} className="row no-gutters mb-2">
                        <div className="col">{item.fileName ? item.fileName : <FileTextOutlined />}</div>
                        <div className="col-auto">
                            <ButtonWrapper
                                type="primary"
                                href={item.documentUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Translate value={i18.Job.Buttons.Open} />
                            </ButtonWrapper>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

JobFiles.propTypes = {
    files: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
};
