import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { partnerReadActions } from '../../../../api';
import { PartnerAdminActionsList } from './';

class PartnerAdminActionsContainer extends React.Component {
    componentDidMount () {
        this.props.dispatch(partnerReadActions.getActivityLog(this.props.match.params.partnerId));
    }

    render () {
        return (
            <div className='content_block'>
                <PartnerAdminActionsList
                    data={this.props.partnerReadActivityLog}
                    loading={this.props.sendPartnerReadActivityLogGET}
                />
            </div>
        );
    }
}

PartnerAdminActionsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    partnerReadActivityLog: PropTypes.array.isRequired,
    sendPartnerReadActivityLogGET: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        partnerReadActivityLog,
        sendPartnerReadActivityLogGET
    } = state.partnerReadReducers;

    return {
        partnerReadActivityLog,
        sendPartnerReadActivityLogGET
    };
}

const connectedPartnerAdminActionsContainer = withRouter(connect(mapStateToProps)(PartnerAdminActionsContainer));
export { connectedPartnerAdminActionsContainer as PartnerAdminActionsContainer };
