import { monthlyRewardsServices } from './services';
import { monthlyRewardsConstants } from './constants';
import { Message } from '../../../components/message';
import { i18, translate } from '../../../utilities';

export const monthlyRewardsActions = {
    getMonthlyRewards,
    putMonthlyRewards
};

function getMonthlyRewards() {
    return dispatch => {
        dispatch(request());

        return monthlyRewardsServices.getMonthlyRewards().then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: monthlyRewardsConstants.GET_MONTHLY_REWARDS_REQUEST };
    }

    function success(data) {
        return {
            type: monthlyRewardsConstants.GET_MONTHLY_REWARDS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: monthlyRewardsConstants.GET_MONTHLY_REWARDS_ERROR,
            error
        };
    }
}

function putMonthlyRewards(data) {
    return dispatch => {
        dispatch(request());

        return monthlyRewardsServices.putMonthlyRewards(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: monthlyRewardsConstants.PUT_MONTHLY_REWARDS_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.MonthlyRewardsUpdated)
        });

        return {
            type: monthlyRewardsConstants.PUT_MONTHLY_REWARDS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: monthlyRewardsConstants.PUT_MONTHLY_REWARDS_ERROR,
            error
        };
    }
}
