export const userConstants = {
    LOGOUT: 'LOGOUT',

    POST_LOGIN_REQUEST: 'POST_LOGIN_REQUEST',
    POST_LOGIN_SUCCESS: 'POST_LOGIN_SUCCESS',
    POST_LOGIN_ERROR: 'POST_LOGIN_ERROR',

    POST_CHANGE_PASSWORD_REQUEST: 'POST_CHANGE_PASSWORD_REQUEST',
    POST_CHANGE_PASSWORD_SUCCESS: 'POST_CHANGE_PASSWORD_SUCCESS',
    POST_CHANGE_PASSWORD_ERROR: 'POST_CHANGE_PASSWORD_ERROR',

    POST_RESET_PASSWORD_REQUEST: 'POST_RESET_PASSWORD_REQUEST',
    POST_RESET_PASSWORD_SUCCESS: 'POST_RESET_PASSWORD_SUCCESS',
    POST_RESET_PASSWORD_ERROR: 'POST_RESET_PASSWORD_ERROR',

    POST_CREATE_PASSWORD_REQUEST: 'POST_CREATE_PASSWORD_REQUEST',
    POST_CREATE_PASSWORD_SUCCESS: 'POST_CREATE_PASSWORD_SUCCESS',
    POST_CREATE_PASSWORD_ERROR: 'POST_CREATE_PASSWORD_ERROR'
};
