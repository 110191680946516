import { getCountry } from '../../../utilities';
import { apiPaths, handleResponse, requestOdata } from '../../config';

export const odataServices = {
    getInternalClientsGrid,
    getJobGridQuery,
    getJobBiddingsGrid,
    getJobPartnersByClientSegmentGrid,
    getPartnersGrid,
    getJobPartnersGridForServiceAndClientSegment,
    getJobPartnersGridForService
};

function getInternalClientsGrid(query) {
    return requestOdata(`${apiPaths.odata.internalClientsGrid}('${getCountry().toLowerCase()}')${query || ''}`).then(
        handleResponse
    );
}

function getJobGridQuery(query) {
    return requestOdata(`${apiPaths.odata.jobsGrid}('${getCountry().toLowerCase()}')${query || ''}`).then(
        handleResponse
    );
}

function getPartnersGrid(query) {
    return requestOdata(`${apiPaths.odata.partnersGrid}('${getCountry().toLowerCase()}')${query || ''}`).then(
        handleResponse
    );
}

function getJobPartnersGridForServiceAndClientSegment(clientSegment, serviceId, query) {
    return requestOdata(
        `${
            apiPaths.odata.PartnerGridForServiceAndClientSegment
        }('${clientSegment}','${getCountry().toLowerCase()}',${serviceId})${query || ''}`
    ).then(handleResponse);
}

function getJobPartnersGridForService(serviceId, query) {
    return requestOdata(`${apiPaths.odata.PartnerGridForService}(${serviceId})${query || ''}`).then(handleResponse);
}

function getJobPartnersByClientSegmentGrid(clientSegment, query) {
    return requestOdata(
        `${apiPaths.odata.PartnerGridClientSegmentQuery}('${getCountry().toLowerCase()}','${clientSegment}')${
            query || ''
        }`
    ).then(handleResponse);
}

function getJobBiddingsGrid(jobId, query) {
    return requestOdata(
        `${apiPaths.odata.jobBiddingsGrid}('${getCountry().toLowerCase()}',${jobId})${query || ''}`
    ).then(handleResponse);
}
