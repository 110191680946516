import { serviceSettingsConstants } from './constants';

export const serviceSettingsReducers = function (state = {
    serviceSettings: [],
    serviceSetting: {}
}, action) {
    switch (action.type) {
    case serviceSettingsConstants.GET_SERVICE_SETTINGS_REQUEST:
        return {
            ...state,
            serviceSettings: action.update ? state.serviceSettings : [],
            send: true,
            sendServiceSettingsGET: true
        };
    case serviceSettingsConstants.GET_SERVICE_SETTINGS_SUCCESS:
        return {
            ...state,
            serviceSettings: action.payload,
            send: false,
            sendServiceSettingsGET: false
        };
    case serviceSettingsConstants.GET_SERVICE_SETTINGS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceSettingsGET: false
        };
    case serviceSettingsConstants.GET_SERVICE_SETTINGS_BY_ID_REQUEST:
        return {
            ...state,
            send: true,
            sendServiceSettingGET: true,
            sendServiceSettingId: action.serviceSettingId
        };
    case serviceSettingsConstants.GET_SERVICE_SETTINGS_BY_ID_SUCCESS:
        return {
            ...state,
            serviceSetting: action.payload,
            send: false,
            sendServiceSettingGET: false,
            sendServiceSettingId: null
        };
    case serviceSettingsConstants.GET_SERVICE_SETTINGS_BY_ID_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceSettingGET: false,
            sendServiceSettingId: null
        };
    case serviceSettingsConstants.POST_SERVICE_SETTINGS_REQUEST:
        return {
            ...state,
            send: true,
            sendServiceSettingPOST: true
        };
    case serviceSettingsConstants.POST_SERVICE_SETTINGS_SUCCESS:
        return {
            ...state,
            send: false,
            sendServiceSettingPOST: false
        };
    case serviceSettingsConstants.POST_SERVICE_SETTINGS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceSettingPOST: false
        };
    case serviceSettingsConstants.PUT_SERVICE_SETTINGS_REQUEST:
        return {
            ...state,
            send: true,
            sendServiceSettingPUT: true
        };
    case serviceSettingsConstants.PUT_SERVICE_SETTINGS_SUCCESS:
        return {
            ...state,
            send: false,
            sendServiceSettingPUT: false
        };
    case serviceSettingsConstants.PUT_SERVICE_SETTINGS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceSettingPUT: false
        };
    default:
        return state;
    }
};
