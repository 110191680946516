import React from 'react';
import moment from 'moment';

import json from '../configs/formats';
import { store } from '../api';
import { checkJsonExist } from './checks';
import { env } from '../configs';

const getSystemLang = () => store && checkJsonExist(store.getState(), 'systemReducers.systemLanguage')
    ? store.getState().systemReducers.systemLanguage
    : env.REACT_APP_DEFAULT_LANGUAGE;

const dateType = {
    date: 'date',
    time: 'time',
    dateTime: 'dateTime',
    dateTimeSecond: 'dateTimeSecond'
};

export const formatNumber = () => {
    const lang = getSystemLang();

    return {
        float: json[lang].format.numberFormat.float,
        floatPositive: json[lang].format.numberFormat.floatPositive,
        floatPositiveUpTo2Decimal: json[lang].format.numberFormat.floatPositiveUpTo2Decimal,
        float3Decimal: json[lang].format.numberFormat.float3Decimal,
        float3DecimalPositive: json[lang].format.numberFormat.float3DecimalPositive,
        float4Decimal: json[lang].format.numberFormat.float4Decimal,
        percent: json[lang].format.numberFormat.percent,
        percentPositive: json[lang].format.numberFormat.percentPositive,
        percent3Decimal: json[lang].format.numberFormat.percent3Decimal,
        percent4Decimal: json[lang].format.numberFormat.percent4Decimal,
        number: json[lang].format.numberFormat.number,
        numberPositive: json[lang].format.numberFormat.numberPositive
    };
};

export const dateFormatLocale = (lang = env.REACT_APP_DEFAULT_LANGUAGE) => {
    return json[lang].format.formatDate.locale;
};

export const dateFormat = () => {
    const lang = getSystemLang();

    return {
        locale: json[lang].format.formatDate.locale,
        date: dateType.date,
        time: dateType.time,
        dateTime: dateType.dateTime,
        dateTimeSecond: dateType.dateTimeSecond
    };
};

export const formatDate = (d, options = null, dateLocale = null) => {
    if (!d) {
        return null;
    }

    const date = d ? new Date(d) : null;
    options = options || dateFormat().date;

    if (isNaN(date.getDate())) {
        return d;
    }

    let format = '';
    moment.locale(dateFormat().locale);
    switch (options) {
    case dateType.date:
        format = moment.localeData().longDateFormat('L');
        break;
    case dateType.time:
        format = moment.localeData().longDateFormat('LT');
        break;
    case dateType.dateTime:
        format = moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LT');
        break;
    case dateType.dateTimeSecond:
        format = moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LTS');
        break;
    }

    const formattedDate = moment(date).format(format);
    return <span className='nowrap'>{formattedDate}</span>;
};

export const formatPercentage = (value = 0) => (value || Number.isFinite(value)) && `${Number(value)} %`;

export const formatNoSpaces = (value) => {
    if (!value) {
        return undefined;
    }
    return value.replace(/\s+/g, '');
};