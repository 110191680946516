import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from 'react-final-form';

import { classificatorArray, i18, i18cl, required, routerPaths } from '../../../utilities';
import { FormField, formFieldType, Translate } from '../../../components';
import { history } from '../../../api';

export const ServiceFilterForm = ({ submit, serviceCatalogId, services, submitting }) => {
    const initialRender = useRef(true);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            if (serviceCatalogId) {
                submit({ serviceGroupCode: serviceCatalogId });
            }
        }
    }, [submit, serviceCatalogId]);

    const handleButtonClick = useCallback(() => {
        history.push(routerPaths.services.serviceItemsCreate, {
            serviceCatalogId
        });
    }, [serviceCatalogId]);

    const options = useMemo(
        () =>
            classificatorArray(
                i18cl.SERVICES_CATALOG,
                services,
                'serviceCatalogCode',
                'serviceCatalogId',
                (newLabel, item) => {
                    if (item.serviceCatalogCode) {
                        return `${newLabel} (${item.serviceCatalogCode})`;
                    }

                    return newLabel;
                }
            ),
        [services]
    );

    return (
        <Form
            onSubmit={submit}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="mb-3" autoComplete="off">
                    <div className="row">
                        <div className="col">
                            <FormField
                                title={i18.ServiceItems.Labels.SelectService}
                                name="serviceGroupCode"
                                component={formFieldType.select}
                                options={options}
                                validate={required}
                                disabled={submitting}
                                initialValue={serviceCatalogId}
                            />
                        </div>
                    </div>
                    <div className="text-right">
                        <Button
                            type="primary"
                            htmlType="button"
                            className="mr-3"
                            disabled={!serviceCatalogId}
                            onClick={handleButtonClick}
                        >
                            <Translate value={i18.ServiceItems.Buttons.AddItem} />
                        </Button>
                        <Button icon={<SearchOutlined />} type="primary" htmlType="submit" loading={submitting}>
                            <Translate value={i18.ServiceItems.Buttons.SelectService} />
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

ServiceFilterForm.propTypes = {
    services: PropTypes.array.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    serviceCatalogId: PropTypes.number
};

export default React.memo(ServiceFilterForm);
