import { i18, translate } from '../../../utilities';
import { Message } from '../../../components';
import { servicesServices } from './services';
import { servicesConstants } from './constants';

export const servicesActions = {
    getServices,
    getServicesById,
    postServiceImage,
    postServices,
    putServices
};

function getServices() {
    return dispatch => {
        dispatch(request());

        return servicesServices.getServices().then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: servicesConstants.GET_SERVICES_REQUEST };
    }

    function success(data) {
        return {
            type: servicesConstants.GET_SERVICES_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: servicesConstants.GET_SERVICES_ERROR,
            error
        };
    }
}

function postServices(data) {
    return dispatch => {
        dispatch(request());

        return servicesServices.postServices(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: servicesConstants.POST_SERVICES_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.ServiceAdded)
        });

        return {
            type: servicesConstants.POST_SERVICES_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: servicesConstants.POST_SERVICES_ERROR,
            error
        };
    }
}

function putServices(data) {
    return dispatch => {
        dispatch(request());

        return servicesServices.putServices(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: servicesConstants.PUT_SERVICES_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.ServiceUpdated)
        });

        return {
            type: servicesConstants.PUT_SERVICES_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: servicesConstants.PUT_SERVICES_ERROR,
            error
        };
    }
}

function getServicesById(serviceCatalogId, update) {
    return dispatch => {
        dispatch(request());

        return servicesServices.getServicesById(serviceCatalogId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: servicesConstants.GET_SERVICES_BY_ID_REQUEST,
            update: update
        };
    }

    function success(data) {
        return {
            type: servicesConstants.GET_SERVICES_BY_ID_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: servicesConstants.GET_SERVICES_BY_ID_ERROR,
            error
        };
    }
}

function postServiceImage(serviceCatalogId, data) {
    return dispatch => {
        dispatch(request());

        return servicesServices.postServiceImage(serviceCatalogId, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: servicesConstants.POST_SERVICE_IMAGE_REQUEST
        };
    }

    function success(data) {
        return {
            type: servicesConstants.POST_SERVICE_IMAGE_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: servicesConstants.POST_SERVICE_IMAGE_ERROR,
            error
        };
    }
}
