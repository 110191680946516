import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';

import { i18 } from '../../../../utilities';
import { partnerManagementActions, partnerManagementConstants, partnerReadConstants, partnerReadActions } from '../../../../api';
import { ButtonWrapper, Translate } from '../../../../components';
import { PartnerCommunicationsData, PartnerCommunicationsForm } from './';

class PartnerCommunicationsContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            editing: false
        };

        this.fetch = this.fetch.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount () {
        this.fetch();
    }

    fetch () {
        this.props.dispatch(partnerReadActions.getCommunicationLog(this.props.match.params.partnerId)).then(res => {
            if (res && res.type === partnerReadConstants.GET_PARTNER_READ_COMMUNICATION_LOG_SUCCESS) {
                this.setState({ editing: false });
            }
        });
    }

    submit (values) {
        this.props.dispatch(partnerManagementActions.postCommunicationLog(this.props.match.params.partnerId, values)).then(res => {
            if (res && res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_SUCCESS) {
                this.fetch();
            }
        });
    }

    render () {
        return (
            <div className='content_block'>
                {!this.state.editing && (
                    <div className='mb-4 text-right'>
                        <ButtonWrapper
                            type='secondary'
                            icon={<EditOutlined />}
                            onClick={() => this.setState({ editing: true })}
                        >
                            <Translate value={i18.PartnerCommunications.Buttons.Edit} />
                        </ButtonWrapper>
                    </div>
                )}
                {this.state.editing ? (
                    <PartnerCommunicationsForm
                        cancel={() => this.setState({ editing: false })}
                        data={this.props.partnerReadCommunicationLog}
                        submit={this.submit}
                        submitting={this.props.sendPartnerManagementCommunicationLogPOST || this.props.sendPartnerReadCommunicationLogGET}
                    />
                ) : (
                    <PartnerCommunicationsData data={this.props.partnerReadCommunicationLog} />
                )}
            </div>
        );
    }
}

PartnerCommunicationsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    partnerReadCommunicationLog: PropTypes.object.isRequired,
    sendPartnerReadCommunicationLogGET: PropTypes.bool,
    sendPartnerManagementCommunicationLogPOST: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        sendPartnerManagementCommunicationLogPOST
    } = state.partnerManagementReducers;
    const {
        partnerReadCommunicationLog,
        sendPartnerReadCommunicationLogGET
    } = state.partnerReadReducers;

    return {
        partnerReadCommunicationLog,
        sendPartnerReadCommunicationLogGET,
        sendPartnerManagementCommunicationLogPOST
    };
}

const connectedPartnerCommunicationsContainer = withRouter(connect(mapStateToProps)(PartnerCommunicationsContainer));
export { connectedPartnerCommunicationsContainer as PartnerCommunicationsContainer };
