import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import { Error, Title } from './';
import { classificator } from '../../../utilities';
import { Translate } from '../../translate';

export const MultipleCheckboxFormField = (props) => {
    return (
        <div className='form_field_wrapper row'>
            <Title
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
                required={props.required}
            />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <Checkbox.Group onChange={props.input.onChange} value={props.input.value} className={`d-flex flex-wrap ${!props.inline ? 'flex-column' : ''}`}>
                    {props.options.map((item, index) =>
                        <div
                            className={props.optionsClass}
                            key={index}
                        >
                            <Checkbox
                                disabled={props.disabled}
                                value={item[props.optionsValue || 'value']}
                            >
                                {props.optionsClassificator ? (
                                    classificator(props.optionsClassificator + item[props.optionsLabel || 'label'])
                                ) : (
                                    <Translate className={'font-weight-light'} value={ item[props.optionsLabel || 'label']} />
                                )}
                            </Checkbox>
                        </div>
                    )}
                </Checkbox.Group>
            </div>
            <Error
                touched={props.meta.touched}
                error={props.meta.error}
            />
        </div>
    );
};

MultipleCheckboxFormField.propTypes = {
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    input: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.any
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    }),
    options: PropTypes.array,
    optionsClass: PropTypes.string,
    optionsClassificator: PropTypes.string,
    optionsLabel: PropTypes.string,
    optionsValue: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    titleTranslated: PropTypes.bool
};
