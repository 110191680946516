import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { serviceSettingsActions, serviceSettingsConstants } from '../../../api';
import { getCurrentDate, i18, i18cl, required } from '../../../utilities';
import { Classificator, FormField, formFieldType, Translate } from '../../../components';

class ServiceSettingsEditForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.title = this.title.bind(this);
        this.toggle = this.toggle.bind(this);
        this.submit = this.submit.bind(this);
    }

    toggle(state) {
        if (state) {
            this.props
                .dispatch(
                    serviceSettingsActions.getServiceSettingsById(
                        this.props.serviceCatalogId,
                        this.props.serviceSettingId
                    )
                )
                .then(res => {
                    if (res && res.type === serviceSettingsConstants.GET_SERVICE_SETTINGS_BY_ID_SUCCESS) {
                        this.setState({ visible: true });
                    }
                });
        } else {
            this.setState({ visible: false });
        }
    }

    submit(values) {
        this.props
            .dispatch(serviceSettingsActions.putServiceSettings(this.props.serviceCatalogId, values))
            .then(res => {
                if (res && res.type === serviceSettingsConstants.PUT_SERVICE_SETTINGS_SUCCESS) {
                    this.toggle();
                    this.props.dispatch(serviceSettingsActions.getServiceSettings(this.props.serviceCatalogId, true));
                }
            });
    }

    title() {
        if (this.props.serviceSetting && this.props.serviceSetting.classificatorList) {
            const classificator = this.props.serviceSetting.classificatorList.find(
                e => e.classificatorCode === this.props.serviceSetting.classificatorCode
            );
            if (classificator && classificator.classificatorCodeForValues) {
                return (
                    <Classificator
                        value={classificator.classificatorCodeForValues + '.' + this.props.serviceSetting.value}
                    />
                );
            } else {
                return this.props.serviceSetting.value;
            }
        } else {
            return this.props.serviceSetting.value;
        }
    }

    render() {
        return (
            <>
                <Button
                    type="link"
                    icon={<EditOutlined />}
                    loading={this.props.sendServiceSettingId === this.props.serviceSettingId}
                    disabled={this.props.sendServiceSettingGET}
                    onClick={() => this.toggle(true)}
                >
                    <Translate value={i18.ServiceSettingsEditForm.Buttons.Edit} />
                </Button>
                <Modal
                    title={
                        this.props.serviceSetting && (
                            <>
                                <div>{this.title()}</div>
                                <Classificator
                                    className="fs-14 color-secondary"
                                    value={i18cl.SETTINGS_SERVICE + this.props.serviceSetting.classificatorCode}
                                />
                            </>
                        )
                    }
                    visible={this.state.visible}
                    onCancel={() => this.toggle(false)}
                    footer={null}
                    maskClosable={false}
                >
                    <Form
                        onSubmit={this.submit}
                        initialValues={this.props.serviceSetting}
                        render={({ handleSubmit, values }) => (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServiceSettingsEditForm.Labels.ValidFrom}
                                    name="validFrom"
                                    validate={required}
                                    disabled={getCurrentDate() > values.validFrom || this.props.sendServiceSettingPUT}
                                    minDate={{
                                        date: getCurrentDate()
                                    }}
                                    maxDate={{
                                        date: values.validTo
                                    }}
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServiceSettingsEditForm.Labels.ValidTo}
                                    name="validTo"
                                    disabled={this.props.sendServiceSettingPUT}
                                    minDate={{
                                        date: values.validFrom || getCurrentDate()
                                    }}
                                />
                                <div className="text-right">
                                    <Button type="primary" htmlType="submit" loading={this.props.sendServiceSettingPUT}>
                                        <Translate value={i18.ServiceSettingsEditForm.Buttons.Submit} />
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </Modal>
            </>
        );
    }
}

ServiceSettingsEditForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    serviceSetting: PropTypes.object,
    serviceCatalogId: PropTypes.string.isRequired,
    serviceSettingId: PropTypes.number.isRequired,
    sendServiceSettingGET: PropTypes.bool,
    sendServiceSettingPUT: PropTypes.bool,
    sendServiceSettingId: PropTypes.number
};

function mapStateToProps(state) {
    const { serviceSetting, sendServiceSettingGET, sendServiceSettingPOST, sendServiceSettingId } =
        state.serviceSettingsReducers;

    return {
        serviceSetting,
        sendServiceSettingGET,
        sendServiceSettingPOST,
        sendServiceSettingId
    };
}

const connectedServiceSettingsEditForm = connect(mapStateToProps)(ServiceSettingsEditForm);
export { connectedServiceSettingsEditForm as ServiceSettingsEditForm };
