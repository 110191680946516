import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { servicesActions, servicesConstants } from '../../../api';
import { classificatorArray, getCurrentDate, i18, i18cl, required, getContentString } from '../../../utilities';
import { FormField, formFieldType, Translate } from '../../../components';

class ServicesForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.toggle = this.toggle.bind(this);
        this.submit = this.submit.bind(this);
        this.constructVatOptions = this.constructVatOptions.bind(this);
    }

    toggle(state) {
        if (state) {
            this.props.dispatch(servicesActions.getServicesById(0)).then(res => {
                if (res && res.type === servicesConstants.GET_SERVICES_BY_ID_SUCCESS) {
                    this.setState({ visible: true });
                }
            });
        } else {
            this.setState({ visible: false });
        }
    }

    submit(values) {
        const {
            countryVats: { vats }
        } = this.props;
        if (Object.prototype.hasOwnProperty.call(values, 'vatName') && vats.length > 0) {
            const selectedVat = vats.find(v => v.name === values.vatName);
            values.vatPercentage = selectedVat?.percentage;
        }

        this.props.dispatch(servicesActions.postServices(values)).then(res => {
            if (res && res.type === servicesConstants.POST_SERVICES_SUCCESS) {
                this.toggle();
                this.props.dispatch(servicesActions.getServices());
            }
        });
    }

    constructVatOptions() {
        const {
            countryVats: { vats }
        } = this.props;
        return vats.map(vat => ({
            value: vat.name,
            label: getContentString(`${i18cl.INVOICING_VAT}${vat.name}`)
        }));
    }

    render() {
        return (
            <>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    loading={this.props.sendServiceCatalogId === 0}
                    disabled={this.props.sendServiceGET}
                    onClick={() => this.toggle(true)}
                >
                    <Translate value={i18.ServicesForm.Buttons.Add} />
                </Button>
                <Modal
                    title={<Translate value={i18.ServicesForm.Titles.Add} />}
                    visible={this.state.visible}
                    onCancel={() => this.toggle(false)}
                    footer={null}
                    maskClosable={false}
                >
                    <Form
                        onSubmit={this.submit}
                        render={({ handleSubmit, values }) => (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <FormField
                                    component={formFieldType.select}
                                    title={i18.ServicesForm.Labels.Group}
                                    options={classificatorArray(
                                        i18cl.GROUPS_SERVICE,
                                        this.props.service.serviceGroupList
                                    )}
                                    disabled={this.props.sendServicesPOST}
                                    name="serviceGroup"
                                    validate={required}
                                />
                                <FormField
                                    component={formFieldType.select}
                                    title={i18.ServicesForm.Labels.Service}
                                    options={classificatorArray(
                                        i18cl.SERVICES_CATALOG,
                                        this.props.service.serviceCatalogList
                                    )}
                                    disabled={this.props.sendServicesPOST}
                                    name="serviceCatalogCode"
                                    validate={required}
                                />
                                <FormField
                                    component={formFieldType.input}
                                    title={i18.ServicesForm.Labels.ExternalServiceID}
                                    disabled={this.props.sendServicesPOST}
                                    name="externalServiceID"
                                />
                                {!this.props.featureToggles.allowVatEditingForJob && (
                                    <FormField
                                        title={i18.ServicesForm.Labels.VatPercentage}
                                        name="vatName"
                                        options={this.constructVatOptions()}
                                        component={formFieldType.select}
                                        disabled={this.props.sendServicesPOST}
                                    />
                                )}
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServicesForm.Labels.ValidFrom}
                                    name="validFrom"
                                    validate={required}
                                    disabled={this.props.sendServicesPOST}
                                    minDate={{
                                        date: getCurrentDate(0)
                                    }}
                                    maxDate={{
                                        date: values.validTo
                                    }}
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServicesForm.Labels.ValidTo}
                                    name="validTo"
                                    disabled={this.props.sendServicesPOST}
                                    minDate={{
                                        date: values.validFrom || getCurrentDate()
                                    }}
                                />
                                <div className="text-right">
                                    <Button type="primary" htmlType="submit" loading={this.props.sendServicesPOST}>
                                        <Translate value={i18.ServicesForm.Buttons.Submit} />
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </Modal>
            </>
        );
    }
}

ServicesForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    service: PropTypes.object,
    sendServiceGET: PropTypes.bool,
    sendServicesPOST: PropTypes.bool,
    sendServiceCatalogId: PropTypes.number,
    countryVats: PropTypes.object,
    featureToggles: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const { service, sendServiceGET, sendServicesPOST, sendServiceCatalogId } = state.servicesReducers;
    const { countryVats } = state.countryVatReducers;
    const { featureToggles } = state.featureTogglesReducers;

    return {
        service,
        sendServiceGET,
        sendServicesPOST,
        sendServiceCatalogId,
        countryVats,
        featureToggles
    };
}

const connectedServicesForm = connect(mapStateToProps)(ServicesForm);
export { connectedServicesForm as ServicesForm };
