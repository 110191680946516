import { requestApi, apiPaths, handleResponse, methods } from '../../config';

export const partnerManagementServices = {
    postAssignMeAsManager,
    postChangeBlockStatus,
    postChangeLockStatus,
    postToggleKycVerified,
    postPartnerJobSources,
    postChangeStatus,
    postCommunicationLog,
    postDetails,
    postResendEmailConfirmation,
    postProfile,
    postIsAutoBidEnabled,
    patchToggleMtc,
    patchToggleGreenCard
};

function postAssignMeAsManager (partnerId) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/assignMeAsManager', {
        method: methods.post
    }).then(handleResponse);
}

function postChangeBlockStatus (partnerId, data) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/changeBlockStatus', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postChangeLockStatus (partnerId) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/changeLockStatus', {
        method: methods.post
    }).then(handleResponse);
}

function postToggleKycVerified (partnerId) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/toggleKycVerified', {
        method: methods.post
    }).then(handleResponse);
}

function postPartnerJobSources (partnerId, data) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/changeJobSources', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postChangeStatus (partnerId, data) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/changeStatus', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postCommunicationLog (partnerId, data) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/communicationLog', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postDetails (partnerId, data) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/details', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postResendEmailConfirmation (partnerId) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/resendEmailConfirmation', {
        method: methods.post
    }).then(handleResponse);
}

function postProfile (partnerId, data) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/profile', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postIsAutoBidEnabled (partnerId) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/toggleAutoBid', {
        method: methods.post
    }).then(handleResponse);
}

function patchToggleMtc(partnerId) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/toggleAllowedSellMTCMaterials', {
        method: methods.post
    }).then(handleResponse);
}

function patchToggleGreenCard(partnerId) {
    return requestApi(apiPaths.partnerManagement + partnerId + '/toggleHasGreenCard', {
        method: methods.post
    }).then(handleResponse);
}
