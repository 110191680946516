import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Menu } from 'antd';

import { i18, routerPaths } from '../../../utilities';
import { Translate } from '../../../components';
import { history } from '../../../api';

class PartnersMarketingMenu extends React.Component {
    constructor (props) {
        super(props);

        this.select = this.select.bind(this);
    }

    select (path) {
        history.replace(path.key);
    }

    render () {
        const menu = [
            {
                title: i18.PartnersMarketing.Menu.Marketing,
                path: routerPaths.partners.marketing
            }, {
                title: i18.PartnersMarketing.Menu.MarketingAgreed,
                path: routerPaths.partners.marketingAgreed
            }
        ];

        return (
            <Menu
                selectedKeys={[this.props.location.pathname]}
                mode='horizontal'
                onSelect={this.select}
                className='px-2'
            >
                {menu.map((item) =>
                    <Menu.Item
                        key={item.path}
                    >
                        <Translate value={item.title} />
                    </Menu.Item>
                )}
            </Menu>
        );
    };
}

PartnersMarketingMenu.propTypes = {
    backState: PropTypes.object,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

const routedPartnersMarketingMenu = withRouter(PartnersMarketingMenu);
export { routedPartnersMarketingMenu as PartnersMarketingMenu };
