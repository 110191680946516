export const internalClientConstants = {
    POST_INTERNAL_CLIENT_REQUEST: 'POST_INTERNAL_CLIENT_REQUEST',
    POST_INTERNAL_CLIENT_SUCCESS: 'POST_INTERNAL_CLIENT_SUCCESS',
    POST_INTERNAL_CLIENT_ERROR: 'POST_INTERNAL_CLIENT_ERROR',

    PUT_INTERNAL_CLIENT_REQUEST: 'PUT_INTERNAL_CLIENT_REQUEST',
    PUT_INTERNAL_CLIENT_SUCCESS: 'PUT_INTERNAL_CLIENT_SUCCESS',
    PUT_INTERNAL_CLIENT_ERROR: 'PUT_INTERNAL_CLIENT_ERROR',

    GET_INTERNAL_CLIENT_REQUEST: 'GET_INTERNAL_CLIENT_REQUEST',
    GET_INTERNAL_CLIENT_SUCCESS: 'GET_INTERNAL_CLIENT_SUCCESS',
    GET_INTERNAL_CLIENT_ERROR: 'GET_INTERNAL_CLIENT_ERROR'
};
