import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { history, odataActions } from '../../../api';
import { oDataQuery, routerPaths } from '../../../utilities';
import { PartnersSelectionGrid } from './partnersSelectionGrid';

class PartnerSelectionForm extends React.Component {
    static defaultProps = {
        defaultFilter: {
            filters: [],
            orders: [],
            pagination: {
                current: 1,
                pageSize: 10
            }
        }
    }

    constructor (props) {
        super(props);

        this.onOpenProfile = this.onOpenProfile.bind(this);
        this.fetch = this.fetch.bind(this);
        this.onConfirmPartnersSelection = this.onConfirmPartnersSelection.bind(this);
    }

    componentDidUpdate (prevProps) {
        if (this.props.serviceId !== prevProps.serviceId) {
            this.fetch(this.props.defaultFilter);
        }
    }

    fetch (filters) {
        const { clientSegment, serviceId, dispatch } = this.props;

        dispatch(odataActions.getJobPartnersGridForServiceAndClientSegment(clientSegment, serviceId, oDataQuery(filters)));
    }

    onOpenProfile (profileId) {
        history.push(routerPaths.partners.index + '/' + profileId + '/profile', {
            prevRoute: this.props.history.location.pathname,
            jobCreateFormReducers: this.props.jobCreateFormReducers,
            formValues: this.props.formValues
        });
    }

    onConfirmPartnersSelection (selectedPartnerIds) {
        this.props.onPartnersSelected(selectedPartnerIds);
    }

    render () {
        return (
            <>
                <PartnersSelectionGrid
                    multiplePartners
                    odataPartnerGrid={this.props.odataJobPartnersGrid}
                    odataPartnerGridLoading={this.props.sendOdataJobPartnersGrid}
                    searchAggregateName={'SearchAggregate'}
                    onOpenProfile={this.onOpenProfile}
                    fetchOdata={this.fetch}
                    onPartnersSelected={this.onConfirmPartnersSelection}
                    onCloseForm={this.props.onCloseForm}
                    selectedRowKeys={this.props.selectedRowKeys}
                    defaultFilter={this.props.defaultFilter}
                />
            </>
        );
    }
};

PartnerSelectionForm.propTypes = {
    formValues: PropTypes.object.isRequired,
    jobCreateFormReducers: PropTypes.object,
    serviceId: PropTypes.number.isRequired,
    clientSegment: PropTypes.string.isRequired,
    selectedRowKeys: PropTypes.array,
    onPartnersSelected: PropTypes.func.isRequired,
    onCloseForm: PropTypes.func.isRequired,
    defaultFilter: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    odataJobPartnersGrid: PropTypes.object.isRequired,
    sendOdataJobPartnersGrid: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        odataJobPartnersGrid,
        sendOdataJobPartnersGrid
    } = state.odataReducers;

    const jobCreateFormReducers = state.jobCreateFormReducers;

    return {
        odataJobPartnersGrid,
        sendOdataJobPartnersGrid,
        jobCreateFormReducers
    };
}

const connectedPartnerSelectionForm = withRouter(connect(mapStateToProps)(PartnerSelectionForm));
export { connectedPartnerSelectionForm as PartnerSelectionForm };
