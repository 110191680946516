export const toSeconds = (days = 0, hours = 0, minutes = 0, seconds = 0) => {
    return (days * 86400) + (hours * 3600) + (minutes * 60) + seconds;
};

export const toDaysString = (seconds = 0) => {
    const days = Math.floor(seconds / 86400);
    seconds -= days * 86400;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return `${days} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};
