import React from 'react';

import { i18 } from '../../utilities';
import { Translate } from '../../components';
import { ResetPasswordInformation } from './index';

export const ResetPasswordSuccessContainer = () => {
    return (
        <div className='row public_wrapper'>
            <div className='col-12 align-self-center public_content'>
                <ResetPasswordInformation
                    title={<Translate value={i18.ResetSuccess.Titles.Success} />}
                    buttonText={<Translate value={i18.ResetSuccess.Buttons.Login} />}
                />
            </div>
        </div>  
    );
};
