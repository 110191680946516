import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { Error, Title } from '.';
import { Translate } from '../..';
import { i18, getCountry } from '../../../utilities';

export const LocationAutoComplete = (props) => {
    function getSearchOptions () {
        return {
            componentRestrictions: {
                country: [getCountry()]
            },
            types: ['address']
        };
    }

    function onAddressSelected (address) {
        geocodeByAddress(address)
            .then(results => results[0].formatted_address)
            .then(address => props.input.onChange(address));
    };

    return (
        <div className={props.inline ? 'form_field_wrapper row align-items-center' : 'form_field_wrapper row'}>
            <Title
                inline={props.inline}
                required={props.required}
                title={props.title}
                titleDescription={props.titleDescription}
                titleTranslated={props.titleTranslated}
            />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <PlacesAutocomplete
                    value={props.input.value}
                    name={props.input.name}
                    onChange={props.input.onChange}
                    searchOptions={getSearchOptions()}
                    onSelect={onAddressSelected}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <Input
                                style={{ width: '100%' }}
                                title={props.title}
                                maxLength={150}
                                {...getInputProps({
                                    placeholder: props.titleDescription
                                })}
                                disabled={props.disabled}
                                value={props.input.value}
                            />
                            <Error
                                error={props.meta.error}
                                inline={props.inline}
                                touched={props.meta.touched}
                            />
                            {suggestions.length > 0 && <div className='search_input_vertical_container'>
                                {loading &&
                                    <Translate value={i18.Loader.Titles.Default} />
                                }
                                {suggestions.map((suggestion) => {
                                    const className = 'location-suggestion-item';
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className
                                            })}
                                            key={suggestion.placeId}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>}
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
            <div className='form_field_wrapper' />
        </div>
    );
};

LocationAutoComplete.propTypes = {
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    input: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.any
    }),
    maxLength: PropTypes.number,
    meta: PropTypes.shape({
        error: PropTypes.any,
        touched: PropTypes.bool
    }),
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    titleDescription: PropTypes.string,
    titleTranslated: PropTypes.bool
};
