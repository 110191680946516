import { getToken } from '../../../utilities';
import cookie from '../../../utilities/cookie';
import { requestApi, requestApiFormData, requestOdata } from '../../config';
import { userConstants } from './constants';

export const userReducers = function (state = {}, action) {
    switch (action.type) {
    case userConstants.POST_LOGIN_REQUEST:
        return {
            ...state,
            send: true,
            sendUserLogin: true,
            userLogin: state.userLogin
        };
    case userConstants.POST_LOGIN_SUCCESS:
        if (action.payload) {
            window.localStorage.setItem('userAdmin', JSON.stringify(action.payload));
            cookie.setCookie(action.payload.token);
            requestApi.defaults.headers.common.Authorization = getToken();
            requestApiFormData.defaults.headers.common.Authorization = getToken();
            requestOdata.defaults.headers.common.Authorization = getToken();

            return {
                ...state,
                userLogin: action.payload,
                send: false,
                sendUserLogin: false
            };
        } else {
            return {
                ...state,
                userLogin: null,
                send: false,
                sendUserLogin: false
            };
        }
    case userConstants.POST_LOGIN_ERROR:
        return {
            ...state,
            userLogin: null,
            errors: action.error,
            send: false,
            sendUserLogin: false
        };
    case userConstants.LOGOUT:
        requestApi.defaults.headers.common.Authorization = null;
        requestApiFormData.defaults.headers.common.Authorization = null;
        requestOdata.defaults.headers.common.Authorization = null;

        window.localStorage.removeItem('userAdmin');
        cookie.removeCookie();

        return {
            userLogin: null
        };
    case userConstants.POST_CHANGE_PASSWORD_REQUEST:
        return {
            ...state,
            send: true,
            sendUserChangePasswordPOST: true
        };
    case userConstants.POST_CHANGE_PASSWORD_SUCCESS:
        return {
            ...state,
            send: false,
            sendUserChangePasswordPOST: false
        };
    case userConstants.POST_CHANGE_PASSWORD_ERROR:
        return {
            ...state,
            send: false,
            sendUserChangePasswordPOST: false,
            errors: action.error
        };
    case userConstants.POST_RESET_PASSWORD_REQUEST:
        return {
            ...state,
            send: true,
            sendUserResetPasswordPOST: true
        };
    case userConstants.POST_RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            send: false,
            sendUserResetPasswordPOST: false
        };
    case userConstants.POST_RESET_PASSWORD_ERROR:
        return {
            ...state,
            send: false,
            sendUserResetPasswordPOST: false,
            errors: action.error
        };
    case userConstants.POST_CREATE_PASSWORD_REQUEST:
        return {
            ...state,
            send: true,
            sendUserCreatePasswordPOST: true
        };
    case userConstants.POST_CREATE_PASSWORD_SUCCESS:
        return {
            ...state,
            send: false,
            sendUserCreatePasswordPOST: false
        };
    case userConstants.POST_CREATE_PASSWORD_ERROR:
        return {
            ...state,
            send: false,
            sendUserCreatePasswordPOST: false,
            errors: action.error
        };
    default:
        return state;
    }
};
