import { countrySettingsConstants } from './constants';

export const countrySettingsReducers = function (state = {
    countrySettings: []
}, action) {
    switch (action.type) {
    case countrySettingsConstants.GET_COUNTRY_SETTINGS_REQUEST:
        return {
            ...state,
            send: true,
            sendCountrySettings: true
        };
    case countrySettingsConstants.GET_COUNTRY_SETTINGS_SUCCESS:
        return {
            ...state,
            countrySettings: action.payload,
            send: false,
            sendCountrySettings: false
        };
    case countrySettingsConstants.GET_COUNTRY_SETTINGS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountrySettings: false
        };
    case countrySettingsConstants.POST_COUNTRY_SETTINGS_REQUEST:
        return {
            ...state,
            send: true,
            sendCountrySettingsPOST: true
        };
    case countrySettingsConstants.POST_COUNTRY_SETTINGS_SUCCESS:
        return {
            ...state,
            send: false,
            sendCountrySettingsPOST: false
        };
    case countrySettingsConstants.POST_COUNTRY_SETTINGS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountrySettingsPOST: false
        };
    case countrySettingsConstants.PUT_COUNTRY_SETTINGS_REQUEST:
        return {
            ...state,
            send: true,
            sendCountrySettingsPUT: true
        };
    case countrySettingsConstants.PUT_COUNTRY_SETTINGS_SUCCESS:
        return {
            ...state,
            send: false,
            sendCountrySettingsPUT: false
        };
    case countrySettingsConstants.PUT_COUNTRY_SETTINGS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountrySettingsPUT: false
        };
    case countrySettingsConstants.GET_COUNTRY_SETTINGS_BY_ID_REQUEST:
        return {
            ...state,
            send: true,
            sendCountrySettingId: action.sendCountrySettingId,
            sendCountrySettingsById: true
        };
    case countrySettingsConstants.GET_COUNTRY_SETTINGS_BY_ID_SUCCESS:
        return {
            ...state,
            send: false,
            countrySettingsById: action.payload,
            sendCountrySettingsById: false
        };
    case countrySettingsConstants.GET_COUNTRY_SETTINGS_BY_ID_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountrySettingId: null,
            sendCountrySettingsById: false
        };
    default:
        return state;
    }
};
