import React from 'react';
import PropTypes from 'prop-types';

export const ErrorUpload = (props) => {
    return <div className='form_field_error text-center'>{props.touched && props.error}</div>;
};

ErrorUpload.propTypes = {
    touched: PropTypes.bool,
    error: PropTypes.any
};
