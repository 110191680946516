import { servicesConstants } from './constants';

export const servicesReducers = function (state = {
    services: []
}, action) {
    switch (action.type) {
    case servicesConstants.GET_SERVICES_REQUEST:
        return {
            ...state,
            send: true,
            sendServicesGET: true
        };
    case servicesConstants.GET_SERVICES_SUCCESS:
        return {
            ...state,
            services: action.payload,
            send: false,
            sendServicesGET: false
        };
    case servicesConstants.GET_SERVICES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServicesGET: false
        };
    case servicesConstants.GET_SERVICES_BY_ID_REQUEST:
        return {
            ...state,
            service: action.update ? state.service : null,
            send: true,
            sendServiceGET: true
        };
    case servicesConstants.GET_SERVICES_BY_ID_SUCCESS:
        return {
            ...state,
            service: action.payload,
            send: false,
            sendServiceGET: false
        };
    case servicesConstants.GET_SERVICES_BY_ID_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceGET: false
        };
    case servicesConstants.POST_SERVICE_IMAGE_REQUEST:
        return {
            ...state,
            send: true,
            sendServiceImage: true
        };
    case servicesConstants.POST_SERVICE_IMAGE_SUCCESS:
        return {
            ...state,
            send: false,
            sendServiceImage: false
        };
    case servicesConstants.POST_SERVICE_IMAGE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceImage: false
        };
    case servicesConstants.POST_SERVICES_REQUEST:
        return {
            ...state,
            send: true,
            sendServicesPOST: true
        };
    case servicesConstants.POST_SERVICES_SUCCESS:
        return {
            ...state,
            send: false,
            sendServicesPOST: false
        };
    case servicesConstants.POST_SERVICES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServicesPOST: false
        };
    case servicesConstants.PUT_SERVICES_REQUEST:
        return {
            ...state,
            send: true,
            sendServicesPUT: true
        };
    case servicesConstants.PUT_SERVICES_SUCCESS:
        return {
            ...state,
            send: false,
            sendServicesPUT: false
        };
    case servicesConstants.PUT_SERVICES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServicesPUT: false
        };
    default:
        return state;
    }
};
