import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { getCurrentDate, i18, required } from '../../../../../utilities';
import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../../../components';

export const PartnerDocumentsForm = (props) => {
    return (
        <Form
            onSubmit={props.submit}
            initialValues={props.values}
            render={({
                handleSubmit,
                values
            }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <div className='mb-3'>
                        <FormField
                            inline
                            component={formFieldType.upload}
                            disabled={props.submitting}
                            name='file'
                            title={i18.PartnerDocumentsForm.Labels.File}
                            validate={required}
                        />
                        <FormField
                            inline
                            component={formFieldType.date}
                            disabled={props.submitting}
                            name='validFrom'
                            title={i18.PartnerDocumentsForm.Labels.ValidFrom}
                            validate={required}
                        />
                        <FormField
                            inline
                            component={formFieldType.date}
                            disabled={props.submitting}
                            name='validTo'
                            title={i18.PartnerDocumentsForm.Labels.ValidTo}
                            minDate={{
                                date: values.validFrom || getCurrentDate()
                            }}
                        />
                    </div>
                    <div className='text-right'>
                        <ButtonGroup>
                            <ButtonWrapper
                                disabled={props.submitting}
                                onClick={() => props.cancel()}
                            >
                                <Translate value={i18.PartnerDocumentsForm.Buttons.Cancel} />
                            </ButtonWrapper>
                            <ButtonWrapper
                                loading={props.submitting}
                                htmlType='submit'
                                type='primary'
                            >
                                <Translate value={i18.PartnerDocumentsForm.Buttons.Submit} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    </div>
                </form>
            )}
        />
    );
};

PartnerDocumentsForm.propTypes = {
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    values: PropTypes.object.isRequired
};
