import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormField, formFieldType, Translate } from '../../../components';
import {
    getCurrentDate,
    i18,
    translate,
    toSeconds
} from '../../../utilities';
import { jobCreateFormActions } from '../../../api';
import { TimePicker, InputNumber } from 'antd';
import { PartnerSelectionField } from '.';
import { fieldNames } from './fieldNames';

class AdditionalSettingsSection extends React.Component {
    constructor (props) {
        super(props);

        this.onJobDurationChange = this.onJobDurationChange.bind(this);
        this.jobDurationDaysRender = this.jobDurationDaysRender.bind(this);
    }

    componentWillUnmount () {
        this.props.updateKeyValue(fieldNames.partnerId, undefined);
        this.props.updateKeyValue(fieldNames.partnerName, undefined);
        this.props.updateKeyValue(fieldNames.jobDuration, undefined);
        this.props.updateKeyValue(fieldNames.partnerComment, undefined);
        this.props.updateKeyValue(fieldNames.jobDateRange, undefined);

        this.props.dispatch(jobCreateFormActions.setJobDurationDays(0));
        this.props.dispatch(jobCreateFormActions.setJobDurationTime(undefined));
        this.props.dispatch(jobCreateFormActions.setJobPartnerId(undefined));
    }

    onJobDurationChange (timeMoment, timeString) {
        let totalTimeInSecs;

        if (timeString) {
            const splitTimeBySpace = timeString.split(' ');
            const splitTime = splitTimeBySpace[1].split(':');

            const durationObject = {
                days: this.props.jobDurationDays,
                hours: parseInt(splitTime[0]),
                minutes: parseInt(splitTime[1])
            };

            totalTimeInSecs = toSeconds(durationObject.days, durationObject.hours, durationObject.minutes);
        }

        this.props.dispatch(jobCreateFormActions.setJobDurationTime(timeMoment?.valueOf()));
        this.props.updateKeyValue(fieldNames.jobDuration, totalTimeInSecs);
    }

    jobDurationDaysRender () {
        const jobDurationDaysOnChange = (value) => {
            this.props.dispatch(jobCreateFormActions.setJobDurationDays(value));
        };

        return (
            <InputNumber
                min={0}
                max={30}
                placeholder={translate(i18.JobCreateForm.Labels.JobDurationDays)}
                value={this.props.jobDurationDays}
                onChange={jobDurationDaysOnChange}
            />
        );
    }

    render () {
        return (
            <div>
                <Translate className='fs-16 fw_600 font-italic' value={i18.JobCreateForm.Titles.AdditionalSettings} />
                <div className='container-default mt-4'>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <PartnerSelectionField
                                nameId={fieldNames.partnerId}
                                nameValue={fieldNames.partnerName}
                                updateKeyValue={this.props.updateKeyValue}
                                formValues={this.props.formValues}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <FormField
                                placeholderFrom={i18.JobCreateForm.Labels.JobStartDate}
                                placeholderTo={i18.JobCreateForm.Labels.JobEndDate}
                                name={fieldNames.jobDateRange}
                                component={formFieldType.dateRangePicker}
                                time
                                minDate={{
                                    date: getCurrentDate(0)
                                }}
                            />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-12'>
                            <TimePicker
                                style={{ width: '100%' }}
                                showNow={false}
                                value={this.props.jobDurationTime ? moment(this.props.jobDurationTime) : undefined}
                                format={`${this.props.jobDurationDays} HH:mm:ss`}
                                placeholder={translate(i18.JobCreateForm.Labels.JobDuration)}
                                onChange={this.onJobDurationChange}
                                renderExtraFooter={this.jobDurationDaysRender}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col-12'>
                            <FormField
                                placeholder={translate(i18.JobCreateForm.Labels.PartnerComment)}
                                maxLength={500}
                                name={fieldNames.partnerComment}
                                component={formFieldType.textarea}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

AdditionalSettingsSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    updateKeyValue: PropTypes.func.isRequired,
    jobDurationTime: PropTypes.number,
    jobDurationDays: PropTypes.number
};

function mapStateToProps (state) {
    const {
        jobDurationTime,
        jobDurationDays
    } = state.jobCreateFormReducers;

    return {
        jobDurationTime,
        jobDurationDays
    };
}

const connectedAdditionalSettingsSection = connect(mapStateToProps)(AdditionalSettingsSection);
export { connectedAdditionalSettingsSection as AdditionalSettingsSection };
