import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';

import { i18 } from '../../../../utilities';
import { partnerManagementActions, partnerManagementConstants, partnerProfileActions } from '../../../../api';
import { ButtonWrapper, Translate } from '../../../../components';
import { PartnerAccountData, PartnerAccountForm } from './';

class PartnerAccountContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            editing: false,
            vatPercentages: []
        };

        this.submit = this.submit.bind(this);
    }

    mutateSubmit (values) {
        const { countryVats: { vats } } = this.props;

        if (Object.prototype.hasOwnProperty.call(values, 'vatName') && vats.length > 0) {
            const selectedVat = vats.find(v => v.name === values.vatName);
            values.vatPercentage = selectedVat?.percentage;
        }

        return values;
    }

    submit (values) {
        this.props.dispatch(
            partnerManagementActions.postDetails(
                this.props.match.params.partnerId,
                this.mutateSubmit(values)
            )
        ).then(res => {
            if (res && res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_DETAILS_SUCCESS) {
                this.props.dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true));
                this.setState({ editing: false });
            }
        });
    }

    render () {
        return (
            <div className='content_block'>
                {!this.state.editing && (
                    <div className='mb-4 text-right'>
                        <ButtonWrapper
                            type='secondary'
                            icon={<EditOutlined />}
                            onClick={() => this.setState({ editing: true })}
                        >
                            <Translate value={i18.PartnerAccount.Buttons.Edit} />
                        </ButtonWrapper>
                    </div>
                )}
                {this.state.editing ? (
                    <PartnerAccountForm
                        cancel={() => this.setState({ editing: false })}
                        data={this.props.partnerProfile}
                        submit={this.submit}
                        submitting={this.props.sendPartnerManagementDetailsPOST || this.props.sendPartnerProfileGET}
                        vatPercentages={this.state.vatPercentages}
                        countryVats={this.props.countryVats}
                    />
                ) : (
                    <PartnerAccountData
                        data={this.props.partnerProfile}
                    />
                )}
            </div>
        );
    }
}

PartnerAccountContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    partnerProfile: PropTypes.object.isRequired,
    sendPartnerManagementDetailsPOST: PropTypes.bool,
    sendPartnerProfileGET: PropTypes.bool,
    countryVats: PropTypes.arrayOf(PropTypes.object)
};

function mapStateToProps (state) {
    const {
        sendPartnerManagementDetailsPOST
    } = state.partnerManagementReducers;
    const {
        partnerProfile,
        sendPartnerProfileGET
    } = state.partnerProfileReducers;
    const {
        countryVats
    } = state.countryVatReducers;

    return {
        partnerProfile,
        sendPartnerProfileGET,
        sendPartnerManagementDetailsPOST,
        countryVats
    };
}

const connectedPartnerAccountContainer = connect(mapStateToProps)(PartnerAccountContainer);
export { connectedPartnerAccountContainer as PartnerAccountContainer };
