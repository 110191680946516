export const translationConstants = {
    GET_TRANSLATION_ADMIN_FOOTER_REQUEST: 'GET_TRANSLATION_ADMIN_FOOTER_REQUEST',
    GET_TRANSLATION_ADMIN_FOOTER_SUCCESS: 'GET_TRANSLATION_ADMIN_FOOTER_SUCCESS',
    GET_TRANSLATION_ADMIN_FOOTER_ERROR: 'GET_TRANSLATION_ADMIN_FOOTER_ERROR',

    GET_CLASSIFICATION_GROUPS_REQUEST: 'GET_CLASSIFICATION_GROUPS_REQUEST',
    GET_CLASSIFICATION_GROUPS_SUCCESS: 'GET_CLASSIFICATION_GROUPS_SUCCESS',
    GET_CLASSIFICATION_GROUPS_ERROR: 'GET_CLASSIFICATION_GROUPS_ERROR',

    GET_CLASSIFICATIONS_REQUEST: 'GET_CLASSIFICATIONS_REQUEST',
    GET_CLASSIFICATIONS_SUCCESS: 'GET_CLASSIFICATIONS_SUCCESS',
    GET_CLASSIFICATIONS_ERROR: 'GET_CLASSIFICATIONS_ERROR',

    PUT_CLASSIFICATION_REQUEST: 'PUT_CLASSIFICATION_REQUEST',
    PUT_CLASSIFICATION_SUCCESS: 'PUT_CLASSIFICATION_SUCCESS',
    PUT_CLASSIFICATION_ERROR: 'PUT_CLASSIFICATION_ERROR',

    GET_CONTENT_REQUEST: 'GET_CONTENT_REQUEST',
    GET_CONTENT_SUCCESS: 'GET_CONTENT_SUCCESS',
    GET_CONTENT_ERROR: 'GET_CONTENT_ERROR',

    PUT_CONTENT_REQUEST: 'PUT_CONTENT_REQUEST',
    PUT_CONTENT_SUCCESS: 'PUT_CONTENT_SUCCESS',
    PUT_CONTENT_ERROR: 'PUT_CONTENT_ERROR',

    GET_ERROR_MESSAGES_REQUEST: 'GET_ERROR_MESSAGES_REQUEST',
    GET_ERROR_MESSAGES_SUCCESS: 'GET_ERROR_MESSAGES_SUCCESS',
    GET_ERROR_MESSAGES_ERROR: 'GET_ERROR_MESSAGES_ERROR',

    PUT_ERROR_MESSAGE_REQUEST: 'PUT_ERROR_MESSAGE_REQUEST',
    PUT_ERROR_MESSAGE_SUCCESS: 'PUT_ERROR_MESSAGE_SUCCESS',
    PUT_ERROR_MESSAGE_ERROR: 'PUT_ERROR_MESSAGE_ERROR',

    GET_LABELS_REQUEST: 'GET_LABELS_REQUEST',
    GET_LABELS_SUCCESS: 'GET_LABELS_SUCCESS',
    GET_LABELS_ERROR: 'GET_LABELS_ERROR',

    PUT_LABEL_REQUEST: 'PUT_LABEL_REQUEST',
    PUT_LABEL_SUCCESS: 'PUT_LABEL_SUCCESS',
    PUT_LABEL_ERROR: 'PUT_LABEL_ERROR'
};
