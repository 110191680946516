import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { publicActions } from '../../../api';
import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../components';
import {
    accountTypes,
    classificatorTranslatedArray,
    composeValidators,
    getCountry,
    getCurrentDate,
    i18,
    i18clGroups,
    required,
    requiredEmail,
    requiredIBAN,
    countryCodes,
    requiredPhone
} from '../../../utilities';

const PartnersCreateForm = (props) => {
    const country = getCountry();
    const isPolandCountryCode = country === countryCodes.PL;

    const getBankName = (iban, form) => {
        if (!iban) {
            return;
        }
        props.dispatch(publicActions.getBankName(iban)).then(res => {
            if (res && res.payload) {
                form.mutators.updateBankName(res.payload);
            }
        });
    };

    return (
        <Form
            mutators={{
                updateBankName: (args, state, utils) => {
                    utils.changeValue(state, 'bankName', () => args[0]);
                }
            }}
            onSubmit={props.submit}
            initialValues={{
                registrationRoleCode: 'PARTNER'
            }}
            render={({ handleSubmit, values, form }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <FormField
                        inline
                        title={i18.PartnersCreateForm.Labels.AccountType}
                        name='accountTypeCode'
                        component={formFieldType.select}
                        validate={required}
                        options={classificatorTranslatedArray(i18clGroups.TYPES_ACCOUNT)}
                        disabled={props.submitting}
                    />
                    {values.accountTypeCode === accountTypes.JURIDICAL && (
                        <>
                            <FormField
                                inline
                                title={i18.PartnersCreateForm.Labels.Company}
                                name='companyName'
                                component={formFieldType.input}
                                validate={required}
                                disabled={props.submitting}
                            />
                            <FormField
                                inline
                                title={isPolandCountryCode ? i18.PartnerAccountForm.Labels.NipCode : i18.PartnersCreateForm.Labels.CompanyCode}
                                name='coCode'
                                component={formFieldType.input}
                                maxLength={20}
                                disabled={props.submitting}
                            />
                        </>
                    )}
                    <FormField
                        inline
                        title={i18.PartnersCreateForm.Labels.Name}
                        name='firstName'
                        component={formFieldType.input}
                        validate={required}
                        disabled={props.submitting}
                        maxLength={25}
                    />
                    <FormField
                        inline
                        title={i18.PartnersCreateForm.Labels.Surname}
                        name='lastName'
                        component={formFieldType.input}
                        validate={required}
                        disabled={props.submitting}
                        maxLength={25}
                    />
                    <FormField
                        inline
                        title={i18.PartnersCreateForm.Labels.Email}
                        name='email'
                        component={formFieldType.input}
                        validate={composeValidators(required, requiredEmail)}
                        disabled={props.submitting}
                    />
                    {values.accountTypeCode === accountTypes.PHYSICAL && (
                        <FormField
                            inline
                            title={i18.PartnersCreateForm.Labels.Birthday}
                            name='birthday'
                            component={formFieldType.date}
                            maxDate={{
                                date: getCurrentDate()
                            }}
                            minDate={{
                                date: getCurrentDate(-1 * 365 * 150)
                            }}
                        />
                    )}
                    <FormField
                        inline
                        title={i18.PartnersCreateForm.Labels.Phone}
                        name="telephone"
                        component={formFieldType.phone}
                        validate={requiredPhone}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnersCreateForm.Labels.IBAN}
                        name='bankAccountNo'
                        component={formFieldType.input}
                        formatNumberType='formattedValue'
                        onBlur={({ target: { value } }) => getBankName(value, form)}
                        validate={isPolandCountryCode ? undefined : requiredIBAN}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnersCreateForm.Labels.BankName}
                        name='bankName'
                        component={formFieldType.input}
                        onBlur={({ target: { value } }) => getBankName(value, form)}
                        validate={required}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnersCreateForm.Labels.Source}
                        name='sourceInfoAboutPortalId'
                        component={formFieldType.select}
                        options={classificatorTranslatedArray(i18clGroups.INFO_ABOUT_PORTAL_FOUND_IN)}
                        validate={required}
                        disabled={props.submitting}
                    />
                    <div className='text-right'>
                        <ButtonGroup>
                            <ButtonWrapper
                                type='text'
                                disabled={props.submitting}
                                onClick={() => props.cancel()}
                            >
                                <Translate value={i18.PartnersCreateForm.Buttons.Cancel} />
                            </ButtonWrapper>
                            <ButtonWrapper
                                type='primary'
                                htmlType='submit'
                                loading={props.submitting}
                            >
                                <Translate value={i18.PartnersCreateForm.Buttons.Submit} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    </div>
                </form>
            )}
        />
    );
};

PartnersCreateForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};

const connectedPartnersCreateForm = connect(null)(PartnersCreateForm);
export { connectedPartnersCreateForm as PartnersCreateForm };
