import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { Error, Title } from './';

export const PasswordFormField = (props) => {
    return (
        <div className='form_field_wrapper row'>
            <Title
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
                required={props.required}
            />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <Input.Password
                    autoFocus={props.autoFocus}
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    spellCheck='off'
                    disabled={props.disabled}
                    name={props.input.name}
                    onChange={props.input.onChange}
                    value={props.input.value}
                    size={props.size}
                />
            </div>
            <Error
                error={props.meta.error}
                inline={props.inline}
                touched={props.meta.touched}
            />
        </div>
    );
};

PasswordFormField.propTypes = {
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    }),
    required: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
    title: PropTypes.string,
    titleTranslated: PropTypes.bool
};
