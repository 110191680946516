import { featureTogglesConstants } from './constants';

export const featureTogglesReducers = function (state = {
    featureToggles: {}
}, action) {
    switch (action.type) {
    case featureTogglesConstants.GET_FEATURE_TOGGLES_REQUEST:
        return {
            ...state,
            send: true
        };
    case featureTogglesConstants.GET_FEATURE_TOGGLES_SUCCESS:
        return {
            ...state,
            featureToggles: action.payload,
            send: false
        };
    case featureTogglesConstants.GET_FEATURE_TOGGLES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false
        };
    default:
        return state;
    }
};
