import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, Alert, Space } from 'antd';
import { EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';

import {
    i18,
    i18clGroups,
    classificatorTranslatedArray,
    translate,
    getDataCountryId,
    formatNumber
} from '../../utilities';
import { ClientSegmentFeeMenu } from './components';
import { ContentHeader, Number, NumberFormField, Translate } from '../../components';
import {
    administrationFeeMarginsActions,
    administrationFeeMarginsConstants
} from '../../api/services/administrationFeeMargins';

class CountryAdministrationFeeMargins extends React.Component {
    constructor(props) {
        super(props);

        this.clientSegments = classificatorTranslatedArray(i18clGroups.CLIENT_SEGMENTS).sort((a, b) =>
            a.value.localeCompare(b.value)
        );
        this.dataCountryId = getDataCountryId();

        this.fetchFeeMargins = this.fetchFeeMargins.bind(this);
        this.handleClientSegmentChange = this.handleClientSegmentChange.bind(this);
        this.submit = this.submit.bind(this);
        this.showEdit = this.showEdit.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);
        this.handleMaterialsClientPriceChange = this.handleMaterialsClientPriceChange.bind(this);
        this.handleMaterialsMarginChange = this.handleMaterialsMarginChange.bind(this);
        this.handleMinimalFeeChange = this.handleMinimalFeeChange.bind(this);
        this.handleMaximumFeeChange = this.handleMaximumFeeChange.bind(this);
        this.showCancelWarning = this.showCancelWarning.bind(this);
        this.closeCancelWarning = this.closeCancelWarning.bind(this);
        this.handleWarningContinueClick = this.handleWarningContinueClick.bind(this);
        this.setResponseData = this.setResponseData.bind(this);

        this.state = {
            selectedSegment: this.clientSegments[1]?.value,
            isEditing: false,
            initialMinimalFee: null,
            initialMaximumFee: null,
            minimalFee: null,
            maximumFee: null,
            initialMaterialsClientPrice: null,
            initialMaterialsMargin: null,
            materialsClientPrice: null,
            materialsMargin: null,
            initialSteps: [],
            steps: [],
            warnedSegment: null,
            isLoading: false
        };
    }

    componentDidMount() {
        this.fetchFeeMargins(this.state.selectedSegment);
    }

    setResponseData(data) {
        const deepClone = JSON.parse(JSON.stringify(data));

        this.setState({
            ...data,
            initialMinimalFee: deepClone?.minimalFee ?? null,
            initialMaximumFee: deepClone?.maximumFee ?? null,
            initialMaterialsClientPrice: deepClone?.materialsClientPrice ?? null,
            initialMaterialsMargin: deepClone?.materialsMargin ?? null,
            initialSteps: deepClone?.steps || [],
            isEditing: false,
            isLoading: false
        });
    }

    fetchFeeMargins(clientSegment) {
        const { dispatch } = this.props;
        this.setState({
            isLoading: true
        });

        dispatch(
            administrationFeeMarginsActions.getAdministrationFeeMargins({
                dataCountryId: this.dataCountryId,
                clientSegment
            })
        ).then(res => {
            if (res.type === administrationFeeMarginsConstants.GET_ADMINISTRATION_FEE_MARGINS_SUCCESS) {
                this.setResponseData(res.payload);
            }
        });
    }

    submit() {
        const { dispatch } = this.props;

        dispatch(
            administrationFeeMarginsActions.putAdministrationFeeMargins(
                {
                    dataCountryId: this.dataCountryId,
                    clientSegment: this.state.selectedSegment
                },
                {
                    minimalFee: this.state.minimalFee,
                    maximumFee: this.state.maximumFee,
                    materialsClientPrice: this.state.materialsClientPrice,
                    materialsMargin: this.state.materialsMargin,
                    steps: this.state.steps.map(({ stepId, marginPercent }) => ({ stepId: stepId ?? 0, marginPercent }))
                }
            )
        ).then(res => {
            if (res.type === administrationFeeMarginsConstants.PUT_ADMINISTRATION_FEE_MARGINS_SUCCESS) {
                this.setResponseData(res.payload);
            }
        });
    }

    showEdit() {
        this.setState({ isEditing: true });
    }

    cancelEdit() {
        this.setState(prevState => ({
            minimalFee: prevState.initialMinimalFee,
            maximumFee: prevState.initialMaximumFee,
            materialsClientPrice: prevState.initialMaterialsClientPrice,
            materialsMargin: prevState.initialMaterialsMargin,
            steps: prevState.initialSteps,
            isEditing: false
        }));
    }

    showCancelWarning(warnedSegment) {
        this.setState({ warnedSegment });
    }

    closeCancelWarning() {
        this.setState({ warnedSegment: null });
    }

    handleWarningContinueClick(clientSegment) {
        this.setState({
            warnedSegment: null,
            isEditing: false,
            selectedSegment: clientSegment
        });
        this.fetchFeeMargins(clientSegment);
    }

    handleClientSegmentChange(props) {
        if (this.state.isEditing) {
            return this.showCancelWarning(props.key);
        }

        this.changeSelectedClientSegment(props.key);
    }

    changeSelectedClientSegment(selectedSegment) {
        this.setState({ selectedSegment });     
        this.fetchFeeMargins(selectedSegment);
    }

    handleStepChange(id, value) {
        const stepsClone = [...this.state.steps];
        const idx = stepsClone.findIndex(item => item.stepId === id);

        stepsClone[idx].marginPercent = value;

        this.setState({ steps: stepsClone });
    }

    handleMinimalFeeChange(value) {
        this.setState({ minimalFee: value });
    }

    handleMaximumFeeChange(value) {
        this.setState({ maximumFee: value });
    }

    handleMaterialsClientPriceChange(value) {
        this.setState({ materialsClientPrice: value });
    }

    handleMaterialsMarginChange(value) {
        const percentange = !value || (value <= 100) ? value : 100;
        this.setState({ materialsMargin: percentange });
    }

    render() {
        const withPercentageLimit = ({ floatValue }) => (floatValue ?? 0) <= 100;

        const columns = [
            {
                title: <Translate value={i18.AdministrationFeeMargins.Labels.StepName} />,
                render: item =>
                    `${item.marginAmount}${
                        item.isLastStep ? translate(i18.AdministrationFeeMargins.Labels.LastStepSuffix) : ''
                    }`
            },
            {
                title: <Translate value={i18.AdministrationFeeMargins.Labels.StepPercent} />,
                render: item => (
                    <Number
                        isNumberText={!this.state.isEditing}
                        value={item.marginPercent}
                        onValueChange={values => this.handleStepChange(item.stepId, values.floatValue)}
                        addonAfter="%"
                        format={formatNumber().floatPositiveUpTo2Decimal}
                        isAllowed={withPercentageLimit}
                    />
                )
            }
        ];

        return (
            <>
                <ContentHeader title={<Translate value={i18.AdministrationFeeMargins.Titles.Main} />} />
                {this.state.warnedSegment !== null && (
                    <Alert
                        type="error"
                        message={translate(i18.AdministrationFeeMargins.Labels.SegmentChangeWarningMessage)}
                        description={translate(i18.AdministrationFeeMargins.Labels.SegmentChangeWarningDescription)}
                        action={
                            <Space>
                                <Button
                                    danger
                                    onClick={() => this.handleWarningContinueClick(this.state.warnedSegment)}
                                >
                                    <Translate value={i18.AdministrationFeeMargins.Buttons.WarningContinue} />
                                </Button>
                                <Button onClick={this.closeCancelWarning}>
                                    <Translate value={i18.AdministrationFeeMargins.Buttons.WarningCancel} />
                                </Button>
                            </Space>
                        }
                    />
                )}
                <ClientSegmentFeeMenu
                    values={this.clientSegments}
                    selected={this.state.selectedSegment}
                    isEditing={this.state.isEditing}
                    onChange={this.handleClientSegmentChange}
                />
                <div className="content_block">
                    <div className="content_box">
                        <div className="d-flex flex-row justify-content-end mb-5">
                            {!this.state.isEditing && (
                                <Button
                                    type="primary"
                                    icon={<EditOutlined />}
                                    loading={this.props.sendAdministrationFeeMarginsGET}
                                    onClick={this.showEdit}
                                >
                                    <Translate value={i18.AdministrationFeeMargins.Buttons.Edit} />
                                </Button>
                            )}
                            {this.state.isEditing && (
                                <>
                                    <Button
                                        type="default"
                                        icon={<SaveOutlined />}
                                        loading={this.props.sendAdministrationFeeMarginsPUT}
                                        onClick={this.submit}
                                    >
                                        <Translate value={i18.AdministrationFeeMargins.Buttons.Submit} />
                                    </Button>
                                    <Button danger className="ml-4" icon={<CloseOutlined />} onClick={this.cancelEdit}>
                                        <Translate value={i18.AdministrationFeeMargins.Buttons.Cancel} />
                                    </Button>
                                </>
                            )}
                        </div>
                        {!this.state.isLoading && <div className="d-flex justify-content-center">
                            <div className="w-100 centered-table">
                                <div className="d-flex">
                                    <NumberFormField
                                        center
                                        title={i18.AdministrationFeeMargins.Labels.MaterialsClientPrice}
                                        input={{
                                            name: 'materialsClientPrice',
                                            value: this.state.materialsClientPrice,
                                            onChange: this.handleMaterialsClientPriceChange
                                        }}
                                        meta={{ touched: false, error: null }}
                                        formatNumber={{
                                            allowNegative: false,
                                            allowLeadingZeros: false,
                                            decimalScale: 2
                                        }}
                                        numberText={!this.state.isEditing}
                                    />
                                    <div className="form_field_wrapper d-flex align-items-center ml-1">
                                        {this.props.publicConfigurations?.currency || 'EUR'}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <NumberFormField
                                        center
                                        title={i18.AdministrationFeeMargins.Labels.MaterialsMargin}
                                        input={{
                                            name: 'materialsMargin',
                                            value: this.state.materialsMargin,
                                            onChange: this.handleMaterialsMarginChange
                                        }}
                                        meta={{ touched: false, error: null }}
                                        formatNumber={{
                                            allowNegative: false,
                                            allowLeadingZeros: false,
                                            decimalScale: 2
                                        }}
                                        numberText={!this.state.isEditing}
                                    />
                                    <div className="form_field_wrapper d-flex align-items-center ml-1">{'%'}</div>
                                </div>
                                <div className="d-flex">
                                    <NumberFormField
                                        center
                                        title={i18.AdministrationFeeMargins.Labels.MinimalFee}
                                        input={{
                                            name: 'minimalFee',
                                            value: this.state.minimalFee,
                                            onChange: this.handleMinimalFeeChange
                                        }}
                                        meta={{ touched: false, error: null }}
                                        formatNumber={{
                                            allowNegative: false,
                                            allowLeadingZeros: false,
                                            decimalScale: 2
                                        }}
                                        numberText={!this.state.isEditing}
                                    />
                                    <div className="form_field_wrapper d-flex align-items-center ml-1">
                                        {this.props.publicConfigurations?.currency || 'EUR'}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <NumberFormField
                                        center
                                        title={i18.AdministrationFeeMargins.Labels.MaximumFee}
                                        input={{
                                            name: 'maximumFee',
                                            value: this.state.maximumFee,
                                            onChange: this.handleMaximumFeeChange
                                        }}
                                        meta={{ touched: false, error: null }}
                                        formatNumber={{
                                            allowNegative: false,
                                            allowLeadingZeros: false,
                                            decimalScale: 2
                                        }}
                                        numberText={!this.state.isEditing}
                                    />
                                    <div className="form_field_wrapper d-flex align-items-center ml-1">
                                        {this.props.publicConfigurations?.currency || 'EUR'}
                                    </div>
                                </div>
                                <Table
                                    columns={columns}
                                    dataSource={this.state.steps}
                                    loading={this.props.sendAdministrationFeeMarginsGET}
                                    rowKey="stepId"
                                    pagination={false}
                                />
                            </div>
                        </div>}
                    </div>
                </div>
            </>
        );
    }
}

CountryAdministrationFeeMargins.propTypes = {
    dispatch: PropTypes.func.isRequired,
    sendAdministrationFeeMarginsGET: PropTypes.bool,
    sendAdministrationFeeMarginsPUT: PropTypes.bool,
    publicConfigurations: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const { sendAdministrationFeeMarginsGET, sendAdministrationFeeMarginsPUT } = state.administrationFeeMarginsReducers;

    const { publicConfigurations } = state.publicReducers;

    return {
        sendAdministrationFeeMarginsGET,
        sendAdministrationFeeMarginsPUT,
        publicConfigurations
    };
}

const connectedCountryAdministrationFeeMargins = connect(mapStateToProps)(CountryAdministrationFeeMargins);
export { connectedCountryAdministrationFeeMargins as CountryAdministrationFeeMargins };
