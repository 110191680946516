import React from 'react';
import PropTypes from 'prop-types';

export const Error = (props) => {
    if (props.touched && props.error) {
        return <div className={props.inline ? 'form_field_error col-12 col-sm-12 col-md-4' : 'form_field_error col-12'}>{props.touched && props.error}</div>;
    } else {
        return null;
    }
};

Error.propTypes = {
    touched: PropTypes.bool,
    error: PropTypes.any,
    inline: PropTypes.bool
};
