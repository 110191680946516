import { localJobConstants } from './constants';

const defaultValues = {
    isJobEditable: false,
    isJobFilesEditable: false
};

export const localJobReducers = function (
    state = {
        ...defaultValues
    },
    action
) {
    switch (action.type) {
        case localJobConstants.SET_JOB_EDITABLE:
            return {
                ...state,
                isJobEditable: action.payload
            };
        case localJobConstants.SET_JOB_FILES_EDITABLE:
            return {
                ...state,
                isJobFilesEditable: action.payload
            };
        default:
            return state;
    }
};
