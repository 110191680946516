import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import { Translate } from '../../translate';
import { Error, Title } from './';

export const CheckboxFormField = props => {
    return (
        <div className={props.inline ? 'form_field_wrapper row align-items-center' : 'form_field_wrapper row'}>
            <Title inline={props.inline} title={props.title} titleTranslated={props.titleTranslated} />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <Checkbox disabled={props.disabled} checked={!!props.input.checked} onChange={props.input.onChange}>
                    {props.label ? props.labelTranslated ? props.label : <Translate value={props.label} /> : null}
                </Checkbox>
                {props.suffix}
            </div>
            <Error touched={props.meta.touched} error={props.meta.error} />
        </div>
    );
};

CheckboxFormField.propTypes = {
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    label: PropTypes.string,
    labelTranslated: PropTypes.bool,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    input: PropTypes.shape({
        checked: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    }),
    suffix: PropTypes.any
};
