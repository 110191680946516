import React from 'react';
import PropTypes from 'prop-types';

import { classificatorArray, i18, required, requiredArray } from '../../../utilities';
import { FormField, formFieldType } from '../../../components';
import { settingInputType } from './fields';

export const SettingValueField = ({ values, classificatorList }) => {
    const inputType = values.classificatorCode ? classificatorList.find(e => e.classificatorCode === values.classificatorCode).field : settingInputType.input;

    if (values.classificatorCode && inputType === settingInputType.select) {
        return (
            <FormField
                component={formFieldType.select}
                title={i18.CountrySettingsForm.Labels.Value}
                name='value'
                validate={required}
                options={
                    classificatorArray(
                        classificatorList.find(e => e.classificatorCode === values.classificatorCode).classificatorCodeForValues + '.',
                        classificatorList.find(e => e.classificatorCode === values.classificatorCode).fieldValues
                    )
                }
            />
        );
    }

    if (values.classificatorCode && inputType === settingInputType.multiselect) {
        return (
            <FormField
                component={formFieldType.multiSelect}
                title={i18.CountrySettingsForm.Labels.Value}
                name='value'
                validate={requiredArray}
                options={
                    classificatorArray(
                        classificatorList.find(e => e.classificatorCode === values.classificatorCode).classificatorCodeForValues + '.',
                        classificatorList.find(e => e.classificatorCode === values.classificatorCode).fieldValues
                    )
                }
            />
        );
    }

    return (
        <FormField
            component={formFieldType.input}
            title={i18.CountrySettingsForm.Labels.Value}
            name='value'
            validate={required}
        />
    );
};

SettingValueField.propTypes = {
    values: PropTypes.object.isRequired,
    classificatorList: PropTypes.array
};
