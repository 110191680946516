import React from 'react';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';

import { colors } from '../../utilities';
import { BoolFilterField, DateFilterField, NumberFilterField, SelectFilterField, TextFilterField } from './fields';
import { filterFieldTypes } from './';

export const FilterField = props => {
    let sort;

    switch (props.sortOrder ? props.sortOrder.order : null) {
        case 'asc':
            sort = 'ascend';
            break;
        case 'desc':
            sort = 'descend';
            break;
        default:
            sort = false;
    }

    const newFilterIcon =
        props.type === filterFieldTypes.disabled ? (
            <></>
        ) : (
            <SearchOutlined style={{ color: props.data !== undefined ? colors.primary : undefined }} />
        );

    const obj = {
        filterIcon: newFilterIcon,
        sorter: {
            multiple: 1
        },
        sortOrder: sort
    };

    switch (props.type) {
        case filterFieldTypes.bool:
            return {
                filterDropdown: ({ confirm }) => BoolFilterField(props, confirm),
                ...obj
            };
        case filterFieldTypes.date:
            return {
                filterDropdown: ({ confirm }) => DateFilterField(props, confirm),
                ...obj
            };
        case filterFieldTypes.disabled:
            return {
                ...obj
            };
        case filterFieldTypes.number:
            return {
                filterDropdown: ({ confirm }) => NumberFilterField(props, confirm),
                ...obj
            };
        case filterFieldTypes.text:
            return {
                filterDropdown: ({ confirm }) => TextFilterField(props, confirm),
                ...obj
            };
        case filterFieldTypes.select:
            return {
                filterDropdown: ({ confirm }) => SelectFilterField(props, confirm),
                ...obj
            };
        default:
            console.log('Filter field not found');
    }
};

FilterField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    setFilter: PropTypes.func.isRequired,
    sortOrder: PropTypes.string,
    type: PropTypes.string.isRequired,
    data: PropTypes.object
};
