import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { Error, Number } from '../..';
import { Title } from '.';
import {
    parsePhoneNumber,
    getTelephoneLength,
    getUniqueCallingCodes,
    getSelectableCallingCodes,
    addPlusSign,
    translate,
    getPhoneNumberParts,
    getDefaultPhonePrefix
} from '../../../utilities';

export const PhoneFormField = props => {
    const [number, setNumber] = useState(null);
    const [prefix, setPrefix] = useState(null);
    const [isNumberDirty, setIsNumberDirty] = useState(false);
    const [isPrefixDirty, setIsPrefixDirty] = useState(false);

    const phonePrefixes = useMemo(getSelectableCallingCodes, []);
    const fullPhoneNumber = useMemo(() => `${prefix}${number}`, [prefix, number]);
    const telephoneLength = useMemo(() => getTelephoneLength(fullPhoneNumber), [fullPhoneNumber]);

    const onChange = useCallback(
        input => {
            if (isNumberDirty || isPrefixDirty) {
                props.input.onChange(input);
            }
        },
        [props.input, isNumberDirty, isPrefixDirty]
    );

    useEffect(() => {
        const initialValues = getPhoneNumberParts(props.input.value);
        setNumber(initialValues.number);
        setPrefix(initialValues.prefix);

        // Disabling next line since this effect should be done once in component's life
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        onChange(fullPhoneNumber);
    }, [fullPhoneNumber, onChange]);

    const handlePrefixChange = newPrefix => {
        if (newPrefix === prefix) {
            return;
        }

        if (!isPrefixDirty) {
            setIsPrefixDirty(true);
        }

        const numberLength = getTelephoneLength(newPrefix);

        setPrefix(newPrefix);
        setNumber(String(number).substr(0, numberLength.withoutPrefix.max));
    };

    const handleNumberChange = ({ formattedValue }) => {
        if (!isNumberDirty) {
            setIsNumberDirty(true);
        }

        setNumber(formattedValue);
    };

    const handlePaste = e => {
        e.preventDefault();

        const pastedValue = e.clipboardData.getData('Text');

        if (String(pastedValue).includes('+')) {
            const callingCode = parsePhoneNumber(pastedValue).getCallingCode();

            if (!getUniqueCallingCodes().includes(callingCode)) {
                return;
            }

            const plusCallingCode = addPlusSign(callingCode);
            const numberLength = getTelephoneLength(plusCallingCode);
            const phoneNumber = String(pastedValue).substring(plusCallingCode.length, numberLength.max);

            setPrefix(plusCallingCode);
            setNumber(phoneNumber);
            return;
        }

        setNumber(String(pastedValue).substring(0, telephoneLength.withoutPrefix.max));
    };

    const isInputAllowed = ({ formattedValue }) => {
        const numberLength = String(number).length;

        if (numberLength >= telephoneLength.withoutPrefix.max && String(formattedValue).length >= numberLength) {
            return false;
        }
        return true;
    };

    const PrefixSelector = useMemo(
        () => (
            <Select
                disabled={props.disabled}
                onChange={handlePrefixChange}
                style={{ width: '100px' }}
                options={phonePrefixes}
                value={prefix}
                defaultValue={getDefaultPhonePrefix()}
            />
        ),
        [prefix]
    );

    return (
        <div
            className={
                props.inline
                    ? 'form_field_wrapper row align-items-center ' + props.classNameWrapper || ''
                    : 'form_field_wrapper row ' + props.classNameWrapper || ''
            }
        >
            <Title
                inline={props.inline}
                title={props.title}
                titleDescription={props.titleDescription}
                titleTranslated={props.titleTranslated}
                borderTitle={props.borderTitle}
                dimmed={props.dimmed}
                required={props.required}
            />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <Number
                    className={
                        props.borderTitle &&
                        `bg-white text-secondary ${props.highlighted && 'ant-input-highlighted'} ${
                            props.dimmed && 'ant-input-dimmed'
                        }`
                    }
                    isNumberText={false}
                    format={{
                        thousandSeparator: '',
                        allowNegative: false,
                        decimalScale: 0,
                        fixedDecimalScale: true,
                        allowLeadingZeros: true
                    }}
                    addonBefore={PrefixSelector}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none"
                    spellCheck="off"
                    isAllowed={isInputAllowed}
                    placeholder={props.placeholder && translate(props.placeholder)}
                    size={props.size}
                    disabled={props.disabled}
                    name={props.input.name}
                    value={number}
                    onValueChange={handleNumberChange}
                    onPaste={handlePaste}
                />
            </div>
            <Error inline={props.inline} touched={props.meta.touched} error={props.meta.error} />
        </div>
    );
};

PhoneFormField.propTypes = {
    classNameWrapper: PropTypes.string,
    disabled: PropTypes.bool,
    borderTitle: PropTypes.bool,
    dimmed: PropTypes.bool,
    highlighted: PropTypes.bool,
    formatNumber: PropTypes.object,
    formatNumberType: PropTypes.oneOf(['floatValue', 'formattedValue', 'value']),
    formatNumberPrefix: PropTypes.string,
    inline: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func
    }),
    placeholder: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    }),
    required: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
    title: PropTypes.string,
    titleDescription: PropTypes.string,
    titleTranslated: PropTypes.bool
};
