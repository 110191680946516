export const serviceItemsConstants = {
    GET_SERVICE_ITEMS_REQUEST: 'GET_SERVICE_ITEMS_REQUEST',
    GET_SERVICE_ITEMS_SUCCESS: 'GET_SERVICE_ITEMS_SUCCESS',
    GET_SERVICE_ITEMS_ERROR: 'GET_SERVICE_ITEMS_ERROR',

    POST_SERVICE_ITEMS_REQUEST: 'POST_SERVICE_ITEMS_REQUEST',
    POST_SERVICE_ITEMS_SUCCESS: 'POST_SERVICE_ITEMS_SUCCESS',
    POST_SERVICE_ITEMS_ERROR: 'POST_SERVICE_ITEMS_ERROR',

    POST_SERVICE_ITEMS_SORT_REQUEST: 'POST_SERVICE_ITEMS_REQUEST',
    POST_SERVICE_ITEMS_SORT_SUCCESS: 'POST_SERVICE_ITEMS_SUCCESS',
    POST_SERVICE_ITEMS_SORT_ERROR: 'POST_SERVICE_ITEMS_ERROR',

    PUT_SERVICE_ITEMS_DATA_REQUEST: 'PUT_SERVICE_ITEMS_DATA_REQUEST',
    PUT_SERVICE_ITEMS_DATA_SUCCESS: 'PUT_SERVICE_ITEMS_DATA_SUCCESS',
    PUT_SERVICE_ITEMS_DATA_ERROR: 'PUT_SERVICE_ITEMS_DATA_ERROR',

    PATCH_SERVICE_ITEM_STATUS_REQUEST: 'PATCH_SERVICE_ITEM_STATUS_REQUEST',
    PATCH_SERVICE_ITEM_STATUS_SUCCESS: 'PATCH_SERVICE_ITEM_STATUS_SUCCESS',
    PATCH_SERVICE_ITEM_STATUS_ERROR: 'PATCH_SERVICE_ITEM_STATUS_ERROR',

    POST_SERVICE_ITEMS_REQUEST_V2: 'POST_SERVICE_ITEMS_REQUEST_V2',
    POST_SERVICE_ITEMS_SUCCESS_V2: 'POST_SERVICE_ITEMS_SUCCESS_V2',
    POST_SERVICE_ITEMS_ERROR_V2: 'POST_SERVICE_ITEMS_ERROR_V2'
};
