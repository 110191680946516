import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import { i18, translate } from '../../../utilities';
import { Translate } from '../../translate';
import { Error, Title } from './';

const { Dragger } = Upload;

export const UploadFormField = (props) => {
    return (
        <div className='form_field_wrapper row align-items-center'>
            <Title
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
                required={props.required}
            />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <Dragger
                    accept={props.accept}
                    name={props.input.name}
                    multiple={props.multipleFiles ?? false}
                    maxCount={props.maxCount ?? 1}
                    disabled={props.disabled}
                    showUploadList={props.showUploadList ?? false}
                    defaultFileList={props.input.value}
                    customRequest={({ file, onSuccess, onError }) => {
                        if (props.maxFileSizeInBytes && file.size > props.maxFileSizeInBytes) {
                            setTimeout(() => {
                                onError('', `${translate(i18.Components.Dragger.FileCannotExceedBytes)} ${props.maxFileSizeInBytes} (${props.maxFileSizeInBytes / 1024 / 1024}MB)`);
                            }, 0);
                        } else {
                            if (props.multipleFiles && props.input.value.length < props.maxCount) {
                                const files = [file, ...props.input.value];
                                props.input.onChange(files);
                            } else if (!props.multipleFiles) {
                                props.input.onChange([file]);
                            }
                            setTimeout(() => {
                                onSuccess('ok');
                            }, 0);
                        }
                    }}
                    onRemove={removedFile => {
                        if (!props.input.value) {
                            return;
                        }

                        const files = props.input.value.filter(file => {
                            return file.uid !== removedFile.uid;
                        });

                        props.input.onChange(files);
                    }}
                >
                    <div className='fs_13'><Translate value={i18.Components.Dragger.Title} /></div>
                    <div className='fs_12 color_light'><Translate value={i18.Components.Dragger.Description} /></div>
                    {!props.multipleFiles && props.input.value ? <div className='fs_13 fw_600'><LinkOutlined /> {props.input.value[0]?.name}</div> : null}
                </Dragger>
            </div>
            <Error
                inline={props.inline}
                touched={props.meta.touched}
                error={props.meta.error}
            />
        </div>
    );
};

UploadFormField.propTypes = {
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    maxCount: PropTypes.number,
    maxFileSizeInBytes: PropTypes.number,
    multipleFiles: PropTypes.bool,
    showUploadList: PropTypes.bool,
    input: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        value: PropTypes.any
    }),
    meta: PropTypes.shape({
        error: PropTypes.any,
        touched: PropTypes.bool
    }),
    required: PropTypes.bool,
    title: PropTypes.string,
    titleTranslated: PropTypes.bool
};
