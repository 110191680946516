import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, FormField, formFieldType } from '../../../../components';
import { fieldNames } from '../../fieldNames';
import { classificator, i18, i18cl, required } from '../../../../utilities';

class ServiceSettingsFormSection extends React.Component {
    constructor(props) {
        super(props);

        this.onServiceGroupSelected = this.onServiceGroupSelected.bind(this);
        this.appendSelectedServiceToServiceListIfNotExist =
            this.appendSelectedServiceToServiceListIfNotExist.bind(this);
    }

    onServiceGroupSelected() {
        this.props.updateKeyValue(fieldNames.serviceCode, undefined);
    }

    appendSelectedServiceToServiceListIfNotExist(services) {
        const { job } = this.props;

        if (!job.serviceId) {
            return;
        }

        const serviceGroup = services.find(serviceGroup => serviceGroup.serviceGroupCode === job.serviceGroupCode);
        const serviceCode =
            serviceGroup && serviceGroup.services.find(service => service.serviceCode === job.serviceCode);

        if (!serviceGroup?.serviceGroupCode) {
            services.push({
                serviceGroupCode: job.serviceGroupCode,
                services: [
                    {
                        serviceId: job.serviceId,
                        serviceCode: job.serviceCode
                    }
                ]
            });
        } else if (!serviceCode) {
            serviceGroup.services.push({
                serviceId: job.serviceId,
                serviceCode: job.serviceCode
            });
        }
    }

    render() {
        const services = [...this.props.services];

        this.appendSelectedServiceToServiceListIfNotExist(services);

        const servicesByGroup =
            this.props.selectedServiceGroup && services.length
                ? services.find(serviceGroup => serviceGroup.serviceGroupCode === this.props.selectedServiceGroup)
                      .services
                : [];

        const serviceGroups = services.map(serviceGroup => {
            return {
                label: classificator(i18cl.GROUPS_SERVICE + serviceGroup.serviceGroupCode),
                value: serviceGroup.serviceGroupCode
            };
        });

        const servicesBySelectedGroup = servicesByGroup.map(service => {
            return {
                label: classificator(i18cl.SERVICES_CATALOG + service.serviceCode),
                value: service.serviceCode
            };
        });

        return (
            <>
                <Translate className="fs-16 fw_600" value={i18.Job.Titles.ServiceSettings} />
                <div className="container-default mt-4">
                    <div className="row">
                        <div className="col-6">
                            <FormField
                                title={i18.Job.Labels.ServiceGroup}
                                borderTitle
                                name={fieldNames.serviceGroup}
                                component={formFieldType.select}
                                options={serviceGroups}
                                onChange={this.onServiceGroupSelected}
                                disabled
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                        <div className="col-6">
                            <FormField
                                title={i18.Job.Labels.Service}
                                borderTitle
                                name={fieldNames.serviceCode}
                                component={formFieldType.select}
                                options={servicesBySelectedGroup}
                                disabled
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.JobDescription}
                                borderTitle
                                name={fieldNames.jobDescription}
                                component={formFieldType.textarea}
                                maxLength={2000}
                                disabled={!this.props.isJobEditable}
                                highlighted={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormField
                                title={i18.Job.Labels.JobDeadline}
                                borderTitle
                                name={fieldNames.jobDeadline}
                                component={formFieldType.date}
                                validate={required}
                                minDate={{
                                    date: this.props.job[fieldNames.jobDeadline]
                                }}
                                time
                                disabled={!this.props.isJobEditable}
                                highlighted={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

ServiceSettingsFormSection.propTypes = {
    isJobEditable: PropTypes.bool.isRequired,
    job: PropTypes.object,
    services: PropTypes.array,
    dispatch: PropTypes.func,
    selectedServiceGroup: PropTypes.string,
    updateKeyValue: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const { job } = state.jobReducers;

    const { isJobEditable } = state.localJobReducers;

    return {
        isJobEditable,
        job: job?.value || {}
    };
}

const connectedServiceSettingsFormSection = connect(mapStateToProps)(ServiceSettingsFormSection);
export { connectedServiceSettingsFormSection as ServiceSettingsFormSection };
