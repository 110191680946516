import { jobConstants } from './constants';

export const jobReducers = function (
    state = {
        job: {},
        jobValidationStatuses: {
            canCancelStatuses: [],
            canNotEditStatuses: [],
            canCompleteStatuses: [],
            canReassignStandardJobPartnerStatuses: [],
            canReassignFixedJobPartnerStatuses: [],
            canStartBidding: []
        },
        offers: [],
        sendJobUpdatePUT: false,
        sendNewCompetitorOfferPOST: false,
        sendCompetitorOfferPUT: false,
        sendCompetitorOfferDELETE: false,
        jobInvoices: []
    },
    action
) {
    switch (action.type) {
        case jobConstants.GET_JOB_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerReadActivityLogGET: true
            };
        case jobConstants.GET_JOB_SUCCESS:
            return {
                ...state,
                job: action.payload,
                send: false,
                sendPartnerReadActivityLogGET: false
            };
        case jobConstants.GET_JOB_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerReadActivityLogGET: false
            };
        case jobConstants.GET_JOB_OFFERS_SUCCESS:
            return {
                ...state,
                offers: action.payload
            };
        case jobConstants.GET_JOB_OFFERS_ERROR:
            return {
                ...state,
                errors: action.error
            };
        case jobConstants.GET_JOB_OFFERS_REQUEST:
            return {
                ...state,
                send: false
            };
        case jobConstants.GET_JOB_INVOICES_REQUEST:
            return {
                ...state,
                send: true,
                sendJobInvoicesGET: true
            };
        case jobConstants.GET_JOB_INVOICES_SUCCESS:
            return {
                ...state,
                jobInvoices: action.payload?.value?.jobInvoices || [],
                send: false,
                sendJobInvoicesGET: false
            };
        case jobConstants.GET_JOB_INVOICES_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendJobInvoicesGET: false
            };
        case jobConstants.GET_JOB_VALIDATION_STATUSES_REQUEST:
            return {
                ...state,
                send: true,
                sendJobValidationStatusesGet: true
            };
        case jobConstants.GET_JOB_VALIDATION_STATUSES_SUCCESS:
            return {
                ...state,
                jobValidationStatuses: action.payload,
                send: false,
                sendJobValidationStatusesGet: false
            };
        case jobConstants.GET_JOB_VALIDATION_STATUSES_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendJobValidationStatusesGet: false
            };
        case jobConstants.PUT_JOB_REQUEST:
            return {
                ...state,
                send: true,
                sendJobUpdatePUT: true
            };
        case jobConstants.PUT_JOB_SUCCESS:
            return {
                ...state,
                send: false,
                sendJobUpdatePUT: false
            };
        case jobConstants.PUT_JOB_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendJobUpdatePUT: false
            };
        case jobConstants.POST_CANCEL_JOB_REQUEST:
            return {
                ...state,
                send: true,
                sendCancelJobPost: true
            };
        case jobConstants.POST_CANCEL_JOB_SUCCESS:
            return {
                ...state,
                send: false,
                sendCancelJobPost: false
            };
        case jobConstants.POST_CANCEL_JOB_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendCancelJobPost: false
            };
        case jobConstants.POST_COMPLETE_JOB_REQUEST:
            return {
                ...state,
                send: true,
                sendCompleteJobPost: true
            };
        case jobConstants.POST_COMPLETE_JOB_SUCCESS:
            return {
                ...state,
                send: false,
                sendCompleteJobPost: false
            };
        case jobConstants.POST_COMPLETE_JOB_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendCompleteJobPost: false
            };
        case jobConstants.POST_APPROVE_JOB_REQUEST:
            return {
                ...state,
                send: true,
                sendApproveJobPost: true
            };
        case jobConstants.POST_APPROVE_JOB_SUCCESS:
            return {
                ...state,
                send: false,
                sendApproveJobPost: false
            };
        case jobConstants.POST_APPROVE_JOB_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendApproveJobPost: false
            };
        case jobConstants.POST_SET_CALLED_REQUEST:
            return {
                ...state,
                send: true,
                sendSetCalledPost: true
            };
        case jobConstants.POST_SET_CALLED_SUCCESS:
            return {
                ...state,
                send: false,
                sendSetCalledPost: false
            };
        case jobConstants.POST_SET_CALLED_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendSetCalledPost: false
            };
        case jobConstants.POST_NEW_COMPETITOR_OFFER:
            return {
                ...state,
                send: true,
                sendNewCompetitorOfferPOST: true
            };
        case jobConstants.POST_NEW_COMPETITOR_OFFER_SUCCESS:
            return {
                ...state,
                send: false,
                sendNewCompetitorOfferPOST: false
            };
        case jobConstants.POST_NEW_COMPETITOR_OFFER_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendNewCompetitorOfferPOST: false
            };
        case jobConstants.PUT_COMPETITOR_OFFER:
            return {
                ...state,
                send: true,
                sendCompetitorOfferPUT: true
            };
        case jobConstants.PUT_COMPETITOR_OFFER_SUCCESS:
            return {
                ...state,
                send: false,
                sendCompetitorOfferPUT: false
            };
        case jobConstants.PUT_COMPETITOR_OFFER_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendCompetitorOfferPUT: false
            };
        case jobConstants.DELETE_COMPETITOR_OFFER:
            return {
                ...state,
                send: true,
                sendCompetitorOfferDELETE: true
            };
        case jobConstants.DELETE_COMPETITOR_OFFER_SUCCESS:
            return {
                ...state,
                send: false,
                sendCompetitorOfferDELETE: false
            };
        case jobConstants.DELETE_COMPETITOR_OFFER_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendCompetitorOfferDELETE: false
            };
        default:
            return state;
    }
};
