import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

import { formatNumber, translate } from '../../utilities';

export const Number = ({ format, isNumberText, placeholder, addonAfter, addonBefore, ...otherProps }) => {
    const language = useSelector(state => state.language);
    const numberFormat = format || formatNumber().float;
    const placeholderTranslated = placeholder?.value ? translate(placeholder.props.value, false) : placeholder || null;
    const displayType = isNumberText || isNumberText === undefined ? 'text' : 'input';

    const CustomInput = useCallback(
        ({ ...props }) => <Input {...props} addonAfter={addonAfter} addonBefore={addonBefore} />,
        [addonAfter, addonBefore]
    );

    return (
        <NumberFormat
            {...otherProps}
            {...numberFormat}
            language={language}
            placeholder={placeholderTranslated}
            displayType={displayType}
            customInput={CustomInput}
        />
    );
};

Number.propTypes = {
    addonAfter: PropTypes.node,
    addonBefore: PropTypes.node,
    className: PropTypes.string,
    isAllowed: PropTypes.func,
    isNumberText: PropTypes.bool,
    format: PropTypes.object,
    prefix: PropTypes.string,
    autoComplete: PropTypes.string,
    autoCorrect: PropTypes.string,
    autoCapitalize: PropTypes.string,
    spellCheck: PropTypes.string,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onValueChange: PropTypes.func
};
