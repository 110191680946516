import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import { history, userActions } from '../../../api';
import { routerPaths } from '../../../utilities';

class LogoutButton extends React.Component {
    constructor (props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    logout () {
        this.props.dispatch(userActions.logout());
        history.replace(routerPaths.login);
    }

    render () {
        return (
            <Button
                type='text'
                size='large'
                icon={<LogoutOutlined />}
                onClick={() => this.logout()}
            />
        );
    }
}

LogoutButton.propTypes = {
    dispatch: PropTypes.func.isRequired
};

const connectedLogoutButton = connect(null)(LogoutButton);
export { connectedLogoutButton as LogoutButton };
