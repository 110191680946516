import { administrationFeeMarginsConstants } from './constants';

export const administrationFeeMarginsReducers = function (state = {}, action) {
    switch (action.type) {
    case administrationFeeMarginsConstants.GET_ADMINISTRATION_FEE_MARGINS_REQUEST:
        return {
            ...state,
            send: true,
            sendAdministrationFeeMarginsGET: true
        };
    case administrationFeeMarginsConstants.GET_ADMINISTRATION_FEE_MARGINS_SUCCESS:
        return {
            ...state,
            send: false,
            sendAdministrationFeeMarginsGET: false
        };
    case administrationFeeMarginsConstants.GET_ADMINISTRATION_FEE_MARGINS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendAdministrationFeeMarginsGET: false
        };
    case administrationFeeMarginsConstants.PUT_ADMINISTRATION_FEE_MARGINS_REQUEST:
        return {
            ...state,
            send: true,
            sendAdministrationFeeMarginsPUT: true
        };
    case administrationFeeMarginsConstants.PUT_ADMINISTRATION_FEE_MARGINS_SUCCESS:
        return {
            ...state,
            send: false,
            sendAdministrationFeeMarginsPUT: false
        };
    case administrationFeeMarginsConstants.PUT_ADMINISTRATION_FEE_MARGINS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendAdministrationFeeMarginsPUT: false
        };
    default:
        return state;
    }
};
