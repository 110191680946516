/* eslint-disable react/prop-types */
import React from 'react';
import { FormField, Translate, formFieldType } from '../../../../components';
import { Button, Modal } from 'antd';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { jobActions } from '../../../../api';
import { formatNumber, i18, required, translate } from '../../../../utilities';
import OfferForm from './OfferForm';

export default function OfferModal({ toggleModal, jobId, showModal, serviceId }) {
    const offers = useSelector(s => s.jobReducers.offers);

    const dispatch = useDispatch();

    const onSubmit = e => {
        dispatch(jobActions.getJobGeneratedOffers(jobId, e));
    };

    const onApprove = async e => {
        try {
            await dispatch(jobActions.postJobGeneratedOffers(jobId, { offers: Object.values(e) }));
            toggleModal();
            dispatch(jobActions.clearJobGeneratedOffers());
        } catch (error) {}
    };

    const onCancel = () => {
        if (offers.length > 0) {
            const confirmed = window.confirm(translate(i18.Job.Labels.OffersConfirmationQuestion));
            if (confirmed) {
                toggleModal();
                dispatch(jobActions.clearJobGeneratedOffers());
            }
            return;
        }
        toggleModal();
        dispatch(jobActions.clearJobGeneratedOffers());
    };

    return (
        <Modal
            title={<Translate value={i18.Job.Labels.OffersModalTitle} />}
            visible={showModal}
            onCancel={onCancel}
            footer={null}
            maskClosable={false}
            width={'90%'}
        >
            <>
                <Form
                    key={showModal}
                    destroyOnUnregister
                    onSubmit={onSubmit}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <Translate value={i18.Job.Labels.OffersTitle} />
                            </div>
                            <div className="d-flex flex-row align-items-center flex-wrap">
                                <div className="m-1 w-25">
                                    <FormField
                                        title={i18.Job.Labels.OffersClientPrice}
                                        borderTitle
                                        name={'clientPrice'}
                                        validate={required}
                                        component={formFieldType.number}
                                    />
                                </div>
                                <div className="m-1 w-25">
                                    <FormField
                                        title={i18.Job.Labels.OffersAdminFee}
                                        borderTitle
                                        name={'adminFee'}
                                        validate={required}
                                        component={formFieldType.number}
                                    />
                                </div>
                                <div className="m-1 w-25">
                                    <FormField
                                        title={i18.Job.Labels.OffersPartnerReward}
                                        borderTitle
                                        name={'partnerReward'}
                                        validate={required}
                                        component={formFieldType.number}
                                    />
                                </div>
                                <div className="m-1 w-25">
                                    <FormField
                                        title={i18.Job.Labels.OffersMaterialPrice}
                                        borderTitle
                                        name={'materialPrice'}
                                        component={formFieldType.number}
                                    />
                                </div>
                                <div className="m-1 w-25">
                                    <FormField
                                        title={i18.Job.Labels.OffersQuantity}
                                        borderTitle
                                        name={'quantity'}
                                        validate={required}
                                        component={formFieldType.number}
                                        initialValue="3"
                                        formatNumber={formatNumber().number}
                                    />
                                </div>
                                <div className="m-1">
                                    <Button htmlType="submit" className={'rounded job-confirm-button'}>
                                        <Translate value={i18.Job.Labels.OffersGenerate} />
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                />
                <div className="d-flex flex-column mt-1">
                    <div>
                        <Translate value={i18.Job.Labels.OffersGenerated} />
                    </div>
                    <Form
                        onSubmit={onApprove}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <OfferForm offers={offers} jobId={jobId} serviceId={serviceId} />
                                {offers?.length ? (
                                    <div className="d-flex flex-row justify-content-end">
                                        <Button htmlType="submit" className={'m-2 rounded job-confirm-button'}>
                                            <Translate value={i18.Job.Labels.OffersSubmit} />
                                        </Button>
                                        <Button onClick={onCancel} className={'m-2 rounded job-edit-button-cancel'}>
                                            <Translate value={i18.Job.Labels.OffersCancel} />
                                        </Button>
                                    </div>
                                ) : null}
                            </form>
                        )}
                    />
                </div>
            </>
        </Modal>
    );
}
