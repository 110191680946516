import { localJobConstants } from './constants';

export const localJobActions = {
    setJobEditable,
    setJobFilesEditable
};

function setJobEditable(isJobEditable) {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: localJobConstants.SET_JOB_EDITABLE,
            payload: isJobEditable
        };
    }
}

function setJobFilesEditable(isJobFilesEditable) {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {
            type: localJobConstants.SET_JOB_FILES_EDITABLE,
            payload: isJobFilesEditable
        };
    }
}
