import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Translate } from '../../../components';

export const OpenUrlButton = ({ url, title }) => {
  const handleLinkClick = () => {
    const fixedUrl = url.startsWith('http') ? url : `https://${url}`;

    const openedWindow = window.open(fixedUrl, '_blank', 'noopener,noreferrer');
    if (openedWindow) {
      openedWindow.opener = null;
    }
  };

  if (!url) {
    return <></>;
  }

  return (
    <Button
      className="rounded border-0 job-confirm-button mr-3 mb-3"
      onClick={handleLinkClick}
    >
      <Translate value={title} />
    </Button>
  );
};

OpenUrlButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
