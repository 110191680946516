export const jobCreateFormConstants = {
    SET_SELECTED_JOB_STATUS: 'SET_SELECTED_JOB_STATUS',
    SET_SELECTED_CLIENT_SEGMENT: 'SET_SELECTED_CLIENT_SEGMENT',
    SET_SELECTED_VAT_TYPE: 'SET_SELECTED_VAT_TYPE',
    SET_IS_JOB_APPROVAL_NEEDED: 'SET_IS_JOB_APPROVAL_NEEDED',
    SET_IS_JOB_TYPE_EMERGENCY: 'SET_IS_JOB_TYPE_EMERGENCY',
    SET_SELECTED_SERVICE: 'SET_SELECTED_SERVICE',
    SET_SELECTED_SERVICE_GROUP: 'SET_SELECTED_SERVICE_GROUP',
    SET_JOB_DURATION_TIME: 'SET_JOB_DURATION_TIME',
    SET_JOB_DURATION_DAYS: 'SET_JOB_DURATION_DAYS',
    SET_BIDDING_PARTNER_IDS: 'SET_BIDDING_PARTNER_IDS',
    SET_JOB_PARTNER_ID: 'SET_JOB_PARTNER_ID',
    SET_DEFAULT_VALUES: 'SET_DEFAULT_VALUES',
    SET_REDUCER_STATE: 'SET_REDUCER_STATE'
};
