import { requestApi, apiPaths, handleResponse, methods, responseTypes, requestApiFormData } from '../../config';

export const partnerProfileService = {
    getProfile,
    getProfileLogs,
    postProfile,
    pathProfile,
    postProfileImage,
    getProfileDocument,
    postProfileDocument,
    getProfileDocumentPresignedUrl,
    deleteProfileDocument,
    getProfileDocuments,
    getProfileServices,
    getProfileService,
    postProfileServiceDocument,
    getProfileServiceDocuments,
    putProfileService,
    deleteProfileService,
    pathProfileService,
    getProfileAvailableServices,
    postProfileService,
    postResetPassword,
    postProfileSynchronization,
    getProfileServiceItems,
    putProfileServiceItem
};

function getProfile (profileId) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId).then(handleResponse);
}

function getProfileLogs (profileId) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/activityLog').then(handleResponse);
}

function postProfile (data) {
    return requestApi(apiPaths.partnerProfile, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function pathProfile (data) {
    return requestApi(apiPaths.partnerProfile, {
        method: methods.patch,
        data: data
    }).then(handleResponse);
}

function postProfileImage (profileId, data) {
    return requestApiFormData('v2/' + apiPaths.partnerProfile + '/' + profileId + '/profileImage', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function getProfileDocument (profileId, documentId) {
    return requestApi('v2/' + apiPaths.partnerProfile + '/' + profileId + '/document/' + documentId, {
        responseType: responseTypes.arrayBuffer
    }).then(handleResponse);
}

function getProfileDocumentPresignedUrl (profileId, documentId) {
    return requestApi('v2/' + apiPaths.partnerProfile + '/' + profileId + '/documentPresignedUrl/' + documentId).then(handleResponse);
}

function postProfileDocument (profileId, data) {
    return requestApiFormData('v2/' + apiPaths.partnerProfile + '/' + profileId + '/document', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function deleteProfileDocument (profileId, documentId) {
    return requestApi('v2/' + apiPaths.partnerProfile + '/' + profileId + '/document/' + documentId, {
        method: methods.delete
    }).then(handleResponse);
}

function getProfileDocuments (profileId) {
    return requestApi('v2/' + apiPaths.partnerProfile + '/' + profileId + '/documents').then(handleResponse);
}

function getProfileServices (profileId) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/services').then(handleResponse);
}

function getProfileService (profileId, profileServiceId) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/service/' + profileServiceId).then(handleResponse);
}

function postProfileServiceDocument (profileId, profileServiceId, data) {
    return requestApiFormData('v2/' + apiPaths.partnerProfile + '/' + profileId + '/service/' + profileServiceId + '/document', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function getProfileServiceDocuments (profileId, profileServiceId) {
    return requestApi('v2/' + apiPaths.partnerProfile + '/' + profileId + '/service/' + profileServiceId + '/documents').then(handleResponse);
}

function putProfileService (profileId, data) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/service', {
        method: methods.put,
        data: data
    }).then(handleResponse);
}

function deleteProfileService (profileId, profileServiceId) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/service/' + profileServiceId, {
        method: methods.delete
    }).then(handleResponse);
}

function pathProfileService (profileId, data) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/service', {
        method: methods.patch,
        data: data
    }).then(handleResponse);
}

function getProfileAvailableServices (profileId) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/availableServices').then(handleResponse);
}

function postProfileService (profileId, data) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/service', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postResetPassword (profileId) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/resetPassword', {
        method: methods.post
    }).then(handleResponse);
}
function postProfileSynchronization (profileId) {
    return requestApi(apiPaths.partnerProfile + '/' + profileId + '/synchronization/profile', {
        method: methods.post
    }).then(handleResponse);
}

function getProfileServiceItems (profileId) {
    return requestApi(apiPaths.partnerProfileServiceItem.index + profileId + apiPaths.partnerProfileServiceItem.serviceItems)
        .then(handleResponse);
}

function putProfileServiceItem (profileId, data) {
    return requestApi(apiPaths.partnerProfileServiceItem.index + profileId + apiPaths.partnerProfileServiceItem.serviceItem, {
        method: methods.put,
        data
    }).then(handleResponse);
}
