import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { Error, Title } from './';
import { translate } from '../../../utilities';

export const InputFormField = (props) => {
    return (
        <div className={props.inline ? 'form_field_wrapper row align-items-center' : 'form_field_wrapper row'}>
            <Title
                inline={props.inline}
                required={props.required}
                title={props.title}
                titleTranslated={props.titleTranslated}
                borderTitle={props.borderTitle}
                dimmed={props.dimmed}
            />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <Input
                    style={props.style}
                    className={props.borderTitle && `bg-white text-secondary ${props.dimmed && 'ant-input-dimmed'}`}
                    addonAfter={props.addonAfter}
                    addonBefore={props.addonBefore}
                    autoFocus={props.autoFocus}
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    spellCheck='off'
                    maxLength={props.maxLength}
                    onFocus={props.onFocus}
                    disabled={props.disabled}
                    name={props.input.name}
                    onClick={props.onClick}
                    onChange={props.format ? props.format(props.input.onChange) : props.input.onChange}
                    onBlur={props.onBlur}
                    value={props.format ? props.format(props.input.value) : props.input.value}
                    placeholder={props.placeholder && translate(props.placeholder)}
                    size={props.size}
                />
            </div>
            <Error
                error={props.meta.error}
                inline={props.inline}
                touched={props.meta.touched}
            />
        </div>
    );
};

InputFormField.propTypes = {
    addonAfter: PropTypes.any,
    addonBefore: PropTypes.any,
    autoFocus: PropTypes.bool,
    dimmed: PropTypes.bool,
    borderTitle: PropTypes.bool,
    disabled: PropTypes.bool,
    format: PropTypes.func,
    inline: PropTypes.bool,
    input: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.any
    }),
    maxLength: PropTypes.number,
    meta: PropTypes.shape({
        error: PropTypes.any,
        touched: PropTypes.bool
    }),
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
    style: PropTypes.object,
    title: PropTypes.string,
    titleTranslated: PropTypes.bool
};
