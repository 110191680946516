import { JobsStatusConstants } from '../../api';

export const FILE_EDIT_STATUSES = [
    JobsStatusConstants.APPROVED,
    JobsStatusConstants.INPROGRESS,
    JobsStatusConstants.NEW,
    JobsStatusConstants.PAUSED,
    JobsStatusConstants.PREORDER,
    JobsStatusConstants.UNKNOWN,
    JobsStatusConstants.WAITING_FOR_APPROVAL,
    JobsStatusConstants.PAID,
    JobsStatusConstants.RESOLVED,
    JobsStatusConstants.COMPLETED
];
