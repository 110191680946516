import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Input, Table } from 'antd';

import { translationActions, translationConstants } from '../../api';
import { checkJsonProp, i18, i18frontend } from '../../utilities';
import { ContentHeader, Translate } from '../../components';
import { TranslatesLabelsFilterFrontendForm } from './';
import { env } from '../../configs';

class TranslatesLabelsFrontendContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            module: null,
            language: props.publicLanguages[0]?.code || env.REACT_APP_DEFAULT_LANGUAGE,
            translate: null,
            translateEN: null
        };

        this.filter = this.filter.bind(this);
        this.submit = this.submit.bind(this);
        this.change = this.change.bind(this);
    }

    filter (values) {
        this.setState({
            module: values.module,
            language: values.language,
            translate: []
        }, () => {
            this.props.dispatch(translationActions.getLabels('EN', {
                apiGroup: 'Front',
                module: this.state.module
            })).then(res => {
                if (res && res.type === translationConstants.GET_LABELS_SUCCESS) {
                    this.setState({
                        translateEN: res.payload
                    });
                }
            });

            this.props.dispatch(translationActions.getLabels(values.language, {
                apiGroup: 'Front',
                module: this.state.module
            })).then(res => {
                if (res && res.type === translationConstants.GET_LABELS_SUCCESS) {
                    this.setState({
                        translate: res.payload
                    });
                }
            });
        });
    }

    submit (data) {
        this.props.dispatch(translationActions.putLabel(this.state.language, {
            apiGroup: 'Front',
            module: data.label.split('.')[0],
            group: data.label.split('.')[1],
            title: data.label.split('.')[2],
            translate: data.value
        }));
    }

    change (value, label) {
        this.setState(prevState => ({
            translate: {
                ...prevState.translate,
                [label.split('.')[0]]: {
                    ...checkJsonProp(prevState.translate, `${[label.split('.')[0]]}`),
                    [label.split('.')[1]]: {
                        ...checkJsonProp(prevState.translate, `${label.split('.')[0]}.${[label.split('.')[1]]}`),
                        [label.split('.')[2]]: value
                    }
                }
            }
        }));
    }

    render () {
        const modules = Object.keys(i18frontend).map(item => {
            return {
                label: item,
                value: item
            };
        });
        const columns = [
            {
                title: <Translate value={i18.Translates.Labels.Key} />,
                dataIndex: 'label',
                render: (index, item) => (
                    <div>{item.label}</div>
                )
            }, {
                title: <Translate value={i18.Translates.Labels.Default} />,
                render: (index, item) => (
                    <div>{checkJsonProp(this.state.translateEN, item.label)}</div>
                ),
                className: this.state.language === 'EN' ? 'd-none' : ''
            }, {
                title: <Translate value={i18.Translates.Labels.Translate} />,
                dataIndex: 'value',
                render: (index, item) => (
                    <Input
                        value={checkJsonProp(this.state.translate, item.label)}
                        onChange={({ target: { value } }) => this.change(value, item.label)}
                        onBlur={({ target: { value } }) => this.submit({
                            label: item.label,
                            value: value
                        })}
                    />
                )
            }
        ];
        const translates = [];
        const translateModule = i18frontend[this.state.module] || [];

        Object.keys(translateModule).map(group => {
            Object.keys(translateModule[group]).map(item => {
                translates.push({
                    label: `${this.state.module}.${group}.${item}`,
                    value: null
                });
            });
        });

        return (
            <>
                <ContentHeader
                    title={<Translate value={i18.Translates.Titles.LabelsFrontend} />}
                />
                <div className='content_block'>
                    <TranslatesLabelsFilterFrontendForm
                        filter={{
                            language: this.state.language,
                            filter: this.state.filter
                        }}
                        languages={this.props.publicLanguages}
                        modules={modules}
                        submit={this.filter}
                        submitting={this.props.sendTranslationLabels}
                    />
                    <div className='content_box'>
                        <Table
                            columns={columns}
                            dataSource={translates}
                            rowKey='label'
                            size='small'
                            pagination={false}
                        />
                    </div>
                </div>
            </>
        );
    };
}

TranslatesLabelsFrontendContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    publicLanguages: PropTypes.array.isRequired,
    sendTranslationLabels: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        publicLanguages
    } = state.publicReducers;
    const {
        sendTranslationLabels,
        sendTranslationLabelPUT
    } = state.translationReducers;

    return {
        publicLanguages,
        sendTranslationLabels,
        sendTranslationLabelPUT
    };
}

const connectedTranslatesLabelsFrontendContainer = withRouter(connect(mapStateToProps)(TranslatesLabelsFrontendContainer));
export { connectedTranslatesLabelsFrontendContainer as TranslatesLabelsFrontendContainer };
