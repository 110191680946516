import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu, Checkbox, Select } from 'antd';
import { connect } from 'react-redux';
import { Translate, Classificator, FormField, formFieldType } from '../../../components';
import { fieldNames } from './fieldNames';
import { BiddingTypesConstants, ClientSegmentConstants, jobCreateFormActions } from '../../../api';
import { isJobAnOrder } from '..';
import { classificator, classificatorTranslatedArray, i18, i18cl, i18clGroups, translate } from '../../../utilities';
import { isJobAWork } from '../utilities';

class EntryFormSection extends React.Component {
    constructor(props) {
        super(props);

        this.onStatusSelect = this.onStatusSelect.bind(this);
        this.onClientSegmentSelect = this.onClientSegmentSelect.bind(this);
        this.onVatTypeSelect = this.onVatTypeSelect.bind(this);
        this.onApprovalNeedChecked = this.onApprovalNeedChecked.bind(this);
        this.collectWorkStatusesMenuItems = this.collectWorkStatusesMenuItems.bind(this);
        this.collectOrderStatusesMenuItems = this.collectOrderStatusesMenuItems.bind(this);
        this.onBiddingTypeSelect = this.onBiddingTypeSelect.bind(this);
    }

    componentDidMount() {
        this.props.updateKeyValue(fieldNames.needApproval, this.props.isJobApprovalNeeded);
        this.props.updateKeyValue(fieldNames.vatType, this.props.selectedVatType);
        this.props.updateKeyValue(fieldNames.jobStatus, this.props.selectedJobStatus.statusCode);
    }

    onStatusSelect(statusType, statusCode) {
        this.props.dispatch(jobCreateFormActions.setSelectedJobStatus(statusType, statusCode));

        this.props.updateKeyValue(fieldNames.jobStatus, statusCode);

        if (isJobAWork(statusCode)) {
            this.props.updateKeyValue(fieldNames.biddingType, BiddingTypesConstants.FIXED);
        } else {
            this.props.updateKeyValue(fieldNames.partnerReward, undefined);
        }
    }

    onBiddingTypeSelect(biddingType) {
        if (biddingType === BiddingTypesConstants.FACTUAL) {
            this.props.updateKeyValue(fieldNames.partnerReward, undefined);
            this.props.updateKeyValue(fieldNames.clientPrice, undefined);
        }
    }

    onClientSegmentSelect(clientSegment) {
        this.props.dispatch(jobCreateFormActions.setSelectedClientSegment(clientSegment));

        this.props.updateKeyValue(fieldNames.clientSegment, clientSegment);
    }

    onVatTypeSelect(vatType) {
        this.props.dispatch(jobCreateFormActions.setSelectedVatType(vatType));

        this.props.updateKeyValue(fieldNames.vatType, vatType);
    }

    onApprovalNeedChecked(event) {
        this.props.dispatch(jobCreateFormActions.setIsJobApprovalNeeded(event.target.checked));

        this.props.updateKeyValue(fieldNames.needApproval, event.target.checked);
    }

    collectWorkStatusesMenuItems() {
        const { workStatuses } = this.props;

        return workStatuses.map((work, index) => (
            <Menu.Item key={`${work}_${index}`} onClick={() => this.onStatusSelect(i18cl.STATUSES_WORK, work)}>
                <div className="p-1 wrap">
                    <Classificator className="color_primary pr-1" value={i18cl.STATUSES_WORK + work} />
                </div>
            </Menu.Item>
        ));
    }

    collectOrderStatusesMenuItems() {
        const { orderStatuses } = this.props;

        return orderStatuses.map((order, index) => (
            <Menu.Item key={`${order}_${index}`} onClick={() => this.onStatusSelect(i18cl.STATUSES_ORDER, order)}>
                <div className="p-1 wrap">
                    <Classificator className="color_primary pr-1" value={i18cl.STATUSES_ORDER + order} />
                </div>
            </Menu.Item>
        ));
    }

    render() {
        const {
            selectedJobStatus: { statusType, statusCode }
        } = this.props;

        const statusOptions = (
            <Menu selectable>{this.collectOrderStatusesMenuItems().concat(this.collectWorkStatusesMenuItems())}</Menu>
        );

        return (
            <>
                <Translate className="fs-16 fw_600" value={i18.JobCreateForm.Titles.EntryData} />
                <div>
                    <div className="container-default mt-4 mb-4">
                        <div className="row mb-3">
                            <div className="col-12">
                                <Dropdown
                                    className="border-base"
                                    name={fieldNames.jobStatus}
                                    placeholder={translate(i18.JobCreateForm.Labels.Status)}
                                    trigger={['click']}
                                    overlay={statusOptions}
                                    placement="bottomRight"
                                >
                                    <Select
                                        open={false}
                                        style={{ width: '100%', textAlign: 'center' }}
                                        placeholder={translate(i18.JobCreateForm.Labels.Status)}
                                        value={statusCode && classificator(statusType + statusCode)}
                                    />
                                </Dropdown>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <FormField
                                    allowClear={false}
                                    centerText
                                    placeholder={i18.Job.Labels.ClientSegment}
                                    name={fieldNames.clientSegment}
                                    defaultValue={ClientSegmentConstants.ADMINISTRATOR}
                                    component={formFieldType.select}
                                    options={classificatorTranslatedArray(i18clGroups.CLIENT_SEGMENTS)}
                                    onChange={this.onClientSegmentSelect}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Checkbox
                                    checked={this.props.isJobApprovalNeeded}
                                    onChange={this.onApprovalNeedChecked}
                                >
                                    <Translate value={i18.JobCreateForm.Labels.NeedJobApproval} />
                                </Checkbox>
                            </div>
                            {isJobAnOrder(this.props.selectedJobStatus.statusCode) && (
                                <div className="col-6">
                                    <FormField
                                        allowClear={false}
                                        placeholder={i18.Job.Labels.BiddingType}
                                        name={fieldNames.biddingType}
                                        defaultValue={BiddingTypesConstants.STANDARD}
                                        component={formFieldType.select}
                                        options={classificatorTranslatedArray(i18clGroups.BIDDING_TYPES)}
                                        onSelect={this.onBiddingTypeSelect}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

EntryFormSection.propTypes = {
    selectedJobStatus: PropTypes.object,
    selectedVatType: PropTypes.string,
    isJobApprovalNeeded: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    orderStatuses: PropTypes.array,
    workStatuses: PropTypes.array,
    vatTypes: PropTypes.array,
    updateKeyValue: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const { selectedJobStatus, selectedVatType, isJobApprovalNeeded } = state.jobCreateFormReducers;

    return {
        selectedJobStatus,
        selectedVatType,
        isJobApprovalNeeded
    };
}

const connectedEntryFormSection = connect(mapStateToProps)(EntryFormSection);
export { connectedEntryFormSection as EntryFormSection };
