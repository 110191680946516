import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Table } from 'antd';
import { odataActions } from '../../api';
import { oDataQuery, i18 } from '../../utilities';
import { FilterField, filterFieldTypes, Translate } from '../';

class InternalClientSelectionGrid extends React.Component {
    static defaultProps = {
        defaultFilter: {
            filters: [],
            orders: [],
            pagination: {
                current: 1,
                pageSize: 10
            } /*, TODO: figure out why select returns empty array
            select: [
                'ClientFullName',
                'ClientCompanyName',
                'ClientAddress'
            ] */
        }
    }

    constructor (props) {
        super(props);

        this.state = {
            ...this.props.defaultFilter
        };

        this.setFilter = this.setFilter.bind(this);
        this.setOrderAndPagination = this.setOrderAndPagination.bind(this);
        this.onSelectInternalClient = this.onSelectInternalClient.bind(this);
    }

    componentDidMount () {
        this.fetch();
    }

    fetch () {
        const { dispatch } = this.props;

        dispatch(odataActions.getInternalClientsGrid(oDataQuery(this.state)));
    }

    setFilter (filter, resetPagination) {
        this.setState(prevState => ({
            filters: [
                ...prevState.filters.filter(e => e.name !== filter.name),
                ...filter.value !== null ? [filter] : []
            ],
            ...(resetPagination && {
                pagination: this.props.defaultFilter.pagination
            })
        }), () => {
            this.fetch();
        });
    }

    setOrderAndPagination (pagination, columns, order) {
        const orders = [];

        if (Array.isArray(order)) {
            orders.push(
                ...order.map(item => {
                    return {
                        name: item.columnKey,
                        order: item.order === 'ascend' ? 'asc' : 'desc'
                    };
                })
            );
        } else if (order.order) {
            orders.push({
                name: order.columnKey,
                order: order.order === 'ascend' ? 'asc' : 'desc'
            });
        }

        this.setState({
            orders: orders,
            pagination: {
                current: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.fetch();
        });
    }

    onSelectInternalClient (internalClient) {
        this.props.onInternalClientSelected(internalClient);
    }

    render () {
        const columns = [
            {
                dataIndex: 'ClientFirstName',
                key: 'ClientFirstName',
                title: <Translate value={i18.InternalClientSelectionGrid.Labels.ClientFirstName} />,
                ...FilterField({
                    name: 'ClientFirstName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'ClientFirstName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'ClientFirstName')
                })
            }, {
                dataIndex: 'ClientLastName',
                key: 'ClientLastName',
                title: <Translate value={i18.InternalClientSelectionGrid.Labels.ClientLastName} />,
                ...FilterField({
                    name: 'ClientLastName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'ClientLastName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'ClientLastName')
                })
            }, {
                dataIndex: 'ClientCompanyName',
                key: 'ClientCompanyName',
                title: <Translate value={i18.InternalClientSelectionGrid.Labels.ClientCompanyName} />,
                ...FilterField({
                    name: 'ClientCompanyName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'ClientCompanyName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'ClientCompanyName')
                })
            }, {
                dataIndex: 'ClientAddress',
                key: 'ClientAddress',
                title: <Translate value={i18.InternalClientSelectionGrid.Labels.ClientAddress} />,
                ...FilterField({
                    name: 'ClientAddress',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'ClientAddress'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'ClientAddress')
                })
            }
        ];

        return (
            <div>
                <div className='content_block'>
                    <ScrollContainer
                        style={{
                            overflowX: 'auto'
                        }}
                    >
                        <Table
                            rowKey='Key'
                            onChange={(pagination, columns, order) => this.setOrderAndPagination(pagination, columns, order)}
                            rowClassName='cursor_pointer'
                            columns={columns}
                            dataSource={this.props.odataInternalClientsGrid.value || []}
                            loading={this.props.sendOdataInternalClientsGrid}
                            onRow={internalClient => {
                                return {
                                    onClick: () => this.onSelectInternalClient(internalClient)
                                };
                            }}
                            pagination={{
                                total: this.props.odataInternalClientsGrid['@odata.count'],
                                pageSize: this.state.pagination.pageSize,
                                current: this.state.pagination.current
                            }}
                        />
                    </ScrollContainer>
                </div>
            </div>
        );
    }
};

InternalClientSelectionGrid.propTypes = {
    onInternalClientSelected: PropTypes.func.isRequired,
    defaultFilter: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    odataInternalClientsGrid: PropTypes.object.isRequired,
    sendOdataInternalClientsGrid: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        odataInternalClientsGrid,
        sendOdataInternalClientsGrid
    } = state.odataReducers;

    return {
        odataInternalClientsGrid,
        sendOdataInternalClientsGrid
    };
}

const connectedInternalClientSelectionGrid = connect(mapStateToProps)(InternalClientSelectionGrid);
export { connectedInternalClientSelectionGrid as InternalClientSelectionGrid };
