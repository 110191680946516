import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../../../components';
import { classificatorArray, getCurrentDate, i18, i18cl } from '../../../../../utilities';

export const PartnerCommunicationsForm = (props) => {
    return (
        <Form
            onSubmit={props.submit}
            initialValues={props.data}
            render={({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <FormField
                        inline
                        component={formFieldType.date}
                        time
                        title={i18.PartnerCommunicationsForm.Labels.LastCommunication}
                        name='lastCommunication'
                        disabled={props.submitting}
                        maxDate={{
                            date: getCurrentDate(1)
                        }}
                    />
                    <FormField
                        inline
                        component={formFieldType.input}
                        title={i18.PartnerCommunicationsForm.Labels.LastSentInformation}
                        name='lastSentInformation'
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        component={formFieldType.date}
                        time
                        title={i18.PartnerCommunicationsForm.Labels.NextScheduledCall}
                        name='nextScheduledCall'
                        disabled={props.submitting}
                        minDate={{
                            date: getCurrentDate(0)
                        }}
                    />
                    <FormField
                        inline
                        component={formFieldType.date}
                        time
                        title={i18.PartnerCommunicationsForm.Labels.DateOfLastSentInformation}
                        name='dateOfLastSentInformation'
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        component={formFieldType.select}
                        title={i18.PartnerCommunicationsForm.Labels.NonApprovalReasons}
                        name='nonApprovalReason'
                        options={classificatorArray(i18cl.NON_APPROVAL_REASON, props.data.nonApprovalReasons)}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        component={formFieldType.textarea}
                        title={i18.PartnerCommunicationsForm.Labels.Comments}
                        name='comments'
                        disabled={props.submitting}
                    />
                    <div className='text-right'>
                        <ButtonGroup>
                            <ButtonWrapper
                                type='text'
                                disabled={props.submitting}
                                onClick={() => props.cancel()}
                            >
                                <Translate value={i18.PartnerCommunicationsForm.Buttons.Cancel} />
                            </ButtonWrapper>
                            <ButtonWrapper
                                type='primary'
                                htmlType='submit'
                                loading={props.submitting}
                            >
                                <Translate value={i18.PartnerCommunicationsForm.Buttons.Submit} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    </div>
                </form>
            )}
        />
    );
};

PartnerCommunicationsForm.propTypes = {
    cancel: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};
