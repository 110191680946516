import { store } from '../api';

export const getContentString = (key) => {
    const state = store.getState();
    const { publicContent } = state.publicReducers;
    const hasContent = Object.prototype.hasOwnProperty.call(publicContent, key);
    if (hasContent) {
        return publicContent[key];
    };
    return key;
};
