import { env } from '../configs';
import { Environments } from '../configs/environments';

export const getDomain = () => {
    let domain = window.location.hostname.split('.').pop();

    if (domain === 'localhost') {
        domain = env.REACT_APP_DEFAULT_COUNTRY;
    }

    if (process.env.NODE_ENV === Environments.Development ||
        process.env.NODE_ENV === Environments.DockerCompose) {
        domain = env.REACT_APP_DEFAULT_COUNTRY;
    }

    return domain.toUpperCase();
};
