import { requestApi, apiPaths, handleResponse, methods } from '../../config';

export const countrySettingsServices = {
    getCountrySettings,
    postCountrySettings,
    putCountrySettings,
    getCountrySettingsById
};

function getCountrySettings () {
    return requestApi(apiPaths.countrySettings).then(handleResponse);
}

function postCountrySettings (data) {
    return requestApi(apiPaths.countrySettings, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function putCountrySettings (data) {
    return requestApi(apiPaths.countrySettings, {
        method: methods.put,
        data: data
    }).then(handleResponse);
}

function getCountrySettingsById (countrySettingId) {
    return requestApi(apiPaths.countrySettings + '/' + countrySettingId).then(handleResponse);
}
