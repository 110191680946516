import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Menu, Select } from 'antd';
import { Translate, Classificator, FormField, formFieldType } from '../../../components';
import { jobCreateFormActions } from '../../../api';
import { fieldNames } from './fieldNames';
import {
    classificator,
    i18,
    i18cl,
    translate,
    required,
    getCurrentDate
} from '../../../utilities';

class ServiceSettingsFormSection extends React.Component {
    constructor (props) {
        super(props);

        this.onServiceGroupSelect = this.onServiceGroupSelect.bind(this);
        this.onServiceSelect = this.onServiceSelect.bind(this);
    }

    onServiceGroupSelect (serviceGroup) {
        this.props.dispatch(jobCreateFormActions.setSelectedServiceGroup(serviceGroup.serviceGroupCode));
        this.props.dispatch(jobCreateFormActions.setSelectedService({}));
        this.props.updateKeyValue(fieldNames.serviceId, undefined);
    }

    onServiceSelect (service) {
        this.props.dispatch(jobCreateFormActions.setSelectedService(service));
        this.props.updateKeyValue(fieldNames.serviceId, service.serviceId);
    }

    render () {
        const {
            services,
            selectedService: { serviceCode }
        } = this.props;

        const selectedServiceGroup = this.props.selectedServiceGroup;

        const servicesByGroup = selectedServiceGroup
            ? services.find(serviceGroup => serviceGroup.serviceGroupCode === selectedServiceGroup).services
            : [];

        const serviceGroupsOptions = (
            <Menu
                selectable
            >
                {services.map((item, index) =>
                    <Menu.Item
                        key={index}
                        onClick={() => this.onServiceGroupSelect(item)}
                    >
                        <div className='p-1 wrap'>
                            <Classificator
                                className='color_primary pr-1'
                                value={i18cl.GROUPS_SERVICE + item.serviceGroupCode}
                            />
                        </div>
                    </Menu.Item>
                )}
            </Menu>
        );

        const servicesOptions = (
            <Menu
                selectable
            >
                {servicesByGroup.map((item, index) =>
                    <Menu.Item
                        key={index}
                        onClick={() => this.onServiceSelect(item)}
                    >
                        <div className='p-1 wrap'>
                            <Classificator
                                className='color_primary pr-1'
                                value={i18cl.SERVICES_CATALOG + item.serviceCode}
                            />
                        </div>
                    </Menu.Item>
                )}
            </Menu>
        );

        return (
            <>
                <Translate className='fs-16 fw_600' value={i18.JobCreateForm.Titles.ServiceSettings} />
                <div className='container-default mt-4'>
                    <div className='row mb-3'>
                        <div className='col-6'>
                            <Dropdown
                                trigger={['click']}
                                overlay={serviceGroupsOptions}
                                placement='bottomRight'
                            >
                                <Select
                                    open={false}
                                    style={{ width: '100%' }}
                                    placeholder={translate(i18.JobCreateForm.Labels.ServiceGroup)}
                                    value={selectedServiceGroup &&
                                        classificator(i18cl.GROUPS_SERVICE + selectedServiceGroup)
                                    }
                                />
                            </Dropdown>
                        </div>
                        <div className='col-6'>
                            <Dropdown
                                trigger={['click']}
                                overlay={servicesOptions}
                                placement='bottomRight'
                                name={fieldNames.serviceId}
                            >
                                <Select
                                    open={false}
                                    style={{ width: '100%' }}
                                    placeholder={translate(i18.JobCreateForm.Labels.SelectService)}
                                    value={serviceCode &&
                                        classificator(i18cl.SERVICES_CATALOG + serviceCode)
                                    }
                                />
                            </Dropdown>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-12'>
                            <FormField
                                placeholder={translate(i18.JobCreateForm.Labels.JobDescription)}
                                name={fieldNames.jobDescription}
                                component={formFieldType.textarea}
                                maxLength={2000}
                            />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-12'>
                            <FormField
                                placeholder={i18.JobCreateForm.Labels.JobDeadline}
                                name={fieldNames.jobDeadline}
                                component={formFieldType.date}
                                validate={required}
                                minDate={{
                                    date: getCurrentDate(0)
                                }}
                                time
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

ServiceSettingsFormSection.propTypes = {
    services: PropTypes.array,
    dispatch: PropTypes.func,
    selectedService: PropTypes.object,
    selectedServiceGroup: PropTypes.string,
    updateKeyValue: PropTypes.func.isRequired
};

function mapStateToProps (state) {
    const {
        selectedService,
        selectedServiceGroup
    } = state.jobCreateFormReducers;

    return {
        selectedService,
        selectedServiceGroup
    };
}

const connectedServiceSettingsFormSection = connect(mapStateToProps)(ServiceSettingsFormSection);
export { connectedServiceSettingsFormSection as ServiceSettingsFormSection };
