import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class DashboardContainer extends React.Component {
    render () {
        return (
            <div className='content_title'>Dashboard</div>
        );
    }
}

const connectedDashboardContainer = withRouter(connect(null)(DashboardContainer));
export { connectedDashboardContainer as DashboardContainer };
