import { jobCreateFormConstants } from './constants';
import { JobsStatusConstants, JobVatTypeConstants, ClientSegmentConstants } from '../../constants';
import { i18cl } from '../../../utilities';

const defaultValues = {
    // entryFormSection
    selectedJobStatus: {
        statusCode: JobsStatusConstants.NEW,
        statusType: i18cl.STATUSES_ORDER
    },
    selectedClientSegment: ClientSegmentConstants.ADMINISTRATOR,
    isJobApprovalNeeded: true,
    selectedVatType: JobVatTypeConstants.STANDARD,

    // settings
    isJobTypeEmergency: false,

    // service settings
    selectedService: {},
    selectedServiceGroup: undefined,

    // partner form
    biddingPartnerIds: [],

    // additional information
    jobPartnerId: undefined,
    jobDurationTime: undefined,
    jobDurationDays: 0
};

export const jobCreateFormReducers = function (state = {
    ...defaultValues
}, action) {
    switch (action.type) {
    case jobCreateFormConstants.SET_SELECTED_CLIENT_SEGMENT:
        return {
            ...state,
            selectedClientSegment: action.payload
        };
    // entryFormSection
    case jobCreateFormConstants.SET_SELECTED_JOB_STATUS:
        return {
            ...state,
            selectedJobStatus: action.payload
        };
    case jobCreateFormConstants.SET_SELECTED_VAT_TYPE:
        return {
            ...state,
            selectedVatType: action.payload
        };
    case jobCreateFormConstants.SET_IS_JOB_APPROVAL_NEEDED:
        return {
            ...state,
            isJobApprovalNeeded: action.payload
        };
    // settings
    case jobCreateFormConstants.SET_IS_JOB_TYPE_EMERGENCY:
        return {
            ...state,
            isJobTypeEmergency: action.payload
        };
    // service settings
    case jobCreateFormConstants.SET_SELECTED_SERVICE:
        return {
            ...state,
            selectedService: action.payload
        };
    case jobCreateFormConstants.SET_SELECTED_SERVICE_GROUP:
        return {
            ...state,
            selectedServiceGroup: action.payload
        };
    // partners form
    case jobCreateFormConstants.SET_BIDDING_PARTNER_IDS:
        return {
            ...state,
            biddingPartnerIds: action.payload
        };
    // additional information
    case jobCreateFormConstants.SET_JOB_PARTNER_ID:
        return {
            ...state,
            jobPartnerId: action.payload
        };
    case jobCreateFormConstants.SET_JOB_DURATION_TIME:
        return {
            ...state,
            jobDurationTime: action.payload
        };
    case jobCreateFormConstants.SET_JOB_DURATION_DAYS:
        return {
            ...state,
            jobDurationDays: action.payload
        };
    // jobCreateForm
    case jobCreateFormConstants.SET_DEFAULT_VALUES:
        return {
            ...defaultValues
        };
    case jobCreateFormConstants.SET_REDUCER_STATE:
        return {
            ...action.payload
        };
    default:
        return state;
    }
};
