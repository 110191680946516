import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { servicesActions, servicesConstants } from '../../../api';
import {
    classificatorArray,
    formatNumber,
    getCurrentDate,
    i18,
    i18cl,
    required,
    getContentString,
    requiredArray
} from '../../../utilities';
import { Classificator, FormField, formFieldType, Translate } from '../../../components';
import { ClientSegmentConstants, clientSegments, StopTypesConstants } from '../../../api/constants';

export const ServiceEditForm = ({ data }) => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const sendServicesPUT = useSelector(state => state.servicesReducers.sendServicesPUT);
    const countryVats = useSelector(state => state.countryVatReducers.countryVats);
    const featureToggles = useSelector(state => state.featureTogglesReducers.featureToggles);

    const constructVatOptions = useMemo(
        () =>
            countryVats.vats.map(vat => ({
                value: vat.name,
                label: getContentString(`${i18cl.INVOICING_VAT}${vat.name}`)
            })),
        [countryVats.vats]
    );

    const toggle = useCallback(state => {
        setVisible(state);
    }, []);

    const validateSegemnts = useCallback(value => {
        const isError = !!requiredArray(value);

        return isError ? i18.ServicesFormEdit.Errors.Segments : undefined;
    }, []);

    const submit = useCallback(
        values => {
            if (Object.prototype.hasOwnProperty.call(values, 'vatName') && countryVats.vats.length > 0) {
                const selectedVat = countryVats.vats.find(v => v.name === values.vatName);
                values.vatPercentage = selectedVat?.percentage;
            } else {
                values.vatPercentage = null;
            }

            const jobTypes = {
                priceList: StopTypesConstants.PRICE_LIST,
                freeText: StopTypesConstants.FREE_TEXT,
                bidding: StopTypesConstants.BIDDING
            };

            const serviceStoppedJobsList = Object.keys(jobTypes)
                .filter(key => values[`${key}StopType`])
                .flatMap(key =>
                    values[`${key}Segments`].map(segment => ({
                        stoppedJobType: jobTypes[key],
                        clientSegment: segment
                    }))
                );
            values.serviceStoppedJobsList = serviceStoppedJobsList;

            dispatch(servicesActions.putServices(values)).then(res => {
                if (res && res.type === servicesConstants.PUT_SERVICES_SUCCESS) {
                    toggle(false);
                    dispatch(servicesActions.getServicesById(data.serviceCatalogId, true));
                }
            });
        },
        [countryVats.vats, data.serviceCatalogId, dispatch, toggle]
    );

    const initialData = useMemo(() => {
        const initialData = { ...data };

        const jobTypes = {
            priceList: StopTypesConstants.PRICE_LIST,
            freeText: StopTypesConstants.FREE_TEXT,
            bidding: StopTypesConstants.BIDDING
        };

        initialData.priceListStopType = false;
        initialData.freeTextStopType = false;
        initialData.biddingStopType = false;
        initialData.priceListSegments = [];
        initialData.freeTextSegments = [];
        initialData.biddingSegments = [];

        if (initialData.serviceStoppedJobsList) {
            // Loop through each job type
            Object.keys(jobTypes).forEach(key => {
                // Filter the serviceStoppedJobsList for the current job type
                const filteredJobs = initialData.serviceStoppedJobsList.filter(
                    item => item.stoppedJobType === jobTypes[key]
                );

                // If we have any jobs of this type, set the corresponding StopType to true
                if (filteredJobs.length) {
                    initialData[`${key}StopType`] = true;
                }

                // Extract segmentKeys from the filtered jobs, ignoring UNDEFINED
                const segmentKeys = [];
                filteredJobs.forEach(item => {
                    if (item.clientSegment !== ClientSegmentConstants.UNDEFINED) {
                        segmentKeys.push(item.clientSegment);
                    }
                });

                // If we have any valid segmentKeys, add them to the initialData
                if (segmentKeys.length) {
                    initialData[`${key}Segments`] = segmentKeys;
                }
            });
        }

        if (initialData.vatPercentage !== null) {
            const vat = countryVats.vats.find(v => v.percentage === initialData.vatPercentage);
            initialData.vatName = vat ? vat.name : '';
        }

        return initialData;
    }, [data, countryVats.vats]);

    return (
        <div>
            <Button type="link" icon={<EditOutlined />} onClick={() => toggle(true)}>
                <Translate value={i18.ServicesFormEdit.Buttons.Edit} />
            </Button>
            <Modal
                title={<Classificator value={i18cl.SERVICES_CATALOG + data.serviceCatalogCode} />}
                visible={visible}
                onCancel={() => toggle(false)}
                footer={null}
                maskClosable={false}
                destroyOnClose
            >
                <Form
                    onSubmit={submit}
                    initialValues={initialData}
                    render={({ handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <FormField
                                    component={formFieldType.select}
                                    title={i18.ServicesFormEdit.Labels.Group}
                                    options={classificatorArray(i18cl.GROUPS_SERVICE, data.serviceGroupList)}
                                    disabled={sendServicesPUT}
                                    name="serviceGroup"
                                    validate={required}
                                />
                                <FormField
                                    component={formFieldType.number}
                                    formatNumber={formatNumber().numberPositive}
                                    title={i18.ServicesFormEdit.Labels.Priority}
                                    disabled={sendServicesPUT}
                                    name="priority"
                                />
                                <FormField
                                    component={formFieldType.input}
                                    title={i18.ServicesFormEdit.Labels.ExternalServiceId}
                                    disabled={sendServicesPUT}
                                    name="externalServiceID"
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServicesFormEdit.Labels.ValidFrom}
                                    name="validFrom"
                                    validate={required}
                                    disabled={getCurrentDate() > values.validFrom || sendServicesPUT}
                                    minDate={{
                                        date: getCurrentDate()
                                    }}
                                    maxDate={{
                                        date: values.validTo
                                    }}
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServicesFormEdit.Labels.ValidTo}
                                    name="validTo"
                                    disabled={sendServicesPUT}
                                    minDate={{
                                        date: values.validFrom || getCurrentDate()
                                    }}
                                />
                                {!featureToggles.allowVatEditingForJob && (
                                    <FormField
                                        title={i18.ServicesFormEdit.Labels.VatPercentage}
                                        name="vatName"
                                        options={constructVatOptions}
                                        component={formFieldType.select}
                                        disabled={sendServicesPUT}
                                    />
                                )}
                                <FormField
                                    component={formFieldType.checkbox}
                                    title={i18.ServicesFormEdit.Labels.CanReverseVatBeApplied}
                                    disabled={sendServicesPUT}
                                    name="canReverseVatBeApplied"
                                />
                                <FormField
                                    component={formFieldType.textarea}
                                    title={i18.ServicesFormEdit.Labels.ServiceDetailsDescription}
                                    disabled={sendServicesPUT}
                                    validate={required}
                                    maxLength={1500}
                                    name="serviceDetails.description"
                                />
                                <FormField
                                    component={formFieldType.textarea}
                                    title={i18.ServicesFormEdit.Labels.ServiceDetailsAdditionalDescription}
                                    disabled={sendServicesPUT}
                                    maxLength={1500}
                                    name="serviceDetails.additionalDescription"
                                />
                                <FormField
                                    component={formFieldType.number}
                                    title={i18.ServicesFormEdit.Labels.ServiceDetailsPrice}
                                    formatNumber={formatNumber().floatPositiveUpTo2Decimal}
                                    disabled={sendServicesPUT}
                                    name="serviceDetails.price"
                                />
                                <FormField
                                    component={formFieldType.input}
                                    title={i18.ServicesFormEdit.Labels.ServiceDetailsKeywords}
                                    disabled={sendServicesPUT}
                                    name="serviceDetails.keywords"
                                />
                                <FormField
                                    component={formFieldType.input}
                                    title={i18.ServicesFormEdit.Labels.ServiceDetailsHintText}
                                    disabled={sendServicesPUT}
                                    validate={required}
                                    name="serviceDetails.hintText"
                                />
                                <FormField
                                    component={formFieldType.checkbox}
                                    title={i18.ServicesFormEdit.Labels.ShowInWeb}
                                    disabled={sendServicesPUT}
                                    name="showInWeb"
                                />
                                <FormField
                                    component={formFieldType.checkbox}
                                    title={i18.ServicesFormEdit.Labels.ShowInRegistrationForm}
                                    disabled={sendServicesPUT}
                                    name="showInRegistrationForm"
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServicesFormEdit.Labels.ValidFromWeb}
                                    name="webValidFrom"
                                    disabled={sendServicesPUT}
                                    minDate={{
                                        date: getCurrentDate()
                                    }}
                                    maxDate={{
                                        date: values.webValidTo
                                    }}
                                    hidden={!values.showInWeb}
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.ServicesFormEdit.Labels.ValidToWeb}
                                    name="webValidTo"
                                    disabled={sendServicesPUT}
                                    minDate={{
                                        date: values.webValidFrom || getCurrentDate()
                                    }}
                                    hidden={!values.showInWeb}
                                />
                                <FormField
                                    title={i18.ServicesFormEdit.Labels.ServiceDetailsOrder}
                                    name="serviceDetails.order"
                                    component={formFieldType.number}
                                    maxLength={1500}
                                    disabled={sendServicesPUT}
                                    formatNumber={formatNumber().floatPositiveUpTo2Decimal}
                                    validate={required}
                                />
                                <div className="mb-5">
                                    <Translate value={i18.ServicesFormEdit.Labels.ServiceDetailsSelectStopJobType} />
                                </div>
                                <FormField
                                    component={formFieldType.switch}
                                    title={i18.ServicesFormEdit.Labels.ServiceDetailsStopPriceList}
                                    disabled={sendServicesPUT}
                                    name="priceListStopType"
                                    justify={true}
                                />
                                <FormField
                                    key={values.priceListStopType ? 'enabled-1' : 'disabled-1'}
                                    component={formFieldType.multiCheckbox}
                                    disabled={sendServicesPUT || !values.priceListStopType}
                                    inline={false}
                                    name="priceListSegments"
                                    options={Object.values(clientSegments)}
                                    validate={values.priceListStopType ? validateSegemnts : null}
                                />
                                <FormField
                                    component={formFieldType.switch}
                                    title={i18.ServicesFormEdit.Labels.ServiceDetailsStopFreeText}
                                    disabled={sendServicesPUT}
                                    name="freeTextStopType"
                                    justify={true}
                                />
                                <FormField
                                    key={values.freeTextStopType ? 'enabled-2' : 'disabled-2'}
                                    component={formFieldType.multiCheckbox}
                                    disabled={sendServicesPUT || !values.freeTextStopType}
                                    inline={false}
                                    name="freeTextSegments"
                                    options={Object.values(clientSegments)}
                                    validate={values.freeTextStopType ? validateSegemnts : null}
                                />
                                <FormField
                                    component={formFieldType.switch}
                                    title={i18.ServicesFormEdit.Labels.ServiceDetailsStopBidding}
                                    disabled={sendServicesPUT}
                                    name="biddingStopType"
                                    justify={true}
                                />
                                <FormField
                                    key={values.biddingStopType ? 'enabled-3' : 'disabled-3'}
                                    component={formFieldType.multiCheckbox}
                                    disabled={sendServicesPUT || !values.biddingStopType}
                                    inline={false}
                                    name="biddingSegments"
                                    options={Object.values(clientSegments)}
                                    validate={values.biddingStopType ? validateSegemnts : null}
                                />
                                <div className="text-right">
                                    <Button type="primary" htmlType="submit" loading={sendServicesPUT}>
                                        <Translate value={i18.ServicesForm.Buttons.Submit} />
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                />
            </Modal>
        </div>
    );
};

ServiceEditForm.propTypes = {
    data: PropTypes.object.isRequired
};
