export const partnerReadConstants = {
    GET_PARTNER_READ_ACTIVITY_LOG_REQUEST: 'GET_PARTNER_READ_ACTIVITY_LOG_REQUEST',
    GET_PARTNER_READ_ACTIVITY_LOG_SUCCESS: 'GET_PARTNER_READ_ACTIVITY_LOG_SUCCESS',
    GET_PARTNER_READ_ACTIVITY_LOG_ERROR: 'GET_PARTNER_READ_ACTIVITY_LOG_ERROR',

    GET_PARTNER_READ_COMMUNICATION_LOG_REQUEST: 'GET_PARTNER_READ_COMMUNICATION_LOG_REQUEST',
    GET_PARTNER_READ_COMMUNICATION_LOG_SUCCESS: 'GET_PARTNER_READ_COMMUNICATION_LOG_SUCCESS',
    GET_PARTNER_READ_COMMUNICATION_LOG_ERROR: 'GET_PARTNER_READ_COMMUNICATION_LOG_ERROR',

    GET_PARTNER_MARKETING_AGREED_REQUEST: 'GET_PARTNER_MARKETING_AGREED_REQUEST',
    GET_PARTNER_MARKETING_AGREED_SUCCESS: 'GET_PARTNER_MARKETING_AGREED_SUCCESS',
    GET_PARTNER_MARKETING_AGREED_ERROR: 'GET_PARTNER_MARKETING_AGREED_ERROR',

    GET_PARTNER_MARKETING_AGREED_AND_APPROVED_REQUEST: 'GET_PARTNER_MARKETING_AGREED_AND_APPROVED_REQUEST',
    GET_PARTNER_MARKETING_AGREED_AND_APPROVED_SUCCESS: 'GET_PARTNER_MARKETING_AGREED_AND_APPROVED_SUCCESS',
    GET_PARTNER_MARKETING_AGREED_AND_APPROVED_ERROR: 'GET_PARTNER_MARKETING_AGREED_AND_APPROVED_ERROR'
};
