import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { EditOutlined, FileImageOutlined, LoadingOutlined } from '@ant-design/icons';
import { serialize } from 'object-to-formdata';

import { getCurrentDate, i18, translate } from '../../utilities';
import { servicesActions, servicesConstants } from '../../api';
import { Message } from '../';

class ServiceImage extends React.Component {
    constructor (props) {
        super(props);

        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.update = this.update.bind(this);
    }

    validate (type) {
        if (type && type.includes('image/')) {
            return true;
        } else {
            Message({
                type: 'warning',
                message: translate(i18.Components.Cropper.FileNotValid)
            });

            return false;
        }
    }

    submit (values) {
        const data = serialize(values);

        this.props.dispatch(servicesActions.postServiceImage(this.props.service.serviceCatalogId, data)).then(res => {
            if (res && res.type === servicesConstants.POST_SERVICE_IMAGE_SUCCESS) {
                this.update();
            }
        });
    }

    update () {
        this.props.dispatch(servicesActions.getServicesById(this.props.service.serviceCatalogId, true));
    }

    render () {
        const loading = this.props.sendService || this.props.sendServiceImage;

        return (
            <div className='profile_image'>
                <ImgCrop
                    grid
                    rotate
                    modalWidth={800}
                    modalTitle={translate(i18.Components.Cropper.ProfileImage)}
                    beforeCrop={(file) => this.validate(file.type)}
               
                >
                    <Upload
                        accept='image/*'
                        showUploadList={false}
                        customRequest={({ file, onSuccess }) => {
                            setTimeout(() => {
                                onSuccess('ok');
                                this.submit({
                                    validFrom: getCurrentDate(),
                                    validTo: getCurrentDate(100000),
                                    file: file
                                });
                            });
                        }}
                    >
                        <Avatar
                            size={128}
                            src={loading ? undefined : this.props.service.picturePath || undefined}
                            icon={loading ? (
                                <LoadingOutlined
                                    spin
                                />
                            ) : <FileImageOutlined />}
                        />
                        <div className='profile_image_edit'>
                            <EditOutlined />
                        </div>
                    </Upload>
                </ImgCrop>
            </div>
        );
    }
}

ServiceImage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    service: PropTypes.object,
    sendService: PropTypes.bool,
    sendServiceImage: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        service,
        sendService,
        sendServiceImage
    } = state.servicesReducers;

    return {
        service,
        sendService,
        sendServiceImage
    };
}

const connectedServiceImage = connect(mapStateToProps)(ServiceImage);
export { connectedServiceImage as ServiceImage };
