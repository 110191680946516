import { translationServices } from './services';
import { translationConstants } from './constants';
import { Message } from '../../../components';
import { i18, translate } from '../../../utilities';

export const translationActions = {
    getAdminFooter,
    getClassificationGroups,
    getClassifications,
    putClassification,
    getContent,
    putContent,
    getErrorMessages,
    putErrorMessage,
    getLabels,
    putLabel
};

function getAdminFooter (language) {
    return dispatch => {
        dispatch(request());

        return translationServices.getContent(language, 'AdminFooter')
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.GET_TRANSLATION_ADMIN_FOOTER_REQUEST };
    }

    function success (data) {
        return {
            type: translationConstants.GET_TRANSLATION_ADMIN_FOOTER_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.GET_TRANSLATION_ADMIN_FOOTER_ERROR,
            error
        };
    }
}

function getClassificationGroups () {
    return dispatch => {
        dispatch(request());

        return translationServices.getClassificationGroups()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.GET_CLASSIFICATION_GROUPS_REQUEST };
    }

    function success (data) {
        return {
            type: translationConstants.GET_CLASSIFICATION_GROUPS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.GET_CLASSIFICATION_GROUPS_ERROR,
            error
        };
    }
}

function getClassifications (language, query) {
    return dispatch => {
        dispatch(request());

        return translationServices.getClassifications(language, query)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.GET_CLASSIFICATIONS_REQUEST };
    }

    function success (data) {
        return {
            type: translationConstants.GET_CLASSIFICATIONS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.GET_CLASSIFICATIONS_ERROR,
            error
        };
    }
}

function putClassification (language, data) {
    return dispatch => {
        dispatch(request());

        return translationServices.putClassification(language, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.PUT_CLASSIFICATION_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.TranslatedClassificator)
        });

        return {
            type: translationConstants.PUT_CLASSIFICATION_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.PUT_CLASSIFICATION_ERROR,
            error
        };
    }
}

function getContent (language, title) {
    return dispatch => {
        dispatch(request());

        return translationServices.getContent(language, title)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.GET_CONTENT_REQUEST };
    }

    function success (data) {
        return {
            type: translationConstants.GET_CONTENT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.GET_CONTENT_ERROR,
            error
        };
    }
}

function putContent (language, data) {
    return dispatch => {
        dispatch(request());

        return translationServices.putContent(language, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.PUT_CONTENT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.TranslatedContent)
        });

        return {
            type: translationConstants.PUT_CONTENT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.PUT_CONTENT_ERROR,
            error
        };
    }
}

function getErrorMessages (language) {
    return dispatch => {
        dispatch(request());

        return translationServices.getErrorMessages(language)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.GET_ERROR_MESSAGES_REQUEST };
    }

    function success (data) {
        return {
            type: translationConstants.GET_ERROR_MESSAGES_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.GET_ERROR_MESSAGES_ERROR,
            error
        };
    }
}

function putErrorMessage (language, data) {
    return dispatch => {
        dispatch(request());

        return translationServices.putErrorMessage(language, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.PUT_ERROR_MESSAGE_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.TranslatedErrorMessage)
        });

        return {
            type: translationConstants.PUT_ERROR_MESSAGE_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.PUT_ERROR_MESSAGE_ERROR,
            error
        };
    }
}

function getLabels (language, query) {
    return dispatch => {
        dispatch(request());

        return translationServices.getLabels(language, query)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.GET_LABELS_REQUEST };
    }

    function success (data) {
        return {
            type: translationConstants.GET_LABELS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.GET_LABELS_ERROR,
            error
        };
    }
}

function putLabel (language, data) {
    return dispatch => {
        dispatch(request());

        return translationServices.putLabel(language, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: translationConstants.PUT_LABEL_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.TranslatedLabel)
        });

        return {
            type: translationConstants.PUT_LABEL_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: translationConstants.PUT_LABEL_ERROR,
            error
        };
    }
}
