export const formFieldType = {
    checkbox: 'checkbox',
    date: 'date',
    dateRangePicker: 'dateRangePicker',
    input: 'input',
    multiCheckbox: 'multiCheckbox',
    multiSelect: 'multiSelect',
    number: 'number',
    password: 'password',
    phone: 'phone',
    radio: 'radio',
    select: 'select',
    switch: 'switch',
    textarea: 'textarea',
    upload: 'upload',
    uploadAvatar: 'uploadAvatar',
    locationAutoComplete: 'locationAutoComplete',
    clickLink: 'clickLink'
};
