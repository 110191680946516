import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { publicActions, systemActions, translationActions } from '../../../api';
import { i18cl } from '../../../utilities';
import { Classificator } from '../../../components';

class LanguagePicker extends React.Component {
    constructor (props) {
        super(props);

        this.change = this.change.bind(this);
    }

    change (language) {
        this.props.dispatch(systemActions.setLanguage(language));
        this.props.dispatch(translationActions.getAdminFooter(language));
        this.props.dispatch(publicActions.getLocalization(language));
    };

    render () {
        const menu = (
            <Menu
                className='language_select_menu'
                selectedKeys={[this.props.systemLanguage]}
            >
                {this.props.publicLanguages.filter(e => e.code !== this.props.systemLanguage).map((item, index) =>
                    <Menu.Item
                        key={index}
                        onClick={() => this.change(item.code)}
                    >
                        <Classificator value={i18cl.LANGUAGES + item.code} />
                    </Menu.Item>
                )}
            </Menu>
        );

        return (
            <Dropdown
                trigger={['click']}
                overlay={menu}
                placement='bottomRight'
            >
                <Button
                    type='text'
                    size='large'
                >
                    <Classificator value={i18cl.LANGUAGES + this.props.systemLanguage} />
                    <DownOutlined />
                </Button>
            </Dropdown>
        );
    }
}

LanguagePicker.propTypes = {
    dispatch: PropTypes.func.isRequired,
    publicLanguages: PropTypes.array.isRequired,
    systemLanguage: PropTypes.string.isRequired
};

function mapStateToProps (state) {
    const { publicLanguages } = state.publicReducers;
    const { systemLanguage } = state.systemReducers;
    return {
        publicLanguages,
        systemLanguage
    };
}

const connectedLanguagePicker = connect(mapStateToProps)(LanguagePicker);
export { connectedLanguagePicker as LanguagePicker };
