import PropTypes from 'prop-types';
import { message } from 'antd';

export const Message = (props) => {
    message.open({
        content: props.message,
        duration: props.duration,
        type: props.type || 'success'
    });
};

message.config({
    top: 24,
    right: 24,
    getContainer: () => document.getElementById('root')
});

Message.propTypes = {
    type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
    message: PropTypes.object.isRequired,
    duration: PropTypes.number
};
