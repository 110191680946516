import { partnerManagementConstants } from './constants';

export const partnerManagementReducers = function (state = {}, action) {
    switch (action.type) {
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementAssignMeAsManager: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementAssignMeAsManager: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementAssignMeAsManager: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementBlockStatusPOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementBlockStatusPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementBlockStatusPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementLockStatusPOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementLockStatusPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementLockStatusPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementToggleKycVerifiedPOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementToggleKycVerifiedPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementToggleKycVerifiedPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementJobSourcesPOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementJobSourcesPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementJobSourcesPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_STATUS_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementStatusPOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementStatusPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_STATUS_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementStatusPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementCommunicationLogPOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementCommunicationLogPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementCommunicationLogPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_DETAILS_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementDetailsPOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_DETAILS_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementDetailsPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_DETAILS_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementDetailsPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementResetEmailConfirmationPOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementResetEmailConfirmationPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementResetEmailConfirmationPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_PROFILE_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementProfilePOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_PROFILE_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementProfilePOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_PROFILE_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementProfilePOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_AUTO_BID_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementAutoBidPOST: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_AUTO_BID_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementAutoBidPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_AUTO_BID_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementAutoBidPOST: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_MTC_REQUEST:
            return {
                ...state,
                send: true,
                sendPartnerManagementMTC: true
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_MTC_SUCCESS:
            return {
                ...state,
                send: false,
                sendPartnerManagementMTC: false
            };
        case partnerManagementConstants.POST_PARTNER_MANAGEMENT_MTC_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendPartnerManagementMTC: false
            };
        default:
            return state;
    }
};
