import React from 'react';
import PropTypes from 'prop-types';

import { i18 } from '../../../../../utilities';
import { CommaArray, Number, Translate } from '../../../../../components';

export const PartnerServiceData = (props) => {
    props.data.radius = props.data.areas[0]?.radius;
    const account = [
        {
            label: <Translate value={i18.PartnerService.Labels.Status} />,
            value: props.statusChanger
        }, {
            label: <Translate value={i18.PartnerService.Labels.Areas} />,
            value: <CommaArray data={props.data.areas} value='area' />
        }, {
            label: <Translate value={i18.PartnerService.Labels.AreasRadius} />,
            value: props.data.radius
        }, {
            label: <Translate value={`${i18.PartnerService.Labels.HourlyRate}${props.currency}`} />,
            value: <Number value={props.data.hourlyRate} />
        }, {
            label: <Translate value={i18.PartnerService.Labels.Default} />,
            value: props.data.isDefault ? <Translate value={i18.Statuses.Bool.True} /> : <Translate value={i18.Statuses.Bool.False} />,
            actions: props.data.isDefault ? null : props.setDefault
        }
    ];

    return (
        <div className='list_info_wrapper'>
            {account.filter(e => e.visible !== false).map((item, index) =>
                <div
                    key={index}
                    className='list_info row'
                >
                    <div className='list_info_label col-12 col-xl-5'>{item.label}</div>
                    <div className='list_info_value col-12 col-xl'>{item.value}</div>
                    <div className='list_info_actions col-auto'>{item.actions}</div>
                </div>
            )}
        </div>
    );
};

PartnerServiceData.propTypes = {
    data: PropTypes.object.isRequired,
    setDefault: PropTypes.object,
    statusChanger: PropTypes.object,
    currency: PropTypes.string
};
