import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';

import { history, servicesActions } from '../../../api';
import { Classificator, Translate, TranslateStopJobs } from '../../../components';
import { formatDate, i18, i18cl, routerPaths, statusesService } from '../../../utilities';

const ServicesList = ({ dispatch, services, sendServicesGET }) => {
    useEffect(() => {
        dispatch(servicesActions.getServices());
    }, [dispatch]);

    const onRowClick = useCallback(item => {
        return {
            onClick: () => history.push(routerPaths.services.service + item.serviceCatalogId)
        };
    }, []);

    const renderStoppedJobs = item => {
        return <TranslateStopJobs jobsList={item.serviceStoppedJobsList} />;
    };

    const columns = [
        {
            title: <Translate value={i18.ServicesList.Labels.Status} />,
            render: item => statusesService(item.status),
            width: 1
        },
        {
            title: <Translate value={i18.ServicesList.Labels.ServiceCode} />,
            render: item => item.serviceCatalogCode,
            width: 150
        },
        {
            title: <Translate value={i18.ServicesList.Labels.Name} />,
            render: item => (
                <>
                    <Classificator
                        className="d-block color-secondary fs-12"
                        value={i18cl.GROUPS_SERVICE + item.serviceGroup}
                    />
                    <Classificator
                        className="d-block fw_500 color-heading"
                        value={i18cl.SERVICES_CATALOG + item.serviceCatalogCode}
                    />
                </>
            )
        },
        {
            title: <Translate value={i18.ServicesList.Labels.Stopped} />,
            render: renderStoppedJobs
        },
        {
            title: <Translate value={i18.ServicesList.Labels.ValidFrom} />,
            render: item => formatDate(item.validFrom),
            className: 'text-right'
        },
        {
            title: <Translate value={i18.ServicesList.Labels.ValidTo} />,
            render: item => formatDate(item.validTo),
            className: 'text-right'
        }
    ];

    if (!services && sendServicesGET) {
        return null;
    }

    return (
        <Table
            rowKey="serviceCatalogId"
            rowClassName="cursor_pointer"
            columns={columns}
            dataSource={services}
            pagination={false}
            loading={sendServicesGET}
            onRow={onRowClick}
        />
    );
};

ServicesList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    services: PropTypes.array,
    sendServicesGET: PropTypes.bool
};

function mapStateToProps(state) {
    const { services, sendServicesGET } = state.servicesReducers;

    return {
        services,
        sendServicesGET
    };
}

const connectedServicesList = connect(mapStateToProps)(ServicesList);
export { connectedServicesList as ServicesList };
