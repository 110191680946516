import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { AppstoreAddOutlined } from '@ant-design/icons';

import { i18, routerPaths } from '../../../../utilities';
import { history, partnerProfileActions, partnerProfileConstants } from '../../../../api';
import { ButtonWrapper, Translate } from '../../../../components';
import { PartnerServicesForm, PartnerServicesList } from './';

class PartnerServicesContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            visible: false
        };

        this.fetch = this.fetch.bind(this);
        this.click = this.click.bind(this);
        this.toggle = this.toggle.bind(this);
        this.submit = this.submit.bind(this);
    }

    click (profileServiceId) {
        history.push(routerPaths.partners.index + '/' + this.props.match.params.partnerId + '/service/' + profileServiceId, this.props.history.location.state);
    }

    toggle () {
        this.setState(prevState => ({ visible: !prevState.visible }));
    }

    submit (values) {
        this.props.dispatch(partnerProfileActions.postProfileService(this.props.match.params.partnerId, values)).then(res => {
            if (res && res.type === partnerProfileConstants.POST_PARTNER_PROFILE_SERVICE_SUCCESS) {
                this.toggle();
                this.fetch();
            }
        });
    }

    componentDidMount () {
        this.fetch();
    }

    fetch () {
        this.props.dispatch(partnerProfileActions.getProfileServices(this.props.match.params.partnerId));
        this.props.dispatch(partnerProfileActions.getProfileAvailableServices(this.props.match.params.partnerId));
    }

    render () {
        return (
            <div className='content_block'>
                <div className='mb-4 text-right'>
                    {!this.state.visible && (
                        <ButtonWrapper
                            type='primary'
                            icon={<AppstoreAddOutlined />}
                            onClick={() => this.toggle()}
                        >
                            <Translate value={i18.PartnerServices.Buttons.Add} />
                        </ButtonWrapper>
                    )}
                </div>
                {this.state.visible && (
                    <div className='mb-4'>
                        <PartnerServicesForm
                            areas={this.props.publicServiceAreas}
                            services={this.props.partnerProfileAvailableServices}
                            servicesLoading={this.props.sendPartnerProfileAvailableServicesGET}
                            cancel={this.toggle}
                            submit={this.submit}
                            submitting={this.props.sendPartnerProfileServicePOST}
                            currency={this.props.currency}
                        />
                    </div>
                )}
                <PartnerServicesList
                    click={this.click}
                    data={this.props.partnerProfileServices}
                    loading={this.props.sendPartnerProfileServicesGET}
                    currency={this.props.currency}
                />
            </div>
        );
    }
}

PartnerServicesContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    history: PropTypes.object.isRequired,
    partnerProfileAvailableServices: PropTypes.array.isRequired,
    partnerProfileServices: PropTypes.array.isRequired,
    publicServiceAreas: PropTypes.array.isRequired,
    sendPartnerProfileServicesGET: PropTypes.bool.isRequired,
    sendPartnerProfileAvailableServicesGET: PropTypes.bool.isRequired,
    sendPartnerProfileServicePOST: PropTypes.bool.isRequired,
    currency: PropTypes.string
};

function mapStateToProps (state) {
    const {
        partnerProfileAvailableServices,
        partnerProfileServices,
        sendPartnerProfileAvailableServicesGET,
        sendPartnerProfileServicesGET,
        sendPartnerProfileServicePOST
    } = state.partnerProfileReducers;
    const {
        publicServiceAreas,
        publicLocalization: {
            countrySettings: { CURRENCY: currency }
        }
    } = state.publicReducers;

    return {
        partnerProfileAvailableServices,
        partnerProfileServices,
        publicServiceAreas,
        sendPartnerProfileAvailableServicesGET,
        sendPartnerProfileServicesGET,
        sendPartnerProfileServicePOST,
        currency
    };
}

const connectedPartnerServicesContainer = withRouter(connect(mapStateToProps)(PartnerServicesContainer));
export { connectedPartnerServicesContainer as PartnerServicesContainer };
