import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';

import {
    localJobReducers,
    jobCreateFormReducers,
    systemReducers
} from '../local';

import {
    administrationFeeMarginsReducers,
    internalClientReducers,
    countriesReducers,
    countrySettingsReducers,
    countryVatReducers,
    languageReducers,
    odataReducers,
    partnerManagementReducers,
    partnerProfileReducers,
    partnerReadReducers,
    publicReducers,
    serviceGroupsReducers,
    serviceItemsReducers,
    servicesReducers,
    serviceSettingsReducers,
    translationReducers,
    userReducers,
    jobCreationReducers,
    jobReducers,
    featureTogglesReducers
} from '../services';

export const localReducers = {
    localJobReducers,
    jobCreateFormReducers,
    systemReducers
};

export const rootReducers = {
    administrationFeeMarginsReducers,
    internalClientReducers,
    countriesReducers,
    countrySettingsReducers,
    countryVatReducers,
    languageReducers,
    odataReducers,
    partnerManagementReducers,
    partnerProfileReducers,
    partnerReadReducers,
    publicReducers,
    serviceGroupsReducers,
    serviceItemsReducers,
    servicesReducers,
    serviceSettingsReducers,
    translationReducers,
    userReducers,
    jobCreationReducers,
    jobReducers,
    featureTogglesReducers
};

export const rootReducerWithRouter = (history) => combineReducers({
    router: connectRouter(history),
    loadingBar: loadingBarReducer,
    ...localReducers,
    ...rootReducers
});
