import { userServices } from './services';
import { userConstants } from './constants';
import { i18, translate } from '../../../utilities';
import { Message } from '../../../components';

export const userActions = {
    logout,
    postLogin,
    postChangePassword,
    postResetPassword,
    postCreatePassword
};

function logout () {
    return dispatch => {
        dispatch(logout());
    };

    function logout () {
        return {
            type: userConstants.LOGOUT
        };
    }
}

function postLogin (data) {
    return dispatch => {
        dispatch(request());

        return userServices.postLogin(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: userConstants.POST_LOGIN_REQUEST };
    }

    function success (data) {
        return {
            type: userConstants.POST_LOGIN_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: userConstants.POST_LOGIN_ERROR,
            error
        };
    }
}

function postChangePassword (data) {
    return dispatch => {
        dispatch(request());

        return userServices.postChangePassword(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: userConstants.POST_CHANGE_PASSWORD_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.UserPasswordChanged)
        });

        return {
            type: userConstants.POST_CHANGE_PASSWORD_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: userConstants.POST_CHANGE_PASSWORD_ERROR,
            error
        };
    }
}

function postResetPassword (data) {
    return dispatch => {
        dispatch(request());

        return userServices.postResetPassword(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: userConstants.POST_RESET_PASSWORD_REQUEST };
    }

    function success (data) {
        return {
            type: userConstants.POST_RESET_PASSWORD_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: userConstants.POST_RESET_PASSWORD_ERROR,
            error
        };
    }
}

function postCreatePassword (data, code) {
    return dispatch => {
        dispatch(request());

        return userServices.postCreatePassword(data, code)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: userConstants.POST_CREATE_PASSWORD_REQUEST };
    }

    function success (data) {
        return {
            type: userConstants.POST_CREATE_PASSWORD_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: userConstants.POST_CREATE_PASSWORD_ERROR,
            error
        };
    }
}