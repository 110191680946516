export const servicesConstants = {
    GET_SERVICES_REQUEST: 'GET_SERVICES_REQUEST',
    GET_SERVICES_SUCCESS: 'GET_SERVICES_SUCCESS',
    GET_SERVICES_ERROR: 'GET_SERVICES_ERROR',

    GET_SERVICES_BY_ID_REQUEST: 'GET_SERVICES_BY_ID_REQUEST',
    GET_SERVICES_BY_ID_SUCCESS: 'GET_SERVICES_BY_ID_SUCCESS',
    GET_SERVICES_BY_ID_ERROR: 'GET_SERVICES_BY_ID_ERROR',

    POST_SERVICES_REQUEST: 'POST_SERVICES_REQUEST',
    POST_SERVICES_SUCCESS: 'POST_SERVICES_SUCCESS',
    POST_SERVICES_ERROR: 'POST_SERVICES_ERROR',

    PUT_SERVICES_REQUEST: 'PUT_SERVICES_REQUEST',
    PUT_SERVICES_SUCCESS: 'PUT_SERVICES_SUCCESS',
    PUT_SERVICES_ERROR: 'PUT_SERVICES_ERROR',

    POST_SERVICE_IMAGE_REQUEST: 'POST_SERVICE_IMAGE_REQUEST',
    POST_SERVICE_IMAGE_SUCCESS: 'POST_SERVICE_IMAGE_SUCCESS',
    POST_SERVICE_IMAGE_ERROR: 'POST_SERVICE_IMAGE_ERROR'
};
