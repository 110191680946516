export const i18 = {
    API: {
        Messages: {
            PartnerMTCUpdated: 'API.Messages.PartnerMTCUpdated',
            PartnerGreenCardUpdated: 'API.Messages.PartnerGreenCardUpdated',
            MonthlyRewardsUpdated: 'API.Messages.MonthlyRewardsUpdated',
            AdministrationFeeMarginsUpdated: 'API.Messages.AdministrationFeeMarginsUpdated',
            CountrySettingAdded: 'API.Messages.CountrySettingAdded',
            CountrySettingUpdated: 'API.Messages.CountrySettingUpdated',
            CountryVatAdded: 'API.Messages.CountryVatAdded',
            CountryVatDeleted: 'API.Messages.CountryVatDeleted',
            CountryVatUpdated: 'API.Messages.CountryVatUpdated',
            InternalClientCreated: 'API.Messages.InternalClientCreated',
            InternalClientUpdated: 'API.Messages.InternalClientUpdated',
            JobCreated: 'API.Messages.JobCreated',
            LanguagesUpdated: 'API.Messages.LanguagesUpdated',
            PartnerAutoBidToggled: 'API.Messages.PartnerAutoBidToggled',
            PartnerCreated: 'API.Messages.PartnerCreated',
            PartnerDocumentDeleted: 'API.Messages.PartnerDocumentDeleted',
            PartnerDocumentUploaded: 'API.Messages.PartnerDocumentUploaded',
            PartnerImageChanged: 'API.Messages.PartnerImageChanged',
            PartnerJobSourcesChanged: 'API.Messages.PartnerJobSourcesChanged',
            PartnerKycVerifiedToggled: 'API.Messages.PartnerKycVerifiedToggled',
            PartnerManagerAssigned: 'API.Messages.PartnerManagerAssigned',
            PartnerProfileServiceItemUpdated: 'API.Messages.PartnerProfileServiceItemUpdated',
            PartnerPasswordReset: 'API.Messages.PartnerPasswordReset',
            PartnerProfileSynchronizationSuccess: 'API.Messages.PartnerProfileSynchronizationSuccess',
            PartnerServiceAdded: 'API.Messages.PartnerServiceAdded',
            PartnerServiceDeleted: 'API.Messages.PartnerServiceDeleted',
            PartnerServiceDocumentUploaded: 'API.Messages.PartnerServiceDocumentUploaded',
            PartnerServiceUpdated: 'API.Messages.PartnerServiceUpdated',
            PartnersWithMarketingApprovedCopied: 'API.Messages.PartnersWithMarketingApprovedCopied',
            PartnersWithMarketingCopied: 'API.Messages.PartnersWithMarketingCopied',
            PartnerUpdated: 'API.Messages.PartnerUpdated',
            PreorderSendToClientSuccess: 'API.Messages.PreorderSendToClientSuccess',
            ServiceAdded: 'API.Messages.ServiceAdded',
            ServiceDefaultSet: 'API.Messages.ServiceDefaultSet',
            ServiceItemUpdated: 'API.Messages.ServiceItemUpdated',
            ServiceSettingAdded: 'API.Messages.ServiceSettingAdded',
            ServiceSettingUpdated: 'API.Messages.ServiceSettingUpdated',
            ServiceUpdated: 'API.Messages.ServiceUpdated',
            TranslatedClassificator: 'API.Messages.TranslatedClassificator',
            TranslatedContent: 'API.Messages.TranslatedContent',
            TranslatedErrorMessage: 'API.Messages.TranslatedErrorMessage',
            TranslatedLabel: 'API.Messages.TranslatedLabel',
            UserResentedConfirmationEmail: 'API.Messages.UserResentedConfirmationEmail',
            UserStatusBlockChanges: 'API.Messages.UserStatusBlockChanges',
            UserStatusChanges: 'API.Messages.UserStatusChanges',
            UserStatusUnlocked: 'API.Messages.UserStatusUnlocked',
            ServiceItemStatusUpdated: 'API.Messages.ServiceItemStatusUpdated'
        }
    },
    InternalClient: {
        Titles: {
            InternalClient: 'InternalClient.Titles.InternalClient'
        }
    },
    InternalClientSelectionGrid: {
        Buttons: {
            Cancel: 'InternalClientSelectionGrid.Buttons.Cancel',
            Select: 'InternalClientSelectionGrid.Buttons.Select'
        },
        Labels: {
            ClientFirstName: 'InternalClientSelectionGrid.Labels.ClientFirstName',
            ClientLastName: 'InternalClientSelectionGrid.Labels.ClientLastName',
            ClientCompanyName: 'InternalClientSelectionGrid.Labels.ClientCompanyName',
            ClientAddress: 'InternalClientSelectionGrid.Labels.ClientAddress'
        },
        Titles: {
            ClientList: 'InternalClientSelectionGrid.Labels.ClientList'
        }
    },
    InternalClients: {
        Buttons: {
            Add: 'InternalClients.Buttons.Add',
            Clear: 'InternalClients.Buttons.Clear'
        },
        Titles: {
            InternalClients: 'InternalClients.Titles.InternalClients'
        }
    },
    InternalClientsCreate: {
        Titles: {
            InternalClientsCreate: 'InternalClientsCreate.Titles.InternalClientsCreate'
        }
    },
    InternalClientsCreateForm: {
        Buttons: {
            Submit: 'InternalClientsCreateForm.Buttons.Submit',
            Cancel: 'InternalClientsCreateForm.Buttons.Cancel'
        },
        Labels: {
            Address: 'InternalClientsCreateForm.Labels.Address',
            ConfirmationCancelText: 'InternalClientsCreateForm.Labels.ConfirmationCancelText',
            ConfirmationOkText: 'InternalClientsCreateForm.Labels.ConfirmationOkText',
            ConfirmationTitle: 'InternalClientsCreateForm.Labels.ConfirmationTitle',
            Type: 'InternalClientsCreateForm.Labels.Type',
            CompanyCode: 'InternalClientsCreateForm.Labels.CompanyCode',
            CompanyName: 'InternalClientsCreateForm.Labels.CompanyName',
            Email: 'InternalClientsCreateForm.Labels.Email',
            FirstName: 'InternalClientsCreateForm.Labels.FirstName',
            LastName: 'InternalClientsCreateForm.Labels.LastName',
            InvoiceEmail: 'InternalClientsCreateForm.Labels.InvoiceEmail',
            Phone: 'InternalClientsCreateForm.Labels.Phone',
            VatPayerCode: 'InternalClientsCreateForm.Labels.VatPayerCode'
        }
    },
    InternalClientsList: {
        Labels: {
            ClientAddress: 'InternalClientsList.Labels.ClientAddress',
            ClientId: 'InternalClientsList.Labels.ClientId',
            ClientType: 'InternalClientsList.Labels.ClientType',
            ClientCompanyCode: 'InternalClientsList.Labels.ClientCompanyCode',
            ClientCompanyName: 'InternalClientsList.Labels.ClientCompanyName',
            CreationDateTime: 'InternalClientsList.Labels.CreationDateTime',
            ClientEmail: 'InternalClientsList.Labels.ClientEmail',
            ClientFirstName: 'InternalClientsList.Labels.ClientFirstName',
            ClientLastName: 'InternalClientsList.Labels.ClientLastName',
            InvoiceEmail: 'InternalClientsList.Labels.InvoiceEmail',
            ClientTelephone: 'InternalClientsList.Labels.ClientTelephone',
            ClientVatPayerCode: 'InternalClientsList.Labels.ClientVatPayerCode'
        }
    },
    CountriesSettings: {
        Labels: {
            Actions: 'CountriesSettings.Labels.Actions',
            Count: 'CountriesSettings.Labels.Count',
            Name: 'CountriesSettings.Labels.Name',
            Status: 'CountriesSettings.Labels.Status',
            ValidFrom: 'CountriesSettings.Labels.ValidFrom',
            ValidTo: 'CountriesSettings.Labels.ValidTo',
            Value: 'CountriesSettings.Labels.Value'
        },
        Titles: {
            CountrySettings: 'CountriesSettings.Titles.CountrySettings'
        }
    },
    Components: {
        Cropper: {
            ProfileImage: 'Components.Cropper.ProfileImage',
            FileNotValid: 'Components.Cropper.FileNotValid'
        },
        Dragger: {
            Title: 'Components.Dragger.Title',
            Description: 'Components.Dragger.Description',
            FileCannotExceedBytes: 'Components.Dragger.FileCannotExceedBytes'
        }
    },
    CountrySettingsEditForm: {
        Buttons: {
            Edit: 'CountrySettingsEditForm.Buttons.Edit',
            Submit: 'CountrySettingsEditForm.Buttons.Submit'
        },
        Labels: {
            ValidFrom: 'CountrySettingsEditForm.Labels.ValidFrom',
            ValidTo: 'CountrySettingsEditForm.Labels.ValidTo'
        }
    },
    CountrySettingsForm: {
        Buttons: {
            Add: 'CountrySettingsForm.Buttons.Add',
            Submit: 'CountrySettingsForm.Buttons.Submit'
        },
        Labels: {
            Name: 'CountrySettingsForm.Labels.Name',
            Parameter: 'CountrySettingsForm.Labels.Parameter',
            ValidFrom: 'CountrySettingsForm.Labels.ValidFrom',
            ValidTo: 'CountrySettingsForm.Labels.ValidTo',
            Value: 'CountrySettingsForm.Labels.Value'
        },
        Titles: {
            Add: 'CountrySettingsForm.Titles.Add'
        }
    },
    Files: {
        Buttons: {
            ConfirmCancel: 'Files.Buttons.ConfirmCancel',
            ConfirmDelete: 'Files.Buttons.ConfirmDelete',
            Delete: 'Files.Buttons.Delete',
            Download: 'Files.Buttons.Download',
            Submit: 'Files.Buttons.Submit',
            Upload: 'Files.Buttons.Upload'
        },
        Confirms: {
            Delete: 'Files.Confirms.Delete'
        },
        Labels: {
            File: 'Files.Labels.File',
            ValidFrom: 'Files.Labels.ValidFrom',
            ValidTo: 'Files.Labels.ValidTo'
        }
    },
    Filter: {
        Buttons: {
            Date: 'Filter.Buttons.Date',
            DateRange: 'Filter.Buttons.DateRange',
            Reset: 'Filter.Buttons.Reset',
            Submit: 'Filter.Buttons.Submit'
        },
        Values: {
            False: 'Filter.Values.False',
            True: 'Filter.Values.True'
        }
    },
    Forms: {
        Errors: {
            PhoneNumberLength: 'Forms.Errors.PhoneNumberLength',
            PhoneNumberMaxLength: 'Forms.Errors.PhoneNumberMaxLength',
            Required: 'Forms.Errors.Required',
            RequiredAtLeastOne: 'Forms.Errors.RequiredAtLeastOne',
            RequiredAvatar: 'Forms.Errors.RequiredAvatar',
            RequiredEmail: 'Forms.Errors.RequiredEmail',
            RequiredIBAN: 'Forms.Errors.RequiredIBAN',
            RequiredPhoneNumber: 'Forms.Errors.RequiredPhoneNumber',
            RequiredPolicy: 'Forms.Errors.RequiredPolicy',
            RequiredSamePassword: 'Forms.Errors.RequiredSamePassword',
            RequiredStrongPassword: 'Forms.Errors.RequiredStrongPassword',
            RequiredTerms: 'Forms.Errors.RequiredTerms',
            RequiredNumberMax100: 'Forms.Errors.RequiredNumberMax100',
            InvalidPersonalCode: 'Forms.Errors.InvalidPersonalCode',
            CyrillicError: 'Forms.Errors.CyrillicError'
        }
    },
    Job: {
        Titles: {
            Settings: 'Job.Titles.Settings',
            ServiceSettings: 'Job.Titles.ServiceSettings',
            ObjectLocation: 'Job.Titles.ObjectLocation',
            OtherSettings: 'Job.Titles.OtherSettings',
            PhotosBefore: 'Job.Titles.PhotosBefore',
            PhotosAfter: 'Job.Titles.PhotosAfter',
            Files: 'Job.Titles.Files',
            PartnerFiles: 'Job.Titles.PartnerFiles',
            MaterialFiles: 'Job.Titles.MaterialFiles',
            JobScopeFile: 'Job.Titles.JobScopeFile',
            JobScopeDescription: 'Job.Titles.JobScopeDescription',
            MainJobInfo: 'Job.Titles.MainJobInfo',
            ClientInfo: 'Job.Titles.ClientInfo',
            BiddingAssignedPartners: 'Job.Titles.BiddingAssignedPartners',
            Invoices: 'Job.Titles.Invoices',
            FactualDuration: 'Job.Titles.FactualDuration',
            ConfirmJob: 'Job.Titles.ConfirmJob'
        },
        Confirms: {
            JobCancel: 'Job.Confirms.JobCancel',
            JobConfirm: 'Job.Confirms.JobConfirm',
            JobApprove: 'Job.Confirms.JobApprove'
        },
        Labels: {
            JobCode: 'Job.Labels.JobCode',
            Status: 'Job.Labels.Status',
            ClientSegment: 'Job.Labels.ClientSegment',
            BiddingType: 'Job.Labels.BiddingType',
            NeedJobApproval: 'Job.Labels.NeedJobApproval',
            VatType: 'Job.Labels.VatType',
            VatName: 'Job.Labels.VatName',
            MaterialVatName: 'Job.Labels.MaterialVatName',
            MaterialVatType: 'Job.Labels.MaterialVatType',
            ClientPrice: 'Job.Labels.ClientPrice',
            PartnerReward: 'Job.Labels.PartnerReward',
            AdministrationFee: 'Job.Labels.AdministrationFee',
            MaterialExpenses: 'Job.Labels.MaterialExpenses',
            JobType: 'Job.Labels.JobType',
            BiddingPeriod: 'Job.Labels.BiddingPeriod',
            ServiceGroup: 'Job.Labels.ServiceGroup',
            Service: 'Job.Labels.Service',
            JobDescription: 'Job.Labels.JobDescription',
            JobDeadline: 'Job.Labels.JobDeadline',
            ObjectAddress: 'Job.Labels.ObjectAddress',
            ObjectLatitude: 'Job.Labels.ObjectLatitude',
            ObjectLongitude: 'Job.Labels.ObjectLongitude',
            ObjectAdditionalInfo: 'Job.Labels.ObjectAdditionalInfo',
            ResponsibleManager: 'Job.Labels.ResponsibleManager',
            ManagerPhone: 'Job.Labels.ManagerPhone',
            ContactNumber: 'Job.Labels.ContactNumber',
            InternalClient: 'Job.Labels.InternalClient',
            PartnerNameCompanyName: 'Job.Labels.PartnerNameCompanyName',
            JobCreationDate: 'Job.Labels.JobCreationDate',
            JobPeriod: 'Job.Labels.JobPeriod',
            JobDuration: 'Job.Labels.JobDuration',
            AdministratorComment: 'Job.Labels.AdministratorComment',
            JobTrackingUrl: 'Job.Labels.JobTrackingUrl',
            ClientId: 'Job.Labels.ClientId',
            ClientType: 'Job.Labels.ClientType',
            ClientJuridical: 'Job.Labels.ClientJuridical',
            ClientPhysical: 'Job.Labels.ClientPhysical',
            ClientFirstName: 'Job.Labels.ClientFirstName',
            ClientLastName: 'Job.Labels.ClientLastName',
            ClientName: 'Job.Labels.ClientName',
            ClientCompanyCode: 'Job.Labels.ClientCompanyCode',
            ClientCompanyVatCode: 'Job.Labels.ClientCompanyVatCode',
            ClientAddress: 'Job.Labels.ClientAddress',
            ContactPhone: 'Job.Labels.ContactPhone',
            ClientEmail: 'Job.Labels.ClientEmail',
            ClientInvoiceEmail: 'Job.Labels.ClientInvoiceEmail',
            ClientInfo: 'Job.Labels.ClientInfo',
            ClientPaidPrice: 'Job.Labels.ClientPaidPrice',
            BiddingPartnerFullName: 'Job.Labels.BiddingPartnerFullName',
            RejectReason: 'Job.Labels.RejectReason',
            BiddingPartnerCompanyName: 'Job.Labels.BiddingPartnerCompanyName',
            InvoicePDF: 'Job.Labels.InvoicePDF',
            InvoiceType: 'Job.Labels.InvoiceType',
            BiddingPartnerStage: 'Job.Labels.BiddingPartnerStage',
            BiddingPartnerBiddingDate: 'Job.Labels.BiddingPartnerBiddingDate',
            BiddingWinner: 'Job.Labels.BiddingWinner',
            FactualHours: 'Job.Labels.FactualHours',
            FactualMinutes: 'Job.Labels.FactualMinutes',
            PlannedStartDate: 'Job.Labels.PlannedStartDate',
            PlannedMaterialExpenses: 'Job.Labels.PlannedMaterialExpenses',
            InvoicingPeriod: 'Job.Labels.InvoicingPeriod',
            PhoneNumber: 'Job.Labels.PhoneNumber',
            JobScopeDescription: 'Job.Labels.JobScopeDescription',
            ConfirmationOkText: 'Job.Labels.ConfirmationOkText',
            ConfirmationCancelText: 'Job.Labels.ConfirmationCancelText',
            ConfirmationTitle: 'Job.Labels.ConfirmationTitle',
            MaterialsPriceIncluded: 'Job.Labels.MaterialsPriceIncluded',
            MaterialsPriceShouldBeIncluded: 'Job.Labels.MaterialsPriceShouldBeIncluded',
            OffersModalTitle: 'Job.Labels.OffersModalTitle',
            OffersTitle: 'Job.Labels.OffersTitle',
            OffersClientPrice: 'Job.Labels.OffersClientPrice',
            OffersAdminFee: 'Job.Labels.OffersAdminFee',
            OffersPartnerReward: 'Job.Labels.OffersPartnerReward',
            OffersMaterialPrice: 'Job.Labels.OffersMaterialPrice',
            OffersQuantity: 'Job.Labels.OffersQuantity',
            OffersPartner: 'Job.Labels.OffersPartner',
            OffersGenerate: 'Job.Labels.OffersGenerate',
            OffersCancel: 'Job.Labels.OffersCancel',
            OffersSubmit: 'Job.Labels.OffersSubmit',
            OffersGenerated: 'Job.Labels.OffersGenerated',
            OffersConfirmationQuestion: 'Job.Labels.OffersConfirmationQuestion',
            BiddingOffer: 'Job.Labels.BiddingOffer',
            IsPreorderSentToPartners: 'Jobs.Labels.IsPreorderSentToPartners',
            IsPreorderSentToClient: 'Jobs.Labels.IsPreorderSentToClient',
            SendConfirmationTitle: 'Job.Labels.SendConfirmationTitle',
            PreviewPartner: 'Job.Labels.PreviewPartner',
            MainClientName: 'Job.Labels.MainClientName',
            MainClientId: 'Job.Labels.MainClientId',
            CallSectionTitle: 'Job.Labels.CallSectionTitle',
            CallDate: 'Job.Labels.CallDate',
            CallComment: 'Job.Labels.CallComment',
            CalledBy: 'Job.Labels.CalledBy',
            CallModalTitle: 'Job.Labels.CallModalTitle',
            CallModalDate: 'Job.Labels.CallModalDate',
            CallModalComment: 'Job.Labels.CallModalComment',
            AddCompetitorModalTitle: 'Job.Labels.AddCompetitorModalTitle',
            AddCompetitorModalPartner: 'Job.Labels.AddCompetitorModalPartner',
            AddCompetitorModalClientPrice: 'Job.Labels.AddCompetitorModalClientPrice',
            AddCompetitorModalAdminFee: 'Job.Labels.AddCompetitorModalAdminFee',
            AddCompetitorModalPartnerReward: 'Job.Labels.AddCompetitorModalPartnerReward',
            AddCompetitorModalMaterialsPrice: 'Job.Labels.AddCompetitorModalMaterialsPrice',
            AddCompetitorModalPartnerPlaceholder: 'Job.Labels.AddCompetitorModalPartnerPlaceholder',
            RemoveCompetitorModalTitle: 'Job.Labels.RemoveCompetitorModalTitle',
            LeadOffer: 'Job.Labels.LeadOffer',
        },
        Buttons: {
            StartBidding: 'Job.Buttons.StartBidding',
            SendToClient: 'Job.Buttons.SendToClient',
            Open: 'Job.Buttons.Open',
            Cancel: 'Job.Buttons.Cancel',
            CancelJob: 'Job.Buttons.CancelJob',
            ConfirmJobCancel: 'Job.Buttons.ConfirmJobCancel',
            ConfirmCancel: 'Job.Buttons.ConfirmCancel',
            ConfirmJob: 'Job.Buttons.ConfirmJob',
            ConfirmJobConfirm: 'Job.Buttons.ConfirmJobConfirm',
            CancelJobConfirm: 'Job.Buttons.CancelJobConfirm',
            Save: 'Job.Buttons.Save',
            Edit: 'Job.Buttons.Edit',
            EditFiles: 'Job.Buttons.EditFiles',
            AssignNewPartner: 'Job.Buttons.AssignNewPartner',
            ApproveJob: 'Job.Buttons.ApproveJob',
            ConfirmJobApprove: 'Job.Buttons.ConfirmJobApprove',
            CancelJobApprove: 'Job.Buttons.CancelJobApprove',
            ViewBidJobScope: 'Job.Buttons.ViewBidJobScope',
            SetCalled: 'Job.Buttons.SetCalled',
            CallModalSubmit: 'Job.Buttons.CallModalSubmit',
            CallModalCancel: 'Job.Buttons.CallModalCancel',
            AddCompetitor: 'Job.Buttons.AddCompetitor',
            AddCompetitorModalSubmit: 'Job.Buttons.AddCompetitorModalSubmit',
            AddCompetitorModalCancel: 'Job.Buttons.AddCompetitorModalCancel',
            ConfirmRemoveCompetitor: 'Job.Buttons.ConfirmRemoveCompetitor',
            CancelRemoveCompetitor: 'Job.Buttons.CancelRemoveCompetitor',
        },
        Invoices: {
            AdministrationFee: 'Job.Invoices.AdministrationFee',
            Client: 'Job.Invoices.Client',
            Credit: 'Job.Invoices.Credit'
        },
        Errors: {
            PriceValidation: 'Job.Errors.PriceValidation'
        }
    },
    Jobs: {
        Buttons: {
            Add: 'Jobs.Buttons.Add',
            Clear: 'Jobs.Buttons.Clear'
        },
        Labels: {
            Address: 'Jobs.Labels.Address',
            BiddingExpiration: 'Jobs.Labels.BiddingExpiration',
            Company: 'Jobs.Labels.Company',
            Created: 'Jobs.Labels.Created',
            Deadline: 'Jobs.Labels.Deadline',
            IsBidConfirmationNeeded: 'Jobs.Labels.IsBidConfirmationNeeded',
            IsPreorder: 'Jobs.Labels.IsPreorder',
            IsReviewNeeded: 'Jobs.Labels.IsReviewNeeded',
            JobId: 'Jobs.Labels.JobId',
            JobType: 'Jobs.Labels.JobType',
            Finished: 'Jobs.Labels.Finished',
            HasNewActivities: 'Jobs.Labels.HasNewActivities',
            OwnerName: 'Jobs.Labels.OwnerName',
            Partner: 'Jobs.Labels.Partner',
            Service: 'Jobs.Labels.Service',
            Started: 'Jobs.Labels.Started',
            StatusName: 'Jobs.Labels.StatusName',
            ClientSegment: 'Jobs.Labels.ClientSegment',
            PartnerIsExpert: 'Jobs.Labels.PartnerIsExpert',
            NumberOfBids: 'Jobs.Labels.NumberOfBids',
            JobCode: 'Jobs.Labels.JobCode',
            ClientName: 'Jobs.Labels.ClientName',
            ClientPhoneNumber: 'Jobs.Labels.ClientPhoneNumber',
            FullPrice: 'Jobs.Labels.FullPrice',
            ClientInvoiceNumber: 'Jobs.Labels.ClientInvoiceNumber',
            Description: 'Jobs.Labels.Description',
            BiddingType: 'Jobs.Labels.BiddingType',
            IsPreorderSentToPartners: 'Jobs.Labels.IsPreorderSentToPartners',
            IsPreorderSentToClient: 'Jobs.Labels.IsPreorderSentToClient',
            MainClientName: 'Jobs.Labels.MainClientName',
        },
        Titles: {
            Jobs: 'Jobs.Titles.Jobs'
        }
    },
    JobsCreate: {
        Titles: {
            JobsCreate: 'JobsCreate.Titles.JobsCreate'
        }
    },
    JobCreateForm: {
        Titles: {
            AdditionalSettings: 'JobCreateForm.Titles.AdditionalSettings',
            Settings: 'JobCreateForm.Titles.Settings',
            OtherSettings: 'JobCreateForm.Titles.OtherSettings',
            ObjectLocation: 'JobCreateForm.Titles.ObjectLocation',
            ServiceSettings: 'JobCreateForm.Titles.ServiceSettings',
            EntryData: 'JobCreateForm.Titles.EntryData'
        },
        Labels: {
            AdministrationFee: 'JobCreateForm.Labels.AdministrationFee',
            BiddingEndDate: 'JobCreateForm.Labels.BiddingEndDate',
            BiddingStartDate: 'JobCreateForm.Labels.BiddingStartDate',
            InternalClient: 'JobCreateForm.Labels.InternalClient',
            ClientPrice: 'JobCreateForm.Labels.ClientPrice',
            ContactNumber: 'JobCreateForm.Labels.ContactNumber',
            JobDeadline: 'JobCreateForm.Labels.JobDeadline',
            JobDescription: 'JobCreateForm.Labels.JobDescription',
            JobDuration: 'JobCreateForm.Labels.JobDuration',
            JobStartDate: 'JobCreateForm.Labels.JobStartDate',
            JobEndDate: 'JobCreateForm.Labels.JobEndDate',
            JobDurationDays: 'JobCreateForm.Labels.JobDurationDays',
            JobType: 'JobCreateForm.Labels.JobType',
            ManagerPhone: 'JobCreateForm.Labels.ManagerPhone',
            NeedJobApproval: 'JobCreateForm.Labels.NeedJobApproval',
            ObjectAdditionalInfo: 'JobCreateForm.Labels.ObjectAdditionalInfo',
            ObjectAddress: 'JobCreateForm.Labels.ObjectAddress',
            ObjectLatitude: 'JobCreateForm.Labels.ObjectLatitude',
            ObjectLongitude: 'JobCreateForm.Labels.ObjectLongitude',
            PartnerReward: 'JobCreateForm.Labels.PartnerReward',
            PartnerComment: 'JobCreateForm.Labels.PartnerComment',
            PartnerNameCompanyName: 'JobCreateForm.Labels.PartnerNameCompanyName',
            ResponsibleManager: 'JobCreateForm.Labels.ResponsibleManager',
            SelectService: 'JobCreateForm.Labels.SelectService',
            ServiceGroup: 'JobCreateForm.Labels.ServiceGroup',
            Status: 'JobCreateForm.Labels.Status',
            VatType: 'JobCreateForm.Labels.VatType'
        },
        Buttons: {
            ConfirmLocation: 'JobCreateForm.Buttons.ConfirmLocation'
        }
    },
    Languages: {
        Buttons: {
            Reset: 'Languages.Buttons.Reset',
            Submit: 'Languages.Buttons.Submit'
        },
        Labels: {
            Country: 'Languages.Labels.Country',
            Languages: 'Languages.Labels.Languages'
        },
        Titles: {
            Languages: 'Languages.Titles.Languages'
        }
    },
    Loader: {
        Descriptions: {
            Default: 'Loader.Descriptions.Default'
        },
        Titles: {
            Default: 'Loader.Titles.Default'
        }
    },
    Login: {
        Buttons: {
            Submit: 'Login.Buttons.Submit',
            LostPassword: 'Login.Buttons.LostPassword'
        },
        Labels: {
            Email: 'Login.Labels.Email',
            Password: 'Login.Labels.Password'
        },
        Titles: {
            Login: 'Login.Titles.Login'
        }
    },
    Create: {
        Titles: {
            Create: 'Create.Titles.Create'
        }
    },
    Reset: {
        Buttons: {
            Login: 'Reset.Buttons.Login',
            Submit: 'Reset.Buttons.Submit'
        },
        Labels: {
            Email: 'Reset.Labels.Email',
            Submit: 'Reset.Labels.Submit'
        },
        Titles: {
            Reset: 'Reset.Titles.Reset'
        }
    },
    ResetSuccess: {
        Buttons: {
            Login: 'ResetSuccess.Buttons.Login'
        },
        Titles: {
            Success: 'ResetSuccess.Titles.Success',
            PasswordChanged: 'ResetSuccess.Titles.PasswordChanged'
        }
    },
    UserPasswordForm: {
        Buttons: {
            Cancel: 'UserPasswordForm.Buttons.Cancel',
            Change: 'UserPasswordForm.Buttons.Change',
            Submit: 'UserPasswordForm.Buttons.Submit',
            Create: 'UserPasswordForm.Buttons.Create'
        },
        Labels: {
            New: 'UserPasswordForm.Labels.New',
            Old: 'UserPasswordForm.Labels.Old',
            Repeat: 'UserPasswordForm.Labels.Repeat'
        },
        Titles: {
            Create: 'UserPasswordForm.Titles.Create'
        },
        LabelsDescriptions: {
            New: 'UserPasswordForm.LabelsDescriptions.New',
            Old: 'UserPasswordForm.LabelsDescriptions.Old',
            Repeat: 'UserPasswordForm.LabelsDescriptions.Repeat'
        }
    },
    Menu: {
        Titles: {
            InternalClients: 'Menu.Titles.InternalClients',
            Countries: 'Menu.Titles.Countries',
            CountriesLanguages: 'Menu.Titles.CountriesLanguages',
            CountriesSettings: 'Menu.Titles.CountriesSettings',
            Dashboard: 'Menu.Titles.Dashboard',
            Jobs: 'Menu.Titles.Jobs',
            Partners: 'Menu.Titles.Partners',
            PartnersMarketing: 'Menu.Titles.PartnersMarketing',
            ServicesDropdown: 'Menu.Titles.ServicesDropdown',
            Services: 'Menu.Titles.Services',
            ServiceGroups: 'Menu.Titles.ServiceGroups',
            ServiceItems: 'Menu.Titles.ServiceItems',
            Translates: 'Menu.Titles.Translates',
            TranslatesClassificators: 'Menu.Titles.TranslatesClassificators',
            TranslatesContents: 'Menu.Titles.TranslatesContents',
            TranslatesErrors: 'Menu.Titles.TranslatesErrors',
            TranslatesLabels: 'Menu.Titles.TranslatesLabels',
            TranslatesLabelsFronted: 'Menu.Titles.TranslatesLabelsFronted',
            Users: 'Menu.Titles.Users',
            CountriesVat: 'Menu.Titles.CountriesVat',
            AdministrationFeeMargins: 'Menu.Titles.AdministrationFeeMargins',
            MonthlyRewards: 'Menu.Titles.MonthlyRewards'
        }
    },
    Partner: {
        Buttons: {
            PasswordReset: 'Partner.Buttons.PasswordReset',
            ResendEmailConfirmation: 'Partner.Buttons.ResendEmailConfirmation',
            SyncLabbis: 'Partner.Buttons.SyncLabbis'
        },
        Menu: {
            Account: 'Partner.Menu.Account',
            AdminActions: 'Partner.Menu.AdminActions',
            Communications: 'Partner.Menu.Communications',
            Documents: 'Partner.Menu.Documents',
            Logs: 'Partner.Menu.Logs',
            Profile: 'Partner.Menu.Profile',
            ServiceItems: 'Partner.Menu.ServiceItems',
            Services: 'Partner.Menu.Services',
            Devices: 'Partner.Menu.Devices'
        }
    },
    PartnerAccount: {
        Buttons: {
            Edit: 'PartnerAccount.Buttons.Edit'
        },
        Labels: {
            AccountingEmail: 'PartnerAccount.Labels.AccountingEmail',
            AccountType: 'PartnerAccount.Labels.AccountType',
            Address: 'PartnerAccount.Labels.Address',
            AddressAdditionalInfo: 'PartnerAccount.Labels.AddressAdditionalInfo',
            BankAccount: 'PartnerAccount.Labels.BankAccount',
            BankName: 'PartnerAccount.Labels.BankName',
            CompanyName: 'PartnerAccount.Labels.CompanyName',
            CompanyCode: 'PartnerAccount.Labels.CompanyCode',
            NipCode: 'PartnerAccount.Labels.NipCode',
            InsurancePolicyNumber: 'PartnerAccount.Labels.InsurancePolicyNumber',
            Email: 'PartnerAccount.Labels.Email',
            Phone: 'PartnerAccount.Labels.Phone',
            Position: 'PartnerAccount.Labels.Position',
            VATCode: 'PartnerAccount.Labels.VATCode',
            AdministrationMargin: 'PartnerAccount.Labels.AdministrationMargin',
            VatName: 'PartnerAccount.Labels.VatName',
            PersonalCode: 'PartnerAccount.Labels.PersonalCode',
            VatStatusChangedDate: 'PartnerAccount.Labels.VatStatusChangedDate'
        }
    },
    PartnerAccountForm: {
        Buttons: {
            Cancel: 'PartnerAccountForm.Buttons.Cancel',
            Submit: 'PartnerAccountForm.Buttons.Submit'
        },
        Labels: {
            AccountingEmail: 'PartnerAccountForm.Labels.AccountingEmail',
            Address: 'PartnerAccountForm.Labels.Address',
            AddressAdditionalInfo: 'PartnerAccountForm.Labels.AddressAdditionalInfo',
            AccountType: 'PartnerAccountForm.Labels.AccountType',
            CompanyName: 'PartnerAccountForm.Labels.CompanyName',
            InsurancePolicyNumber: 'PartnerAccountForm.Labels.InsurancePolicyNumber',
            CompanyCode: 'PartnerAccountForm.Labels.CompanyCode',
            NipCode: 'PartnerAccountForm.Labels.NipCode',
            BankAccount: 'PartnerAccountForm.Labels.BankAccount',
            BankName: 'PartnerAccountForm.Labels.BankName',
            Email: 'PartnerAccountForm.Labels.Email',
            Phone: 'PartnerAccountForm.Labels.Phone',
            Position: 'PartnerAccountForm.Labels.Position',
            isVatPayer: 'PartnerAccountForm.Labels.isVatPayer',
            VATCode: 'PartnerAccountForm.Labels.VATCode',
            AdministrationMargin: 'PartnerAccountForm.Labels.AdministrationMargin',
            VatPercentage: 'PartnerAccountForm.Labels.VatPercentage',
            VatName: 'PartnerAccountForm.Labels.VatName',
            PersonalCode: 'PartnerAccountForm.Labels.PersonalCode',
            PersonalCodeType: 'PartnerAccountForm.Labels.PersonalCodeType',
            VatStatusChangedDate: 'PartnerAccountForm.Labels.VatStatusChangedDate'
        }
    },
    PartnerAdminActions: {
        Labels: {
            Action: 'PartnerAdminActions.Labels.Action',
            Author: 'PartnerAdminActions.Labels.Author',
            Date: 'PartnerAdminActions.Labels.Date',
            Description: 'PartnerAdminActions.Labels.Description'
        }
    },
    PartnerCommunications: {
        Buttons: {
            Edit: 'PartnerCommunications.Buttons.Edit'
        },
        Labels: {
            Comments: 'PartnerCommunications.Labels.Comments',
            DateOfLastSentInformation: 'PartnerCommunications.Labels.DateOfLastSentInformation',
            LastCommunication: 'PartnerCommunications.Labels.LastCommunication',
            LastSentInformation: 'PartnerCommunications.Labels.LastSentInformation',
            NextScheduledCall: 'PartnerCommunications.Labels.NextScheduledCall',
            NonApprovalReason: 'PartnerCommunications.Labels.NonApprovalReason'
        }
    },
    PartnerCommunicationsForm: {
        Buttons: {
            Cancel: 'PartnerCommunicationsForm.Buttons.Cancel',
            Submit: 'PartnerCommunicationsForm.Buttons.Submit'
        },
        Labels: {
            Comments: 'PartnerCommunicationsForm.Labels.Comments',
            DateOfLastSentInformation: 'PartnerCommunicationsForm.Labels.DateOfLastSentInformation',
            LastCommunication: 'PartnerCommunicationsForm.Labels.LastCommunication',
            LastSentInformation: 'PartnerCommunicationsForm.Labels.LastSentInformation',
            NextScheduledCall: 'PartnerCommunicationsForm.Labels.NextScheduledCall',
            NonApprovalReasons: 'PartnerCommunicationsForm.Labels.NonApprovalReasons'
        }
    },
    PartnerLogs: {
        Labels: {
            Action: 'PartnerLogs.Labels.Action',
            Date: 'PartnerLogs.Labels.Date',
            Description: 'PartnerLogs.Labels.Description'
        }
    },
    PartnerProfile: {
        Buttons: {
            AssignMeAsManager: 'PartnerProfile.Buttons.AssignMeAsManager',
            Block: 'PartnerProfile.Buttons.Block',
            Edit: 'PartnerProfile.Buttons.Edit',
            ChangeJobSources: 'PartnerProfile.Buttons.ChangeJobSources',
            ChangeStatus: 'PartnerProfile.Buttons.ChangeStatus',
            Unblock: 'PartnerProfile.Buttons.Unblock',
            Unlock: 'PartnerProfile.Buttons.Unlock'
        },
        Labels: {
            AboutMe: 'PartnerProfile.Labels.AboutMe',
            AcceptedPrivacyPolicy: 'PartnerProfile.Labels.AcceptedPrivacyPolicy',
            AcceptedTermsAndConditions: 'PartnerProfile.Labels.AcceptedTermsAndConditions',
            AcceptedToBeUsedForMarketingPurpose: 'PartnerProfile.Labels.AcceptedToBeUsedForMarketingPurpose',
            JobSources: 'PartnerProfile.Labels.JobSources',
            Block: 'PartnerProfile.Labels.Block',
            Birthday: 'PartnerProfile.Labels.Birthday',
            City: 'PartnerProfile.Labels.City',
            Email: 'PartnerProfile.Labels.Email',
            KycVerification: 'PartnerProfile.Labels.KycVerification',
            KycVerified: 'PartnerProfile.Labels.KycVerified',
            KycNotVerified: 'PartnerProfile.Labels.KycNotVerified',
            Lock: 'PartnerProfile.Labels.Lock',
            Manager: 'PartnerProfile.Labels.Manager',
            Name: 'PartnerProfile.Labels.Name',
            Telephone: 'PartnerProfile.Labels.Telephone',
            PrivacyPolicy: 'PartnerProfile.Labels.PrivacyPolicy',
            RejectedPrivacyPolicy: 'PartnerProfile.Labels.RejectedPrivacyPolicy',
            RejectedTermsAndConditions: 'PartnerProfile.Labels.RejectedTermsAndConditions',
            RejectedToBeUsedForMarketingPurpose: 'PartnerProfile.Labels.RejectedToBeUsedForMarketingPurpose',
            Source: 'PartnerProfile.Labels.Source',
            Status: 'PartnerProfile.Labels.Status',
            Surname: 'PartnerProfile.Labels.Surname',
            SyncLabbis: 'PartnerProfile.Labels.SyncLabbis',
            TermsAndConditions: 'PartnerProfile.Labels.TermsAndConditions',
            ToBeUsedForMarketingPurpose: 'PartnerProfile.Labels.ToBeUsedForMarketingPurpose',
            IsAutoBidEnabled: 'PartnerProfile.Labels.IsAutoBidEnabled',
            AutoBidEnabled: 'PartnerProfile.Labels.AutoBidEnabled',
            AutoBidDisabled: 'PartnerProfile.Labels.AutoBidDisabled',
            CanSellMaterials: 'PartnerProfile.Labels.CanSellMaterials',
            CanSellMaterialsAccepted: 'PartnerProfile.Labels.CanSellMaterialsAccepted',
            CanSellMaterialsRejected: 'PartnerProfile.Labels.CanSellMaterialsRejected',
            hasGreenCard: 'PartnerProfile.Labels.hasGreenCard',
            hasGreenCardAccepted: 'PartnerProfile.Labels.hasGreenCardAccepted',
            hasGreenCardRejected: 'PartnerProfile.Labels.hasGreenCardRejected',
        },
        Messages: {
            AssignAsManager: 'PartnerProfile.Messages.AssignAsManager',
            Unlock: 'PartnerProfile.Messages.Unlock'
        },
        Statuses: {
            BlockedFalse: 'PartnerProfile.Statuses.BlockedFalse',
            BlockedTrue: 'PartnerProfile.Statuses.BlockedTrue',
            LockedFalse: 'PartnerProfile.Statuses.LockedFalse',
            LockedTrue: 'PartnerProfile.Statuses.LockedTrue'
        }
    },
    PartnerJobSourcesForm: {
        Buttons: {
            Cancel: 'PartnerJobSourcesForm.Buttons.Cancel',
            Submit: 'PartnerJobSourcesForm.Buttons.Submit'
        },
        Labels: {
            JobSources: 'PartnerJobSourcesForm.Labels.JobSources'
        }
    },
    PartnerProfileBlockForm: {
        Buttons: {
            Cancel: 'PartnerProfileBlockForm.Buttons.Cancel',
            Submit: 'PartnerProfileBlockForm.Buttons.Submit'
        },
        Labels: {
            Reason: 'PartnerProfileBlockForm.Labels.Reason'
        }
    },
    PartnerProfileForm: {
        Buttons: {
            Cancel: 'PartnerProfileForm.Buttons.Cancel',
            Submit: 'PartnerProfileForm.Buttons.Submit'
        },
        Labels: {
            AboutMe: 'PartnerProfileForm.Labels.AboutMe',
            City: 'PartnerProfileForm.Labels.City',
            Email: 'PartnerProfileForm.Labels.Email',
            Name: 'PartnerProfileForm.Labels.Name',
            Phone: 'PartnerProfileForm.Labels.Phone',
            Surname: 'PartnerProfileForm.Labels.Surname'
        }
    },
    PartnerProfileStatusForm: {
        Buttons: {
            Cancel: 'PartnerProfileStatusForm.Buttons.Cancel',
            Submit: 'PartnerProfileStatusForm.Buttons.Submit'
        },
        Labels: {
            Status: 'PartnerProfileStatusForm.Labels.Status'
        }
    },
    PartnerSelection: {
        Labels: {
            FirstName: 'PartnerSelection.Labels.FirstName',
            LastName: 'PartnerSelection.Labels.LastName',
            Email: 'PartnerSelection.Labels.Email',
            FullNameCompanyName: 'PartnerSelection.Labels.FullNameCompanyName',
            CompanyName: 'PartnerSelection.Labels.CompanyName',
            SearchPlaceholder: 'PartnerSelection.Labels.SearchPlaceholder',
            SelectedPartnersIds: 'PartnerSelection.Labels.SelectedPartnersIds',
            ManualPartnerSelection: 'PartnerSelection.Labels.ManualPartnerSelection'
        },
        Titles: {
            PartnerList: 'PartnerSelection.Titles.PartnerList',
            PartnerSelection: 'PartnerSelection.Titles.PartnerSelection'
        },
        Buttons: {
            Cancel: 'PartnerSelection.Buttons.Cancel',
            Select: 'PartnerSelection.Buttons.Select'
        }
    },
    PartnerServices: {
        Buttons: {
            Add: 'PartnerServices.Buttons.Add'
        }
    },
    PartnerServicesList: {
        Labels: {
            Areas: 'PartnerServicesList.Labels.Areas',
            AreasRadius: 'PartnerServicesList.Labels.AreasRadius',
            Default: 'PartnerServicesList.Labels.Default',
            HourlyRate: 'PartnerServicesList.Labels.HourlyRate',
            HourlyRateEUR: 'PartnerServicesList.Labels.HourlyRateEUR',
            HourlyRateRUB: 'PartnerServicesList.Labels.HourlyRateRUB',
            HourlyRatePLN: 'PartnerServicesList.Labels.HourlyRatePLN',
            HourlyRateCZK: 'PartnerServicesList.Labels.HourlyRateCZK',
            HourlyRateHUF: 'PartnerServicesList.Labels.HourlyRateHUF',
            IsDefault: 'PartnerServicesList.Labels.IsDefault',
            Name: 'PartnerServicesList.Labels.Name',
            Sertificates: 'PartnerServicesList.Labels.Sertificates',
            ServiceCode: 'PartnerServicesList.Labels.ServiceCode',
            Status: 'PartnerServicesList.Labels.Status'
        }
    },
    PartnerDevicesList: {
        Labels: {
            Version: 'PartnerDevicesList.Labels.Version',
            BaseOS: 'PartnerDevicesList.Labels.BaseOS',
            DeviceId: 'PartnerDevicesList.Labels.DeviceId',
            DeviceName: 'PartnerDevicesList.Labels.DeviceName'
        }
    },
    PartnerServicesForm: {
        Buttons: {
            Cancel: 'PartnerServicesForm.Buttons.Cancel',
            Submit: 'PartnerServicesForm.Buttons.Submit'
        },
        Labels: {
            Areas: 'PartnerServicesForm.Labels.Areas',
            AreasRadius: 'PartnerServicesForm.Labels.AreasRadius',
            HourlyRate: 'PartnerServicesForm.Labels.HourlyRate',
            HourlyRateEUR: 'PartnerServicesForm.Labels.HourlyRateEUR',
            HourlyRateRUB: 'PartnerServicesForm.Labels.HourlyRateRUB',
            HourlyRatePLN: 'PartnerServicesForm.Labels.HourlyRatePLN',
            HourlyRateCZK: 'PartnerServicesForm.Labels.HourlyRateCZK',
            HourlyRateHUF: 'PartnerServicesForm.Labels.HourlyRateHUF',
            Service: 'PartnerServicesForm.Labels.Service'
        }
    },
    Partners: {
        Buttons: {
            Add: 'Partners.Buttons.Add',
            Clear: 'Partners.Buttons.Clear'
        },
        Titles: {
            Partners: 'Partners.Titles.Partners'
        },
        Tooltip: {
            APPROVED: 'Partners.Tooltip.APPROVED',
            EMAIL_APPROVED: 'Partners.Tooltip.EMAIL_APPROVED',
            EMAIL_NOT_APPROVED: 'Partners.Tooltip.EMAIL_NOT_APPROVED'
        }
    },
    PartnersCreate: {
        Titles: {
            PartnersCreate: 'PartnersCreate.Titles.PartnersCreate'
        }
    },
    PartnersCreateForm: {
        Buttons: {
            Cancel: 'PartnersCreateForm.Buttons.Cancel',
            Submit: 'PartnersCreateForm.Buttons.Submit'
        },
        Labels: {
            AccountType: 'PartnersCreateForm.Labels.AccountType',
            Birthday: 'PartnersCreateForm.Labels.Birthday',
            Company: 'PartnersCreateForm.Labels.Company',
            CompanyCode: 'PartnersCreateForm.Labels.CompanyCode',
            Email: 'PartnersCreateForm.Labels.Email',
            IBAN: 'PartnersCreateForm.Labels.IBAN',
            BankName: 'PartnersCreateForm.Labels.BankName',
            Name: 'PartnersCreateForm.Labels.Name',
            Password: 'PartnersCreateForm.Labels.Password',
            PasswordRepeat: 'PartnersCreateForm.Labels.PasswordRepeat',
            Phone: 'PartnersCreateForm.Labels.Phone',
            RegistrationRole: 'PartnersCreateForm.Labels.RegistrationRole',
            Source: 'PartnersCreateForm.Labels.Source',
            Surname: 'PartnersCreateForm.Labels.Surname'
        }
    },
    PartnersFilter: {
        Buttons: {
            Submit: 'PartnersFilter.Buttons.Submit',
            SubmitClear: 'PartnersFilter.Buttons.SubmitClear'
        },
        Labels: {
            SearchString: 'PartnersFilter.Labels.SearchString'
        }
    },
    PartnersList: {
        Labels: {
            CompletedServiceCount: 'PartnersList.Labels.CompletedServiceCount',
            Email: 'PartnersList.Labels.Email',
            FirstName: 'PartnersList.Labels.FirstName',
            HasAgreedToReceiveMarketingInformation: 'PartnersList.Labels.HasAgreedToReceiveMarketingInformation',
            IncompleteServiceCount: 'PartnersList.Labels.IncompleteServiceCount',
            LastName: 'PartnersList.Labels.LastName',
            LastLoginDate: 'PartnersList.Labels.LastLoginDate',
            HasNewActivities: 'PartnersList.Labels.HasNewActivities',
            PendingServiceCount: 'PartnersList.Labels.PendingServiceCount',
            RegistrationDate: 'PartnersList.Labels.RegistrationDate',
            SearchPlaceholder: 'PartnersList.Labels.SearchPlaceholder',
            StatusName: 'PartnersList.Labels.StatusName',
            SynchronizationDate: 'PartnersList.Labels.SynchronizationDate',
            TotalServiceCount: 'PartnersList.Labels.TotalServiceCount',
            CompanyName: 'PartnersList.Labels.CompanyName',
            PhoneNumber: 'PartnersList.Labels.PhoneNumber',
            HasDocuments: 'PartnersList.Labels.HasDocuments'
        },
        Values: {
            MarketingFalse: 'PartnersList.Values.MarketingFalse',
            MarketingTrue: 'PartnersList.Values.MarketingTrue',
            DocumentsFalse: 'PartnersList.Values.DocumentsFalse',
            DocumentsTrue: 'PartnersList.Values.DocumentsTrue'
        }
    },
    PartnersMarketing: {
        Buttons: {
            Copy: 'PartnersMarketing.Buttons.Copy'
        },
        Labels: {
            Email: 'PartnersMarketing.Labels.Email'
        },
        Menu: {
            Marketing: 'PartnersMarketing.Menu.Marketing',
            MarketingAgreed: 'PartnersMarketing.Menu.MarketingAgreed'
        },
        Titles: {
            PartnersMarketing: 'PartnersMarketing.Titles.PartnersMarketing'
        }
    },
    PartnerDocuments: {
        Buttons: {
            Add: 'PartnerDocuments.Buttons.Add'
        }
    },
    PartnerDocumentsForm: {
        Buttons: {
            Cancel: 'PartnerDocumentsForm.Buttons.Cancel',
            Submit: 'PartnerDocumentsForm.Buttons.Submit'
        },
        Labels: {
            File: 'PartnerDocumentsForm.Labels.File',
            ValidFrom: 'PartnerDocumentsForm.Labels.ValidFrom',
            ValidTo: 'PartnerDocumentsForm.Labels.ValidTo'
        }
    },
    PartnerDocumentsList: {
        Buttons: {
            Upload: 'PartnerDocumentsList.Buttons.Upload'
        },
        Labels: {
            Actions: 'PartnerDocumentsList.Labels.Actions',
            Count: 'PartnerDocumentsList.Labels.Count',
            Deleted: 'PartnerDocumentsList.Labels.Deleted',
            Name: 'PartnerDocumentsList.Labels.Name',
            ParameterStatus: 'PartnerDocumentsList.Labels.ParameterStatus',
            Status: 'PartnerDocumentsList.Labels.Status',
            ValidFrom: 'PartnerDocumentsList.Labels.ValidFrom',
            ValidTo: 'PartnerDocumentsList.Labels.ValidTo'
        }
    },
    PartnerService: {
        Buttons: {
            Delete: 'PartnerService.Buttons.Delete',
            Edit: 'PartnerService.Buttons.Edit',
            SetDefault: 'PartnerService.Buttons.SetDefault'
        },
        Labels: {
            Areas: 'PartnerService.Labels.Areas',
            AreasRadius: 'PartnerService.Labels.AreasRadius',
            Default: 'PartnerService.Labels.Default',
            IsDefault: 'PartnerService.Labels.IsDefault',
            HourlyRate: 'PartnerService.Labels.HourlyRate',
            HourlyRateEUR: 'PartnerService.Labels.HourlyRateEUR',
            HourlyRateRUB: 'PartnerService.Labels.HourlyRateRUB',
            HourlyRatePLN: 'PartnerService.Labels.HourlyRatePLN',
            HourlyRateCZK: 'PartnerService.Labels.HourlyRateCZK',
            HourlyRateHUF: 'PartnerService.Labels.HourlyRateHUF',
            Status: 'PartnerService.Labels.Status'
        },
        Messages: {
            Delete: 'PartnerService.Messages.Delete',
            SetDefault: 'PartnerService.Messages.SetDefault'
        },
        Titles: {
            Documents: 'PartnerService.Titles.Documents',
            Status: 'PartnerService.Titles.Status'
        }
    },
    PartnerServiceForm: {
        Buttons: {
            Cancel: 'PartnerServiceForm.Buttons.Cancel',
            Submit: 'PartnerServiceForm.Buttons.Submit'
        },
        Labels: {
            Areas: 'PartnerServiceForm.Labels.Areas',
            AreasRadius: 'PartnerServiceForm.Labels.AreasRadius',
            HourlyRate: 'PartnerServiceForm.Labels.HourlyRate',
            HourlyRateEUR: 'PartnerServiceForm.Labels.HourlyRateEUR',
            HourlyRateRUB: 'PartnerServiceForm.Labels.HourlyRateRUB',
            HourlyRatePLN: 'PartnerServiceForm.Labels.HourlyRatePLN',
            HourlyRateCZK: 'PartnerServiceForm.Labels.HourlyRateCZK',
            HourlyRateHUF: 'PartnerServiceForm.Labels.HourlyRateHUF'
        }
    },
    PartnerServiceDocumentsList: {
        Labels: {
            Actions: 'PartnerServiceDocumentsList.Labels.Actions',
            Count: 'PartnerServiceDocumentsList.Labels.Count',
            Name: 'PartnerServiceDocumentsList.Labels.Name',
            ParameterStatus: 'PartnerServiceDocumentsList.Labels.ParameterStatus',
            Status: 'PartnerServiceDocumentsList.Labels.Status',
            ValidFrom: 'PartnerServiceDocumentsList.Labels.ValidFrom',
            ValidTo: 'PartnerServiceDocumentsList.Labels.ValidTo'
        }
    },
    PopConfirm: {
        Buttons: {
            Cancel: 'PopConfirm.Buttons.Cancel',
            Confirm: 'PopConfirm.Buttons.Confirm'
        }
    },
    Service: {
        Labels: {
            ExternalServiceId: 'Service.Labels.ExternalServiceId',
            Priority: 'Service.Labels.Priority',
            ServiceGroup: 'Service.Labels.ServiceGroup',
            ShowInWeb: 'Service.Labels.ShowInWeb',
            ShowInRegistrationForm: 'Service.Labels.ShowInRegistrationForm',
            ValidFrom: 'Service.Labels.ValidFrom',
            ValidFromWeb: 'Service.Labels.ValidFromWeb',
            ValidTo: 'Service.Labels.ValidTo',
            ValidToWeb: 'Service.Labels.ValidToWeb',
            VatPercentage: 'Service.Labels.VatPercentage',
            CanReverseVatBeApplied: 'Service.Labels.CanReverseVatBeApplied',
            ServiceDetailsDescription: 'Service.Labels.ServiceDetailsDescription',
            ServiceDetailsAdditionalDescription: 'Service.Labels.ServiceDetailsAdditionalDescription',
            ServiceDetailsPrice: 'Service.Labels.ServiceDetailsPrice',
            ServiceDetailsKeywords: 'Service.Labels.ServiceDetailsKeywords',
            ServiceDetailsHintText: 'Service.Labels.ServiceDetailsHintText',
            ServiceDetailsOrder: 'Service.Labels.ServiceDetailsOrder',
            ServiceDetailsStoppedJobs: 'Service.Labels.ServiceDetailsStoppedJobs'
        },
        Titles: {
            Information: 'Service.Titles.Information'
        }
    },
    Services: {
        Titles: {
            Services: 'Services.Titles.Services'
        }
    },
    ServicesForm: {
        Buttons: {
            Add: 'ServicesForm.Buttons.Add',
            Submit: 'ServicesForm.Buttons.Submit'
        },
        Labels: {
            ExternalServiceID: 'ServicesForm.Labels.ExternalServiceID',
            Group: 'ServicesForm.Labels.Group',
            Service: 'ServicesForm.Labels.Service',
            ValidFrom: 'ServicesForm.Labels.ValidFrom',
            ValidTo: 'ServicesForm.Labels.ValidTo',
            VatPercentage: 'ServicesForm.Labels.VatPercentage'
        },
        Titles: {
            Add: 'ServicesForm.Titles.Add'
        }
    },
    ServicesFormEdit: {
        Buttons: {
            Edit: 'ServicesFormEdit.Buttons.Edit',
            Submit: 'ServicesFormEdit.Buttons.Submit'
        },
        Labels: {
            ExternalServiceId: 'ServicesFormEdit.Labels.ExternalServiceId',
            Group: 'ServicesFormEdit.Labels.Group',
            Priority: 'ServicesFormEdit.Labels.Priority',
            ShowInWeb: 'ServicesFormEdit.Labels.ShowInWeb',
            CanReverseVatBeApplied: 'ServicesFormEdit.Labels.CanReverseVatBeApplied',
            ShowInRegistrationForm: 'ServicesFormEdit.Labels.ShowInRegistrationForm',
            ValidFrom: 'ServicesFormEdit.Labels.ValidFrom',
            ValidFromWeb: 'ServicesFormEdit.Labels.ValidFromWeb',
            ValidTo: 'ServicesFormEdit.Labels.ValidTo',
            ValidToWeb: 'ServicesFormEdit.Labels.ValidToWeb',
            VatPercentage: 'ServicesFormEdit.Labels.VatPercentage',
            ServiceDetailsDescription: 'ServicesFormEdit.Labels.ServiceDetailsDescription',
            ServiceDetailsAdditionalDescription: 'ServicesFormEdit.Labels.ServiceDetailsAdditionalDescription',
            ServiceDetailsPrice: 'ServicesFormEdit.Labels.ServiceDetailsPrice',
            ServiceDetailsKeywords: 'ServicesFormEdit.Labels.ServiceDetailsKeywords',
            ServiceDetailsHintText: 'ServicesFormEdit.Labels.ServiceDetailsHintText',
            ServiceDetailsOrder: 'ServicesFormEdit.Labels.ServiceDetailsOrder',
            ServiceDetailsStoppedJobs: 'ServiceFormEdit.Labels.StoppedJobs',
            ServiceDetailsStopPriceList: 'ServiceFormEdit.Labels.StopPriceList',
            ServiceDetailsStopFreeText: 'ServiceFormEdit.Labels.StopFreeText',
            ServiceDetailsStopBidding: 'ServiceFormEdit.Labels.StopBidding',
            ServiceDetailsSelectStopJobType: 'ServiceFormEdit.Labels.SelectStopJobType',
            ServiceDetailsApartmentSegment: 'ServiceFormEdit.Labels.ServiceDetailsApartmentSegment',
            ServiceDetailsAdministratorSegment: 'ServiceFormEdit.Labels.ServiceDetailsAdministratorSegment'
        },
        Errors: {
            Segments: 'ServiceFormEdit.Errors.Segments'
        }
    },
    ServicesList: {
        Buttons: {
            Edit: 'ServicesList.Buttons.Edit'
        },
        Labels: {
            Name: 'ServicesList.Labels.Name',
            Status: 'ServicesList.Labels.Status',
            ServiceCode: 'ServicesList.Labels.ServiceCode',
            ValidFrom: 'ServicesList.Labels.ValidFrom',
            ValidTo: 'ServicesList.Labels.ValidTo',
            Stopped: 'ServicesList.Labels.Stopped'
        }
    },
    ServiceGroup: {
        Buttons: {
            Edit: 'ServiceGroup.Buttons.Edit',
            Cancel: 'ServiceGroup.Buttons.Cancel',
            Submit: 'ServiceGroup.Buttons.Submit'
        },
        Labels: {
            ServiceGroupCode: 'ServiceGroup.Labels.ServiceGroupCode',
            Description: 'ServiceGroup.Labels.Description',
            AdditionalDescription: 'ServiceGroup.Labels.AdditionalDescription',
            HourlyPrice: 'ServiceGroup.Labels.HourlyPrice',
            HintText: 'ServiceGroup.Labels.HintText',
            SearchKeywords: 'ServiceGroup.Labels.SearchKeywords',
            Order: 'ServiceGroup.Labels.Order',
            AlwaysShowInSearch: 'ServiceGroup.Labels.AlwaysShowInSearch'
        }
    },
    ServiceGroups: {
        Labels: {
            Code: 'ServiceGroups.Labels.Code',
            Description: 'ServiceGroups.Labels.Description'
        }
    },
    PartnerServiceItems: {
        Labels: {
            Status: 'PartnerServiceItems.Labels.Status',
            Service: 'PartnerServiceItems.Labels.Service',
            JobTranslation: 'PartnerServiceItems.Labels.JobTranslation',
            UnitOfMeasure: 'PartnerServiceItems.Labels.UnitOfMeasure',
            Price: 'PartnerServiceItems.Labels.Price',
            Active: 'PartnerServiceItems.Labels.Active'
        },
        Statuses: {
            Active: 'PartnerServiceItems.Statuses.Active',
            Inactive: 'PartnerServiceItems.Statuses.Inactive'
        }
    },
    ServiceItems: {
        Buttons: {
            SelectService: 'ServiceItems.Buttons.SelectService',
            AddItem: 'ServiceItems.Buttons.AddItem'
        },
        Labels: {
            Actions: 'ServiceItems.Labels.Actions',
            Cancel: 'ServiceItems.Labels.Cancel',
            Coefficient: 'ServiceItems.Labels.Coefficient',
            Edit: 'ServiceItems.Labels.Edit',
            Margin: 'ServiceItems.Labels.Margin',
            Price: 'ServiceItems.Labels.Price',
            Save: 'ServiceItems.Labels.Save',
            SelectService: 'ServiceItems.Labels.SelectService',
            JobTitle: 'ServiceItems.Labels.JobTitle',
            UnitOfMeasure: 'ServiceItems.Labels.UnitOfMeasure',
            Code: 'ServiceItems.Labels.Code',
            ConfirmationUnhide: 'ServiceItems.Labels.ConfirmationUnhide',
            ConfirmationHide: 'ServiceItems.Labels.ConfirmationHide',
            ConfirmationOkText: 'ServiceItems.Labels.ConfirmationOkText',
            ConfirmationCancelText: 'ServiceItems.Labels.ConfirmationCancelText',
            Unhide: 'ServiceItems.Labels.Unhide',
            Hide: 'ServiceItems.Labels.Hide',
            ServiceId: 'ServiceItems.Labels.ServiceId',
            JobId: 'ServiceItems.Labels.JobId',
            JobDescription: 'ServiceItems.Labels.JobDescription',
            MinimalPrice: 'ServiceItems.Labels.MinimalPrice',
            PriceReduction: 'ServiceItems.Labels.PriceReduction',
            Copy: 'ServiceItems.Labels.Copy',
            Info: 'ServiceItems.Labels.Info'
        },
        Titles: {
            ServiceItems: 'ServiceItems.Title.ServiceItems'
        }
    },
    ServiceItemsCreate: {
        Buttons: {
            Submit: 'ServiceItemsCreate.Buttons.Create',
            Cancel: 'ServiceItemsCreate.Buttons.Cancel',
            AddLanguage: 'ServiceItemsCreate.Buttons.AddLanguage',
            InformModalClose: 'ServiceItemsCreate.Buttons.InformModalClose'
        },
        Labels: {
            Language: 'ServiceItemsCreate.Labels.Language',
            JobTitle: 'ServiceItemsCreate.Labels.JobTitle',
            JobDescription: 'ServiceItemsCreate.Labels.JobDescription',
            MeasuringUnit: 'ServiceItemsCreate.Labels.MeasuringUnit',
            PartnerPrice: 'ServiceItemsCreate.Labels.PartnerPrice',
            MinimalPartnerPrice: 'ServiceItemsCreate.Labels.MinimalPartnerPrice',
            Margin: 'ServiceItemsCreate.Labels.Margin',
            Coefficient: 'ServiceItemsCreate.Labels.Coefficient',
            PriceReduction: 'ServiceItemsCreate.Labels.PriceReduction',
            ConfirmationTitle: 'ServiceItemsCreate.Labels.ConfirmationTitle',
            ConfirmationOkText: 'ServiceItemsCreate.Labels.ConfirmationOkText',
            ConfirmationCancelText: 'ServiceItemsCreate.Labels.ConfirmationCancelText',
            InformModalTitle: 'ServiceItemsCreate.Labels.InformModalTitle',
            InformModalDescription: 'ServiceItemsCreate.Labels.InformModalDescription',
            InformModalCopy: 'ServiceItemsCreate.Labels.InformModalCopy',
            InformModalServiceId: 'ServiceItemsCreate.Labels.InformModalServiceId',
            InformModalService: 'ServiceItemsCreate.Labels.InformModalService',
            InformModalJobId: 'ServiceItemsCreate.Labels.InformModalJobId',
            InformModalJobTitle: 'ServiceItemsCreate.Labels.InformModalJobTitle',
            InformModalJobDescription: 'ServiceItemsCreate.Labels.InformModalJobDescription'
        },
        Titles: {
            ServiceItemsCreate: 'ServiceItemsCreate.Titles.ServiceItemsCreate',
            JobDescriptions: 'ServiceItemsCreate.Titles.JobDescriptions',
            PriceInformation: 'ServiceItemsCreate.Titles.PriceInformation'
        }
    },
    ServiceSettingsForm: {
        Buttons: {
            Add: 'ServiceSettingsForm.Buttons.Add',
            Submit: 'ServiceSettingsForm.Buttons.Submit'
        },
        Labels: {
            Parameter: 'ServiceSettingsForm.Labels.Parameter',
            ValidFrom: 'ServiceSettingsForm.Labels.ValidFrom',
            ValidTo: 'ServiceSettingsForm.Labels.ValidTo',
            Value: 'ServiceSettingsForm.Labels.Value'
        },
        Titles: {
            Add: 'ServiceSettingsForm.Titles.Add'
        }
    },
    ServiceSettingsEditForm: {
        Buttons: {
            Edit: 'ServiceSettingsEditForm.Buttons.Edit',
            Submit: 'ServiceSettingsEditForm.Buttons.Submit'
        },
        Labels: {
            ValidFrom: 'ServiceSettingsEditForm.Labels.ValidFrom',
            ValidTo: 'ServiceSettingsEditForm.Labels.ValidTo'
        },
        Titles: {
            Submit: 'ServiceSettingsEditForm.Buttons.Submit'
        }
    },
    ServiceSettingsList: {
        Buttons: {
            Edit: 'ServiceSettingsList.Buttons.Edit'
        },
        Labels: {
            Actions: 'ServiceSettingsList.Labels.Actions',
            Count: 'ServiceSettingsList.Labels.Count',
            Name: 'ServiceSettingsList.Labels.Name',
            Status: 'ServiceSettingsList.Labels.Status',
            ValidFrom: 'ServiceSettingsList.Labels.ValidFrom',
            ValidTo: 'ServiceSettingsList.Labels.ValidTo',
            Value: 'ServiceSettingsList.Labels.Value'
        },
        Titles: {
            Settings: 'ServiceSettingsList.Titles.Settings'
        }
    },
    Statuses: {
        Bool: {
            False: 'Statuses.Bool.False',
            True: 'Statuses.Bool.True'
        },
        Required: {
            False: 'Statuses.Required.False',
            True: 'Statuses.Required.True'
        }
    },
    Translates: {
        Buttons: {
            Submit: 'Translates.Buttons.Submit'
        },
        Contents: {
            AdminFooter: 'Translates.Contents.AdminFooter',
            AdministratorMaterialInfo: 'Translates.Contents.AdministratorMaterialInfo',
            AdministratorOrderBiddingTermsAndConditions:
                'Translates.Contents.AdministratorOrderBiddingTermsAndConditions',
            ApartmentMaterialInfo: 'Translates.Contents.ApartmentMaterialInfo',
            ApartmentOrderBiddingTermsAndConditions: 'Translates.Contents.ApartmentOrderBiddingTermsAndConditions',
            InvoiceMailBody: 'Translates.Contents.InvoiceMailBody',
            InvoiceMailSubject: 'Translates.Contents.InvoiceMailSubject',
            MailClientConfirmationBody: 'Translates.Contents.MailClientConfirmationBody',
            MailClientConfirmationSubject: 'Translates.Contents.MailClientConfirmationSubject',
            MailClientPasswordResetBody: 'Translates.Contents.MailClientPasswordResetBody',
            MailClientPasswordResetSubject: 'Translates.Contents.MailClientPasswordResetSubject',
            PartnerPasswordResetSuccessSubtext: 'Translates.Contents.PartnerPasswordResetSuccessSubtext',
            MailConfirmationSubject: 'Translates.Contents.MailConfirmationSubject',
            MailLoginInfoSubject: 'Translates.Contents.MailLoginInfoSubject',
            MailPasswordResetSubject: 'Translates.Contents.MailPasswordResetSubject',
            PartnerBlocked: 'Translates.Contents.PartnerBlocked',
            PartnerLegal: 'Translates.Contents.PartnerLegal',
            PartnerPolicy: 'Translates.Contents.PartnerPolicy',
            PublicFooter: 'Translates.Contents.PublicFooter',
            SmsProfileConfirmed: 'Translates.Contents.SmsProfileConfirmed',
            SmsRegistrationSuccess: 'Translates.Contents.SmsRegistrationSuccess'
        },
        Environments: {
            Admin: 'Translates.Environments.Admin',
            Front: 'Translates.Environments.Front'
        },
        Labels: {
            Default: 'Translates.Labels.Default',
            Key: 'Translates.Labels.Key',
            SaveContentAsHTML: 'Translates.Labels.SaveContentAsHTML',
            SaveContentAsPlainText: 'Translates.Labels.SaveContentAsPlainText',
            Translate: 'Translates.Labels.Translate',
            NotifyPartners: 'Translates.Labels.NotifyPartners',
            ConfirmationOkText: 'Translates.Labels.ConfirmationOkText',
            ConfirmationCancelText: 'Translates.Labels.ConfirmationCancelText',
            ConfirmationTitle: 'Translates.Labels.ConfirmationTitle'
        },
        Titles: {
            Classificators: 'Translates.Titles.Classificators',
            Contents: 'Translates.Titles.Contents',
            ErrorMessages: 'Translates.Titles.ErrorMessages',
            Labels: 'Translates.Titles.Labels',
            LabelsFrontend: 'Translates.Titles.LabelsFrontend'
        }
    },
    TranslatesFilter: {
        Buttons: {
            Create: 'TranslatesFilter.Buttons.Create',
            CreateClose: 'TranslatesFilter.Buttons.CreateClose',
            CreateOpen: 'TranslatesFilter.Buttons.CreateOpen',
            Filter: 'TranslatesFilter.Buttons.Filter'
        },
        Labels: {
            Contents: 'TranslatesFilter.Labels.Contents',
            EnglishTranslate: 'TranslatesFilter.Labels.EnglishTranslate',
            Environment: 'TranslatesFilter.Labels.Environment',
            Group: 'TranslatesFilter.Labels.Group',
            Key: 'TranslatesFilter.Labels.Key',
            Languages: 'TranslatesFilter.Labels.Languages',
            Modules: 'TranslatesFilter.Labels.Modules'
        }
    },
    CountryVat: {
        Titles: {
            Main: 'CountryVat.Titles.Main',
            Form: 'CountryVat.Titles.Form'
        },
        Labels: {
            VatName: 'CountryVat.Labels.VatName',
            VatPercentage: 'CountryVat.Labels.VatPercentage',
            VatTranslation: 'CountryVat.Labels.VatTranslation',
            VatAction: 'CountryVat.Labels.VatAction',
            VatKey: 'CountryVat.Labels.VatKey',
            IsVatDefault: 'CountryVat.Labels.IsVatDefault',
            VatDefault: 'CountryVat.Labels.VatDefault'
        },
        Buttons: {
            Submit: 'CountryVat.Buttons.Submit',
            Add: 'CountryVat.Buttons.Add'
        }
    },
    AdministrationFeeMargins: {
        Titles: {
            Main: 'AdministrationFeeMargins.Titles.Main'
        },
        Labels: {
            MinimalFee: 'AdministrationFeeMargins.Labels.MinimalFee',
            MaximumFee: 'AdministrationFeeMargins.Labels.MaximumFee',
            MaterialsClientPrice: 'AdministrationFeeMargins.Labels.MaterialsClientPrice',
            MaterialsMargin: 'AdministrationFeeMargins.Labels.MaterialsMargin',
            StepName: 'AdministrationFeeMargins.Labels.StepName',
            StepPercent: 'AdministrationFeeMargins.Labels.StepPercent',
            LastStepSuffix: 'AdministrationFeeMargins.Labels.LastStepSuffix',
            SegmentChangeWarningMessage: 'AdministrationFeeMargins.Labels.SegmentChangeWarningMessage',
            SegmentChangeWarningDescription: 'AdministrationFeeMargins.Labels.SegmentChangeWarningDescription'
        },
        Buttons: {
            Cancel: 'AdministrationFeeMargins.Buttons.Cancel',
            Edit: 'AdministrationFeeMargins.Buttons.Edit',
            Submit: 'AdministrationFeeMargins.Buttons.Submit',
            WarningContinue: 'AdministrationFeeMargins.Buttons.WarningContinue',
            WarningCancel: 'AdministrationFeeMargins.Buttons.WarningCancel'
        }
    },
    CountriesMonthlyRewards: {
        Titles: {
            Main: 'CountriesMonthlyRewards.Titles.Main'
        },
        Labels: {
            MonthlyRewards: 'CountriesMonthlyRewards.Labels.MonthlyRewards',
            Month: 'CountriesMonthlyRewards.Labels.Month'
        },
        Buttons: {
            Edit: 'CountriesMonthlyRewards.Buttons.Edit',
            Submit: 'CountriesMonthlyRewards.Buttons.Submit',
            Cancel: 'CountriesMonthlyRewards.Buttons.Cancel'
        }
    }
};

export const i18cl = {
    ACTIVITY_LOG: 'ACTIVITY_LOG.',
    COUNTRIES: 'COUNTRIES.',
    CLIENT_SEGMENTS: 'CLIENT_SEGMENTS.',
    BIDDING_TYPES: 'BIDDING_TYPES.',
    DOCUMENT_VALIDITY_VALUE: 'DOCUMENT_VALIDITY_VALUE.',
    GROUPS_SERVICE: 'GROUPS_SERVICE.',
    INFO_ABOUT_PORTAL_FOUND_IN: 'INFO_ABOUT_PORTAL_FOUND_IN.',
    JOB_VAT_TYPES: 'JOB_VAT_TYPES.',
    LANGUAGES: 'LANGUAGES.',
    NON_APPROVAL_REASON: 'NON_APPROVAL_REASON.',
    ROLES_USER: 'ROLES_USER.',
    SERVICES_CATALOG: 'SERVICES_CATALOG.',
    SERVICE_TYPES_DOCUMENT: 'SERVICE_TYPES_DOCUMENT.',
    SETTINGS_COUNTRY: 'SETTINGS_COUNTRY.',
    SETTINGS_SERVICE: 'SETTINGS_SERVICE.',
    STATUSES_COURSE: 'STATUSES_COURSE.',
    STATUSES_LABBIS_SYNC: 'STATUSES_LABBIS_SYNC.',
    STATUSES_ORDER: 'STATUSES_ORDER.',
    STATUSES_PARAMETER: 'STATUSES_PARAMETER.',
    STATUSES_PROFILE: 'STATUSES_PROFILE.',
    STATUSES_PROFILE_SERVICE: 'STATUSES_PROFILE_SERVICE.',
    STATUSES_SERVICES: 'STATUSES_SERVICES.',
    STATUSES_WORK: 'STATUSES_WORK.',
    TYPES_ACCOUNT: 'TYPES_ACCOUNT.',
    TYPES_DOCUMENT: 'TYPES_DOCUMENT.',
    TYPES_WORK: 'TYPES_WORK.',
    INVOICING_VAT: 'Invoicing.Vat.',
    INVOICING_SERVICE: 'Invoicing.Service.'
};

export const i18clGroups = {
    BIDDING_STAGES: 'BIDDING_STAGES',
    BIDDING_TYPES: 'BIDDING_TYPES',
    CLIENT_SEGMENTS: 'CLIENT_SEGMENTS',
    INFO_ABOUT_PORTAL_FOUND_IN: 'INFO_ABOUT_PORTAL_FOUND_IN',
    ROLES_USER: 'ROLES_USER',
    SERVICES_CATALOG: 'SERVICES_CATALOG',
    STATUSES_ORDER: 'STATUSES_ORDER',
    STATUSES_PROFILE: 'STATUSES_PROFILE',
    STATUSES_PROFILE_SERVICE: 'STATUSES_PROFILE_SERVICE',
    STATUSES_WORK: 'STATUSES_WORK',
    TYPES_ACCOUNT: 'TYPES_ACCOUNT',
    TYPES_WORK: 'TYPES_WORK',
    INVOICING_VAT: 'Invoicing.Vat.',
    INVOICING_SERVICE: 'Invoicing.Service.'
};

const i18ContentsEmailsClient = [
    'MailClientConfirmationSubject',
    'MailClientConfirmationBody',
    'MailClientPasswordResetSubject',
    'MailClientPasswordResetBody'
];

const i18ContentsSms = ['SmsProfileConfirmed', 'SmsRegistrationSuccess'];

const i18ContentsAlsoUsedByBackend = ['MailConfirmationSubject', 'MailPasswordResetSubject', 'MailLoginInfoSubject'];

const invoiceMailContent = ['InvoiceMailBody', 'InvoiceMailSubject'];

const materialInfoContent = ['AdministratorMaterialInfo', 'ApartmentMaterialInfo'];

export const i18contents = [
    'PartnerBlocked',
    'PartnerLegal',
    'PartnerPolicy',
    'PublicFooter',
    'AdminFooter',
    'AdministratorOrderBiddingTermsAndConditions',
    'ApartmentOrderBiddingTermsAndConditions',
    'PartnerPasswordResetSuccessSubtext',
    ...i18ContentsEmailsClient,
    ...i18ContentsAlsoUsedByBackend,
    ...invoiceMailContent,
    ...materialInfoContent,
    ...i18ContentsSms
];

export const i18environments = ['Admin', 'Front'];
