import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

import cookie from './cookie';

export function RequireAuth ({ children, to, from, isNotRequired }) {
    const isAuth = cookie.getCookie();
    if (isAuth === undefined || isNotRequired === true) {
        return <Redirect to={{ pathname: to, state: { from } }} />;
    }

    return children;
}

RequireAuth.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    from: PropTypes.object,
    isNotRequired: PropTypes.bool
};
