import { serviceSettingsServices } from './services';
import { serviceSettingsConstants } from './constants';
import { Message } from '../../../components/message';
import { i18, translate } from '../../../utilities';

export const serviceSettingsActions = {
    getServiceSettings,
    getServiceSettingsById,
    postServiceSettings,
    putServiceSettings
};

function getServiceSettings (serviceCatalogId, update) {
    return dispatch => {
        dispatch(request());

        return serviceSettingsServices.getServiceSettings(serviceCatalogId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: serviceSettingsConstants.GET_SERVICE_SETTINGS_REQUEST,
            update: update
        };
    }

    function success (data) {
        return {
            type: serviceSettingsConstants.GET_SERVICE_SETTINGS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: serviceSettingsConstants.GET_SERVICE_SETTINGS_ERROR,
            error
        };
    }
}

function getServiceSettingsById (serviceCatalogId, serviceSettingId) {
    return dispatch => {
        dispatch(request());

        return serviceSettingsServices.getServiceSettingsById(serviceCatalogId, serviceSettingId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: serviceSettingsConstants.GET_SERVICE_SETTINGS_BY_ID_REQUEST,
            serviceSettingId: serviceSettingId
        };
    }

    function success (data) {
        return {
            type: serviceSettingsConstants.GET_SERVICE_SETTINGS_BY_ID_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: serviceSettingsConstants.GET_SERVICE_SETTINGS_BY_ID_ERROR,
            error
        };
    }
}

function postServiceSettings (serviceCatalogId, data) {
    return dispatch => {
        dispatch(request());

        return serviceSettingsServices.postServiceSettings(serviceCatalogId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: serviceSettingsConstants.POST_SERVICE_SETTINGS_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.ServiceSettingAdded)
        });

        return {
            type: serviceSettingsConstants.POST_SERVICE_SETTINGS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: serviceSettingsConstants.POST_SERVICE_SETTINGS_ERROR,
            error
        };
    }
}

function putServiceSettings (serviceCatalogId, data) {
    return dispatch => {
        dispatch(request());

        return serviceSettingsServices.putServiceSettings(serviceCatalogId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: serviceSettingsConstants.PUT_SERVICE_SETTINGS_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.ServiceSettingUpdated)
        });

        return {
            type: serviceSettingsConstants.PUT_SERVICE_SETTINGS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: serviceSettingsConstants.PUT_SERVICE_SETTINGS_ERROR,
            error
        };
    }
}
