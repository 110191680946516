import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { i18, translate } from '../../../utilities';
import { partnerReadActions } from '../../../api';
import { ButtonWrapper, Message, Translate } from '../../../components';
import { CopyOutlined } from '@ant-design/icons';

class PartnersMarketingAgreedContainer extends React.Component {
    constructor (props) {
        super(props);

        this.copy = this.copy.bind(this);
    }

    componentDidMount () {
        this.props.dispatch(partnerReadActions.getMarketingAgreed());
    }

    copy () {
        navigator.clipboard.writeText(this.props.partnerMarketingAgreed.separatedEmails).then(() => {
            Message({
                message: translate(i18.API.Messages.PartnersWithMarketingCopied)
            });
        });
    }

    render () {
        return (
            <div className='content_block'>
                <div className='mr-4 text-right'>
                    <ButtonWrapper
                        icon={<CopyOutlined />}
                        onClick={() => this.copy()}
                    >
                        <Translate value={i18.PartnersMarketing.Buttons.Copy} />
                    </ButtonWrapper>
                </div>
                {this.props.partnerMarketingAgreed.separatedEmails}
            </div>
        );
    }
}

PartnersMarketingAgreedContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    partnerMarketingAgreed: PropTypes.any.isRequired
};

function mapStateToProps (state) {
    const {
        partnerMarketingAgreed
    } = state.partnerReadReducers;

    return {
        partnerMarketingAgreed
    };
}

const connectedPartnersMarketingAgreedContainer = connect(mapStateToProps)(PartnersMarketingAgreedContainer);
export { connectedPartnersMarketingAgreedContainer as PartnersMarketingAgreedContainer };
