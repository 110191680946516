import { requestApi, apiPaths, handleResponse, methods, requestApiFormData } from '../../config';

export const servicesServices = {
    getServices,
    getServicesById,
    postServiceImage,
    postServices,
    putServices
};

function getServices() {
    return requestApi(apiPaths.services).then(handleResponse);
}

function getServicesById(serviceCatalogId) {
    return requestApi(apiPaths.services + '/' + serviceCatalogId).then(handleResponse);
}

function postServiceImage(serviceCatalogId, data) {
    return requestApiFormData(apiPaths.services + '/' + serviceCatalogId + '/image', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postServices(data) {
    return requestApi(apiPaths.services, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function putServices(data) {
    return requestApi(apiPaths.services, {
        method: methods.put,
        data: data
    }).then(handleResponse);
}
