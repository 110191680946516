import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { ReCaptcha } from 'react-recaptcha-v3';

import { composeValidators, i18, required, requiredStrongPassword } from '../../../utilities';
import { userActions, userConstants, requestApi, requestApiFormData } from '../../../api';
import { ButtonPrimary, FormField, formFieldType, Translate } from '../../../components';
import { ResetPasswordInformation } from '../../resetPasswordSuccess';
import { env } from '../../../configs';

class CreatePasswordForm extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            created: false,
            recaptchaLoaded: false
        };

        this.reCaptchaCallback = this.reCaptchaCallback.bind(this);
        this.submit = this.submit.bind(this);
    }

    reCaptchaCallback (token) {
        this.setState({
            recaptchaLoaded: true
        });

        requestApi.defaults.headers.common.captchaToken = token;
        requestApiFormData.defaults.headers.common.captchaToken = token;
    }

    submit (values) {
        const { code } = this.props.match.params;

        this.props.dispatch(userActions.postCreatePassword(values, code)).then(res => {
            if (res && res.type === userConstants.POST_CREATE_PASSWORD_SUCCESS) {
                this.setState({ created: true });
            } else {
                this.setState({
                    recaptchaLoaded: false
                });
            }
        });
    }

    render () {
        return (
            <div className='content_data_item'>
                {this.state.created
                    ? (
                        <ResetPasswordInformation
                            title={<Translate value={i18.ResetSuccess.Titles.PasswordChanged} />}
                            buttonText={<Translate value={i18.ResetSuccess.Buttons.Login} />}
                        />)
                    : (
                        <Form
                            onSubmit={this.submit}
                            validate={values => {
                                const errors = {};
                                if (values.password !== values.passwordRepeat) {
                                    errors.password = <Translate value={i18.Forms.Errors.RequiredSamePassword} />;
                                    errors.passwordRepeat = <Translate value={i18.Forms.Errors.RequiredSamePassword} />;
                                }
                                return errors;
                            }}
                            render={({
                                handleSubmit,
                                values
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    autoComplete='off'
                                >
                                    {!this.state.recaptchaLoaded && (
                                        <ReCaptcha
                                            sitekey={env.REACT_APP_RECAPTCHA}
                                            verifyCallback={this.reCaptchaCallback}
                                        />
                                    )}
                                    <div className='mb-4'>
                                        <FormField
                                            component={formFieldType.password}
                                            name='password'
                                            title={i18.UserPasswordForm.Labels.New}
                                            titleDescription={i18.UserPasswordForm.LabelsDescriptions.New}
                                            validate={composeValidators(required, requiredStrongPassword)}
                                        />
                                        <FormField
                                            component={formFieldType.password}
                                            name='passwordRepeat'
                                            title={i18.UserPasswordForm.Labels.Repeat}
                                            titleDescription={i18.UserPasswordForm.LabelsDescriptions.Repeat}
                                            validate={composeValidators(required, requiredStrongPassword)}
                                        />
                                    </div>
                                    <div className='text-right'>
                                        <ButtonPrimary
                                            htmlType='submit'
                                            loading={this.props.sendUserCreatePasswordPOST}
                                        >
                                            <Translate value={i18.UserPasswordForm.Buttons.Create} />
                                        </ButtonPrimary>
                                    </div>
                                </form>
                            )}
                        />)}
            </div>
        );
    };
}

CreatePasswordForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    sendUserCreatePasswordPOST: PropTypes.bool,
    match: PropTypes.object.isRequired
};

function mapStateToProps (state) {
    const {
        sendUserCreatePasswordPOST
    } = state.userReducers;

    return {
        sendUserCreatePasswordPOST
    };
}

const connectedCreatePasswordForm = connect(mapStateToProps)(CreatePasswordForm);
export { connectedCreatePasswordForm as CreatePasswordForm };
