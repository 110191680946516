import { checkJsonProp } from './';
import { store } from '../api';

export const countrySettings = {
    PhonePlaceholder: 'PHONE_PLACEHOLDER',
    ShowVatCode: 'SHOW_VAT_CODE',
    VatPercentages: 'PARTNER_VAT_PERCENTAGE_VALUES'
};

export const getCountrySettingValue = (value) => {
    const state = store.getState();

    const translate = checkJsonProp(state.publicReducers.publicLocalization.countrySettings, value);

    if (translate) {
        return translate;
    } else {
        return '';
    }
};

export const getBooleanCountrySettingValue = (value) => {
    return getCountrySettingValue(value).toLowerCase() === 'true';
};
