import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { checkJsonProp } from '../../utilities';

class Translate extends React.Component {
    render() {
        if (!this.props.value) {
            return <span className="color_error">No value</span>;
        }

        return (
            <span className={this.props.className}>
                {checkJsonProp(this.props.publicLocalization?.translations, this.props.value) || this.props.value}
            </span>
        );
    }
}

Translate.propTypes = {
    className: PropTypes.string,
    publicLocalization: PropTypes.object,
    value: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    const { publicLocalization } = state.publicReducers;

    return {
        publicLocalization
    };
}

const connectedTranslate = connect(mapStateToProps)(Translate);
export { connectedTranslate as Translate };
