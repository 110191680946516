import React from 'react';

import { checkJsonProp, routerPaths } from '../../../utilities';
import { history } from '../../config';
import { Notification } from '../../../components';

export const showErrorNotification = result => {
    const error = checkJsonProp(result, 'error.response.data') || {};
    const errorValidation = checkJsonProp(result, 'error.response.data.validationErrors');
    const errorStatus = checkJsonProp(result, 'error.response.status');

    if (errorStatus === 401 || errorStatus === 403) {
        history.replace(routerPaths.logout);
    }

    Notification({
        type: 'error',
        message: error.title || (result.error && result.error.message),
        description: errorValidation
            ? errorValidation.map(item => item.errors.map((item, index) => <div key={index}>{item}</div>))
            : error.detail
    });
};

export const serverErrorsMiddleware = store => next => action => {
    const { type } = action;
    const matches = /(.*)_(ERROR)$/.exec(type);
    const result = next(action);

    if (!matches) {
        return result;
    }

    showErrorNotification(result);
    return result;
};
