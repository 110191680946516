import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

export const ButtonGroup = (props) => {
    return (
        <Button.Group>
            {props.children}
        </Button.Group>
    );
};

ButtonGroup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]).isRequired
};
