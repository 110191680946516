import React from 'react';
import PropTypes from 'prop-types';

import { Title } from './';

export const ClickLinkField = props => {
    const handleLinkClick = () => {
        const url = props.input.value.startsWith('http') ? props.input.value : `https://${props.input.value}`;

        const openedWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (openedWindow) {
            openedWindow.opener = null;
        }
    };

    const className = `${props.inline ? 'form_field_wrapper row align-items-center' : 'form_field_wrapper row'}`;

    if (!props.input.value) {
        return <></>;
    }

    return (
        <div className={className} style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={handleLinkClick}>
            <Title
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
                justify={props.justify}
            />
        </div>
    );
};

ClickLinkField.propTypes = {
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    inline: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string
    }),
    justify: PropTypes.bool
};
