import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { formatDate, i18, statusesDocument } from '../../../../../utilities';
import { FileDelete, FileDownload, Translate } from '../../../../../components';

export const PartnerDocumentsList = (props) => {
    const { update } = props;
    const columns = [
        {
            title: <Translate value={i18.PartnerDocumentsList.Labels.Status} />,
            render: (item) => statusesDocument(item.status),
            width: 1
        }, {
            title: <Translate value={i18.PartnerDocumentsList.Labels.Name} />,
            render: (item) => item.documentName
        }, {
            title: <Translate value={i18.PartnerDocumentsList.Labels.ValidFrom} />,
            render: (item) => formatDate(item.validFrom)
        }, {
            title: <Translate value={i18.PartnerDocumentsList.Labels.ValidTo} />,
            render: (item) => formatDate(item.validTo)
        }, {
            title: <Translate value={i18.PartnerDocumentsList.Labels.Deleted} />,
            render: (item) => item.isDeleted && <CheckOutlined />
        }, {
            title: <Translate value={i18.PartnerDocumentsList.Labels.Actions} />,
            render: (item) => (
                <Button.Group>
                    {!item.isDeleted && (
                        <FileDelete
                            profileId={item.profileId}
                            documentId={item.documentId}
                            update={() => update()}
                        />
                    )}
                    <FileDownload
                        name={item.documentName}
                        documentId={item.documentId}
                        profileId={item.profileId}
                        canPreview={true}
                    />
                </Button.Group>
            ),
            className: 'text-right'
        }
    ];

    return (
        <Table
            rowKey='documentId'
            columns={columns}
            dataSource={props.data}
            pagination={false}
            loading={props.loading}
        />
    );
};

PartnerDocumentsList.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    update: PropTypes.func.isRequired
};
