import { partnerManagementServices } from './services';
import { partnerManagementConstants } from './constants';
import { Message } from '../../../components';
import { i18, translate } from '../../../utilities';

export const partnerManagementActions = {
    postAssignMeAsManager,
    postChangeBlockStatus,
    postChangeLockStatus,
    postToggleKycVerified,
    postPartnerJobSources,
    postChangeStatus,
    postCommunicationLog,
    postDetails,
    postResendEmailConfirmation,
    postProfile,
    postIsAutoBidEnabled,
    toggleSellMtc,
    toggleGreenCard,
};

function postAssignMeAsManager(profileId) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postAssignMeAsManager(profileId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.PartnerManagerAssigned)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_ERROR,
            error
        };
    }
}

function postChangeBlockStatus(partnerId, data) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postChangeBlockStatus(partnerId, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.UserStatusBlockChanges)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_ERROR,
            error
        };
    }
}

function postChangeLockStatus(partnerId) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postChangeLockStatus(partnerId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.UserStatusUnlocked)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_ERROR,
            error
        };
    }
}

function postToggleKycVerified(profileId) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postToggleKycVerified(profileId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.PartnerKycVerifiedToggled)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_SUCCESS
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_ERROR,
            error
        };
    }
}

function postPartnerJobSources(partnerId, data) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postPartnerJobSources(partnerId, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.PartnerJobSourcesChanged)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_ERROR,
            error
        };
    }
}

function postChangeStatus(partnerId, data) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postChangeStatus(partnerId, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_STATUS_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.UserStatusChanges)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_STATUS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_STATUS_ERROR,
            error
        };
    }
}

function postCommunicationLog(partnerId, data) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postCommunicationLog(partnerId, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_REQUEST };
    }

    function success(data) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_ERROR,
            error
        };
    }
}

function postDetails(partnerId, data) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postDetails(partnerId, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_DETAILS_REQUEST };
    }

    function success(data) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_DETAILS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_DETAILS_ERROR,
            error
        };
    }
}

function postResendEmailConfirmation(partnerId) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postResendEmailConfirmation(partnerId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.UserResentedConfirmationEmail)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_ERROR,
            error
        };
    }
}

function postProfile(partnerId, data) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postProfile(partnerId, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_PROFILE_REQUEST };
    }

    function success(data) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_PROFILE_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_PROFILE_ERROR,
            error
        };
    }
}

function postIsAutoBidEnabled(profileId) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.postIsAutoBidEnabled(profileId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_AUTO_BID_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.PartnerAutoBidToggled)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_AUTO_BID_SUCCESS
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_AUTO_BID_ERROR,
            error
        };
    }
}

function toggleSellMtc(profileId) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.patchToggleMtc(profileId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_MTC_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.PartnerMTCUpdated)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_MTC_SUCCESS
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_MTC_ERROR,
            error
        };
    }
}

function toggleGreenCard(profileId) {
    return dispatch => {
        dispatch(request());

        return partnerManagementServices.patchToggleGreenCard(profileId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_GREEN_CARD_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.PartnerGreenCardUpdated)
        });

        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_GREEN_CARD_SUCCESS
        };
    }

    function failure(error) {
        return {
            type: partnerManagementConstants.POST_PARTNER_MANAGEMENT_GREEN_CARD_ERROR,
            error
        };
    }
}
