import cookie from './cookie';

export const getToken = () => {
    const token = cookie.getCookie();

    if (token) {
        return 'Bearer ' + token;
    } else {
        return undefined;
    }
};
