import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { partnerProfileActions, partnerProfileConstants } from '../../api';
import { i18 } from '../../utilities';
import { Translate } from '../';

class FileDelete extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: false
        };

        this.delete = this.delete.bind(this);
    }

    delete (e) {
        e.stopPropagation();

        this.setState({ loading: true });

        this.props.dispatch(partnerProfileActions.deleteProfileDocument(this.props.profileId, this.props.documentId)).then(res => {
            if (res && res.type === partnerProfileConstants.DELETE_PARTNER_PROFILE_DOCUMENT_SUCCESS) {
                this.props.update();
            }

            this.setState({ loading: false });
        });
    }

    render () {
        return (
            <Popconfirm
                placement='bottomRight'
                title={<Translate value={i18.Files.Confirms.Delete} />}
                onConfirm={(e) => this.delete(e)}
                okText={<Translate value={i18.Files.Buttons.ConfirmDelete} />}
                cancelText={<Translate value={i18.Files.Buttons.ConfirmCancel} />}
            >
                <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => e.stopPropagation()}
                    loading={this.state.loading}
                    type={this.props.type ? this.props.type : 'link'}
                >
                    <Translate value={i18.Files.Buttons.Delete} />
                </Button>
            </Popconfirm>
        );
    }
}

FileDelete.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileId: PropTypes.number.isRequired,
    documentId: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['primary', 'text', 'danger', 'link', 'dashed']),
    update: PropTypes.func
};

const connectedFileDelete = connect(null)(FileDelete);
export { connectedFileDelete as FileDelete };
