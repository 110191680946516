import React from 'react';
import PropTypes from 'prop-types';

import { Error, Number } from '../../';
import { Title } from './';
import { translate } from '../../../utilities';

export const NumberFormField = props => {
    const containerClassName = ['form_field_wrapper'];

    if (props.center) containerClassName.push('d-flex flex-row');
    else if (props.inline) containerClassName.push('row align-items-center');
    else containerClassName.push('row');

    const inputWrapperClassName = [];

    if (props.center) inputWrapperClassName.push('ml-4');
    else if (props.inline) inputWrapperClassName.push('form_field col-12 col-sm-12 col-md-4');
    else inputWrapperClassName.push('form_field col-12');

    const inputClassName = [];

    if (props.borderTitle) inputClassName.push('bg-white text-secondary');
    if (props.highlighted) inputClassName.push('ant-input-highlighted');
    if (props.dimmed) inputClassName.push('ant-input-dimmed');

    return (
        <div className={containerClassName.join(' ')}>
            <Title
                center={props.center}
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
                required={props.required}
                borderTitle={props.borderTitle}
                dimmed={props.dimmed}
            />
            <div className={inputWrapperClassName.join(' ')}>
                <Number
                    addonAfter={props.addonAfter}
                    addonBefore={props.addonBefore}
                    className={inputClassName.join(' ')}
                    isAllowed={props.isAllowed}
                    isNumberText={!!props.numberText}
                    format={props.formatNumber}
                    prefix={props.formatNumberPrefix}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none"
                    spellCheck="off"
                    size={props.size}
                    placeholder={props.placeholder && translate(props.placeholder)}
                    disabled={props.disabled}
                    name={props.input.name}
                    value={props.input.value}
                    onValueChange={values => {
                        props.input.onChange(
                            props.formatNumberType ? values[props.formatNumberType] : values.floatValue
                        );
                    }}
                />
            </div>
            <Error inline={props.inline} touched={props.meta.touched} error={props.meta.error} />
        </div>
    );
};

NumberFormField.propTypes = {
    addonAfter: PropTypes.any,
    addonBefore: PropTypes.any,
    borderTitle: PropTypes.bool,
    dimmed: PropTypes.bool,
    highlighted: PropTypes.bool,
    disabled: PropTypes.bool,
    formatNumber: PropTypes.object,
    formatNumberType: PropTypes.oneOf(['floatValue', 'formattedValue', 'value']),
    formatNumberPrefix: PropTypes.string,
    inline: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func
    }),
    isAllowed: PropTypes.func,
    placeholder: PropTypes.string,
    placeholderFixed: PropTypes.bool,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    }),
    numberText: PropTypes.bool,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    center: PropTypes.bool
};
