import React from 'react';
import { Tag } from 'antd';

import { i18clGroups } from '../i18';
import { JobsStatusConstants } from '../../api';
import { colors } from '../colors';
import { classificatorTranslatedArray } from '../translate';

export const statusesJob = (status) => {
    const work = classificatorTranslatedArray(i18clGroups.STATUSES_WORK);
    const order = classificatorTranslatedArray(i18clGroups.STATUSES_ORDER);
    const merged = [
        ...work,
        ...order
    ];
    const cleared = merged.filter((item, index, self) =>
        index === self.findIndex((e) => (
            e.value === item.value
        ))
    );
    const current = cleared.find(e => e.value === status)?.label;

    switch (status) {
    case JobsStatusConstants.PAID:
    case JobsStatusConstants.COMPLETED:
    case JobsStatusConstants.APPROVED:
    case JobsStatusConstants.INPROGRESS:
        return (
            <Tag
                color={colors.success}
            >
                {current}
            </Tag>
        );
    case JobsStatusConstants.NEW:
        return (
            <Tag
                color={colors.primary}
            >
                {current}
            </Tag>
        );
    case JobsStatusConstants.RESOLVED:
    case JobsStatusConstants.FINISHED:
        return (
            <Tag
                color={colors.light}
            >
                {current}
            </Tag>
        );
    case JobsStatusConstants.CANCELED:
        return (
            <Tag
                color={colors.error}
            >
                {current}
            </Tag>
        );
    case JobsStatusConstants.PREORDER:
    case JobsStatusConstants.PAUSED:
    case JobsStatusConstants.WAITING_FOR_APPROVAL:
    case JobsStatusConstants.WAITING_FOR_CUSTOMER_APPROVAL:
    case JobsStatusConstants.PRIMARY:
        return (
            <Tag
                color={colors.warning}
            >
                {current}
            </Tag>
        );
    case JobsStatusConstants.UNKNOWN:
        return (
            <Tag
                color={colors.black}
            >
                {current}
            </Tag>
        );
    case JobsStatusConstants.CANCELED_BY_CLIENT:
        return (
            <Tag
                color={colors.error}
            >
                {current}
            </Tag>
        );
    case JobsStatusConstants.CANCELED_BY_PARTNER:
        return (
            <Tag
                color={colors.error}
            >
                {current}
            </Tag>
        );
    default:
        return null;
    }
};
