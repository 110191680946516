import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

import { Translate } from '../translate';
import { i18 } from '../../utilities';

import './index.less';

export const ContentLoader = (props) => {
    return (
        <div className='content_loader'>
            <div className='content_loader_icon'>
                <LoadingOutlined spin />
            </div>
            <div className='content_loader_title'>
                <Translate value={props.title || i18.Loader.Titles.Default} />
            </div>
            <div className='content_loader_description'>
                <Translate value={props.description || i18.Loader.Descriptions.Default} />
            </div>
        </div>
    );
};

ContentLoader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};
