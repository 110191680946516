import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Input, Table } from 'antd';

import { checkJsonProp, i18 } from '../../utilities';
import { translationActions, translationConstants } from '../../api';
import { ContentHeader, Translate } from '../../components';
import { TranslatesErrorsFilter } from './components';
import { env } from '../../configs';

class TranslatesErrorsContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            language: props.publicLanguages[0]?.code || env.REACT_APP_DEFAULT_LANGUAGE,
            translates: [],
            translatesEN: []
        };

        this.filter = this.filter.bind(this);
        this.submit = this.submit.bind(this);
        this.change = this.change.bind(this);
    }

    filter (values) {
        this.setState({
            language: values.language,
            translate: []
        });

        this.props.dispatch(translationActions.getErrorMessages(values.language, values.filter)).then(res => {
            if (res && res.type === translationConstants.GET_ERROR_MESSAGES_SUCCESS) {
                this.setState({
                    translates: res.payload
                });
            }
        });

        this.props.dispatch(translationActions.getErrorMessages('EN', values.filter)).then(res => {
            if (res && res.type === translationConstants.GET_ERROR_MESSAGES_SUCCESS) {
                this.setState({
                    translatesEN: res.payload
                });
            }
        });
    }

    submit (data) {
        this.props.dispatch(translationActions.putErrorMessage(this.state.language, {
            message: data.label,
            translation: data.value
        }));
    }

    change (value, label) {
        this.setState(prevState => ({
            translates: {
                ...prevState.translates,
                [label]: value
            }
        }));
    }

    isLanguageEn () {
        return this.state.language === 'EN';
    }

    render () {
        const columns = [
            {
                title: <Translate value={i18.Translates.Labels.Key} />,
                dataIndex: 'label',
                width: this.isLanguageEn() ? '50%' : '30%'
            }, {
                title: <Translate value={i18.Translates.Labels.Default} />,
                render: (index, item) => (
                    <div>{checkJsonProp(this.state.translatesEN, item.label)}</div>
                ),
                className: this.isLanguageEn() ? 'd-none' : '',
                width: this.isLanguageEn() ? '50%' : '30%'
            }, {
                title: <Translate value={i18.Translates.Labels.Translate} />,
                dataIndex: 'value',
                render: (index, item) => (
                    <Input
                        value={item.value}
                        onChange={({ target: { value } }) => this.change(value, item.label)}
                        onBlur={({ target: { value } }) => this.submit({
                            label: item.label,
                            value: value
                        })}
                    />
                ),
                width: this.isLanguageEn() ? '50%' : '40%'
            }
        ];
        const translates = Object.entries(this.state.translates).map(([label, value]) => ({
            label,
            value
        }));

        return (
            <>
                <ContentHeader
                    title={<Translate value={i18.Translates.Titles.ErrorMessages} />}
                />
                <div className='content_block'>
                    <TranslatesErrorsFilter
                        filter={{
                            language: this.state.language
                        }}
                        languages={this.props.publicLanguages}
                        submit={this.filter}
                        submitting={this.props.sendTranslationErrorMessages}
                    />
                    <div className='content_box'>
                        <Table
                            columns={columns}
                            dataSource={translates}
                            rowKey='label'
                            size='small'
                            pagination={false}
                        />
                    </div>
                </div>
            </>
        );
    };
}

TranslatesErrorsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    publicLanguages: PropTypes.array.isRequired,
    sendTranslationErrorMessages: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        publicLanguages
    } = state.publicReducers;
    const {
        sendTranslationErrorMessages
    } = state.translationReducers;

    return {
        publicLanguages,
        sendTranslationErrorMessages
    };
}

const connectedTranslatesErrorsContainer = withRouter(connect(mapStateToProps)(TranslatesErrorsContainer));
export { connectedTranslatesErrorsContainer as TranslatesErrorsContainer };
