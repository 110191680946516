import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Menu } from 'antd';
import {
    AppstoreAddOutlined,
    BarChartOutlined,
    GlobalOutlined,
    TeamOutlined,
    ToolOutlined,
    TranslationOutlined
} from '@ant-design/icons';

import { getToken, i18, routerPaths } from '../utilities';
import { Translate } from '../components';
import { history } from '../api';
import { env } from '../configs';

const { SubMenu } = Menu;

class PartMenu extends React.Component {
    constructor(props) {
        super(props);

        this.select = this.select.bind(this);
    }

    select(path) {
        history.replace(path.key);
    }

    render() {
        const menu = [
            /* {
                title: i18.Menu.Titles.Dashboard,
                path: routerPaths.dashboard,
                icon: <HomeOutlined />
            }, */
            {
                title: i18.Menu.Titles.ServicesDropdown,
                path: routerPaths.services.services,
                icon: <AppstoreAddOutlined />,
                submenu: [
                    {
                        title: i18.Menu.Titles.ServiceGroups,
                        path: routerPaths.serviceGroups.serviceGroups
                    },
                    {
                        title: i18.Menu.Titles.Services,
                        path: routerPaths.services.services
                    },
                    {
                        title: i18.Menu.Titles.ServiceItems,
                        path: routerPaths.services.serviceItems
                    }
                ]
            },
            {
                title: i18.Menu.Titles.Users,
                icon: <TeamOutlined />,
                path: 'users',
                submenu: [
                    {
                        title: i18.Menu.Titles.Partners,
                        path: routerPaths.partners.index
                    },
                    {
                        title: i18.Menu.Titles.InternalClients,
                        path: routerPaths.internalClients.index
                    },
                    {
                        title: i18.Menu.Titles.PartnersMarketing,
                        path: routerPaths.partners.marketing
                    }
                ]
            },
            {
                title: i18.Menu.Titles.Jobs,
                path: routerPaths.jobs.index,
                icon: <ToolOutlined />
            },
            {
                title: i18.Menu.Titles.Countries,
                icon: <GlobalOutlined />,
                path: 'countries',
                submenu: [
                    {
                        title: i18.Menu.Titles.CountriesLanguages,
                        path: routerPaths.countries.languages
                    },
                    {
                        title: i18.Menu.Titles.CountriesSettings,
                        path: routerPaths.countries.settings
                    },
                    {
                        title: i18.Menu.Titles.CountriesVat,
                        path: routerPaths.countries.vats
                    },
                    {
                        title: i18.Menu.Titles.AdministrationFeeMargins,
                        path: routerPaths.countries.administrationFeeMargins
                    }
                    // TODO: uncomment when ready
                    // {
                    //     title: i18.Menu.Titles.MonthlyRewards,
                    //     path: routerPaths.countries.monthlyRewards
                    // }
                ]
            },
            {
                title: i18.Menu.Titles.Translates,
                path: 'translates',
                icon: <TranslationOutlined />,
                submenu: [
                    {
                        title: i18.Menu.Titles.TranslatesLabels,
                        path: routerPaths.translates.labels
                    },
                    {
                        title: i18.Menu.Titles.TranslatesLabelsFronted,
                        path: routerPaths.translates.labelsFrontend
                    },
                    {
                        title: i18.Menu.Titles.TranslatesClassificators,
                        path: routerPaths.translates.classificators
                    },
                    {
                        title: i18.Menu.Titles.TranslatesContents,
                        path: routerPaths.translates.contents
                    },
                    {
                        title: i18.Menu.Titles.TranslatesErrors,
                        path: routerPaths.translates.errors
                    }
                ]
            },
        ];

        return (
            <div>
                <div className="menu_logo">
                    <img src="../../images/logo_white.svg" alt="portal-pro" />
                </div>
                <Menu
                    selectedKeys={[this.props.location.pathname]}
                    mode="inline"
                    theme="dark"
                    onSelect={this.select}
                    defaultOpenKeys={['countries', 'translates', 'users']}
                >
                    {menu.map(item =>
                        item.submenu ? (
                            <SubMenu
                                key={`submenu-${item.path}`}
                                icon={item.icon}
                                title={<Translate value={item.title} />}
                            >
                                {item.submenu.map(item => (
                                    <Menu.Item key={item.path}>
                                        <Translate value={item.title} />
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                        ) : (
                            <Menu.Item key={item.path} icon={item.icon}>
                                <Translate value={item.title} />
                            </Menu.Item>
                        )
                    )}
                </Menu>
            </div>
        );
    }
}

PartMenu.propTypes = {
    location: PropTypes.object.isRequired
};

const RoutedPartMenu = withRouter(PartMenu);
export { RoutedPartMenu as PartMenu };
