import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';

import {
    classificator,
    classificatorTranslatedArray,
    formatDate,
    i18,
    i18clGroups
} from '../../../utilities';
import { FilterField, filterFieldTypes, Translate } from '../../../components';

const InternalClientsTable = (props) => {
    const columns = [
        {
            dataIndex: 'ClientId',
            key: 'ClientId',
            title: <Translate value={i18.InternalClientsList.Labels.ClientId} />,
            ...FilterField({
                name: 'ClientId',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientId'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'ClientId')
            })
        }, {
            dataIndex: 'ClientType',
            key: 'ClientType',
            title: <Translate value={i18.InternalClientsList.Labels.ClientType} />,
            render: (item) => classificator(`${i18clGroups.TYPES_ACCOUNT}.${item}`),
            ...FilterField({
                name: 'ClientType',
                options: classificatorTranslatedArray(i18clGroups.TYPES_ACCOUNT),
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientType'),
                type: filterFieldTypes.select,
                data: props.filters.find(e => e.name === 'ClientType')
            })
        }, {
            dataIndex: 'ClientFirstName',
            key: 'ClientFirstName',
            title: <Translate value={i18.InternalClientsList.Labels.ClientFirstName} />,
            ...FilterField({
                name: 'ClientFirstName',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientFirstName'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'ClientFirstName')
            })
        }, {
            dataIndex: 'ClientLastName',
            key: 'ClientLastName',
            title: <Translate value={i18.InternalClientsList.Labels.ClientLastName} />,
            ...FilterField({
                name: 'ClientLastName',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientLastName'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'ClientLastName')
            })
        }, {
            dataIndex: 'ClientCompanyName',
            key: 'ClientCompanyName',
            title: <Translate value={i18.InternalClientsList.Labels.ClientCompanyName} />,
            ...FilterField({
                name: 'ClientCompanyName',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientCompanyName'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'ClientCompanyName')
            })
        }, {
            dataIndex: 'ClientCompanyCode',
            key: 'ClientCompanyCode',
            title: <Translate value={i18.InternalClientsList.Labels.ClientCompanyCode} />,
            ...FilterField({
                name: 'ClientCompanyCode',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientCompanyCode'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'ClientCompanyCode')
            })
        }, {
            dataIndex: 'ClientVatPayerCode',
            key: 'ClientVatPayerCode',
            title: <Translate value={i18.InternalClientsList.Labels.ClientVatPayerCode} />,
            ...FilterField({
                name: 'ClientVatPayerCode',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientVatPayerCode'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'ClientVatPayerCode')
            })
        }, {
            dataIndex: 'ClientAddress',
            key: 'ClientAddress',
            title: <Translate value={i18.InternalClientsList.Labels.ClientAddress} />,
            ...FilterField({
                name: 'ClientAddress',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientAddress'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'ClientAddress')
            })
        }, {
            dataIndex: 'ClientTelephone',
            key: 'ClientTelephone',
            title: <Translate value={i18.InternalClientsList.Labels.ClientTelephone} />,
            ...FilterField({
                name: 'ClientTelephone',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientTelephone'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'ClientTelephone')
            })
        }, {
            dataIndex: 'ClientEmail',
            key: 'ClientEmail',
            title: <Translate value={i18.InternalClientsList.Labels.ClientEmail} />,
            ...FilterField({
                name: 'ClientEmail',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'ClientEmail'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'ClientEmail')
            })
        }, {
            dataIndex: 'InvoiceEmail',
            key: 'InvoiceEmail',
            title: <Translate value={i18.InternalClientsList.Labels.InvoiceEmail} />,
            ...FilterField({
                name: 'InvoiceEmail',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'InvoiceEmail'),
                type: filterFieldTypes.text,
                data: props.filters.find(e => e.name === 'InvoiceEmail')
            })
        }, {
            dataIndex: 'CreationDateTime',
            key: 'CreationDateTime',
            title: <Translate value={i18.InternalClientsList.Labels.CreationDateTime} />,
            render: (item) => formatDate(item),
            ...FilterField({
                name: 'CreationDateTime',
                setFilter: props.setFilter,
                sortOrder: props.orders.find(e => e.name === 'CreationDateTime'),
                type: filterFieldTypes.date,
                data: props.filters.find(e => e.name === 'CreationDateTime')
            })
        }
    ];

    return (
        <Table
            rowKey='ClientId'
            onChange={(pagination, columns, order) => props.setOrderAndPagination(pagination, columns, order)}
            rowClassName='cursor_pointer'
            columns={columns}
            dataSource={props.odataInternalClientsGrid.value || []}
            loading={props.sendOdataInternalClientsGrid}
            onRow={item => {
                return {
                    onClick: () => props.onOpenClient(item.ClientId)
                };
            }}
            pagination={{
                total: props.odataInternalClientsGrid['@odata.count'],
                pageSize: props.pagination.pageSize,
                current: props.pagination.current
            }}
        />
    );
};

InternalClientsTable.propTypes = {
    setFilter: PropTypes.func.isRequired,
    setOrderAndPagination: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    orders: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    odataInternalClientsGrid: PropTypes.object.isRequired,
    sendOdataInternalClientsGrid: PropTypes.bool,
    onOpenClient: PropTypes.func
};

function mapStateToProps (state) {
    const {
        odataInternalClientsGrid,
        sendOdataInternalClientsGrid
    } = state.odataReducers;

    return {
        odataInternalClientsGrid,
        sendOdataInternalClientsGrid
    };
}

const connectedInternalClientsTable = connect(mapStateToProps)(InternalClientsTable);
export { connectedInternalClientsTable as InternalClientsTable };
