import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from 'react-final-form';

import { classificatorArray, i18, i18cl, required } from '../../../utilities';
import { FormField, formFieldType, Translate } from '../../../components';

export const TranslatesClassificatorsFilter = (props) => {
    return (
        <Form
            onSubmit={props.submit}
            initialValues={props.filter}
            render={({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    className='mb-3'
                    autoComplete='off'
                >
                    <div className='row'>
                        <div className='col'>
                            <FormField
                                title={i18.TranslatesFilter.Labels.Languages}
                                name='language'
                                component={formFieldType.select}
                                options={classificatorArray(i18cl.LANGUAGES, props.languages, 'code')}
                                validate={required}
                                disabled={props.submitting}
                            />
                        </div>
                        <div className='col'>
                            <FormField
                                title={i18.TranslatesFilter.Labels.Group}
                                name='filter.group'
                                component={formFieldType.select}
                                options={props.groups}
                                optionsArray
                                validate={required}
                                disabled={props.submitting}
                            />
                        </div>
                    </div>
                    <div className='text-right'>
                        <Button
                            icon={<SearchOutlined />}
                            type='primary'
                            htmlType='submit'
                            loading={props.submitting}
                        >
                            <Translate value={i18.TranslatesFilter.Buttons.Filter} />
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

TranslatesClassificatorsFilter.propTypes = {
    filter: PropTypes.object.isRequired,
    groups: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};
