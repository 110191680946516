import React, { Component } from 'react';
import { Modal } from 'antd';
import { store } from '../../api';
import { Provider } from 'react-redux';
import './customModal.less';

export class CustomModal extends Component {
    static modalInstance = null;

    static open({ content, okText, width, title, hideOkButton = false, closable = true, style }) {
        CustomModal.modalInstance = Modal.info({
            title,
            content: <Provider store={store}>{content}</Provider>,
            width: width || '50%',
            icon: null,
            okText,
            closable,
            className: hideOkButton ? 'hide-ok-button' : '',
            style
        });
    }

    static close() {
        if (CustomModal.modalInstance) {
            CustomModal.modalInstance.destroy();
        }
    }

    render() {
        return null;
    }
}
