import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { ReCaptcha } from 'react-recaptcha-v3';

import { formatNoSpaces, i18, required, requiredEmailAllowSpaces, routerPaths } from '../../../utilities';
import { history, requestApi, requestApiFormData, userActions, userConstants } from '../../../api';
import { ButtonPrimary, FormField, formFieldType, Translate } from '../../../components';
import { env } from '../../../configs';

class LoginForm extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            recaptchaLoaded: false,
            loginName: ''
        };

        this.reCaptchaCallback = this.reCaptchaCallback.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
    }

    componentDidMount () {
        const loginName = window.sessionStorage.getItem('_email') || '';
        this.setState({ loginName });
    }

    reCaptchaCallback (token) {
        this.setState({
            recaptchaLoaded: true
        });

        requestApi.defaults.headers.common.captchaToken = token;
        requestApiFormData.defaults.headers.common.captchaToken = token;
    }

    submitLogin (values) {
        window.sessionStorage.setItem('_email', values.loginName);
        this.props.dispatch(userActions.postLogin(values)).then(res => {
            if (res && res.type === userConstants.POST_LOGIN_SUCCESS) {
                if (res.payload.roleCode === 'ADMIN') {
                    window.sessionStorage.removeItem('_email');
                    history.replace(routerPaths.partners.index);
                } else {
                    history.replace(routerPaths.logout);
                }
            } else {
                this.setState({
                    recaptchaLoaded: false
                });
            }
        });
    }

    render () {
        return (
            <Form
                onSubmit={this.submitLogin}
                mutators={{
                    updateKeyValue: (args, state, utils) => {
                        utils.changeValue(state, args[0], () => args[1]);
                    }
                }}
                render={({ handleSubmit, form, values }) => (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete='off'
                    >
                        {!this.state.recaptchaLoaded && (
                            <ReCaptcha
                                sitekey={env.REACT_APP_RECAPTCHA}
                                verifyCallback={this.reCaptchaCallback}
                            />
                        )}
                        <div className='mb-3'>
                            <FormField
                                component={formFieldType.input}
                                title={i18.Login.Labels.Email}
                                disabled={this.props.sendUserLogin}
                                name='loginName'
                                format={formatNoSpaces}
                                validate={requiredEmailAllowSpaces}
                                size='large'
                            />
                            <FormField
                                component={formFieldType.password}
                                title={i18.Login.Labels.Password}
                                disabled={this.props.sendUserLogin}
                                name='password'
                                validate={required}
                                size='large'
                            />
                        </div>
                        <div className='mb-3 text-center'>
                            <ButtonPrimary
                                block
                                onClick={form.submit}
                                htmlType='submit'
                                loading={this.props.sendUserLogin}
                                size='large'
                            >
                                <Translate value={i18.Login.Buttons.Submit}/>
                            </ButtonPrimary>
                        </div>
                    </form>
                )}
            />
        );
    };
}

LoginForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    sendUserLogin: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        sendUserLogin
    } = state.userReducers;

    return {
        sendUserLogin
    };
}

const connectedLoginForm = connect(mapStateToProps)(LoginForm);
export { connectedLoginForm as LoginForm };
