import { i18 } from '../../utilities/i18';
import { StopTypesConstants } from './stopTypesConstants';

export const stopTypes = {
    PRICE_LIST: {
        value: StopTypesConstants.PRICE_LIST,
        label: i18.ServicesFormEdit.Labels.ServiceDetailsStopPriceList
    },
    FREE_TEXT: {
        value: StopTypesConstants.FREE_TEXT,
        label: i18.ServicesFormEdit.Labels.ServiceDetailsStopFreeText
    },
    BIDDING: {
        value: StopTypesConstants.BIDDING,
        label: i18.ServicesFormEdit.Labels.ServiceDetailsStopBidding
    }
};
