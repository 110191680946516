import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { CloudUploadOutlined } from '@ant-design/icons';
import { serialize } from 'object-to-formdata';

import { i18, i18cl } from '../../../../utilities';
import { partnerProfileActions, partnerProfileConstants } from '../../../../api';
import { ButtonWrapper, Classificator, Translate } from '../../../../components';
import { PartnerDocumentsForm, PartnerDocumentsList } from './';

class PartnerDocumentsContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            visible: []
        };

        this.fetch = this.fetch.bind(this);
        this.add = this.add.bind(this);
        this.cancel = this.cancel.bind(this);
        this.submit = this.submit.bind(this);
    }

    fetch () {
        this.props.dispatch(partnerProfileActions.getProfileDocuments(this.props.match.params.partnerId));
    }

    add (value) {
        this.setState(prevState => ({
            visible: [...prevState.visible, value]
        }));
    }

    cancel (value) {
        this.setState(prevState => ({
            visible: prevState.visible.filter(e => e !== value)
        }));
    }

    submit (values) {
        const data = serialize(values);

        this.props.dispatch(partnerProfileActions.postProfileDocument(this.props.match.params.partnerId, data)).then(res => {
            if (res && res.type === partnerProfileConstants.POST_PARTNER_PROFILE_DOCUMENT_SUCCESS) {
                this.cancel(values.value);
                this.fetch();
            }
        });
    }

    render () {
        return (
            <div className='content_block'>
                {this.props.partnerProfileDocuments.map((item, index) =>
                    <div
                        key={index}
                        className='mb-4'
                    >
                        <div className='content_subtitle_wrapper'>
                            <div className='content_subtitle'>
                                <Classificator value={i18cl.TYPES_DOCUMENT + item.value} />
                            </div>
                            <div>
                                {!this.state.visible.includes(index) && (
                                    <ButtonWrapper
                                        type='primary'
                                        icon={<CloudUploadOutlined />}
                                        onClick={() => this.add(item.value)}
                                    >
                                        <Translate value={i18.PartnerDocuments.Buttons.Add} />
                                    </ButtonWrapper>
                                )}
                            </div>
                        </div>
                        {this.state.visible.includes(item.value) && (
                            <div className='mb-4'>
                                <PartnerDocumentsForm
                                    cancel={() => this.cancel(item.value)}
                                    submit={this.submit}
                                    submitting={this.props.sendPartnerProfileDocumentPOST}
                                    values={item}
                                />
                            </div>
                        )}
                        <PartnerDocumentsList
                            data={item.files}
                            loading={this.props.sendPartnerProfileDocumentsGET || this.props.sendPartnerProfileDocumentPOST}
                            update={this.fetch}
                        />
                    </div>
                )}
            </div>
        );
    }
}

PartnerDocumentsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    partnerProfileDocuments: PropTypes.array.isRequired,
    sendPartnerProfileDocumentsGET: PropTypes.bool.isRequired,
    sendPartnerProfileDocumentPOST: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        partnerProfileDocuments,
        sendPartnerProfileDocumentsGET
    } = state.partnerProfileReducers;

    return {
        partnerProfileDocuments,
        sendPartnerProfileDocumentsGET
    };
}

const connectedPartnerDocumentsContainer = withRouter(connect(mapStateToProps)(PartnerDocumentsContainer));
export { connectedPartnerDocumentsContainer as PartnerDocumentsContainer };
