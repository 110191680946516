import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { Switch } from 'antd';

import { i18 } from '../../../../utilities';
import {
    partnerManagementActions,
    partnerManagementConstants,
    partnerProfileActions,
    partnerProfileConstants
} from '../../../../api';
import { ButtonWrapper, PopConfirmWrapper, Translate } from '../../../../components';
import { PartnerJobSourcesForm, PartnerBlockForm, PartnerProfileData, PartnerProfileForm, PartnerStatusForm } from './';

class PartnerProfileContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            editingBlock: false,
            editingStatus: false,
            editingJobSources: false
        };

        this.submit = this.submit.bind(this);
        this.submitAssignManager = this.submitAssignManager.bind(this);
        this.submitStatus = this.submitStatus.bind(this);
        this.submitStatusBlock = this.submitStatusBlock.bind(this);
        this.submitStatusLock = this.submitStatusLock.bind(this);
        this.submitPartnerJobSources = this.submitPartnerJobSources.bind(this);
        this.submitPartnerToggleKycVerified = this.submitPartnerToggleKycVerified.bind(this);
        this.submitIsAutoBidEnabled = this.submitIsAutoBidEnabled.bind(this);
        this.submitPartnerToggleMtc = this.submitPartnerToggleMtc.bind(this);
        this.submitPartnerToggleGreenCard = this.submitPartnerToggleGreenCard.bind(this);
    }

    submit(values) {
        this.props
            .dispatch(partnerManagementActions.postProfile(this.props.match.params.partnerId, values))
            .then(res => {
                if (res && res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_PROFILE_SUCCESS) {
                    this.props
                        .dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true))
                        .then(res => {
                            if (res && res.type === partnerProfileConstants.GET_PARTNER_PROFILE_SUCCESS) {
                                this.setState({ editing: false });
                            }
                        });
                }
            });
    }

    submitAssignManager() {
        this.props
            .dispatch(partnerManagementActions.postAssignMeAsManager(this.props.match.params.partnerId))
            .then(res => {
                if (
                    res &&
                    res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_SUCCESS
                ) {
                    this.props.dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true));
                }
            });
    }

    submitPartnerToggleKycVerified() {
        this.props
            .dispatch(partnerManagementActions.postToggleKycVerified(this.props.match.params.partnerId))
            .then(res => {
                if (
                    res &&
                    res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_SUCCESS
                ) {
                    this.props.dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true));
                }
            });
    }

    submitPartnerToggleMtc() {
        this.props.dispatch(partnerManagementActions.toggleSellMtc(this.props.match.params.partnerId)).then(res => {
            if (res && res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_MTC_SUCCESS) {
                this.props.dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true));
            }
        });
    }

    submitPartnerToggleGreenCard() {
        this.props.dispatch(partnerManagementActions.toggleGreenCard(this.props.match.params.partnerId)).then(res => {
            if (res && res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_GREEN_CARD_SUCCESS) {
                this.props.dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true));
            }
        });
    }

    submitPartnerJobSources(values) {
        this.props
            .dispatch(partnerManagementActions.postPartnerJobSources(this.props.match.params.partnerId, values))
            .then(res => {
                if (res && res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_SUCCESS) {
                    this.props
                        .dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true))
                        .then(res => {
                            if (res && res.type === partnerProfileConstants.GET_PARTNER_PROFILE_SUCCESS) {
                                this.setState({ editingJobSources: false });
                            }
                        });
                }
            });
    }

    submitStatus(values) {
        this.props
            .dispatch(partnerManagementActions.postChangeStatus(this.props.match.params.partnerId, values))
            .then(res => {
                if (res && res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_STATUS_SUCCESS) {
                    this.props
                        .dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true))
                        .then(res => {
                            if (res && res.type === partnerProfileConstants.GET_PARTNER_PROFILE_SUCCESS) {
                                this.setState({ editingStatus: false });
                            }
                        });
                }
            });
    }

    submitStatusBlock(values) {
        this.props
            .dispatch(partnerManagementActions.postChangeBlockStatus(this.props.match.params.partnerId, values))
            .then(res => {
                if (
                    res &&
                    res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_SUCCESS
                ) {
                    this.props
                        .dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true))
                        .then(res => {
                            if (res && res.type === partnerProfileConstants.GET_PARTNER_PROFILE_SUCCESS) {
                                this.setState({ editingBlock: false });
                            }
                        });
                }
            });
    }

    submitStatusLock(values) {
        this.props
            .dispatch(partnerManagementActions.postChangeLockStatus(this.props.match.params.partnerId, values))
            .then(res => {
                if (res && res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_SUCCESS) {
                    this.props.dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true));
                }
            });
    }

    submitIsAutoBidEnabled() {
        this.props
            .dispatch(partnerManagementActions.postIsAutoBidEnabled(this.props.match.params.partnerId))
            .then(res => {
                if (res && res.type === partnerManagementConstants.POST_PARTNER_MANAGEMENT_AUTO_BID_SUCCESS) {
                    this.props.dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true));
                }
            });
    }

    render() {
        return (
            <div className="content_block">
                {!this.state.editing && (
                    <div className="mb-4 text-right">
                        <ButtonWrapper
                            type="secondary"
                            icon={<EditOutlined />}
                            onClick={() => this.setState({ editing: true })}
                        >
                            <Translate value={i18.PartnerProfile.Buttons.Edit} />
                        </ButtonWrapper>
                    </div>
                )}
                {this.state.editing ? (
                    <PartnerProfileForm
                        cancel={() => this.setState({ editing: false })}
                        data={this.props.partnerProfile}
                        submit={this.submit}
                        submitting={this.props.sendPartnerManagementProfilePOST || this.props.sendPartnerProfileGET}
                    />
                ) : (
                    <PartnerProfileData
                        data={this.props.partnerProfile}
                        block={
                            !this.state.editingBlock && (
                                <ButtonWrapper
                                    type="link"
                                    onClick={() => this.setState({ editingBlock: true })}
                                    loading={this.props.sendPartnerManagementBlockStatusPOST}
                                >
                                    {this.props.partnerProfile.isBlocked ? (
                                        <Translate value={i18.PartnerProfile.Buttons.Unblock} />
                                    ) : (
                                        <Translate value={i18.PartnerProfile.Buttons.Block} />
                                    )}
                                </ButtonWrapper>
                            )
                        }
                        assignManager={
                            this.props.userLogin.loginName !==
                                this.props.partnerProfile.responsibleAdminEmailAddress && (
                                <PopConfirmWrapper
                                    placement="topLeft"
                                    title={<Translate value={i18.PartnerProfile.Messages.AssignAsManager} />}
                                    onConfirm={() => this.submitAssignManager()}
                                >
                                    <ButtonWrapper
                                        type="link"
                                        loading={
                                            this.props.sendPartnerManagementAssignMeAsManager ||
                                            this.props.sendPartnerProfileGET
                                        }
                                    >
                                        <Translate value={i18.PartnerProfile.Buttons.AssignMeAsManager} />
                                    </ButtonWrapper>
                                </PopConfirmWrapper>
                            )
                        }
                        blockForm={
                            this.state.editingBlock && (
                                <PartnerBlockForm
                                    cancel={() => this.setState({ editingBlock: false })}
                                    submit={this.submitStatusBlock}
                                    submitting={
                                        this.props.sendPartnerManagementBlockStatusPOST ||
                                        this.props.sendPartnerProfileGET
                                    }
                                    data={{ blockReason: this.props.partnerProfile.blockReason }}
                                />
                            )
                        }
                        unlock={
                            this.props.partnerProfile.isLocked && (
                                <PopConfirmWrapper
                                    placement="topLeft"
                                    title={<Translate value={i18.PartnerProfile.Messages.Unlock} />}
                                    onConfirm={() => this.submitStatusLock()}
                                >
                                    <ButtonWrapper
                                        type="link"
                                        loading={
                                            this.props.sendPartnerManagementLockStatusPOST ||
                                            this.props.sendPartnerProfileGET
                                        }
                                    >
                                        <Translate value={i18.PartnerProfile.Buttons.Unlock} />
                                    </ButtonWrapper>
                                </PopConfirmWrapper>
                            )
                        }
                        status={
                            !this.state.editingStatus && (
                                <ButtonWrapper
                                    type="link"
                                    onClick={() => this.setState({ editingStatus: true })}
                                    loading={this.props.sendPartnerManagementStatusPOST}
                                >
                                    <Translate value={i18.PartnerProfile.Buttons.ChangeStatus} />
                                </ButtonWrapper>
                            )
                        }
                        statusForm={
                            this.state.editingStatus && (
                                <PartnerStatusForm
                                    cancel={() => this.setState({ editingStatus: false })}
                                    submit={this.submitStatus}
                                    submitting={
                                        this.props.sendPartnerManagementStatusPOST || this.props.sendPartnerProfileGET
                                    }
                                    data={{ status: this.props.partnerProfile.status }}
                                />
                            )
                        }
                        jobSourcesAction={
                            !this.state.editingJobSources && (
                                <ButtonWrapper
                                    type="link"
                                    onClick={() => this.setState({ editingJobSources: true })}
                                    loading={this.props.sendPartnerManagementJobSourcesPOST}
                                >
                                    <Translate value={i18.PartnerProfile.Buttons.ChangeJobSources} />
                                </ButtonWrapper>
                            )
                        }
                        kycVerifiedAction={
                            <Switch
                                checked={this.props.partnerProfile.kycVerified}
                                onChange={this.submitPartnerToggleKycVerified}
                                disabled={this.props.sendPartnerManagementToggleKycVerifiedPOST}
                            />
                        }
                        mtcAction={
                            <Switch
                                checked={this.props.partnerProfile.allowedSellMTCMaterials}
                                onChange={this.submitPartnerToggleMtc}
                                disabled={this.props.sendPartnerManagementMTC}
                            />
                        }
                        greenCardAction={
                            <Switch
                                checked={this.props.partnerProfile.hasGreenCard}
                                onChange={this.submitPartnerToggleGreenCard}
                            />
                        }
                        isAutoBidEnabled={
                            <Switch
                                checked={this.props.partnerProfile.isAutoBidEnabled}
                                onChange={this.submitIsAutoBidEnabled}
                                disabled={this.props.sendPartnerManagementAutoBidPOST}
                            />
                        }
                        jobSourcesForm={
                            this.state.editingJobSources && (
                                <PartnerJobSourcesForm
                                    cancel={() => this.setState({ editingJobSources: false })}
                                    submit={this.submitPartnerJobSources}
                                    submitting={
                                        this.props.sendPartnerManagementJobSourcesPOST ||
                                        this.props.sendPartnerProfileGET
                                    }
                                    data={{ jobSources: this.props.partnerProfile.jobSources }}
                                />
                            )
                        }
                    />
                )}
            </div>
        );
    }
}

PartnerProfileContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    partnerProfile: PropTypes.object.isRequired,
    sendPartnerManagementAssignMeAsManager: PropTypes.bool,
    sendPartnerManagementBlockStatusPOST: PropTypes.bool,
    sendPartnerManagementLockStatusPOST: PropTypes.bool,
    sendPartnerManagementProfilePOST: PropTypes.bool,
    sendPartnerManagementJobSourcesPOST: PropTypes.bool,
    sendPartnerManagementStatusPOST: PropTypes.bool,
    sendPartnerManagementToggleKycVerifiedPOST: PropTypes.bool,
    sendPartnerManagementMTC: PropTypes.bool,
    sendPartnerManagementAutoBidPOST: PropTypes.bool,
    sendPartnerProfileGET: PropTypes.bool,
    userLogin: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const {
        sendPartnerManagementAssignMeAsManager,
        sendPartnerManagementBlockStatusPOST,
        sendPartnerManagementJobSourcesPOST,
        sendPartnerManagementLockStatusPOST,
        sendPartnerManagementProfilePOST,
        sendPartnerManagementStatusPOST,
        sendPartnerManagementToggleKycVerifiedPOST,
        sendPartnerManagementAutoBidPOST
    } = state.partnerManagementReducers;
    const { partnerProfile, sendPartnerProfileGET } = state.partnerProfileReducers;
    const { userLogin } = state.userReducers;

    return {
        partnerProfile,
        sendPartnerManagementAssignMeAsManager,
        sendPartnerManagementBlockStatusPOST,
        sendPartnerManagementLockStatusPOST,
        sendPartnerManagementProfilePOST,
        sendPartnerManagementJobSourcesPOST,
        sendPartnerManagementStatusPOST,
        sendPartnerManagementToggleKycVerifiedPOST,
        sendPartnerManagementAutoBidPOST,
        sendPartnerProfileGET,
        userLogin
    };
}

const connectedPartnerProfileContainer = connect(mapStateToProps)(PartnerProfileContainer);
export { connectedPartnerProfileContainer as PartnerProfileContainer };
