import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Table } from 'antd';
import { ClearOutlined, SaveOutlined } from '@ant-design/icons';

import { languageActions, publicActions, systemActions } from '../../api';
import { classificatorArray, i18, i18cl } from '../../utilities';
import { Classificator, ContentHeader, FormField, formFieldType, Translate } from '../../components';

class CountriesLanguagesContainer extends React.Component {
    constructor (props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount () {
        this.props.dispatch(languageActions.getCountriesLanguages());
        this.props.dispatch(languageActions.getAllLanguages());
    }

    submit (values) {
        this.props.dispatch(languageActions.postCountriesLanguages(values.countries)).then(() => {
            this.props.dispatch(languageActions.getCountriesLanguages());
            this.props.dispatch(publicActions.getLanguages()).then(lang => {
                if (lang.payload && lang.payload.length > 0) {
                    this.props.dispatch(systemActions.setLanguage(lang.payload[0].code));
                    this.props.dispatch(publicActions.getLocalization(lang.payload[0].code));
                }
            });
        });
    };

    render () {
        const columns = [
            {
                title: <Translate value={i18.Languages.Labels.Country} />,
                render: (item) => (
                    <Classificator value={i18cl.COUNTRIES + item.countryCode} />
                )
            }, {
                title: <Translate value={i18.Languages.Labels.Languages} />,
                dataIndex: 'Languages',
                render: (item, object, index) => (
                    <FormField
                        component={formFieldType.multiSelect}
                        name={`countries.${index.toString()}.languages`}
                        options={classificatorArray(i18cl.LANGUAGES, this.props.allLanguages)}
                    />
                ),
                width: '70%'
            }
        ];

        return (
            <Form
                onSubmit={this.submit}
                initialValues={{
                    countries: this.props.countriesLanguages
                }}
                render={({
                    handleSubmit,
                    form,
                    pristine
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete='off'
                    >
                        <ContentHeader
                            title={<Translate value={i18.Languages.Titles.Languages} />}
                            extra={
                                <Button.Group>
                                    {!pristine || this.props.sendLanguages ? (
                                        <Button
                                            icon={<ClearOutlined />}
                                            disabled={this.props.sendLanguagesPOST}
                                            onClick={() => form.reset()}
                                        >
                                            <Translate value={i18.Languages.Buttons.Reset} />
                                        </Button>
                                    ) : null}
                                    <Button
                                        disabled={pristine || this.props.sendLanguages}
                                        icon={<SaveOutlined />}
                                        loading={this.props.sendLanguagesPOST}
                                        type='primary'
                                        htmlType='submit'
                                    >
                                        <Translate value={i18.Languages.Buttons.Submit} />
                                    </Button>
                                </Button.Group>
                            }
                        />
                        <div className='content_block'>
                            <div className='content_box'>
                                <Table
                                    columns={columns}
                                    dataSource={this.props.countriesLanguages}
                                    loading={this.props.sendLanguages}
                                    rowKey='countryCode'
                                    pagination={false}
                                />

                            </div>
                        </div>
                    </form>
                )}
            />
        );
    };
}

CountriesLanguagesContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    countriesLanguages: PropTypes.array.isRequired,
    allLanguages: PropTypes.array.isRequired,
    publicLanguages: PropTypes.array.isRequired,
    sendLanguages: PropTypes.bool,
    sendLanguagesPOST: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        countriesLanguages,
        allLanguages,
        sendLanguages,
        sendLanguagesPOST
    } = state.languageReducers;
    const {
        publicLanguages,
        publicLocalization
    } = state.publicReducers;

    return {
        countriesLanguages,
        allLanguages,
        sendLanguages,
        sendLanguagesPOST,
        publicLanguages,
        publicLocalization
    };
}

const connectedCountriesLanguagesContainer = connect(mapStateToProps)(CountriesLanguagesContainer);
export { connectedCountriesLanguagesContainer as CountriesLanguagesContainer };
