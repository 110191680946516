import { getCountry, getLanguage } from '../../utilities';
import { requestApi, requestApiFormData } from '../config';
import { systemConstants } from './constants';

export const systemReducers = function (state = {
    systemCountry: getCountry(),
    systemLanguage: getLanguage()
}, action) {
    switch (action.type) {
    case systemConstants.SET_SYSTEM_COUNTRY:
        requestApi.defaults.headers.common.CountryCode = action.payload;
        requestApiFormData.defaults.headers.common.CountryCode = action.payload;

        return {
            ...state,
            systemCountry: action.payload
        };
    case systemConstants.SET_SYSTEM_LANGUAGE:
        window.localStorage.setItem('language', action.payload);
        requestApi.defaults.headers.common.LangCode = action.payload;
        requestApiFormData.defaults.headers.common.LangCode = action.payload;

        return {
            ...state,
            systemLanguage: action.payload
        };
    default:
        return state;
    }
};
