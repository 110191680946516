import { ClientSegmentConstants } from '../api';

export const getFormattedStopJobsEntries = serviceStoppedJobsList => {
    const formattedStopJobsList = serviceStoppedJobsList?.reduce((acc, s) => {
        if (!acc[s.stoppedJobType]) {
            acc[s.stoppedJobType] = [];
        }

        if (s.clientSegment !== ClientSegmentConstants.UNDEFINED) {
            acc[s.stoppedJobType].push(s.clientSegment);
        }

        return acc;
    }, []);

    const formattedStopJobsEntries = Object.entries(formattedStopJobsList || {});

    return formattedStopJobsEntries;
};
