import { serviceItemsConstants } from './constants';

export const serviceItemsReducers = function (
    state = {
        serviceItems: []
    },
    action
) {
    switch (action.type) {
        case serviceItemsConstants.GET_SERVICE_ITEMS_REQUEST:
            return {
                ...state,
                send: true,
                sendServiceItemsGET: true
            };
        case serviceItemsConstants.GET_SERVICE_ITEMS_SUCCESS:
            return {
                ...state,
                serviceItems: action.payload.serviceItems,
                send: false,
                sendServiceItemsGET: false
            };
        case serviceItemsConstants.GET_SERVICE_ITEMS_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendServiceItemsGET: false
            };
        case serviceItemsConstants.POST_SERVICE_ITEMS_REQUEST:
            return {
                ...state,
                send: true,
                sendServiceItemsPOST: true
            };
        case serviceItemsConstants.POST_SERVICE_ITEMS_SUCCESS:
            return {
                ...state,
                send: false,
                sendServiceItemsPOST: false
            };
        case serviceItemsConstants.POST_SERVICE_ITEMS_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendServiceItemsPOST: false
            };
        case serviceItemsConstants.POST_SERVICE_ITEMS_SORT_REQUEST:
            return {
                ...state,
                send: true,
                sendServiceItemsSortPOST: true
            };
        case serviceItemsConstants.POST_SERVICE_ITEMS_SORT_SUCCESS:
            return {
                ...state,
                send: false,
                sendServiceItemsSortPOST: false
            };
        case serviceItemsConstants.POST_SERVICE_ITEMS_SORT_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendServiceItemsSortPOST: false
            };
        case serviceItemsConstants.PUT_SERVICE_ITEMS_DATA_REQUEST:
            return {
                ...state,
                send: true,
                sendServiceItemsDataPUT: true
            };
        case serviceItemsConstants.PUT_SERVICE_ITEMS_DATA_SUCCESS:
            return {
                ...state,
                send: false,
                sendServiceItemsDataPUT: false
            };
        case serviceItemsConstants.PUT_SERVICE_ITEMS_DATA_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendServiceItemsDataPUT: false
            };
        case serviceItemsConstants.PATCH_SERVICE_ITEM_STATUS_REQUEST:
            return {
                ...state,
                send: true,
                sendServiceItemStatusPATCH: true
            };
        case serviceItemsConstants.PATCH_SERVICE_ITEM_STATUS_SUCCESS: {
            const updatedServiceItems = state.serviceItems.map(item =>
                item.id === action.payload.id ? { ...item, isDisabled: action.payload.isDisabled } : item
            );

            return {
                ...state,
                serviceItems: updatedServiceItems,
                send: false,
                sendServiceItemStatusPATCH: false
            };
        }
        case serviceItemsConstants.PATCH_SERVICE_ITEM_STATUS_ERROR:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendServiceItemStatusPATCH: false
            };
        case serviceItemsConstants.POST_SERVICE_ITEMS_REQUEST_V2:
            return {
                ...state,
                send: true,
                sendServiceItemsPOSTV2: true
            };
        case serviceItemsConstants.POST_SERVICE_ITEMS_SUCCESS_V2:
            return {
                ...state,
                send: false,
                sendServiceItemsPOSTV2: false
            };
        case serviceItemsConstants.POST_SERVICE_ITEMS_ERROR_V2:
            return {
                ...state,
                errors: action.error,
                send: false,
                sendServiceItemsPOSTV2: false
            };
        default:
            return state;
    }
};
