import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Avatar, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { EditOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { serialize } from 'object-to-formdata';

import { getCurrentDate, i18, translate } from '../../utilities';
import { partnerProfileActions, partnerProfileConstants } from '../../api';
import { Message } from '../';

class ProfileImage extends React.Component {
    constructor (props) {
        super(props);

        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.update = this.update.bind(this);
    }

    validate (type) {
        if (type && type.includes('image/')) {
            return true;
        } else {
            Message({
                type: 'warning',
                message: translate(i18.Components.Cropper.FileNotValid)
            });

            return false;
        }
    }

    submit (values) {
        const data = serialize(values);

        this.props.dispatch(partnerProfileActions.postProfileImage(this.props.profileId, data)).then(res => {
            if (res && res.type === partnerProfileConstants.POST_PARTNER_PROFILE_IMAGE_SUCCESS) {
                this.update();
            }
        });
    }

    update () {
        this.props.dispatch(partnerProfileActions.getProfile(this.props.profileId, true));
    }

    render () {
        const loading = this.props.sendPartnerProfileImage || this.props.sendPartnerProfileGET;

        return (
            <div className='profile_image'>
                <ImgCrop
                    grid
                    rotate
                    modalWidth={800}
                    modalTitle={translate(i18.Components.Cropper.ProfileImage)}
                    beforeCrop={(file) => this.validate(file.type)}
                >
                    <Upload
                        accept='image/*'
                        showUploadList={false}
                        customRequest={({ file, onSuccess }) => {
                            setTimeout(() => {
                                onSuccess('ok');
                                this.submit({
                                    validFrom: getCurrentDate(),
                                    validTo: getCurrentDate(100000),
                                    file: file
                                });
                            });
                        }}
                    >
                        <Avatar
                            size={128}
                            src={loading ? undefined : this.props.partnerProfile.picturePath || undefined}
                            icon={loading ? (
                                <LoadingOutlined
                                    spin
                                />
                            ) : <UserOutlined />}
                        />
                        <div className='profile_image_edit'>
                            <EditOutlined />
                        </div>
                    </Upload>
                </ImgCrop>
            </div>
        );
    }
}

ProfileImage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileId: PropTypes.number.isRequired,
    partnerProfile: PropTypes.object,
    sendPartnerProfileGET: PropTypes.bool,
    sendPartnerProfileImage: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        partnerProfile,
        sendPartnerProfileGET,
        sendPartnerProfileImage
    } = state.partnerProfileReducers;

    return {
        partnerProfile,
        sendPartnerProfileGET,
        sendPartnerProfileImage
    };
}

const connectedProfileImage = connect(mapStateToProps)(ProfileImage);
export { connectedProfileImage as ProfileImage };
