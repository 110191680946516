import { requestApi, apiPaths, handleResponse, methods } from '../../config';

export const internalClientService = {
    postInternalClient,
    getInternalClient,
    putInternalClient
};
function postInternalClient (data) {
    return requestApi(apiPaths.internalClients.internalClient, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function putInternalClient (id, data) {
    return requestApi(`${apiPaths.internalClients.internalClient}/${id}`, {
        method: methods.put,
        data: data
    }).then(handleResponse);
}

function getInternalClient (id) {
    return requestApi(`${apiPaths.internalClients.internalClient}/${id}`, {
        method: methods.get
    }).then(handleResponse);
}
