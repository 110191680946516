import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { i18, i18cl, statusesService } from '../../../../../utilities';
import { Classificator, CommaArray, Number, Translate } from '../../../../../components';

export const PartnerServicesList = (props) => {
    props.data.forEach(item => { item.radius = item.areas[0]?.radius; });
    const columns = [
        {
            title: <Translate value={i18.PartnerServicesList.Labels.Status} />,
            render: (item) => statusesService(item.profileServiceStatus),
            width: 1
        }, {
            title: <Translate value={i18.PartnerServicesList.Labels.Default} />,
            render: (item) => item.isDefault && <Translate value={i18.PartnerServicesList.Labels.IsDefault} />
        }, {
            title: <Translate value={i18.PartnerServicesList.Labels.ServiceCode} />,
            render: (item) => item.serviceCode
        }, {
            title: <Translate value={i18.PartnerServicesList.Labels.Name} />,
            render: (item) => <Classificator value={i18cl.SERVICES_CATALOG + item.serviceCode} />
        }, {
            title: <Translate value={i18.PartnerServicesList.Labels.Sertificates} />,
            render: (item) => <span>{item.serviceAssignedDocumentCount} / {item.partnerSubmittedDocumentCount}</span>
        }, {
            title: <Translate value={i18.PartnerServicesList.Labels.Areas} />,
            render: (item) => (
                <CommaArray
                    data={item.areas}
                    value='area'
                />
            )
        }, {
            title: <Translate value={i18.PartnerServicesList.Labels.AreasRadius} />,
            dataIndex: 'radius'
        }, {
            title: <Translate value={`${i18.PartnerServicesList.Labels.HourlyRate}${props.currency}`} />,
            render: (item) => <Number value={item.hourlyRate} />,
            className: 'text-right'
        }
    ];

    return (
        <Table
            rowKey='profileServiceId'
            columns={columns}
            size='large'
            rowClassName='cursor_pointer'
            dataSource={props.data}
            pagination={false}
            onRow={item => {
                return {
                    onClick: () => props.click(item.profileServiceId)
                };
            }}
            loading={props.loading}
        />
    );
};

PartnerServicesList.propTypes = {
    data: PropTypes.array.isRequired,
    click: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    currency: PropTypes.string
};
