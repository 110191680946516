export const i18frontend = {
    API: {
        Messages: {
            PartnerAccountUpdated: 'API.Messages.PartnerAccountUpdated',
            PartnerDocumentDeleted: 'API.Messages.PartnerDocumentDeleted',
            PartnerDocumentUploaded: 'API.Messages.PartnerDocumentUploaded',
            PartnerProfileUpdated: 'API.Messages.PartnerProfileUpdated',
            PartnerResendEmailConfirmationSuccess: 'API.Messages.PartnerResendEmailConfirmationSuccess',
            PartnerServiceApproveRequested: 'API.Messages.PartnerServiceApproveRequested',
            PartnerServiceDocumentDeleted: 'API.Messages.PartnerServiceDocumentDeleted',
            PartnerServiceDocumentUploaded: 'API.Messages.PartnerServiceDocumentUploaded',
            PartnerServiceFormed: 'API.Messages.PartnerServiceFormed',
            PartnerServiceUpdated: 'API.Messages.PartnerServiceUpdated',
            UserPasswordChanged: 'API.Messages.UserPasswordChanged'
        }
    },
    AvatarImage: {
        Buttons: {
            Edit: 'AvatarImage.Buttons.Edit',
            Preview: 'AvatarImage.Buttons.Preview'
        },
        Errors: {
            BadFileType: 'AvatarImage.Errors.BadFileType'
        },
        Title: {
            Avatar: 'AvatarImage.Title.Avatar'
        }
    },
    Components: {
        Confirm: {
            Cancel: 'Components.Confirm.Cancel',
            Confirm: 'Components.Confirm.Confirm',
            Service: 'Components.Confirm.Service',
            Document: 'Components.Confirm.Document'
        },
        ContentLoader: {
            Loading: 'Components.ContentLoader.Loading',
            LoadingDescription: 'Components.ContentLoader.LoadingDescription'
        },
        ContentNoData: {
            NoData: 'Components.ContentNoData.NoData'
        },
        Dragger: {
            Title: 'Components.Dragger.Title',
            Description: 'Components.Dragger.Description'
        },
        ProfilePicker: {
            Logout: 'Components.ProfilePicker.Logout'
        }
    },
    Dashboard: {
        Labels: {
            StepLabel: 'Dashboard.Labels.StepLabel',
            FirstStepTitle: 'Dashboard.Labels.FirstStepTitle',
            FirstStepDescription: 'Dashboard.Labels.FirstStepDescription',
            SecondStepTitle: 'Dashboard.Labels.SecondStepTitle',
            SecondStepDescription: 'Dashboard.Labels.SecondStepDescription',
            ThirdStepTitle: 'Dashboard.Labels.ThirdStepTitle'
        },
        Buttons: {
            FirstStep: 'Dashboard.Labels.FirstStep',
            SecondStep: 'Dashboard.Labels.SecondStep'
        }
    },
    Forms: {
        Errors: {
            PhoneNumberLength: 'Forms.Errors.PhoneNumberLength',
            PhoneNumberMaxLength: 'Forms.Errors.PhoneNumberMaxLength',
            Required: 'Forms.Errors.Required',
            RequiredAvatar: 'Forms.Errors.RequiredAvatar',
            RequiredAtLeastOne: 'Forms.Errors.RequiredAtLeastOne',
            RequiredEmail: 'Forms.Errors.RequiredEmail',
            RequiredEmailType: 'Forms.Errors.RequiredEmailType',
            RequiredIBAN: 'Forms.Errors.RequiredIBAN',
            RequiredPhoneNumber: 'Forms.Errors.RequiredPhoneNumber',
            RequiredSamePassword: 'Forms.Errors.RequiredSamePassword',
            RequiredStrongPassword: 'Forms.Errors.RequiredStrongPassword',
            InvalidPersonalCode: 'Forms.Errors.InvalidPersonalCode',
            CyrillicError: 'Forms.Errors.CyrillicError'
        }
    },
    Header: {
        Tooltips: {
            Language: 'Header.Tooltips.Language',
            Logout: 'Header.Tooltips.Logout'
        }
    },
    AdministrationFeeInvoice: {
        Labels: {
            InvoiceNumber: 'AdministrationFeeInvoice.Labels.InvoiceNumber',
            GenerationDate: 'AdministrationFeeInvoice.Labels.GenerationDate',
            Description: 'AdministrationFeeInvoice.Labels.Description',
            InvoiceTotalSum: 'AdministrationFeeInvoice.Labels.InvoiceTotalSum',
            PaymentTerm: 'AdministrationFeeInvoice.Labels.PaymentTerm',
            Seller: 'AdministrationFeeInvoice.Labels.Seller',
            SellerAddress: 'AdministrationFeeInvoice.Labels.SellerAddress',
            CompanyCode: 'AdministrationFeeInvoice.Labels.CompanyCode',
            VatCode: 'AdministrationFeeInvoice.Labels.VatCode',
            BankName: 'AdministrationFeeInvoice.Labels.BankName',
            BankAccount: 'AdministrationFeeInvoice.Labels.BankAccount',
            CompanyBuyer: 'AdministrationFeeInvoice.Labels.CompanyBuyer',
            PrivateBuyer: 'AdministrationFeeInvoice.Labels.PrivateBuyer',
            BuyerAddress: 'AdministrationFeeInvoice.Labels.BuyerAddress',
            IndividualActivityCode: 'AdministrationFeeInvoice.Labels.IndividualActivityCode',
            Email: 'AdministrationFeeInvoice.Labels.Email',
            PhoneNumber: 'AdministrationFeeInvoice.Labels.PhoneNumber'
        }
    },
    ClientInvoice: {
        Labels: {
            InvoiceNumber: 'ClientInvoice.Labels.InvoiceNumber',
            IssueDate: 'ClientInvoice.Labels.IssueDate',
            Description: 'ClientInvoice.Labels.Description',
            TotalSum: 'ClientInvoice.Labels.TotalSum',
            PaymentTerm: 'ClientInvoice.Labels.PaymentTerm',
            Seller: 'ClientInvoice.Labels.Seller',
            SellerAddress: 'ClientInvoice.Labels.SellerAddress',
            SellerCode: 'ClientInvoice.Labels.SellerCode',
            SellerVATCode: 'ClientInvoice.Labels.SellerVATCode',
            SellerEmail: 'ClientInvoice.Labels.SellerEmail',
            SellerPhone: 'ClientInvoice.Labels.SellerPhone',
            SellerBankName: 'ClientInvoice.Labels.SellerBankName',
            SellerBankAccount: 'ClientInvoice.Labels.SellerBankAccount',
            Buyer: 'ClientInvoice.Labels.Buyer',
            BuyerAddress: 'ClientInvoice.Labels.BuyerAddress',
            BuyerCompanyCode: 'ClientInvoice.Labels.BuyerCompanyCode',
            BuyerVatCode: 'ClientInvoice.Labels.BuyerVatCode',
            ServiceReceiver: 'ClientInvoice.Labels.ServiceReceiver'
        }
    },
    CreditInvoice: {
        Labels: {
            PrimaryInvoice: 'CreditInvoice.Labels.PrimaryInvoice',
            PrimaryIssueDate: 'CreditInvoice.Labels.PrimaryIssueDate'
        }
    },
    Invoice: {
        Titles: {
            Invoice: 'Invoice.Titles.Invoice'
        }
    },
    InvoiceSummary: {
        Labels: {
            TotalPriceWithoutVAT: 'InvoiceSummary.Labels.TotalPriceWithoutVAT',
            TotalPricePure: 'InvoiceSummary.Labels.TotalPricePure',
            TotalPriceGeneral: 'InvoiceSummary.Labels.TotalPriceGeneral',
            TotalReverseVAT: 'InvoiceSummary.Labels.TotalReverseVAT',
            ForService: 'InvoiceSummary.Labels.ForService',
            ForMaterials: 'InvoiceSummary.Labels.ForMaterials',
            TotalVAT: 'InvoiceSummary.Labels.TotalVAT',
            TotalPriceWithVAT: 'InvoiceSummary.Labels.TotalPriceWithVAT',
            PaidSum: 'InvoiceSummary.Labels.PaidSum'
        }
    },
    InvoiceItems: {
        Labels: {
            TotalPriceWithoutVAT: 'InvoiceItems.Labels.TotalPriceWithoutVAT',
            UnitPriceWithoutVAT: 'InvoiceItems.Labels.UnitPriceWithoutVAT',
            Unit: 'InvoiceItems.Labels.Unit',
            Quantity: 'InvoiceItems.Labels.Quantity',
            Service: 'InvoiceItems.Labels.Service',
            Sequence: 'InvoiceItems.Labels.Sequence'
        }
    },
    Invoices: {
        Menu: {
            Mine: 'Invoices.Menu.Mine',
            Client: 'Invoices.Menu.Client',
            Credit: 'Invoices.Menu.Credit'
        },
        Titles: {
            Invoices: 'Invoices.Titles.Invoices'
        },
        Tooltips: {
            Cancel: 'Invoices.Tooltips.Cancel',
            Filter: 'Invoices.Tooltips.Filter'
        }
    },
    InvoicesList: {
        Buttons: {
            Preview: 'InvoicesList.Buttons.Preview'
        },
        Labels: {
            Status: 'InvoicesList.Labels.Status',
            Service: 'InvoicesList.Labels.Service',
            Client: 'InvoicesList.Labels.Client',
            InvoiceNumber: 'InvoicesList.Labels.InvoiceNumber',
            InvoiceDate: 'InvoicesList.Labels.InvoiceDate',
            TotalPrice: 'InvoicesList.Labels.TotalPrice'
        }
    },
    InvoicesFilter: {
        Labels: {
            Client: 'InvoicesFilter.Labels.Client',
            InvoiceNumber: 'InvoicesFilter.Labels.InvoiceNumber',
            InvoiceDate: 'InvoicesFilter.Labels.InvoiceDate',
            PrimaryInvoiceNumber: 'InvoicesFilter.Labels.PrimaryInvoiceNumber'
        },
        Buttons: {
            Clear: 'InvoicesFilter.Buttons.Clear',
            Submit: 'InvoicesFilter.Buttons.Submit'
        }
    },
    Login: {
        Buttons: {
            AcceptLegalInfo: 'Login.Buttons.AcceptLegalInfo',
            AcceptTerms: 'Login.Buttons.AcceptTerms',
            BlockedClose: 'Login.Buttons.BlockedClose',
            LostPassword: 'Login.Buttons.LostPassword',
            Registration: 'Login.Buttons.Registration',
            Submit: 'Login.Buttons.Submit',
            SubmitTermsAndPrivacy: 'Login.Buttons.SubmitTermsAndPrivacy'
        },
        Labels: {
            AcceptEmailToBeUsedForMarketing: 'Login.Labels.AcceptEmailToBeUsedForMarketing',
            AcceptLegalInfo: 'Login.Labels.AcceptLegalInfo',
            AcceptTerms: 'Login.Labels.AcceptTerms',
            Email: 'Login.Labels.Email',
            Password: 'Login.Labels.Password',
            Registration: 'Login.Labels.Registration'
        },
        Titles: {
            Login: 'Login.Titles.Login',
            TermsAndPrivacyAgreement: 'Login.Titles.TermsAndPrivacyAgreement'
        }
    },
    Menu: {
        Client: {
            Dashboard: 'Menu.Client.Dashboard'
        },
        Partner: {
            Dashboard: 'Menu.Partner.Dashboard',
            Invoices: 'Menu.Partner.Invoices',
            Services: 'Menu.Partner.Services',
            Suppliers: 'Menu.Partner.Suppliers',
            User: 'Menu.Partner.User',
            Works: 'Menu.Partner.Works'
        }
    },
    Partner: {
        Menu: {
            Account: 'Partner.Menu.Account',
            Documents: 'Partner.Menu.Documents',
            Profile: 'Partner.Menu.Profile'
        },
        Titles: {
            Account: 'Partner.Titles.Account',
            Documents: 'Partner.Titles.Documents',
            Profile: 'Partner.Titles.Profile',
            ProfileInfo: 'Partner.Titles.ProfileInfo',
            Security: 'Partner.Titles.Security'
        },
        Tooltips: {
            Edit: 'Partner.Tooltips.Edit',
            EditCancel: 'Partner.Tooltips.EditCancel'
        }
    },
    PartnerAccount: {
        Buttons: {
            Cancel: 'PartnerAccount.Buttons.Cancel',
            Submit: 'PartnerAccount.Buttons.Submit'
        },
        Labels: {
            AccountingEmail: 'PartnerAccount.Labels.AccountingEmail',
            Address: 'PartnerAccount.Labels.Address',
            AddressAdditionalInfo: 'PartnerAccount.Labels.AddressAdditionalInfo',
            BankAccountNo: 'PartnerAccount.Labels.BankAccountNo',
            BankName: 'PartnerAccount.Labels.BankName',
            InsurancePolicyNumber: 'PartnerAccount.Labels.InsurancePolicyNumber',
            DetailChangeNotice: 'PartnerAccount.Labels.DetailChangeNotice',
            Code: 'PartnerAccount.Labels.Code',
            CompanyName: 'PartnerAccount.Labels.CompanyName',
            Email: 'PartnerAccount.Labels.Email',
            Phone: 'PartnerAccount.Labels.Phone',
            Position: 'PartnerAccount.Labels.Position',
            VAT: 'PartnerAccount.Labels.VAT',
            IsVatPayer: 'PartnerAccount.Labels.IsVatPayer',
            Type: 'PartnerAccount.Labels.Type',
            VatName: 'PartnerAccount.Labels.VatName',
            NipCode: 'PartnerAccount.Labels.NipCode',
            PersonalCodeType: 'PartnerAccount.Labels.PersonalCodeType',
            PersonalCode: 'PartnerAccount.Labels.PersonalCode'
        },
        LabelsDescriptions: {
            AccountingEmail: 'PartnerAccount.LabelsDescriptions.AccountingEmail',
            Address: 'PartnerAccount.LabelsDescriptions.Address',
            AddressAdditionalInfo: 'PartnerAccount.LabelsDescriptions.AddressAdditionalInfo',
            BankAccountNo: 'PartnerAccount.LabelsDescriptions.BankAccountNo',
            BankName: 'PartnerAccount.LabelsDescriptions.BankName',
            InsurancePolicyNumber: 'PartnerAccount.LabelsDescriptions.InsurancePolicyNumber',
            Code: 'PartnerAccount.LabelsDescriptions.Code',
            CompanyName: 'PartnerAccount.LabelsDescriptions.CompanyName',
            Email: 'PartnerAccount.LabelsDescriptions.Email',
            Phone: 'PartnerAccount.LabelsDescriptions.Phone',
            Position: 'PartnerAccount.LabelsDescriptions.Position',
            VAT: 'PartnerAccount.LabelsDescriptions.VAT',
            VatName: 'PartnerAccount.LabelsDescriptions.VatName',
            NipCode: 'PartnerAccount.LabelsDescriptions.NipCode',
            PersonalCodeType: 'PartnerAccount.LabelsDescriptions.PersonalCodeType',
            PersonalCode: 'PartnerAccount.LabelsDescriptions.PersonalCode'
        },
        SubTitles: {
            Account: 'PartnerProfile.SubTitles.Account'
        },
        Tooltips: {
            Edit: 'PartnerAccount.Tooltips.Edit',
            EditCancel: 'PartnerAccount.Tooltips.EditCancel'
        }
    },
    PartnerDocuments: {
        Buttons: {
            Cancel: 'PartnerDocuments.Buttons.Cancel',
            Delete: 'PartnerDocuments.Buttons.Delete',
            Download: 'PartnerDocuments.Buttons.Download',
            Submit: 'PartnerDocuments.Buttons.Submit'
        },
        Labels: {
            Actions: 'PartnerDocuments.Labels.Actions',
            File: 'PartnerDocuments.Labels.File',
            Status: 'PartnerDocuments.Labels.Status',
            ValidFrom: 'PartnerDocuments.Labels.ValidFrom',
            ValidTo: 'PartnerDocuments.Labels.ValidTo'
        },
        LabelsDescriptions: {
            File: 'PartnerDocuments.LabelsDescriptions.File',
            ValidFrom: 'PartnerDocuments.LabelsDescriptions.ValidFrom',
            ValidTo: 'PartnerDocuments.LabelsDescriptions.ValidTo'
        },
        Tooltips: {
            Add: 'PartnerDocuments.Tooltips.Add',
            Cancel: 'PartnerDocuments.Tooltips.Cancel',
            Delete: 'PartnerDocuments.Tooltips.Delete',
            Download: 'PartnerDocuments.Tooltips.Download'
        }
    },
    PartnerProfile: {
        Buttons: {
            Cancel: 'PartnerProfile.Buttons.Cancel',
            ChangePassword: 'PartnerProfile.Buttons.ChangePassword',
            DeleteAccount: 'PartnerProfile.Buttons.DeleteAccount',
            Submit: 'PartnerProfile.Buttons.Submit'
        },
        DeleteProfileConfirmation: {
            Title: 'PartnerProfile.DeleteProfileConfirmation.Title',
            ConfirmText: 'PartnerProfile.DeleteProfileConfirmation.ConfirmText',
            CancelText: 'PartnerProfile.DeleteProfileConfirmation.CancelText'
        },
        Labels: {
            About: 'PartnerProfile.Labels.About',
            AboutEmpty: 'PartnerProfile.Labels.AboutEmpty',
            AcceptedMarketingPurposes: 'PartnerProfile.Labels.AcceptedMarketingPurposes',
            Account: 'PartnerProfile.Labels.Account',
            Birthday: 'PartnerProfile.Labels.Birthday',
            ChangePassword: 'PartnerProfile.Labels.ChangePassword',
            Email: 'PartnerProfile.Labels.Email',
            MarketingAccepted: 'PartnerProfile.Labels.MarketingAccepted',
            MarketingRejected: 'PartnerProfile.Labels.MarketingRejected',
            FirstName: 'PartnerProfile.Labels.FirstName',
            Status: 'PartnerProfile.Labels.Status',
            LastName: 'PartnerProfile.Labels.LastName',
            Telephone: 'PartnerProfile.Labels.Telephone'
        },
        LabelsDescriptions: {
            About: 'PartnerProfile.LabelsDescriptions.About',
            Name: 'PartnerProfile.LabelsDescriptions.Name',
            Telephone: 'PartnerProfile.LabelsDescriptions.Telephone',
            Surname: 'PartnerProfile.LabelsDescriptions.Surname'
        },
        SubTitles: {
            Profile: 'PartnerProfile.SubTitles.Profile',
            Statuses: 'PartnerProfile.SubTitles.Statuses'
        }
    },
    PartnerWizard: {
        Buttons: {
            Approve: 'PartnerWizard.Buttons.Approve',
            Edit: 'PartnerWizard.Buttons.Edit',
            Navigate: 'PartnerWizard.Buttons.Navigate'
        },
        Labels: {
            ConfirmIdentityText: 'PartnerWizard.Labels.ConfirmIdentityText',
            ConfirmIdentityLinkText: 'PartnerWizard.Labels.ConfirmIdentityLinkText'
        },
        Steps: {
            Approve: 'PartnerWizard.Steps.Approve',
            DocumentsCourses: 'PartnerWizard.Steps.DocumentsCourses',
            Profile: 'PartnerWizard.Steps.Profile',
            Service: 'PartnerWizard.Steps.Service'
        },
        Titles: {
            ConfirmIdentity: 'PartnerWizard.Titles.ConfirmIdentity',
            Documents: 'PartnerWizard.Titles.Documents',
            UntilWorkPrefix: 'PartnerWizard.Titles.UntilWorkPrefix',
            UntilWorkStepsLeft: 'PartnerWizard.Titles.UntilWorkStepsLeft',
            UntilWorkSuffix: 'PartnerWizard.Titles.UntilWorkSuffix'
        }
    },
    RegistrationPartner: {
        Buttons: {
            Back: 'RegistrationPartner.Buttons.Back',
            BackFirst: 'RegistrationPartner.Buttons.BackFirst',
            Email: 'RegistrationPartner.Buttons.Email',
            Login: 'RegistrationPartner.Buttons.Login',
            Register: 'RegistrationPartner.Buttons.Register',
            Registration: 'RegistrationPartner.Buttons.Registration'
        },
        Labels: {
            Login: 'RegistrationPartner.Labels.Login'
        },
        Steps: {
            Approve: 'RegistrationPartner.Steps.Approve',
            Profile: 'RegistrationPartner.Steps.Profile',
            Services: 'RegistrationPartner.Steps.Services'
        },
        Titles: {
            Registration: 'RegistrationPartner.Titles.Registration'
        }
    },
    RegistrationPartnerProfile: {
        Buttons: {
            AgreementsClose: 'RegistrationPartnerProfile.Buttons.AgreementsClose',
            AcceptLegalInfo: 'RegistrationPartnerProfile.Buttons.AcceptLegalInfo',
            AcceptTerms: 'RegistrationPartnerProfile.Buttons.AcceptTerms'
        },
        Labels: {
            AcceptLegalInfo: 'RegistrationPartnerProfile.Labels.AcceptLegalInfo',
            AcceptTerms: 'RegistrationPartnerProfile.Labels.AcceptTerms',
            AcceptPrivacyPolicy: 'RegistrationPartnerProfile.Labels.AcceptPrivacyPolicy',
            Birthday: 'RegistrationPartnerProfile.Labels.Birthday',
            Company: 'RegistrationPartnerProfile.Labels.Company',
            Email: 'RegistrationPartnerProfile.Labels.Email',
            Name: 'RegistrationPartnerProfile.Labels.Name',
            Password: 'RegistrationPartnerProfile.Labels.Password',
            PasswordRepeat: 'RegistrationPartnerProfile.Labels.PasswordRepeat',
            Phone: 'RegistrationPartnerProfile.Labels.Phone',
            Surname: 'RegistrationPartnerProfile.Labels.Surname',
            Type: 'RegistrationPartnerProfile.Labels.Type'
        },
        LabelsDescriptions: {
            Company: 'RegistrationPartnerProfile.LabelsDescriptions.Company',
            Email: 'RegistrationPartnerProfile.LabelsDescriptions.Email',
            Name: 'RegistrationPartnerProfile.LabelsDescriptions.Name',
            Password: 'RegistrationPartnerProfile.LabelsDescriptions.Password',
            PasswordRepeat: 'RegistrationPartnerProfile.LabelsDescriptions.PasswordRepeat',
            Phone: 'RegistrationPartnerProfile.LabelsDescriptions.Phone',
            Surname: 'RegistrationPartnerProfile.LabelsDescriptions.Surname'
        }
    },
    RegistrationPartnerSuccess: {
        Buttons: {
            Login: 'RegistrationPartnerSuccess.Buttons.Login',
            Resend: 'RegistrationPartnerSuccess.Buttons.Resend'
        },
        SubTitles: {
            Success: 'RegistrationPartnerSuccess.SubTitles.Success'
        },
        Titles: {
            Success: 'RegistrationPartnerSuccess.Titles.Success'
        }
    },
    Reset: {
        Buttons: {
            Login: 'Reset.Buttons.Login',
            Submit: 'Reset.Buttons.Submit'
        },
        Labels: {
            Email: 'Reset.Labels.Email',
            Submit: 'Reset.Labels.Submit'
        },
        Titles: {
            Reset: 'Reset.Titles.Reset'
        }
    },
    ResetSuccess: {
        Buttons: {
            Login: 'ResetSuccess.Buttons.Login'
        },
        Titles: {
            Success: 'ResetSuccess.Titles.Success',
            PasswordChanged: 'ResetSuccess.Titles.PasswordChanged'
        }
    },
    Service: {
        Titles: {
            Documents: 'Service.Titles.Documents',
            Profile: 'Service.Titles.Profile'
        },
        Menu: {
            Courses: 'Service.Menu.Courses',
            Documents: 'Service.Menu.Documents',
            Profile: 'Service.Menu.Profile'
        }
    },
    ServiceDocuments: {
        Buttons: {
            Cancel: 'ServiceDocuments.Buttons.Cancel',
            Delete: 'ServiceDocuments.Buttons.Delete',
            Download: 'ServiceDocuments.Buttons.Download',
            Submit: 'ServiceDocuments.Buttons.Submit',
            Upload: 'ServiceDocuments.Buttons.Upload'
        },
        Labels: {
            Actions: 'ServiceDocuments.Labels.Actions',
            File: 'ServiceDocuments.Labels.File',
            Status: 'ServiceDocuments.Labels.Status',
            ValidFrom: 'ServiceDocuments.Labels.ValidFrom',
            ValidTo: 'ServiceDocuments.Labels.ValidTo'
        },
        Tooltips: {
            Add: 'ServiceDocuments.Tooltips.Add',
            Cancel: 'ServiceDocuments.Tooltips.Cancel',
            Delete: 'ServiceDocuments.Tooltips.Delete',
            Download: 'ServiceDocuments.Tooltips.Download'
        }
    },
    ServiceDocumentsForm: {
        Buttons: {
            Cancel: 'ServiceDocumentsForm.Buttons.Cancel',
            Submit: 'ServiceDocumentsForm.Buttons.Submit'
        },
        Labels: {
            Actions: 'ServiceDocumentsForm.Labels.Actions',
            File: 'ServiceDocumentsForm.Labels.File',
            Status: 'ServiceDocumentsForm.Labels.Status',
            ValidFrom: 'ServiceDocumentsForm.Labels.ValidFrom',
            ValidTo: 'ServiceDocumentsForm.Labels.ValidTo'
        },
        LabelsDescriptions: {
            File: 'ServiceDocumentsForm.LabelsDescriptions.File',
            ValidFrom: 'ServiceDocumentsForm.LabelsDescriptions.ValidFrom',
            ValidTo: 'ServiceDocumentsForm.LabelsDescriptions.ValidTo'
        }
    },
    ServiceForm: {
        Buttons: {
            Cancel: 'ServiceForm.Buttons.Cancel',
            Submit: 'ServiceForm.Buttons.Submit'
        },
        Labels: {
            Cities: 'ServiceForm.Labels.Cities',
            AreasRadius: 'ServiceForm.Labels.AreasRadius',
            HourlyRateEUR: 'ServiceForm.Labels.HourlyRateEUR',
            HourlyRateRUB: 'ServiceForm.Labels.HourlyRateRUB',
            HourlyRatePLN: 'ServiceForm.Labels.HourlyRatePLN',
            Service: 'ServiceForm.Labels.Service'
        },
        LabelsDescriptions: {
            Cities: 'ServiceForm.LabelsDescriptions.Cities',
            AreasRadius: 'ServiceForm.LabelsDescriptions.AreasRadius',
            HourlyRateEUR: 'ServiceForm.LabelsDescriptions.HourlyRateEUR',
            HourlyRateRUB: 'ServiceForm.LabelsDescriptions.HourlyRateRUB',
            HourlyRatePLN: 'ServiceForm.LabelsDescriptions.HourlyRatePLN',
            Service: 'ServiceForm.LabelsDescriptions.Service'
        }
    },
    ServiceProfile: {
        Buttons: {
            Approve: 'ServiceProfile.Buttons.Approve',
            ApproveValid: 'ServiceProfile.Buttons.ApproveValid',
            ApproveWaiting: 'ServiceProfile.Buttons.ApproveWaiting'
        },
        Labels: {
            HourlyRateEUR: 'ServiceProfile.Labels.HourlyRateEUR',
            HourlyRateRUB: 'ServiceProfile.Labels.HourlyRateRUB',
            HourlyRatePLN: 'ServiceProfile.Labels.HourlyRatePLN',
            Rate: 'ServiceProfile.Labels.Rate',
            Status: 'ServiceProfile.Labels.Status'
        },
        SubTitles: {
            Information: 'ServiceProfile.SubTitles.Information',
            Statuses: 'ServiceProfile.SubTitles.Statuses'
        },
        Tooltips: {
            Delete: 'ServiceProfile.Tooltips.Delete',
            Edit: 'ServiceProfile.Tooltips.Edit'
        }
    },
    ServiceProfileForm: {
        Buttons: {
            Cancel: 'ServiceProfileForm.Buttons.Cancel',
            Submit: 'ServiceProfileForm.Buttons.Submit'
        },
        Labels: {
            Areas: 'ServiceProfileForm.Labels.Areas',
            AreasRadius: 'ServiceProfileForm.Labels.AreasRadius',
            HourlyRateEUR: 'ServiceProfileForm.Labels.HourlyRateEUR',
            HourlyRateRUB: 'ServiceProfileForm.Labels.HourlyRateRUB',
            HourlyRatePLN: 'ServiceProfileForm.Labels.HourlyRatePLN'
        },
        LabelsDescriptions: {
            Areas: 'ServiceProfileForm.LabelsDescriptions.Areas',
            AreasRadius: 'ServiceProfileForm.LabelsDescriptions.AreasRadius',
            HourlyRateEUR: 'ServiceProfileForm.LabelsDescriptions.HourlyRateEUR',
            HourlyRateRUB: 'ServiceProfileForm.LabelsDescriptions.HourlyRateRUB',
            HourlyRatePLN: 'ServiceProfileForm.LabelsDescriptions.HourlyRatePLN'
        }
    },
    Services: {
        Titles: {
            Services: 'Services.Titles.Services'
        },
        Tooltips: {
            Add: 'Services.Tooltips.Add',
            Cancel: 'Services.Tooltips.Cancel'
        }
    },
    ServicesList: {
        Buttons: {
            Preview: 'ServicesList.Buttons.Preview'
        },
        Labels: {
            CitiesRadius: 'ServicesList.Labels.CitiesRadius',
            HourlyRateEUR: 'ServicesList.Labels.HourlyRateEUR',
            HourlyRateRUB: 'ServicesList.Labels.HourlyRateRUB',
            HourlyRatePLN: 'ServicesList.Labels.HourlyRatePLN',
            Rate: 'ServicesList.Labels.Rate',
            Rating: 'ServicesList.Labels.Rating',
            Status: 'ServicesList.Labels.Status'
        }
    },
    Validate: {
        Buttons: {
            Login: 'Validate.Buttons.Login'
        },
        Titles: {
            Error: 'Validate.Buttons.Error'
        }
    },
    UserPasswordForm: {
        Buttons: {
            Cancel: 'UserPasswordForm.Buttons.Cancel',
            Change: 'UserPasswordForm.Buttons.Change',
            Create: 'UserPasswordForm.Buttons.Create',
            Submit: 'UserPasswordForm.Buttons.Submit'
        },
        Labels: {
            New: 'UserPasswordForm.Labels.New',
            Old: 'UserPasswordForm.Labels.Old',
            Repeat: 'UserPasswordForm.Labels.Repeat'
        },
        Titles: {
            Create: 'UserPasswordForm.Titles.Create'
        },
        LabelsDescriptions: {
            New: 'UserPasswordForm.LabelsDescriptions.New',
            Old: 'UserPasswordForm.LabelsDescriptions.Old',
            Repeat: 'UserPasswordForm.LabelsDescriptions.Repeat'
        }
    },
    Work: {
        Buttons: {
            Download: 'Work.Buttons.Download'
        },
        Labels: {
            Address: 'Work.Labels.Address',
            Client: 'Work.Labels.Client',
            Contacts: 'Work.Labels.Contacts',
            Code: 'Work.Labels.Code',
            Information: 'Work.Labels.Information',
            PerformUntil: 'Work.Labels.PerformUntil',
            PlannedStartDate: 'Work.Labels.PlannedStartDate',
            Type: 'Work.Labels.Type'
        },
        Titles: {
            Work: 'Work.Titles.Work'
        }
    },
    WorkHistory: {
        Labels: {
            Date: 'WorkHistory.Labels.Date',
            Status: 'WorkHistory.Labels.Status'
        },
        Titles: {
            History: 'WorkHistory.Titles.History'
        }
    },
    WorkInvoice: {
        Labels: {
            Actions: 'WorkInvoice.Labels.Actions',
            Status: 'WorkInvoice.Labels.Status',
            InvoiceType: 'WorkInvoice.Labels.InvoiceType',
            InvoiceNumber: 'WorkInvoice.Labels.InvoiceNumber',
            InvoiceDate: 'WorkInvoice.Labels.InvoiceDate',
            TotalPrice: 'WorkInvoice.Labels.TotalPrice'
        },
        Titles: {
            Invoices: 'WorkInvoice.Titles.Invoices'
        }
    },
    WorkResult: {
        Labels: {
            Comment: 'WorkResult.Labels.Comment',
            Duration: 'WorkResult.Labels.Duration',
            Earnings: 'WorkResult.Labels.Earnings',
            EarningsNet: 'WorkResult.Labels.EarningsNet',
            EarningsVat: 'WorkResult.Labels.EarningsVat',
            EarningsVatPercentage: 'WorkResult.Labels.EarningsVatPercentage',
            FactualDuration: 'WorkResult.Labels.FactualDuration',
            FactualHours: 'WorkResult.Labels.FactualHours',
            FactualMinutes: 'WorkResult.Labels.FactualMinutes',
            Fee: 'WorkResult.Labels.Fee',
            FeeNet: 'WorkResult.Labels.FeeNet',
            FeeVat: 'WorkResult.Labels.FeeVat',
            FeeVatPercentage: 'WorkResult.Labels.FeeVatPercentage',
            Income: 'WorkResult.Labels.Income',
            MaterialExpenses: 'WorkResult.Labels.MaterialExpenses',
            Partner: 'WorkResult.Labels.Partner',
            PerformDate: 'WorkResult.Labels.PerformDate',
            Status: 'WorkResult.Labels.Status'
        },
        Titles: {
            Result: 'WorkResult.Titles.Result'
        }
    },
    Works: {
        Titles: {
            Works: 'Works.Titles.Works'
        },
        Tooltips: {
            Cancel: 'Works.Tooltips.Cancel',
            Filter: 'Works.Tooltips.Filter'
        }
    },
    WorksFilter: {
        Buttons: {
            Clear: 'WorksFilter.Buttons.Clear',
            Filter: 'WorksFilter.Buttons.Filter'
        },
        Labels: {
            Address: 'WorksFilter.Labels.Address',
            Client: 'WorksFilter.Labels.Client',
            Code: 'WorksFilter.Labels.Code',
            Description: 'WorksFilter.Labels.Description',
            Emergency: 'WorksFilter.Labels.Emergency',
            EmergencyCheckbox: 'WorksFilter.Labels.EmergencyCheckbox',
            PerformDate: 'WorksFilter.Labels.PerformDate',
            Service: 'WorksFilter.Labels.Service',
            Status: 'WorksFilter.Labels.Status'
        }
    },
    WorksList: {
        Buttons: {
            Preview: 'WorksList.Buttons.Preview'
        },
        Labels: {
            Code: 'WorksList.Labels.Code',
            PerformUntil: 'WorksList.Labels.PerformUntil',
            Price: 'WorksList.Labels.Price',
            Status: 'WorksList.Labels.Status'
        }
    }
};
