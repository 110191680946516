import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';

import { formatDate, getCurrentDate, i18, i18cl, statusesService } from '../../../utilities';
import { Classificator, Translate } from '../../../components';
import { ServiceSettingsEditForm, ServiceSettingsForm } from './';

class ServiceSettingsList extends React.Component {
    render() {
        const columns = [
            {
                title: <Translate value={i18.ServiceSettingsList.Labels.Name} />,
                render: item => <Classificator value={i18cl.SETTINGS_SERVICE + item.classificatorCode} />
            },
            {
                title: <Translate value={i18.ServiceSettingsList.Labels.Count} />,
                render: item => item.data.length,
                className: 'text-right'
            }
        ];
        const columnsInner = [
            {
                title: <Translate value={i18.ServiceSettingsList.Labels.Status} />,
                render: item => statusesService(item.status)
            },
            {
                title: <Translate value={i18.ServiceSettingsList.Labels.Value} />,
                render: item =>
                    item.valueClassificatorCode ? (
                        <Classificator value={item.valueClassificatorCode + '.' + item.value} />
                    ) : (
                        item.value
                    )
            },
            {
                title: <Translate value={i18.ServiceSettingsList.Labels.ValidFrom} />,
                render: item => formatDate(item.validFrom)
            },
            {
                title: <Translate value={i18.ServiceSettingsList.Labels.ValidTo} />,
                render: item => formatDate(item.validTo)
            },
            {
                title: <Translate value={i18.ServiceSettingsList.Labels.Actions} />,
                render: item =>
                    item.validTo > getCurrentDate() || !item.validTo ? (
                        <ServiceSettingsEditForm
                            serviceCatalogId={this.props.serviceCatalogId}
                            serviceSettingId={item.serviceSettingId}
                        />
                    ) : null,
                className: 'text-right'
            }
        ];

        return (
            <>
                <div className="content_subtitle_wrapper">
                    <div className="content_subtitle">
                        <Translate value={i18.ServiceSettingsList.Titles.Settings} />
                    </div>
                    <div>
                        <ServiceSettingsForm serviceCatalogId={this.props.serviceCatalogId} />
                    </div>
                </div>
                <div className="content_box">
                    <Table
                        rowKey="classificatorCode"
                        columns={columns}
                        dataSource={this.props.serviceSettings}
                        pagination={false}
                        loading={this.props.sendServiceSettingsGET}
                        expandable={{
                            expandedRowRender: item => (
                                <Table
                                    rowKey="serviceSettingId"
                                    columns={columnsInner}
                                    size="large"
                                    dataSource={item.data}
                                    pagination={false}
                                />
                            )
                        }}
                    />
                </div>
            </>
        );
    }
}

ServiceSettingsList.propTypes = {
    serviceCatalogId: PropTypes.string,
    serviceSettings: PropTypes.array,
    sendServiceSettingsGET: PropTypes.bool
};

function mapStateToProps(state) {
    const { serviceSettings, sendServiceSettingsGET } = state.serviceSettingsReducers;

    return {
        serviceSettings,
        sendServiceSettingsGET
    };
}

const connectedServiceSettingsList = connect(mapStateToProps)(ServiceSettingsList);
export { connectedServiceSettingsList as ServiceSettingsList };
