import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { i18, routerPaths } from '../../utilities';
import { history, internalClientActions, internalClientConstants } from '../../api';
import { ContentHeader, Translate } from '../../components';
import { InternalClientsCreateForm } from '.';

class InternalClientsCreateContainer extends React.Component {
    constructor (props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    submit (values) {
        this.props.dispatch(internalClientActions.postInternalClient(values)).then(res => {
            if (res && res.type === internalClientConstants.POST_INTERNAL_CLIENT_SUCCESS) {
                history.push(routerPaths.internalClients.index);
            }
        });
    }

    render () {
        return (
            <div>
                <ContentHeader
                    title={<Translate value={i18.InternalClientsCreate.Titles.InternalClientsCreate} />}
                    back={routerPaths.internalClients.index}
                    backState={this.props.history.location.state}
                />
                <div className='content_block'>
                    <InternalClientsCreateForm
                        cancel={() => history.push(routerPaths.internalClients.index, this.props.history.location.state)}
                        submit={this.submit}
                        submitting={this.props.sendInternalClientPOST}
                    />
                </div>
            </div>
        );
    }
}

InternalClientsCreateContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    sendInternalClientPOST: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        sendInternalClientPOST
    } = state.internalClientReducers;

    return {
        sendInternalClientPOST
    };
}

const connectedInternalClientsCreateContainer = connect(mapStateToProps)(InternalClientsCreateContainer);
export { connectedInternalClientsCreateContainer as InternalClientsCreateContainer };
