import { getDomain } from '../utilities';

export const getCountry = () => {
    return getDomain();
};

export const getCountryStartingCoordinates = () => {
    switch (getCountry()) {
    case countryCodes.LT:
        return { lat: 54.6871555, lng: 25.2796514 };
    case countryCodes.LV:
        return { lat: 56.9496487, lng: 24.1051865 };
    case countryCodes.EE:
        return { lat: 59.4369608, lng: 24.7535747 };
    case countryCodes.EN:
        return { lat: 51.5072178, lng: -0.1275862 };
    case countryCodes.PL:
        return { lat: 52.2296756, lng: 21.0122287 };
    case countryCodes.ES:
        return { lat: 40.4167754, lng: -3.7037902 };
    case countryCodes.RU:
        return { lat: 59.9310584, lng: 30.3609096 };
    }
};

export const countryCodes = {
    LT: 'LT',
    LV: 'LV',
    EE: 'EE',
    EN: 'EN',
    PL: 'PL',
    ES: 'ES',
    RU: 'RU'
};
