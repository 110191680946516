import { languageConstants } from './constants';

export const languageReducers = function (state = {
    countriesLanguages: [],
    allLanguages: []
}, action) {
    switch (action.type) {
    case languageConstants.GET_LANGUAGES_REQUEST:
        return {
            ...state,
            send: true,
            sendLanguages: true
        };
    case languageConstants.GET_LANGUAGES_SUCCESS:
        return {
            ...state,
            countriesLanguages: action.payload,
            send: false,
            sendLanguages: false
        };
    case languageConstants.GET_LANGUAGES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendLanguages: false
        };
    case languageConstants.GET_ALL_LANGUAGES_REQUEST:
        return {
            ...state,
            send: true,
            sendAllLanguages: true
        };
    case languageConstants.GET_ALL_LANGUAGES_SUCCESS:
        return {
            ...state,
            allLanguages: action.payload,
            send: false,
            sendAllLanguages: false
        };
    case languageConstants.GET_ALL_LANGUAGES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendAllLanguages: false
        };
    case languageConstants.POST_LANGUAGES_REQUEST:
        return {
            ...state,
            send: true,
            sendLanguagesPOST: true
        };
    case languageConstants.POST_LANGUAGES_SUCCESS:
        return {
            ...state,
            send: false,
            sendLanguagesPOST: false
        };
    case languageConstants.POST_LANGUAGES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendLanguagesPOST: false
        };
    default:
        return state;
    }
};
