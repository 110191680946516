import { systemConstants } from './constants';

export const systemActions = {
    setCountry,
    setLanguage
};

function setCountry (country) {
    return dispatch => {
        dispatch(setCountry(country));
    };

    function setCountry (country) {
        return {
            type: systemConstants.SET_SYSTEM_COUNTRY,
            payload: country
        };
    }
}

function setLanguage (language) {
    return dispatch => {
        dispatch(setLanguage(language));
    };

    function setLanguage (language) {
        return {
            type: systemConstants.SET_SYSTEM_LANGUAGE,
            payload: language
        };
    }
}
