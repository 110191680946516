import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Form } from 'react-final-form';
import { CustomModal, FormField, Translate, formFieldType } from '../../../components';
import { i18, required, translate } from '../../../utilities';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { jobActions } from '../../../api';

const fieldNames = {
    callDate: 'callDate',
    callComment: 'callComment',
};

const SetClientCalledButtonComponent = ({ dispatch, jobId, adminCallInfo }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        setInitialValues({
            [fieldNames.callDate]: adminCallInfo?.clientCalledAt,
            [fieldNames.callComment]: adminCallInfo?.comment,
        });
    }, [adminCallInfo]);
    
    const handleSubmitForm = async values => {
        try {
            setIsLoading(true);
            await dispatch(
                jobActions.postSetCalled(jobId, {
                    clientCalledAt: values[fieldNames.callDate],
                    callComment: values[fieldNames.callComment]
                })
            );

            dispatch(jobActions.getJob(jobId));
            CustomModal.close();
        } catch {
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenModal = () => {
        CustomModal.open({
            content: (
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                    render={({ handleSubmit }) => (
                        <>
                            <FormField
                                title={i18.Job.Labels.CallModalDate}
                                borderTitle
                                name={fieldNames.callDate}
                                component={formFieldType.date}
                                validate={required}
                                time
                                disabled={isLoading}
                            />
                            <FormField
                                name={fieldNames.callComment}
                                title={i18.Job.Labels.CallModalComment}
                                borderTitle
                                validate={required}
                                component={formFieldType.textarea}
                                maxLength={1000}
                                disabled={isLoading}
                            />
                            <Button.Group className="d-flex justify-content-end">
                                <Button onClick={CustomModal.close}>
                                    <Translate value={i18.Job.Buttons.CallModalCancel} />
                                </Button>
                                <Button
                                    className="ml-3"
                                    type="primary"
                                    htmlType="submit"
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    <Translate value={i18.Job.Buttons.CallModalSubmit} />
                                </Button>
                            </Button.Group>
                        </>
                    )}
                />
            ),
            hideOkButton: true,
            closable: true,
            title: translate(i18.Job.Labels.CallModalTitle)
        });
    };

    return (
        <Button className="mr-3 mb-3 rounder job-set-called-button border-0" onClick={handleOpenModal}>
            <Translate value={i18.Job.Buttons.SetCalled} />
        </Button>
    );
};

SetClientCalledButtonComponent.propTypes = {
    dispatch: PropTypes.func.isRequired,
    jobId: PropTypes.string.isRequired,
    adminCallInfo: PropTypes.object,
};

export const SetClientCalledButton = connect(null)(SetClientCalledButtonComponent);
