export const serviceGroupsConstants = {
    GET_SERVICE_GROUPS_REQUEST: 'GET_SERVICE_GROUPS_REQUEST',
    GET_SERVICE_GROUPS_SUCCESS: 'GET_SERVICE_GROUPS_SUCCESS',
    GET_SERVICE_GROUPS_ERROR: 'GET_SERVICE_GROUPS_ERROR',

    GET_SERVICE_GROUP_REQUEST: 'GET_SERVICE_GROUP_REQUEST',
    GET_SERVICE_GROUP_SUCCESS: 'GET_SERVICE_GROUP_SUCCESS',
    GET_SERVICE_GROUP_ERROR: 'GET_SERVICE_GROUP_ERROR',

    POST_SERVICE_GROUP_IMAGE_REQUEST: 'POST_SERVICE_GROUP_IMAGE_REQUEST',
    POST_SERVICE_GROUP_IMAGE_SUCCESS: 'POST_SERVICE_GROUP_IMAGE_SUCCESS',
    POST_SERVICE_GROUP_IMAGE_ERROR: 'POST_SERVICE_GROUP_IMAGE_ERROR',

    PUT_SERVICE_GROUP_DATA_REQUEST: 'PUT_SERVICE_GROUP_DATA_REQUEST',
    PUT_SERVICE_GROUP_DATA_SUCCESS: 'PUT_SERVICE_GROUP_DATA_SUCCESS',
    PUT_SERVICE_GROUP_DATA_ERROR: 'PUT_SERVICE_GROUP_DATA_ERROR'
};
