export const partnerProfileConstants = {
    POST_PARTNER_PROFILE_REQUEST: 'POST_PARTNER_PROFILE_REQUEST',
    POST_PARTNER_PROFILE_SUCCESS: 'POST_PARTNER_PROFILE_SUCCESS',
    POST_PARTNER_PROFILE_ERROR: 'POST_PARTNER_PROFILE_ERROR',

    GET_PARTNER_PROFILE_LOGS_REQUEST: 'GET_PARTNER_PROFILE_LOGS_REQUEST',
    GET_PARTNER_PROFILE_LOGS_SUCCESS: 'GET_PARTNER_PROFILE_LOGS_SUCCESS',
    GET_PARTNER_PROFILE_LOGS_ERROR: 'GET_PARTNER_PROFILE_LOGS_ERROR',

    PATCH_PARTNER_PROFILE_REQUEST: 'PATCH_PARTNER_PROFILE_REQUEST',
    PATCH_PARTNER_PROFILE_SUCCESS: 'PATCH_PARTNER_PROFILE_SUCCESS',
    PATCH_PARTNER_PROFILE_ERROR: 'PATCH_PARTNER_PROFILE_ERROR',

    GET_PARTNER_PROFILE_REQUEST: 'GET_PARTNER_PROFILE_REQUEST',
    GET_PARTNER_PROFILE_SUCCESS: 'GET_PARTNER_PROFILE_SUCCESS',
    GET_PARTNER_PROFILE_ERROR: 'GET_PARTNER_PROFILE_ERROR',

    POST_PARTNER_PROFILE_IMAGE_REQUEST: 'POST_PARTNER_PROFILE_IMAGE_REQUEST',
    POST_PARTNER_PROFILE_IMAGE_SUCCESS: 'POST_PARTNER_PROFILE_IMAGE_SUCCESS',
    POST_PARTNER_PROFILE_IMAGE_ERROR: 'POST_PARTNER_PROFILE_IMAGE_ERROR',

    GET_PARTNER_PROFILE_DOCUMENT_REQUEST: 'GET_PARTNER_PROFILE_DOCUMENT_REQUEST',
    GET_PARTNER_PROFILE_DOCUMENT_SUCCESS: 'GET_PARTNER_PROFILE_DOCUMENT_SUCCESS',
    GET_PARTNER_PROFILE_DOCUMENT_ERROR: 'GET_PARTNER_PROFILE_DOCUMENT_ERROR',

    GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_REQUEST: 'GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_REQUEST',
    GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_SUCCESS: 'GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_SUCCESS',
    GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_ERROR: 'GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_ERROR',

    POST_PARTNER_PROFILE_DOCUMENT_REQUEST: 'POST_PARTNER_PROFILE_DOCUMENT_REQUEST',
    POST_PARTNER_PROFILE_DOCUMENT_SUCCESS: 'POST_PARTNER_PROFILE_DOCUMENT_SUCCESS',
    POST_PARTNER_PROFILE_DOCUMENT_ERROR: 'POST_PARTNER_PROFILE_DOCUMENT_ERROR',

    DELETE_PARTNER_PROFILE_DOCUMENT_REQUEST: 'DELETE_PARTNER_PROFILE_DOCUMENT_REQUEST',
    DELETE_PARTNER_PROFILE_DOCUMENT_SUCCESS: 'DELETE_PARTNER_PROFILE_DOCUMENT_SUCCESS',
    DELETE_PARTNER_PROFILE_DOCUMENT_ERROR: 'DELETE_PARTNER_PROFILE_DOCUMENT_ERROR',

    GET_PARTNER_PROFILE_DOCUMENTS_REQUEST: 'GET_PARTNER_PROFILE_DOCUMENTS_REQUEST',
    GET_PARTNER_PROFILE_DOCUMENTS_SUCCESS: 'GET_PARTNER_PROFILE_DOCUMENTS_SUCCESS',
    GET_PARTNER_PROFILE_DOCUMENTS_ERROR: 'GET_PARTNER_PROFILE_DOCUMENTS_ERROR',

    GET_PARTNER_PROFILE_SERVICES_REQUEST: 'GET_PARTNER_PROFILE_SERVICES_REQUEST',
    GET_PARTNER_PROFILE_SERVICES_SUCCESS: 'GET_PARTNER_PROFILE_SERVICES_SUCCESS',
    GET_PARTNER_PROFILE_SERVICES_ERROR: 'GET_PARTNER_PROFILE_SERVICES_ERROR',

    GET_PARTNER_PROFILE_SERVICE_REQUEST: 'GET_PARTNER_PROFILE_SERVICE_REQUEST',
    GET_PARTNER_PROFILE_SERVICE_SUCCESS: 'GET_PARTNER_PROFILE_SERVICE_SUCCESS',
    GET_PARTNER_PROFILE_SERVICE_ERROR: 'GET_PARTNER_PROFILE_SERVICE_ERROR',

    POST_PARTNER_SERVICE_DOCUMENT_REQUEST: 'POST_PARTNER_SERVICE_DOCUMENT_REQUEST',
    POST_PARTNER_SERVICE_DOCUMENT_SUCCESS: 'POST_PARTNER_SERVICE_DOCUMENT_SUCCESS',
    POST_PARTNER_SERVICE_DOCUMENT_ERROR: 'POST_PARTNER_SERVICE_DOCUMENT_ERROR',

    GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_REQUEST: 'GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_REQUEST',
    GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_SUCCESS: 'GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_SUCCESS',
    GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_ERROR: 'GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_ERROR',

    PUT_PARTNER_PROFILE_SERVICE_REQUEST: 'PUT_PARTNER_PROFILE_SERVICE_REQUEST',
    PUT_PARTNER_PROFILE_SERVICE_SUCCESS: 'PUT_PARTNER_PROFILE_SERVICE_SUCCESS',
    PUT_PARTNER_PROFILE_SERVICE_ERROR: 'PUT_PARTNER_PROFILE_SERVICE_ERROR',

    DELETE_PARTNER_PROFILE_SERVICE_REQUEST: 'DELETE_PARTNER_PROFILE_SERVICE_REQUEST',
    DELETE_PARTNER_PROFILE_SERVICE_SUCCESS: 'DELETE_PARTNER_PROFILE_SERVICE_SUCCESS',
    DELETE_PARTNER_PROFILE_SERVICE_ERROR: 'DELETE_PARTNER_PROFILE_SERVICE_ERROR',

    PATCH_PARTNER_PROFILE_SERVICE_REQUEST: 'PATCH_PARTNER_PROFILE_SERVICE_REQUEST',
    PATCH_PARTNER_PROFILE_SERVICE_SUCCESS: 'PATCH_PARTNER_PROFILE_SERVICE_SUCCESS',
    PATCH_PARTNER_PROFILE_SERVICE_ERROR: 'PATCH_PARTNER_PROFILE_SERVICE_ERROR',

    POST_PARTNER_PROFILE_SERVICE_REQUEST: 'POST_PARTNER_PROFILE_SERVICE_REQUEST',
    POST_PARTNER_PROFILE_SERVICE_SUCCESS: 'POST_PARTNER_PROFILE_SERVICE_SUCCESS',
    POST_PARTNER_PROFILE_SERVICE_ERROR: 'POST_PARTNER_PROFILE_SERVICE_ERROR',

    GET_PARTNER_PROFILE_AVAILABLE_SERVICES_REQUEST: 'GET_PARTNER_PROFILE_AVAILABLE_SERVICES_REQUEST',
    GET_PARTNER_PROFILE_AVAILABLE_SERVICES_SUCCESS: 'GET_PARTNER_PROFILE_AVAILABLE_SERVICES_SUCCESS',
    GET_PARTNER_PROFILE_AVAILABLE_SERVICES_ERROR: 'GET_PARTNER_PROFILE_AVAILABLE_SERVICES_ERROR',

    POST_PARTNER_RESET_PASSWORD_REQUEST: 'POST_PARTNER_RESET_PASSWORD_REQUEST',
    POST_PARTNER_RESET_PASSWORD_SUCCESS: 'POST_PARTNER_RESET_PASSWORD_SUCCESS',
    POST_PARTNER_RESET_PASSWORD_ERROR: 'POST_PARTNER_RESET_PASSWORD_ERROR',

    POST_PARTNER_PROFILE_SYNCHRONIZATION_REQUEST: 'POST_PARTNER_PROFILE_SYNCHRONIZATION_REQUEST',
    POST_PARTNER_PROFILE_SYNCHRONIZATION_SUCCESS: 'POST_PARTNER_PROFILE_SYNCHRONIZATION_SUCCESS',
    POST_PARTNER_PROFILE_SYNCHRONIZATION_ERROR: 'POST_PARTNER_PROFILE_SYNCHRONIZATION_ERROR',

    GET_PARTNER_SERVICE_ITEMS_REQUEST: 'GET_PARTNER_SERVICE_ITEMS_REQUEST',
    GET_PARTNER_SERVICE_ITEMS_SUCCESS: 'GET_PARTNER_SERVICE_ITEMS_SUCCESS',
    GET_PARTNER_SERVICE_ITEMS_ERROR: 'GET_PARTNER_SERVICE_ITEMS_ERROR',

    PUT_PARTNER_SERVICE_ITEM_REQUEST: 'PUT_PARTNER_SERVICE_ITEM_REQUEST',
    PUT_PARTNER_SERVICE_ITEM_SUCCESS: 'PUT_PARTNER_SERVICE_ITEM_SUCCESS',
    PUT_PARTNER_SERVICE_ITEM_ERROR: 'PUT_PARTNER_SERVICE_ITEM_ERROR'
};
