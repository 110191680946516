export const fieldNames = {
    jobStatus: 'status',
    clientSegment: 'clientSegment',
    biddingType: 'biddingType',
    needApproval: 'bidConfirmationNeeded',
    vatType: 'workVatType',

    clientPrice: 'fullPrice',
    partnerReward: 'partnerReward',
    administrationFee: 'platformPrice',
    jobType: 'jobType',
    biddingDateRange: 'adDateRange',

    serviceId: 'serviceId',
    jobDescription: 'description',
    jobDeadline: 'deadline',

    latitude: 'latitude',
    longitude: 'longitude',
    address: 'objectAddress',
    additionalLocationInfo: 'additionalObjectInformation',

    managerPhone: 'workManagerTelephone',
    responsibleManager: 'responsibleManager',
    contactNumber: 'contactTelephone',
    internalClientName: 'clientName',
    internalClientId: 'clientId',

    partnerIds: 'partnerListBidding',

    jobDuration: 'jobDuration',
    jobDateRange: 'jobDateRange',
    partnerComment: 'partnerComments',
    partnerId: 'partnerId',
    partnerName: 'partnerName',

    files: 'attachments'
};
