import PropTypes from 'prop-types';
import { notification } from 'antd';

export const Notification = (props) => {
    notification.open({
        description: props.description,
        duration: props.duration,
        message: props.message,
        placement: 'bottomRight',
        type: props.type
    });
};

notification.config({
    getContainer: () => document.getElementById('root')
});

Notification.propTypes = {
    description: PropTypes.object,
    duration: PropTypes.number,
    message: PropTypes.object,
    type: PropTypes.oneOf(['success', 'error', 'info', 'warning']).isRequired
};
