import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from '../../../../components';
import { i18 } from '../../../../utilities';
import { PlusOutlined } from '@ant-design/icons';

const AddCompetitorButtonComponent = ({ serviceId, onOpenModal }) => (
    <Button className="mt-3" icon={<PlusOutlined />} type="link" onClick={onOpenModal} disabled={!serviceId}>
        <Translate value={i18.Job.Buttons.AddCompetitor} />
    </Button>
);

AddCompetitorButtonComponent.propTypes = {
    serviceId: PropTypes.number,
    onOpenModal: PropTypes.func.isRequired
};

export const AddCompetitorButton = connect(null)(AddCompetitorButtonComponent);
