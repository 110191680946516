import React from 'react';

import { i18, routerPaths } from '../../utilities';
import { history } from '../../api';
import { ButtonLink, Translate } from '../../components';
import { LoginForm } from './components';

export const LoginContainer = () => {
    return (
        <div className='row public_wrapper'>
            <div className='col-12 align-self-center public_content'>
                <div className='public_inner_sm'>
                    <div className='public_title'>
                        <Translate value={i18.Login.Titles.Login} />
                    </div>
                    <LoginForm />
                    <div className='row'>
                        <div className='col-12 col-md text-left text-md-right fs_14'>
                            <ButtonLink
                                className='p-0'
                                onClick={() => history.push(routerPaths.public.resetPassword)}
                            >
                                <Translate value={i18.Login.Buttons.LostPassword} />
                            </ButtonLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
