import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { Error, Title } from './';

const { TextArea } = Input;

export const TextareaFormField = (props) => {
    return (
        <div className={props.inline ? 'form_field_wrapper row align-items-center' : 'form_field_wrapper row'}>
            <Title
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
                borderTitle={props.borderTitle}
            />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <TextArea
                    autoFocus={props.autoFocus}
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    className={props.borderTitle && `bg-white text-secondary ${props.highlighted && 'ant-input-highlighted'}`}
                    spellCheck='false'
                    disabled={props.disabled}
                    name={props.input.name}
                    maxLength={props.maxLength}
                    onChange={props.input.onChange}
                    value={props.input.value}
                    placeholder={props.placeholder}
                    size={props.size}
                    autoSize={{
                        minRows: 3,
                        maxRows: 10
                    }}
                />
            </div>
            <Error
                inline={props.inline}
                touched={props.meta.touched}
                error={props.meta.error}
            />
        </div>
    );
};

TextareaFormField.propTypes = {
    autoFocus: PropTypes.bool,
    borderTitle: PropTypes.bool,
    highlighted: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    })
};
