import { requestApi, apiPaths, handleResponse, methods } from '../../config';

export const monthlyRewardsServices = {
    getMonthlyRewards,
    putMonthlyRewards
};

function getMonthlyRewards() {
    return requestApi(apiPaths.monthlyRewards).then(handleResponse);
}

function putMonthlyRewards(data) {
    return requestApi(apiPaths.monthlyRewards, {
        method: methods.put,
        data
    }).then(handleResponse);
}
