import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from 'react-final-form';

import {
    classificatorArray,
    i18,
    i18cl,
    i18contents,
    required,
    translate
} from '../../../utilities';
import { FormField, formFieldType, Translate } from '../../../components';

export const TranslatesContentsFilterForm = (props) => {
    const contents = i18contents.map((item) => ({
        label: translate('Translates.Contents.' + item),
        value: item
    }));

    return (
        <Form
            onSubmit={props.submit}
            initialValues={props.filter}
            render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} className="mb-3" autoComplete="off">
                    <div className="row">
                        <div className="col">
                            <FormField
                                title={i18.TranslatesFilter.Labels.Languages}
                                name="language"
                                component={formFieldType.select}
                                options={classificatorArray(
                                    i18cl.LANGUAGES,
                                    props.languages,
                                    'code'
                                )}
                                validate={required}
                                disabled={props.submitting}
                            />
                        </div>
                        <div className="col">
                            <FormField
                                title={i18.TranslatesFilter.Labels.Contents}
                                name="filter.title"
                                component={formFieldType.select}
                                options={contents}
                                optionsLabel="label"
                                optionsValue="value"
                                validate={required}
                                disabled={props.submitting}
                            />
                        </div>
                    </div>
                    <div className="text-right">
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            htmlType="submit"
                            loading={props.submitting}
                        >
                            <Translate value={i18.TranslatesFilter.Buttons.Filter} />
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

TranslatesContentsFilterForm.propTypes = {
    filter: PropTypes.object.isRequired,
    languages: PropTypes.array.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};
