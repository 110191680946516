import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Button, Modal } from 'antd';

import { formatNumber, getContentString, i18, i18clGroups, required } from '../../../utilities';
import { FormField, formFieldType, Translate } from '../../../components';
import { actionTypes } from '../countryVat';

export const CountryVatForm = (props) => (
    <Modal
        destroyOnClose
        title={<Translate value={i18.CountryVat.Titles.Form} />}
        visible={Boolean(props.actionType)}
        onCancel={props.onCancel}
        footer={null}
        maskClosable={false}
    >
        <Form
            onSubmit={props.submit}
            initialValues={props.initialValues}
            render={({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <FormField
                        name='name'
                        component={formFieldType.input}
                        title={i18.CountryVat.Labels.VatName}
                        validate={required}
                        disabled={props.loading}
                    />
                    <FormField
                        name='percentage'
                        component={formFieldType.number}
                        title={i18.CountryVat.Labels.VatPercentage}
                        validate={required}
                        disabled={props.loading}
                        formatNumber={formatNumber().floatPositiveUpTo2Decimal}
                    />
                    {props.actionType === actionTypes.update && (
                        <FormField
                            disabled
                            component={formFieldType.input}
                            title={i18.CountryVat.Labels.VatKey}
                            name='name'
                            format={value => getContentString(`${i18clGroups.INVOICING_VAT}${value}`)}
                            validate={required}
                        />
                    )}
                    <FormField
                        name='isDefault'
                        component={formFieldType.checkbox}
                        title={i18.CountryVat.Labels.IsVatDefault}
                        disabled={props.loading || (props.initialValues && props.initialValues.isDefault)}
                        validate={!props.isCountryVatExist ? required : null}
                    />
                    <div className='text-right'>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={props.loading}
                        >
                            <Translate value={i18.CountryVat.Buttons.Submit} />
                        </Button>
                    </div>
                </form>
            )}
        />
    </Modal>
);

CountryVatForm.propTypes = {
    submit: PropTypes.func,
    loading: PropTypes.bool,
    onCancel: PropTypes.func,
    initialValues: PropTypes.object,
    isCountryVatExist: PropTypes.bool,
    actionType: PropTypes.oneOf(['string', 'bool'])
};
