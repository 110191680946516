import React from 'react';

import { i18 } from '../../utilities';
import { ContentHeader, Translate } from '../../components';
import { CountriesSettingsList, CountriesSettingsForm } from './components';

export const CountriesSettingsContainer = () => {
    return (
        <>
            <ContentHeader
                title={<Translate value={i18.CountriesSettings.Titles.CountrySettings} />}
                extra={<CountriesSettingsForm />}
            />
            <div className='content_block'>
                <div className='content_box'>
                    <CountriesSettingsList />
                </div>
            </div>
        </>
    );
};
