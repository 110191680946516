import { jobService } from './services';
import { jobConstants } from './constants';
import { i18, translate } from '../../../utilities';
import { Message } from '../../../components';

export const jobActions = {
    cancelJob,
    completeJob,
    getJob,
    getJobValidationStatuses,
    updateJob,
    approveJob,
    assignNewPartner,
    assignNewPartnerStandard,
    getJobInvoices,
    startBiddingJob,
    sendJobToClient,
    getJobScopeFileUrl,
    getJobGeneratedOffers,
    postJobGeneratedOffers,
    clearJobGeneratedOffers,
    postSetCalled,
    postNewCompetitorOffer,
    putCompetitorOffer,
    deleteCompetitorOffer
};

function cancelJob(jobId) {
    return dispatch => {
        dispatch(request());

        return jobService.cancelJob(jobId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.POST_CANCEL_JOB_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.POST_CANCEL_JOB_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.POST_CANCEL_JOB_ERROR,
            error
        };
    }
}

function startBiddingJob(jobId) {
    return dispatch => {
        dispatch(request());

        return jobService.startBiddingJob(jobId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.POST_CANCEL_JOB_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.POST_CANCEL_JOB_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.POST_CANCEL_JOB_ERROR,
            error
        };
    }
}

function sendJobToClient(jobId) {
    return dispatch => {
        dispatch(request());

        return jobService.sendJobToClient(jobId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.POST_CANCEL_JOB_REQUEST
        };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.PreorderSendToClientSuccess)
        });

        return {
            type: jobConstants.POST_CANCEL_JOB_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.POST_CANCEL_JOB_ERROR,
            error
        };
    }
}

function completeJob(jobId, requestData) {
    return dispatch => {
        dispatch(request());

        return jobService.completeJob(jobId, requestData).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.POST_COMPLETE_JOB_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.POST_COMPLETE_JOB_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.POST_COMPLETE_JOB_ERROR,
            error
        };
    }
}

function getJob(jobId) {
    return dispatch => {
        dispatch(request());

        return jobService.getJob(jobId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.GET_JOB_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.GET_JOB_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.GET_JOB_ERROR,
            error
        };
    }
}

function getJobValidationStatuses() {
    return dispatch => {
        dispatch(request());

        return jobService.getJobValidationStatuses().then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.GET_JOB_VALIDATION_STATUSES_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.GET_JOB_VALIDATION_STATUSES_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.GET_JOB_VALIDATION_STATUSES_ERROR,
            error
        };
    }
}

function updateJob(jobId, jobData) {
    return dispatch => {
        dispatch(request());

        return jobService.updateJob(jobId, jobData).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.PUT_JOB_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.PUT_JOB_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.PUT_JOB_ERROR,
            error
        };
    }
}

function assignNewPartner(jobId, partnerId) {
    return dispatch => {
        dispatch(request());

        return jobService.assignNewPartner(jobId, partnerId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.PUT_ASSIGN_NEW_PARTNER_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.PUT_ASSIGN_NEW_PARTNER_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.PUT_ASSIGN_NEW_PARTNER_ERROR,
            error
        };
    }
}

function assignNewPartnerStandard(jobId, partnerId) {
    return dispatch => {
        dispatch(request());

        return jobService.assignNewPartnerStandard(jobId, partnerId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.PUT_ASSIGN_NEW_PARTNER_STANDARD_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.PUT_ASSIGN_NEW_PARTNER_STANDARD_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.PUT_ASSIGN_NEW_PARTNER_STANDARD_ERROR,
            error
        };
    }
}

function approveJob(jobId, payload) {
    return dispatch => {
        dispatch(request());

        return jobService.approveJob(jobId, payload).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.POST_APPROVE_JOB_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.POST_APPROVE_JOB_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.POST_APPROVE_JOB_ERROR,
            error
        };
    }
}

function getJobInvoices(jobId) {
    return dispatch => {
        dispatch(request());

        return jobService.getJobInvoices(jobId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.GET_JOB_INVOICES_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.GET_JOB_INVOICES_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.GET_JOB_INVOICES_ERROR,
            error
        };
    }
}

function getJobScopeFileUrl(jobId, partnerId) {
    return dispatch => {
        dispatch(request());

        return jobService.getJobScopeFileUrl(jobId, partnerId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.GET_JOB_SCOPE_FILE_URL_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.GET_JOB_SCOPE_FILE_URL_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.GET_JOB_SCOPE_FILE_URL_ERROR,
            error
        };
    }
}

function getJobGeneratedOffers(jobId, data) {
    return dispatch => {
        dispatch(request());

        return jobService.getJobGeneratedOffers(jobId, data).then(
            response => {
                if (!response?.data?.isSuccessful) {
                    dispatch(failure({ response: { data: { detail: response.data?.commandError?.message ?? '' } } }));
                } else if (response?.data?.isSuccessful) {
                    dispatch(success(response?.data?.value?.generatedOffers));
                }
            },
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.GET_JOB_OFFERS_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.GET_JOB_OFFERS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.GET_JOB_OFFERS_ERROR,
            error
        };
    }
}

function postJobGeneratedOffers(jobId, data) {
    return dispatch => {
        dispatch(request());

        return jobService.postJobGeneratedOffers(jobId, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.POST_JOB_OFFERS_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.POST_JOB_OFFERS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.POST_JOB_OFFERS_ERROR,
            error
        };
    }
}

function clearJobGeneratedOffers() {
    return dispatch => {
        dispatch(clear());
    };

    function clear() {
        return {
            type: jobConstants.GET_JOB_OFFERS_SUCCESS,
            payload: []
        };
    }
}

function postSetCalled(jobId, data) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await jobService.postSetCalled(jobId, data);
            dispatch(success(response));
            return response;
        } catch (error) {
            dispatch(failure(error));
            throw error;
        }
    };

    function request() {
        return {
            type: jobConstants.POST_APPROVE_JOB_REQUEST
        };
    }

    function success(data) {
        return {
            type: jobConstants.POST_SET_CALLED_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.POST_SET_CALLED_ERROR,
            error
        };
    }
}

function postNewCompetitorOffer(jobId, data) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await jobService.postNewCompetitorOffer(jobId, data);
            dispatch(success(response));
            return response;
        } catch (error) {
            dispatch(failure(error));
            throw error;
        }
    };

    function request() {
        return {
            type: jobConstants.POST_NEW_COMPETITOR_OFFER
        };
    }

    function success(data) {
        return {
            type: jobConstants.POST_NEW_COMPETITOR_OFFER_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.POST_NEW_COMPETITOR_OFFER_ERROR,
            error
        };
    }
}

function putCompetitorOffer(jobId, orderBidId, data) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await jobService.putCompetitorOffer(jobId, orderBidId, data);
            dispatch(success(response));
            return response;
        } catch (error) {
            dispatch(failure(error));
            throw error;
        }
    };

    function request() {
        return {
            type: jobConstants.PUT_COMPETITOR_OFFER
        };
    }

    function success(data) {
        return {
            type: jobConstants.PUT_COMPETITOR_OFFER_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.PUT_COMPETITOR_OFFER_ERROR,
            error
        };
    }
}

function deleteCompetitorOffer(jobId, orderBidId) {
    return async dispatch => {
        dispatch(request());

        return jobService.deleteCompetitorOffer(jobId, orderBidId).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return {
            type: jobConstants.DELETE_COMPETITOR_OFFER
        };
    }

    function success(data) {
        return {
            type: jobConstants.DELETE_COMPETITOR_OFFER_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: jobConstants.DELETE_COMPETITOR_OFFER_ERROR,
            error
        };
    }
}
