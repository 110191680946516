import { requestApi, apiPaths, handleResponse, methods } from '../../config';
import { ApplicationType } from '../../constants';

export const userServices = {
    postLogin,
    postChangePassword,
    postResetPassword,
    postCreatePassword
};

function postLogin (data) {
    return requestApi(`${apiPaths.user.login}${ApplicationType.ADMIN}`, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postResetPassword (data) {
    return requestApi(apiPaths.user.resetPassword, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postChangePassword (data) {
    return requestApi(apiPaths.user.changePassword, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postCreatePassword (data, code) {
    return requestApi(`${apiPaths.user.createPassword}${code}`, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}