import { partnerReadServices } from './services';
import { partnerReadConstants } from './constants';

export const partnerReadActions = {
    getActivityLog,
    getCommunicationLog,
    getMarketingAgreed,
    getMarketingAgreedAndApproved
};

function getActivityLog (profileId) {
    return dispatch => {
        dispatch(request());

        return partnerReadServices.getActivityLog(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerReadConstants.GET_PARTNER_READ_ACTIVITY_LOG_REQUEST };
    }

    function success (data) {
        return {
            type: partnerReadConstants.GET_PARTNER_READ_ACTIVITY_LOG_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerReadConstants.GET_PARTNER_READ_ACTIVITY_LOG_ERROR,
            error
        };
    }
}

function getCommunicationLog (profileId) {
    return dispatch => {
        dispatch(request());

        return partnerReadServices.getCommunicationLog(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerReadConstants.GET_PARTNER_READ_COMMUNICATION_LOG_REQUEST };
    }

    function success (data) {
        return {
            type: partnerReadConstants.GET_PARTNER_READ_COMMUNICATION_LOG_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerReadConstants.GET_PARTNER_READ_COMMUNICATION_LOG_ERROR,
            error
        };
    }
}

function getMarketingAgreed () {
    return dispatch => {
        dispatch(request());

        return partnerReadServices.getMarketingAgreed()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerReadConstants.GET_PARTNER_MARKETING_AGREED_REQUEST };
    }

    function success (data) {
        return {
            type: partnerReadConstants.GET_PARTNER_MARKETING_AGREED_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerReadConstants.GET_PARTNER_MARKETING_AGREED_ERROR,
            error
        };
    }
}

function getMarketingAgreedAndApproved () {
    return dispatch => {
        dispatch(request());

        return partnerReadServices.getMarketingAgreedAndApproved()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerReadConstants.GET_PARTNER_MARKETING_AGREED_AND_APPROVED_REQUEST };
    }

    function success (data) {
        return {
            type: partnerReadConstants.GET_PARTNER_MARKETING_AGREED_AND_APPROVED_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerReadConstants.GET_PARTNER_MARKETING_AGREED_AND_APPROVED_ERROR,
            error
        };
    }
}
