import { partnerProfileService } from './services';
import { partnerProfileConstants } from './constants';
import { Message } from '../../../components';
import { i18, translate } from '../../../utilities';

export const partnerProfileActions = {
    getProfile,
    getProfileLogs,
    postProfile,
    pathProfile,
    postProfileImage,
    getProfileDocument,
    getProfileDocumentPresignedUrl,
    postProfileDocument,
    deleteProfileDocument,
    getProfileDocuments,
    getProfileServices,
    getProfileService,
    getProfileServiceDocuments,
    putProfileService,
    deleteProfileService,
    pathProfileService,
    postProfileServiceDocument,
    getProfileAvailableServices,
    postProfileService,
    postResetPassword,
    postProfileSynchronization,
    getProfileServiceItems,
    putProfileServiceItem
};

function getProfile (profileId, update) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfile(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_REQUEST,
            update: update
        };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_ERROR,
            error
        };
    }
}

function getProfileLogs (profileId, update) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfileLogs(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_LOGS_REQUEST,
            update: update
        };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_LOGS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_LOGS_ERROR,
            error
        };
    }
}

function postProfile (data) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.postProfile(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.POST_PARTNER_PROFILE_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerCreated)
        });

        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_ERROR,
            error
        };
    }
}

function pathProfile (data) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.pathProfile(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.PATCH_PARTNER_PROFILE_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerUpdated)
        });

        return {
            type: partnerProfileConstants.PATCH_PARTNER_PROFILE_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.PATCH_PARTNER_PROFILE_ERROR,
            error
        };
    }
}

function postProfileImage (profileId, data) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.postProfileImage(profileId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.POST_PARTNER_PROFILE_IMAGE_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerImageChanged)
        });

        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_IMAGE_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_IMAGE_ERROR,
            error
        };
    }
}

function getProfileDocument (profileId, documentId) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfileDocument(profileId, documentId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_REQUEST };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_ERROR,
            error
        };
    }
}

function getProfileDocumentPresignedUrl (profileId, documentId) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfileDocumentPresignedUrl(profileId, documentId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_REQUEST };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENT_PRESIGNED_URL_ERROR,
            error
        };
    }
}

function postProfileDocument (profileId, data) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.postProfileDocument(profileId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.POST_PARTNER_PROFILE_DOCUMENT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerDocumentUploaded)
        });

        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_DOCUMENT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_DOCUMENT_ERROR,
            error
        };
    }
}

function deleteProfileDocument (profileId, documentId) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.deleteProfileDocument(profileId, documentId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.DELETE_PARTNER_PROFILE_DOCUMENT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerDocumentDeleted)
        });

        return {
            type: partnerProfileConstants.DELETE_PARTNER_PROFILE_DOCUMENT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.DELETE_PARTNER_PROFILE_DOCUMENT_ERROR,
            error
        };
    }
}

function getProfileDocuments (profileId) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfileDocuments(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENTS_REQUEST };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENTS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_DOCUMENTS_ERROR,
            error
        };
    }
}

function getProfileServices (profileId, update) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfileServices(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SERVICES_REQUEST,
            update: update
        };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SERVICES_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SERVICES_ERROR,
            error
        };
    }
}

function getProfileService (profileId, profileServiceId, update) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfileService(profileId, profileServiceId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_REQUEST,
            update: update
        };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_ERROR,
            error
        };
    }
}

function getProfileServiceDocuments (profileId, profileServiceId, update) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfileServiceDocuments(profileId, profileServiceId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_REQUEST,
            update: update
        };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_SERVICE_DOCUMENTS_ERROR,
            error
        };
    }
}

function putProfileService (profileId, data) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.putProfileService(profileId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.PUT_PARTNER_PROFILE_SERVICE_REQUEST
        };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.PUT_PARTNER_PROFILE_SERVICE_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.PUT_PARTNER_PROFILE_SERVICE_ERROR,
            error
        };
    }
}

function deleteProfileService (profileId, profileServiceId) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.deleteProfileService(profileId, profileServiceId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.DELETE_PARTNER_PROFILE_SERVICE_REQUEST
        };
    }

    function success () {
        Message({
            message: translate(i18.API.Messages.PartnerServiceDeleted)
        });

        return {
            type: partnerProfileConstants.DELETE_PARTNER_PROFILE_SERVICE_SUCCESS
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.DELETE_PARTNER_PROFILE_SERVICE_ERROR,
            error
        };
    }
}

function pathProfileService (profileId, data) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.pathProfileService(profileId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.PATCH_PARTNER_PROFILE_SERVICE_REQUEST };
    }

    function success () {
        Message({
            message: translate(i18.API.Messages.PartnerServiceUpdated)
        });

        return { type: partnerProfileConstants.PATCH_PARTNER_PROFILE_SERVICE_SUCCESS };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.PATCH_PARTNER_PROFILE_SERVICE_ERROR,
            error
        };
    }
}

function postProfileServiceDocument (profileId, profileServiceId, data) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.postProfileServiceDocument(profileId, profileServiceId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.POST_PARTNER_SERVICE_DOCUMENT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerServiceDocumentUploaded)
        });

        return {
            type: partnerProfileConstants.POST_PARTNER_SERVICE_DOCUMENT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.POST_PARTNER_SERVICE_DOCUMENT_ERROR,
            error
        };
    }
}

function getProfileAvailableServices (profileId) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfileAvailableServices(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.GET_PARTNER_PROFILE_AVAILABLE_SERVICES_REQUEST };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_AVAILABLE_SERVICES_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_PROFILE_AVAILABLE_SERVICES_ERROR,
            error
        };
    }
}

function postProfileService (profileId, data) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.postProfileService(profileId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.POST_PARTNER_PROFILE_SERVICE_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerServiceAdded)
        });

        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_SERVICE_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_SERVICE_ERROR,
            error
        };
    }
}

function postResetPassword (profileId) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.postResetPassword(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: partnerProfileConstants.POST_PARTNER_RESET_PASSWORD_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerPasswordReset)
        });

        return {
            type: partnerProfileConstants.POST_PARTNER_RESET_PASSWORD_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.POST_PARTNER_RESET_PASSWORD_ERROR,
            error
        };
    }
}

function postProfileSynchronization (profileId) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.postProfileSynchronization(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_SYNCHRONIZATION_REQUEST,
            loading: profileId
        };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerProfileSynchronizationSuccess)
        });

        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_SYNCHRONIZATION_SUCCESS,
            payload: data,
            loading: profileId
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.POST_PARTNER_PROFILE_SYNCHRONIZATION_ERROR,
            loading: profileId,
            error
        };
    }
}

function getProfileServiceItems (profileId) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.getProfileServiceItems(profileId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.GET_PARTNER_SERVICE_ITEMS_REQUEST,
            loading: profileId
        };
    }

    function success (data) {
        return {
            type: partnerProfileConstants.GET_PARTNER_SERVICE_ITEMS_SUCCESS,
            payload: data,
            loading: profileId
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.GET_PARTNER_SERVICE_ITEMS_ERROR,
            loading: profileId,
            error
        };
    }
}

function putProfileServiceItem (profileId, data) {
    return dispatch => {
        dispatch(request());

        return partnerProfileService.putProfileServiceItem(profileId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: partnerProfileConstants.PUT_PARTNER_SERVICE_ITEM_REQUEST,
            loading: profileId
        };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.PartnerProfileServiceItemUpdated)
        });

        return {
            type: partnerProfileConstants.PUT_PARTNER_SERVICE_ITEM_SUCCESS,
            payload: data,
            loading: profileId
        };
    }

    function failure (error) {
        return {
            type: partnerProfileConstants.PUT_PARTNER_SERVICE_ITEM_ERROR,
            loading: profileId,
            error
        };
    }
}
