import React from 'react';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { i18cl } from '../i18';
import { ProfileStatusConstants } from '../../api';
import { Classificator } from '../../components';
import { colors } from '../colors';

export const statusesProfile = (status) => {
    const classificator = <Classificator value={i18cl.STATUSES_PROFILE + status} />;

    switch (status) {
    case ProfileStatusConstants.APPROVED:
        return (
            <Tag
                color={colors.success}
                icon={<CheckCircleOutlined />}
            >
                {classificator}
            </Tag>
        );
    case ProfileStatusConstants.EMAIL_APPROVED:
        return (
            <Tag
                color={colors.success}
                icon={<CheckCircleOutlined />}
            >
                {classificator}
            </Tag>
        );
    case ProfileStatusConstants.EMAIL_NOT_APPROVED:
        return (
            <Tag
                color={colors.error}
                icon={<CloseCircleOutlined />}
            >
                {classificator}
            </Tag>
        );
    default:
        return null;
    }
};
