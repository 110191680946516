const getAdminData = () => {
    const string = window.localStorage.getItem('userAdmin');

    return JSON.parse(string);
};

export const getDataCountryId = () => {
    const data = getAdminData();

    if (data?.dataCountryId) {
        return data.dataCountryId;
    }

    return null;
};
