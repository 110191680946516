import { checkJsonProp } from './';
import { store } from '../api';

export const translate = value => {
    const state = store.getState();

    const translate = checkJsonProp(state.publicReducers.publicLocalization.translations, value);

    if (translate) {
        return translate;
    } else {
        return value;
    }
};

export const classificator = value => {
    const state = store.getState();

    const translate = checkJsonProp(state.publicReducers.publicLocalization.classificators, value);

    if (translate) {
        return translate;
    } else {
        return value;
    }
};

export const classificatorArray = (name, array, code, value, modifyLabel) => {
    if (!Array.isArray(array)) {
        return [];
    }

    return array.map(item => {
        let newLabel = classificator(name + (code ? item[code] : item));

        if (modifyLabel) {
            newLabel = modifyLabel(newLabel, item);
        }

        return {
            label: newLabel,
            value: value || code ? item[value || code] : item
        };
    });
};

export const classificatorTranslatedArray = classificator => {
    const state = store.getState();

    const classificatorObject = checkJsonProp(state.publicReducers.publicLocalization.classificators, classificator);

    if (classificatorObject) {
        return Object.entries(classificatorObject).map(([key, value]) => ({
            label: value || key,
            value: key
        }));
    } else {
        return [];
    }
};
