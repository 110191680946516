import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';

import { Error, Title } from './';
import { dateFormatLocale, translate } from '../../../utilities';

import moment from 'moment';

const { RangePicker } = DatePicker;

class DateRangePickerFormField extends PureComponent {
    render () {
        moment.locale(dateFormatLocale(this.props.systemLanguage));

        const currentDateFormat = moment.localeData().longDateFormat('L');
        const currentTimeFormat = ` ${moment.localeData().longDateFormat('LT')}`;
        const currentFormat = this.props.time ? currentDateFormat + currentTimeFormat : currentDateFormat;
        const format = this.props.time ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';

        let disabledDates = null;
        let minDate = null;
        let minSubstract = null;
        let minSubstractType = null;
        let maxDate = null;
        let maxSubstract = null;
        let maxSubstractType = null;

        if (this.props.minDate) {
            minDate = this.props.minDate.date;
            minSubstract = this.props.minDate.subtract;
            minSubstractType = this.props.minDate.subtractType;
        }

        if (this.props.maxDate) {
            maxDate = this.props.maxDate.date;
            maxSubstract = this.props.maxDate.subtract;
            maxSubstractType = this.props.maxDate.subtractType;
        }

        disabledDates = (current) => {
            if (minDate && maxDate) {
                const min = current.isBefore(moment(minDate, format).subtract(minSubstract || 0, minSubstractType || 'day'));
                const max = current.isAfter(moment(maxDate, format).subtract(maxSubstract || 0, maxSubstractType || 'day'));

                return (
                    min || max
                );
            } else if (minDate) {
                return current.isBefore(moment(minDate, format).subtract(minSubstract || 0, minSubstractType || 'day'));
            } else if (maxDate) {
                return current.isAfter(moment(maxDate, format).subtract(maxSubstract || 0, maxSubstractType || 'day'));
            }
        };

        return (
            <div
                className={this.props.inline ? 'form_field_wrapper row align-items-center' : 'form_field_wrapper row'}
                style={this.props.style}
            >
                <Title
                    inline={this.props.inline}
                    title={this.props.title}
                    titleTranslated={this.props.titleTranslated}
                    borderTitle={this.props.borderTitle}
                    dimmed={this.props.dimmed}
                />
                <div className={this.props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                    <RangePicker
                        autoFocus={this.props.autoFocus}
                        autoComplete='off'
                        autoCorrect='off'
                        autoCapitalize='none'
                        className={this.props.borderTitle && `bg-white border-title ${this.props.dimmed && 'ant-input-dimmed'}`}
                        spellCheck='off'
                        placeholder={[
                            this.props.placeholderFrom ? translate(this.props.placeholderFrom) : currentFormat?.toLowerCase(),
                            this.props.placeholderTo ? translate(this.props.placeholderTo) : currentFormat?.toLowerCase()
                        ]}
                        disabled={this.props.disabled}
                        size={this.props.size}
                        style={{ width: '100%' }}
                        name={this.props.input.name}
                        value={this.props.input.value ? [
                            this.props.input.value.from ? moment(moment.utc(this.props.input.value.from).local().format(format)) : undefined,
                            this.props.input.value.to ? moment(moment.utc(this.props.input.value.to).local().format(format)) : undefined]
                            : null}
                        onChange={(moments, _) => moments ? this.props.input.onChange(this.format(moments)) : this.props.input.onChange(undefined)}
                        format={currentFormat}
                        showToday={false}
                        showTime={this.props.time ? {
                            defaultValue: moment('00:00:00', 'HH:mm:ss'),
                            format: 'HH:mm:ss'
                        } : false}
                        disabledDate={disabledDates}
                    />
                </div>
                <Error
                    inline={this.props.inline}
                    touched={this.props.meta.touched}
                    error={this.props.meta.error}
                />
            </div>
        );
    };

    format (moments) {
        return {
            from: this.props.time ? moments[0].format() : this.stripDayTime(moments[0]).format(),
            to: this.props.time ? moments[1].format() : this.stripDayTime(moments[1]).format()
        };
    }

    stripDayTime (moment) {
        return moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }
}

DateRangePickerFormField.propTypes = {
    autoFocus: PropTypes.bool,
    borderTitle: PropTypes.bool,
    dimmed: PropTypes.bool,
    formatDate: PropTypes.oneOf(['date', 'week', 'month', 'year']),
    time: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
    style: PropTypes.object,
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    placeholderFrom: PropTypes.string,
    placeholderTo: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    }),
    minDate: PropTypes.shape({
        date: PropTypes.string,
        subtract: PropTypes.number,
        subtractType: PropTypes.number
    }),
    maxDate: PropTypes.shape({
        date: PropTypes.string,
        subtract: PropTypes.number,
        subtractType: PropTypes.string
    }),
    systemLanguage: PropTypes.string
};

function mapStateToProps (state) {
    const {
        systemLanguage
    } = state.systemReducers;

    return {
        systemLanguage
    };
}

const connectedDateRangePickerFormField = connect(mapStateToProps)(DateRangePickerFormField);
export { connectedDateRangePickerFormField as DateRangePickerFormField };
