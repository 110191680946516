export const partnerManagementConstants = {
    POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_REQUEST: 'POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_REQUEST',
    POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_SUCCESS: 'POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_SUCCESS',
    POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_ERROR: 'POST_PARTNER_MANAGEMENT_ASSIGN_ME_AS_MANAGER_ERROR',

    POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_REQUEST: 'POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_REQUEST',
    POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_SUCCESS: 'POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_SUCCESS',
    POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_ERROR: 'POST_PARTNER_MANAGEMENT_CHANGE_BLOCK_STATUS_ERROR',

    POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_REQUEST: 'POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_REQUEST',
    POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_SUCCESS: 'POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_SUCCESS',
    POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_ERROR: 'POST_PARTNER_MANAGEMENT_CHANGE_LOCK_STATUS_ERROR',

    POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_REQUEST: 'POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_REQUEST',
    POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_SUCCESS: 'POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_SUCCESS',
    POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_ERROR: 'POST_PARTNER_MANAGEMENT_CHANGE_JOB_SOURCES_ERROR',

    POST_PARTNER_MANAGEMENT_CHANGE_STATUS_REQUEST: 'POST_PARTNER_MANAGEMENT_CHANGE_STATUS_REQUEST',
    POST_PARTNER_MANAGEMENT_CHANGE_STATUS_SUCCESS: 'POST_PARTNER_MANAGEMENT_CHANGE_STATUS_SUCCESS',
    POST_PARTNER_MANAGEMENT_CHANGE_STATUS_ERROR: 'POST_PARTNER_MANAGEMENT_CHANGE_STATUS_ERROR',

    POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_REQUEST: 'POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_REQUEST',
    POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_SUCCESS: 'POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_SUCCESS',
    POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_ERROR: 'POST_PARTNER_MANAGEMENT_COMMUNICATION_LOG_ERROR',

    POST_PARTNER_MANAGEMENT_DETAILS_REQUEST: 'POST_PARTNER_MANAGEMENT_DETAILS_REQUEST',
    POST_PARTNER_MANAGEMENT_DETAILS_SUCCESS: 'POST_PARTNER_MANAGEMENT_DETAILS_SUCCESS',
    POST_PARTNER_MANAGEMENT_DETAILS_ERROR: 'POST_PARTNER_MANAGEMENT_DETAILS_ERROR',

    POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_REQUEST: 'POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_REQUEST',
    POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_SUCCESS: 'POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_SUCCESS',
    POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_ERROR: 'POST_PARTNER_MANAGEMENT_RESET_EMAIL_CONFIRMATION_ERROR',

    POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_REQUEST: 'POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_REQUEST',
    POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_SUCCESS: 'POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_SUCCESS',
    POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_ERROR: 'POST_PARTNER_MANAGEMENT_TOGGLE_KYC_VERIFIED_ERROR',

    POST_PARTNER_MANAGEMENT_PROFILE_REQUEST: 'POST_PARTNER_MANAGEMENT_PROFILE_REQUEST',
    POST_PARTNER_MANAGEMENT_PROFILE_SUCCESS: 'POST_PARTNER_MANAGEMENT_PROFILE_SUCCESS',
    POST_PARTNER_MANAGEMENT_PROFILE_ERROR: 'POST_PARTNER_MANAGEMENT_PROFILE_ERROR',

    POST_PARTNER_MANAGEMENT_AUTO_BID_REQUEST: 'POST_PARTNER_MANAGEMENT_AUTO_BID_REQUEST',
    POST_PARTNER_MANAGEMENT_AUTO_BID_SUCCESS: 'POST_PARTNER_MANAGEMENT_AUTO_BID_SUCCESS',
    POST_PARTNER_MANAGEMENT_AUTO_BID_ERROR: 'POST_PARTNER_MANAGEMENT_AUTO_BID_ERROR',

    POST_PARTNER_MANAGEMENT_MTC_REQUEST: 'POST_PARTNER_MANAGEMENT_MTC_REQUEST',
    POST_PARTNER_MANAGEMENT_MTC_SUCCESS: 'POST_PARTNER_MANAGEMENT_MTC_SUCCESS',
    POST_PARTNER_MANAGEMENT_MTC_ERROR: 'POST_PARTNER_MANAGEMENT_MTC_ERROR',

    POST_PARTNER_MANAGEMENT_GREEN_CARD_REQUEST: 'POST_PARTNER_MANAGEMENT_GREEN_CARD_REQUEST',
    POST_PARTNER_MANAGEMENT_GREEN_CARD_SUCCESS: 'POST_PARTNER_MANAGEMENT_GREEN_CARD_SUCCESS',
    POST_PARTNER_MANAGEMENT_GREEN_CARD_ERROR: 'POST_PARTNER_MANAGEMENT_GREEN_CARD_ERROR',
};
