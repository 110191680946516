import { requestApi, apiPaths, handleResponse, methods } from '../../config';

export const languageServices = {
    getCountriesLanguages,
    getAllLanguages,
    postCountriesLanguages
};

function getCountriesLanguages () {
    return requestApi(apiPaths.countriesLangauges).then(handleResponse);
}

function getAllLanguages () {
    return requestApi(apiPaths.allLanguages).then(handleResponse);
}

function postCountriesLanguages (data) {
    return requestApi(apiPaths.countriesLangauges, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}
