import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { classificatorTranslatedArray, i18, i18clGroups, required } from '../../../../../utilities';
import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../../../components';

export const PartnerStatusForm = (props) => {
    return (
        <Form
            onSubmit={props.submit}
            initialValues={props.data}
            render={({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <FormField
                        inline
                        title={i18.PartnerProfileStatusForm.Labels.Status}
                        name='status'
                        component={formFieldType.select}
                        options={classificatorTranslatedArray(i18clGroups.STATUSES_PROFILE)}
                        validate={required}
                        disabled={props.submitting}
                    />
                    <div className='text-right'>
                        <ButtonGroup>
                            <ButtonWrapper
                                type='text'
                                disabled={props.submitting}
                                onClick={() => props.cancel()}
                            >
                                <Translate value={i18.PartnerProfileStatusForm.Buttons.Cancel} />
                            </ButtonWrapper>
                            <ButtonWrapper
                                type='primary'
                                htmlType='submit'
                                loading={props.submitting}
                            >
                                <Translate value={i18.PartnerProfileStatusForm.Buttons.Submit} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    </div>
                </form>
            )}
        />
    );
};

PartnerStatusForm.propTypes = {
    cancel: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};
