import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import {
    ContentLoader,
    CustomModal,
    FormField,
    Translate,
    filterFieldTypes,
    formFieldType
} from '../../../../components';
import { i18, oDataQuery, required, translate } from '../../../../utilities';
import { jobActions, odataServices, showErrorNotification } from '../../../../api';
import { debounce } from 'lodash';
import { connect } from 'react-redux';

const fieldNames = {
    partnerId: 'partnerId',
    clientPrice: 'clientPrice',
    adminFee: 'adminFee',
    partnerReward: 'partnerReward',
    materialsPrice: 'materialsPrice'
};

const AddCompetitorModalComponent = ({
    jobId,
    serviceId,
    orderBidId,
    isEdit,
    initialPartnerId,
    initialClientPrice,
    initialPartnerReward,
    initialAdminFee,
    initialMaterialsPrice,
    dispatch,
    isLoading,
    onReload
}) => {
    const [isInitiated, setIsInitiated] = useState(!isEdit);
    const [isFetchingPartners, setIsFetchingPartners] = useState(false);
    const [partnerNames, setPartnerNames] = useState([]);
    const [initialValues] = useState({
        [fieldNames.partnerId]: initialPartnerId,
        [fieldNames.clientPrice]: initialClientPrice ?? 0,
        [fieldNames.partnerReward]: initialPartnerReward ?? 0,
        [fieldNames.adminFee]: initialAdminFee ?? 0,
        [fieldNames.materialsPrice]: initialMaterialsPrice ?? 0
    });

    const handleSubmitForm = async values => {
        try {
            const data = {
                partnerId: values[fieldNames.partnerId],
                clientPrice: values[fieldNames.clientPrice],
                adminFee: values[fieldNames.adminFee],
                partnerReward: values[fieldNames.partnerReward],
                materialPrice: values[fieldNames.materialsPrice]
            };

            if (!isEdit) {
                await dispatch(
                    jobActions.postNewCompetitorOffer(jobId, {
                        profileId: values[fieldNames.partnerId],
                        ...data
                    })
                );
            } else {
                await dispatch(jobActions.putCompetitorOffer(jobId, orderBidId, data));
            }

            onReload();
            CustomModal.close();
        } catch {}
    };

    const calculateTotal = useCallback((value, values) => {
        const clientPrice = values[fieldNames.clientPrice];
        const partnerReward = values[fieldNames.partnerReward];
        const administrationFee = values[fieldNames.adminFee];
        const total = (Number(partnerReward) + Number(administrationFee)).toFixed(2);

        if (Number(clientPrice) !== Number(total)) {
            return translate(i18.Job.Errors.PriceValidation);
        }
    }, []);

    const fetchPartnerNames = useCallback(
        async (searchValue = '', partnerId) => {
            setIsFetchingPartners(true);

            try {
                const filters = [
                    {
                        name: 'SearchAggregate',
                        type: filterFieldTypes.text,
                        value: searchValue
                    }
                ];

                if (partnerId) {
                    filters.push({
                        name: 'PartnerId',
                        type: filterFieldTypes.number,
                        value: partnerId
                    });
                }

                const result = await odataServices.getJobPartnersGridForService(
                    serviceId,
                    oDataQuery({
                        filters: filters,
                        orders: [],
                        pagination: {
                            pageSize: 100,
                            current: 1
                        },
                        select: []
                    })
                );

                if (result.value) {
                    setPartnerNames(
                        result.value.map(item => ({
                            label: item.FirstName + ' ' + item.LastName,
                            value: item.PartnerId
                        }))
                    );
                }
            } catch (error) {
                showErrorNotification({ error });
            } finally {
                setIsFetchingPartners(false);
            }
        },
        [serviceId]
    );

    useEffect(() => {
        const initiate = async () => {
            await fetchPartnerNames('', initialPartnerId);
            setIsInitiated(true);
        };

        if (isEdit) {
            initiate();
        }
    }, [fetchPartnerNames, initialPartnerId, isEdit]);

    const debouncedFetchPartnerNames = debounce(fetchPartnerNames, 300);

    if (!isInitiated) {
        return <ContentLoader />;
    }

    return (
        <Form
            onSubmit={handleSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
                <>
                    <div className="row align-items-end">
                        <div className="col-4">
                            <FormField
                                name={fieldNames.partnerId}
                                title={i18.Job.Labels.AddCompetitorModalPartner}
                                component={formFieldType.select}
                                validate={required}
                                options={partnerNames}
                                onSearch={debouncedFetchPartnerNames}
                                loading={isFetchingPartners}
                                placeholder={i18.Job.Labels.AddCompetitorModalPartnerPlaceholder}
                            />
                        </div>
                        <div className="col-2">
                            <FormField
                                name={fieldNames.clientPrice}
                                title={i18.Job.Labels.AddCompetitorModalClientPrice}
                                validate={calculateTotal}
                                component={formFieldType.number}
                                formatNumber={{
                                    allowNegative: false,
                                    allowLeadingZeros: false,
                                    decimalScale: 2
                                }}
                            />
                        </div>
                        <div className="col-2">
                            <FormField
                                name={fieldNames.adminFee}
                                title={i18.Job.Labels.AddCompetitorModalAdminFee}
                                validate={calculateTotal}
                                component={formFieldType.number}
                                formatNumber={{
                                    allowNegative: false,
                                    allowLeadingZeros: false,
                                    decimalScale: 2
                                }}
                            />
                        </div>
                        <div className="col-2">
                            <FormField
                                name={fieldNames.partnerReward}
                                title={i18.Job.Labels.AddCompetitorModalPartnerReward}
                                validate={calculateTotal}
                                component={formFieldType.number}
                                formatNumber={{
                                    allowNegative: false,
                                    allowLeadingZeros: false,
                                    decimalScale: 2
                                }}
                            />
                        </div>
                        <div className="col-2">
                            <FormField
                                name={fieldNames.materialsPrice}
                                title={i18.Job.Labels.AddCompetitorModalMaterialsPrice}
                                component={formFieldType.number}
                                formatNumber={{
                                    allowNegative: false,
                                    allowLeadingZeros: false,
                                    decimalScale: 2
                                }}
                            />
                        </div>
                    </div>
                    <Button.Group className="d-flex justify-content-end">
                        <Button onClick={CustomModal.close}>
                            <Translate value={i18.Job.Buttons.AddCompetitorModalCancel} />
                        </Button>
                        <Button
                            className="ml-3"
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmit}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            <Translate value={i18.Job.Buttons.AddCompetitorModalSubmit} />
                        </Button>
                    </Button.Group>
                </>
            )}
        />
    );
};

AddCompetitorModalComponent.propTypes = {
    orderBidId: PropTypes.string,
    jobId: PropTypes.string.isRequired,
    serviceId: PropTypes.number.isRequired,
    isEdit: PropTypes.bool,
    initialPartnerId: PropTypes.number,
    initialClientPrice: PropTypes.number,
    initialPartnerReward: PropTypes.number,
    initialAdminFee: PropTypes.number,
    initialMaterialsPrice: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    onReload: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    const { sendNewCompetitorOfferPOST, sendCompetitorOfferPUT } = state.jobReducers;

    return {
        isLoading: sendNewCompetitorOfferPOST || sendCompetitorOfferPUT
    };
};

export const AddCompetitorModal = connect(mapStateToProps)(AddCompetitorModalComponent);
