/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Checkbox } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { Classificator, Image, Translate } from '../../../components';
import { i18, i18cl } from '../../../utilities';

import { ServiceGroupEdit } from '.';

export const ServiceGroupInformation = (props) => {
    const [isEditing, setIsEditing] = useState(false);

    const information = [
        {
            label: <Translate value={i18.ServiceGroup.Labels.ServiceGroupCode} />,
            value: (
                <>
                    <span className="mr-5">{props.data.serviceGroupCode}</span>
                    <Classificator
                        value={i18cl.GROUPS_SERVICE + props.data.serviceGroupCode}
                    />
                </>
            )
        },
        {
            label: <Translate value={i18.ServiceGroup.Labels.Description} />,
            value: props.data.description
        },
        {
            label: (
                <Translate value={i18.ServiceGroup.Labels.AdditionalDescription} />
            ),
            value: props.data.additionalDescription
        },
        {
            label: <Translate value={i18.ServiceGroup.Labels.HourlyPrice} />,
            value: props.data.hourlyPrice
        },
        {
            label: <Translate value={i18.ServiceGroup.Labels.HintText} />,
            value: props.data.hintText
        },
        {
            label: <Translate value={i18.ServiceGroup.Labels.SearchKeywords} />,
            value: props.data.searchKeywords
        },
        {
            label: <Translate value={i18.ServiceGroup.Labels.Order} />,
            value: props.data.order
        }, props.data?.hasOwnProperty('alwaysShowInSearch') && {
            label: <Translate value={i18.ServiceGroup.Labels.AlwaysShowInSearch} />,
            value: props.data.alwaysShowInSearch
        }
    ].filter(Object);

    const onUpdateServiceGroupData = (data) => {
        props.updateServiceGroupData(data, () => setIsEditing(!setIsEditing));
    };

    return (
        <div>
            <div className="w-100 float-right">
                <Button
                    className="float-right"
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => setIsEditing(!isEditing)}
                >
                    {isEditing && <Translate value={i18.ServiceGroup.Buttons.Cancel} />}
                    {!isEditing && <Translate value={i18.ServiceGroup.Buttons.Edit} />}
                </Button>
            </div>
            <div className="row">
                <div className="col-auto">
                    <Image
                        imagePath={props.data.imagePath}
                        submitImage={props.updateServiceGroupImage}
                        loading={props.loading}
                    />
                </div>
                <div className="col">
                    {isEditing && (
                        <ServiceGroupEdit
                            onSubmit={onUpdateServiceGroupData}
                            submitting={props.loading}
                            serviceGroup={props.data}
                        />
                    )}
                    {!isEditing && (
                        <div className="list_info_wrapper">
                            {information
                                .filter((e) => e.visible !== false)
                                .map((item, index) => (
                                    <div key={index} className="list_info row">
                                        <div className="list_info_label col-12 col-xl-5 text-right">
                                            {item.label}
                                        </div>
                                        <div className="list_info_value col-12 col-xl-7">
                                            {typeof item.value === 'boolean' ? (
                                                <Checkbox checked={item.value} disabled={true} />
                                            ) : (
                                                item.value
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ServiceGroupInformation.propTypes = {
    data: PropTypes.object.isRequired,
    getServiceGroup: PropTypes.func.isRequired,
    updateServiceGroupImage: PropTypes.func.isRequired,
    updateServiceGroupData: PropTypes.func.isRequired,
    loading: PropTypes.bool
};
