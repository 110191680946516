import { requestApi, apiPaths, handleResponse } from '../../config';

export const publicServices = {
    getBankName,
    getLanguages,
    getLocalization,
    getServiceAreas,
    getContent,
    getConfigurations
};

function getBankName (iban) {
    return requestApi(apiPaths.public.bankName + '?iban=' + iban).then(handleResponse);
}

function getLanguages () {
    return requestApi(apiPaths.public.languages).then(handleResponse);
}

function getLocalization () {
    return requestApi(apiPaths.public.localization).then(handleResponse);
}

function getServiceAreas () {
    return requestApi(apiPaths.public.serviceAreas).then(handleResponse);
}

function getContent () {
    return requestApi(apiPaths.public.content).then(handleResponse);
}

function getConfigurations () {
    return requestApi(apiPaths.public.configuration).then(handleResponse);
}
