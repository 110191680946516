import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Button, Modal } from 'antd';

import { i18, required } from '../../../utilities';
import { FormField, formFieldType, Translate } from '../../../components';

export const JobCompleteForm = (props) => (
    <Modal
        destroyOnClose
        title={<Translate value={i18.Job.Titles.ConfirmJob} />}
        visible={Boolean(props.actionType)}
        onCancel={props.onCancel}
        onSubmit={props.onSubmit}
        footer={null}
        maskClosable={false}
    >
        <Form
            initialValues={props.initialValues}
            onSubmit={props.submit}
            render={({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <FormField
                        name='period'
                        component={formFieldType.input}
                        title={i18.Job.Labels.InvoicingPeriod}
                        validate={required}
                    />
                    <div className='text-right'>
                        <Button
                            type='primary'
                            htmlType='submit'
                        >
                            <Translate value={i18.Job.Buttons.ConfirmJob} />
                        </Button>
                    </div>
                </form>
            )}
        />
    </Modal>
);

JobCompleteForm.propTypes = {
    submit: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    actionType: PropTypes.oneOf(['string', 'bool'])
};
