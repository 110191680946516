import { internalClientService } from './services';
import { internalClientConstants } from './constants';
import { Message } from '../../../components';
import { i18, translate } from '../../../utilities';

export const internalClientActions = {
    postInternalClient,
    getInternalClient,
    putInternalClient
};

function postInternalClient (data) {
    return dispatch => {
        dispatch(request());
        return internalClientService.postInternalClient(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: internalClientConstants.POST_INTERNAL_CLIENT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.InternalClientCreated)
        });

        return {
            type: internalClientConstants.POST_INTERNAL_CLIENT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: internalClientConstants.POST_INTERNAL_CLIENT_ERROR,
            error
        };
    }
}

function putInternalClient (id, data) {
    return dispatch => {
        dispatch(request());
        return internalClientService.putInternalClient(id, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: internalClientConstants.PUT_INTERNAL_CLIENT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.InternalClientUpdated)
        });

        return {
            type: internalClientConstants.PUT_INTERNAL_CLIENT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: internalClientConstants.PUT_INTERNAL_CLIENT_ERROR,
            error
        };
    }
}

function getInternalClient (id) {
    return dispatch => {
        dispatch(request());
        return internalClientService.getInternalClient(id)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: internalClientConstants.GET_INTERNAL_CLIENT_REQUEST
        };
    }

    function success (data) {
        return {
            type: internalClientConstants.GET_INTERNAL_CLIENT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: internalClientConstants.GET_INTERNAL_CLIENT_ERROR,
            error
        };
    }
}
