import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { partnerProfileActions, partnerProfileConstants } from '../../../../api';
import { PartnerServiceItemsList } from './components';

class PartnerServiceItemsContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            serviceItems: []
        };

        this.updateServiceItems = this.updateServiceItems.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount () {
        this.props.dispatch(partnerProfileActions.getProfileServiceItems(this.props.match.params.partnerId)).then((response) => {
            if (response.type === partnerProfileConstants.GET_PARTNER_SERVICE_ITEMS_SUCCESS) {
                this.setState({ serviceItems: response.payload });
            }
        });
    }

    updateServiceItems (item) {
        const clone = [...this.state.serviceItems];
        const updatedItemIndex = clone.findIndex((serviceItem) => serviceItem.serviceItemId === item.serviceItemId);
        clone.splice(updatedItemIndex, 1, item);
        this.setState({ serviceItems: clone });
    }

    handleUpdate (item) {
        this.props.dispatch(partnerProfileActions.putProfileServiceItem(this.props.match.params.partnerId, item)).then((response) => {
            if (response.type === partnerProfileConstants.PUT_PARTNER_SERVICE_ITEM_SUCCESS) {
                this.updateServiceItems(item);
            }
        });
    }

    render () {
        return (
            <div className='content_block'>
                <PartnerServiceItemsList
                    data={this.state.serviceItems}
                    loading={this.props.sendPartnerServiceItemsGET}
                    handleUpdate={this.handleUpdate}
                />
            </div>
        );
    }
}

PartnerServiceItemsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    partnerProfileServiceItems: PropTypes.array,
    sendPartnerServiceItemsGET: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        partnerProfileServiceItems,
        sendPartnerServiceItemsGET
    } = state.partnerProfileReducers;

    return {
        partnerProfileServiceItems,
        sendPartnerServiceItemsGET
    };
}

const connectedPartnerServiceItemsContainer = withRouter(connect(mapStateToProps)(PartnerServiceItemsContainer));
export { connectedPartnerServiceItemsContainer as PartnerServiceItemsContainer };
