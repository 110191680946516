import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import {
    accountTypes,
    checkCyrillicLetters,
    getCurrentDate,
    i18,
    required,
    requiredPhone
} from '../../../../../utilities';
import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../../../components';

export const PartnerProfileForm = (props) => {
    return (
        <Form
            onSubmit={props.submit}
            validate={values => {
                const errors = {};
                if (checkCyrillicLetters(values?.firstName)) {
                    errors.firstName = <Translate value={i18.Forms.Errors.CyrillicError} />;
                }

                if (checkCyrillicLetters(values?.lastName)) {
                    errors.lastName = <Translate value={i18.Forms.Errors.CyrillicError} />;
                }
                return errors;
            }}
            initialValues={props.data}
            render={({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <FormField
                        inline
                        title={i18.PartnerProfileForm.Labels.Name}
                        name='firstName'
                        component={formFieldType.input}
                        validate={required}
                        disabled={props.submitting}
                        maxLength={25}
                    />
                    <FormField
                        inline
                        title={i18.PartnerProfileForm.Labels.Surname}
                        name='lastName'
                        component={formFieldType.input}
                        validate={required}
                        disabled={props.submitting}
                        maxLength={25}
                    />
                    <FormField
                        inline
                        title={i18.PartnerProfileForm.Labels.AboutMe}
                        name='aboutMeInfo'
                        component={formFieldType.textarea}
                        disabled={props.submitting}
                    />
                    {props.data.account.typeCode === accountTypes.PHYSICAL && (
                        <FormField
                            inline
                            component={formFieldType.date}
                            disabled={props.submitting}
                            title={i18.PartnerProfile.Labels.Birthday}
                            name='birthday'
                            maxDate={{
                                date: getCurrentDate()
                            }}
                            minDate={{
                                date: getCurrentDate(-1 * 365 * 150)
                            }}
                        />
                    )}
                    <FormField
                        inline
                        title={i18.PartnerProfileForm.Labels.Phone}
                        name="telephone"
                        component={formFieldType.phone}
                        validate={requiredPhone}
                        disabled={props.submitting}
                    />
                    <div className='text-right'>
                        <ButtonGroup>
                            <ButtonWrapper
                                type='text'
                                disabled={props.submitting}
                                onClick={() => props.cancel()}
                            >
                                <Translate value={i18.PartnerProfileForm.Buttons.Cancel} />
                            </ButtonWrapper>
                            <ButtonWrapper
                                type='primary'
                                htmlType='submit'
                                loading={props.submitting}
                            >
                                <Translate value={i18.PartnerProfileForm.Buttons.Submit} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    </div>
                </form>
            )}
        />
    );
};

PartnerProfileForm.propTypes = {
    cancel: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};
