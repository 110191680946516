import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { i18, routerPaths } from '../../utilities';
import { ContentHeader, Translate } from '../../components';
import { PartnersMarketingMenu } from './components';
import { PartnersMarketingAgreedAndApprovedContainer, PartnersMarketingAgreedContainer } from './containers';

class PartnersMarketingContainer extends React.Component {
    render () {
        return (
            <>
                <ContentHeader title={<Translate value={i18.PartnersMarketing.Titles.PartnersMarketing} />} />
                <PartnersMarketingMenu />
                <Switch>
                    <Route
                        component={PartnersMarketingAgreedContainer}
                        exact
                        path={routerPaths.partners.marketing}
                    />
                    <Route
                        component={PartnersMarketingAgreedAndApprovedContainer}
                        exact
                        path={routerPaths.partners.marketingAgreed}
                    />
                </Switch>
            </>
        );
    }
}

PartnersMarketingContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    partnerMarketingAgreed: PropTypes.any.isRequired,
    sendPartnerPasswordReset: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        partnerMarketingAgreed
    } = state.partnerReadReducers;

    return {
        partnerMarketingAgreed
    };
}

const connectedPartnersMarketingContainer = connect(mapStateToProps)(PartnersMarketingContainer);
export { connectedPartnersMarketingContainer as PartnersMarketingContainer };
