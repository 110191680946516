import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { PartnerDeviceList } from '.';

class PartnerDevicesContainer extends React.Component {
    render () {
        return (
            <div className="content_block">
                <PartnerDeviceList
                    data={this.props.devices}
                />
            </div>
        );
    }
}

PartnerDevicesContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    devices: PropTypes.arrayOf(PropTypes.object)
};

function mapStateToProps (state) {
    const { partnerProfile } = state.partnerProfileReducers;

    return {
        devices: partnerProfile?.devices ?? []
    };
}

const connectedPartnerDevicesContainer = withRouter(
    connect(mapStateToProps)(PartnerDevicesContainer)
);
export { connectedPartnerDevicesContainer as PartnerDevicesContainer };
