import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { partnerProfileActions } from '../../../../api';
import { PartnerLogsList } from './';

class PartnerLogsContainer extends React.Component {
    componentDidMount () {
        this.props.dispatch(partnerProfileActions.getProfileLogs(this.props.match.params.partnerId));
    }

    render () {
        return (
            <div className='content_block'>
                <PartnerLogsList
                    data={this.props.partnerProfileLogs}
                    loading={this.props.sendPartnerProfileLogsGET}
                />
            </div>
        );
    }
}

PartnerLogsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    partnerProfileLogs: PropTypes.array.isRequired,
    sendPartnerProfileLogsGET: PropTypes.bool.isRequired
};

function mapStateToProps (state) {
    const {
        partnerProfileLogs,
        sendPartnerProfileLogsGET
    } = state.partnerProfileReducers;

    return {
        partnerProfileLogs,
        sendPartnerProfileLogsGET
    };
}

const connectedPartnerLogsContainer = withRouter(connect(mapStateToProps)(PartnerLogsContainer));
export { connectedPartnerLogsContainer as PartnerLogsContainer };
