import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Translate } from '../../../components';
import { i18 } from '../../../utilities';

export const ContentsTypeToggle = (props) => {
    const handleToggle = () => {
        props.onToggle(!props.toggleValue);
    };

    return (
        <Button onClick={handleToggle}>
            <Translate value={props.toggleValue
                ? i18.Translates.Labels.SaveContentAsPlainText
                : i18.Translates.Labels.SaveContentAsHTML
            } />
        </Button>
    );
};

ContentsTypeToggle.propTypes = {
    onToggle: PropTypes.func,
    toggleValue: PropTypes.bool
};
