export const languageConstants = {
    GET_LANGUAGES_REQUEST: 'GET_LANGUAGES_REQUEST',
    GET_LANGUAGES_SUCCESS: 'GET_LANGUAGES_SUCCESS',
    GET_LANGUAGES_ERROR: 'GET_LANGUAGES_ERROR',

    GET_ALL_LANGUAGES_REQUEST: 'GET_ALL_LANGUAGES_REQUEST',
    GET_ALL_LANGUAGES_SUCCESS: 'GET_ALL_LANGUAGES_SUCCESS',
    GET_ALL_LANGUAGES_ERROR: 'GET_ALL_LANGUAGES_ERROR',

    POST_LANGUAGES_REQUEST: 'POST_LANGUAGES_REQUEST',
    POST_LANGUAGES_SUCCESS: 'POST_LANGUAGES_SUCCESS',
    POST_LANGUAGES_ERROR: 'POST_LANGUAGES_ERROR'
};
