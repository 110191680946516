import { countriesConstants } from './constants';

export const countriesReducers = function (state = {
    countries: []
}, action) {
    switch (action.type) {
    case countriesConstants.GET_COUNTRIES_REQUEST:
        return {
            ...state,
            send: true,
            sendCountries: true
        };
    case countriesConstants.GET_COUNTRIES_SUCCESS:
        return {
            ...state,
            countries: action.payload,
            send: false,
            sendCountries: false
        };
    case countriesConstants.GET_COUNTRIES_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountries: false
        };
    default:
        return state;
    }
};
