import React from 'react';
import { Tag } from 'antd';

import { Classificator } from '../../components';
import { colors, i18cl } from '../';

export const statusesParameter = (status) => {
    const classificator = <Classificator value={i18cl.STATUSES_PARAMETER + status} />;

    switch (status) {
    case 'NOT_VALID':
        return <Tag color={colors.error}>{classificator}</Tag>;
    case 'VALID':
        return <Tag color={colors.success}>{classificator}</Tag>;
    case 'WILL_BE_VALID':
        return <Tag color={colors.warning}>{classificator}</Tag>;
    default:
        return null;
    }
};
