import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { formatDate, i18, i18cl, statusesDocument, statusesParameter } from '../../../../../utilities';
import { Classificator, FileDelete, FileDownload, Translate } from '../../../../../components';
import { PartnerServiceDocumentsForm } from './';

export const PartnerServiceDocumentsList = (props) => {
    const {
        loading,
        profileId,
        profileServiceId,
        update
    } = props;
    const columns = [
        {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.ParameterStatus} />,
            render: (item) => statusesParameter(item.parameterStatus)
        }, {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.Status} />,
            render: (item) => statusesDocument(item.documentStatus)
        }, {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.Name} />,
            render: (item) => <Classificator value={i18cl.SERVICE_TYPES_DOCUMENT + item.value} />
        }, {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.Count} />,
            render: (item) => item.files.length
        }, {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.Actions} />,
            render: (item) => (
                <PartnerServiceDocumentsForm
                    initialData={{
                        value: item.value,
                        classificatorCode: item.classificatorCode,
                        documentFilePath: item.documentFilePath
                    }}
                    profileId={profileId}
                    profileServiceId={profileServiceId}
                    title={<Classificator value={i18cl.SERVICE_TYPES_DOCUMENT + item.value} />}
                    update={() => update()}
                />
            ),
            className: 'text-right'
        }
    ];
    const columnsInner = [
        {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.Status} />,
            render: (item) => statusesDocument(item.status),
            width: 1
        }, {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.Name} />,
            render: (item) => item.documentName
        }, {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.ValidFrom} />,
            render: (item) => formatDate(item.validFrom),
            className: 'text-right'
        }, {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.ValidTo} />,
            render: (item) => formatDate(item.validTo),
            className: 'text-right'
        }, {
            title: <Translate value={i18.PartnerDocumentsList.Labels.Deleted} />,
            render: (item) => item.isDeleted && <CheckOutlined />,
            className: 'text-right'
        }, {
            title: <Translate value={i18.PartnerServiceDocumentsList.Labels.Actions} />,
            render: (item) => (
                <Button.Group>
                    {!item.isDeleted && (
                        <FileDelete
                            documentId={item.documentId}
                            profileId={item.profileId}
                            update={() => update()}
                        />
                    )}
                    <FileDownload
                        name={item.documentName}
                        documentId={item.documentId}
                        profileId={item.profileId}
                        canPreview={true}
                    />
                </Button.Group>
            ),
            className: 'text-right'
        }
    ];

    props.data.map((item, index) => {
        item.index = index;
    });

    return (
        <Table
            rowKey='index'
            columns={columns}
            size='large'
            dataSource={props.data}
            pagination={false}
            loading={loading}
            expandable={{
                expandedRowRender: item =>
                    <Table
                        rowKey='documentId'
                        columns={columnsInner}
                        dataSource={item.files}
                        pagination={false}
                    />
            }}
        />
    );
};

PartnerServiceDocumentsList.propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    profileId: PropTypes.string.isRequired,
    profileServiceId: PropTypes.string.isRequired,
    update: PropTypes.func.isRequired
};
