import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Menu } from 'antd';

import { i18, routerPaths } from '../../../utilities';
import { Translate } from '../../../components';
import { history, partnerProfileActions } from '../../../api';

class PartnerMenu extends React.Component {
    constructor (props) {
        super(props);

        this.select = this.select.bind(this);
    }

    componentDidMount () {
        this.props.dispatch(partnerProfileActions.getProfileDocuments(this.props.match.params.partnerId));
    }

    select (path) {
        history.replace(path.key, this.props.backState);
    }

    render () {
        const { partnerId } = this.props.match.params;
        const menu = [
            {
                title: i18.Partner.Menu.Profile,
                path: routerPaths.partners.index + '/' + partnerId + '/profile'
            }, {
                title: i18.Partner.Menu.Account,
                path: routerPaths.partners.index + '/' + partnerId + '/account'
            }, ...(this.props.partnerProfileDocuments.length ? [{
                title: i18.Partner.Menu.Documents,
                path: routerPaths.partners.index + '/' + partnerId + '/documents'
            }] : []), {
                title: i18.Partner.Menu.Services,
                path: routerPaths.partners.index + '/' + partnerId + '/services'
            }, {
                title: i18.Partner.Menu.ServiceItems,
                path: routerPaths.partners.index + '/' + partnerId + '/service-items'
            }, {
                title: i18.Partner.Menu.Logs,
                path: routerPaths.partners.index + '/' + partnerId + '/logs'
            }, {
                title: i18.Partner.Menu.Communications,
                path: routerPaths.partners.index + '/' + partnerId + '/communications'
            }, {
                title: i18.Partner.Menu.AdminActions,
                path: routerPaths.partners.index + '/' + partnerId + '/admin-actions'
            }, {
                title: i18.Partner.Menu.Devices,
                path: routerPaths.partners.index + '/' + partnerId + '/devices'
            }
        ];

        return (
            <Menu
                selectedKeys={[this.props.location.pathname]}
                mode='horizontal'
                onSelect={this.select}
                className='px-2'
            >
                {menu.map((item) =>
                    <Menu.Item
                        key={item.path}
                    >
                        <Translate value={item.title} />
                    </Menu.Item>
                )}
            </Menu>
        );
    };
}

PartnerMenu.propTypes = {
    backState: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    partnerProfileDocuments: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

function mapStateToProps (state) {
    const {
        partnerProfileDocuments
    } = state.partnerProfileReducers;

    return {
        partnerProfileDocuments
    };
}

const routedPartnerMenu = withRouter(connect(mapStateToProps)(PartnerMenu));
export { routedPartnerMenu as PartnerMenu };
