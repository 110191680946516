import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Form } from 'react-final-form';

import { i18, required, formatNumber } from '../../../utilities';
import { FormField, formFieldType, Translate } from '../../../components';

export const ServiceGroupEdit = (props) => {
    return (
        <Form
            onSubmit={props.onSubmit}
            initialValues={props.serviceGroup}
            render={({ handleSubmit, values, form }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                    <FormField
                        inline
                        title={i18.ServiceGroup.Labels.Description}
                        name="description"
                        component={formFieldType.input}
                        maxLength={1500}
                        disabled={props.submitting}
                        validate={required}
                    />
                    <FormField
                        inline
                        title={i18.ServiceGroup.Labels.AdditionalDescription}
                        name="additionalDescription"
                        component={formFieldType.textarea}
                        maxLength={1500}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.ServiceGroup.Labels.HourlyPrice}
                        name="hourlyPrice"
                        component={formFieldType.number}
                        disabled={props.submitting}
                        validate={required}
                    />
                    <FormField
                        inline
                        title={i18.ServiceGroup.Labels.HintText}
                        name="hintText"
                        component={formFieldType.input}
                        maxLength={1500}
                        disabled={props.submitting}
                        validate={required}
                    />
                    <FormField
                        inline
                        title={i18.ServiceGroup.Labels.SearchKeywords}
                        name="searchKeywords"
                        component={formFieldType.textarea}
                        maxLength={6000}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.ServiceGroup.Labels.Order}
                        name="order"
                        component={formFieldType.number}
                        maxLength={1500}
                        disabled={props.submitting}
                        formatNumber={formatNumber().floatPositiveUpTo2Decimal}
                        validate={required}
                    />
                    <FormField
                        inline
                        name="alwaysShowInSearch"
                        component={formFieldType.checkbox}
                        title={i18.ServiceGroup.Labels.AlwaysShowInSearch}
                    />
                    <div className="text-right">
                        <Button type="primary" htmlType="submit" loading={props.submitting}>
                            <Translate value={i18.ServiceGroup.Buttons.Submit} />
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

ServiceGroupEdit.propTypes = {
    serviceGroup: PropTypes.object,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool
};
