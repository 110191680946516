import React, { useCallback } from 'react';
import { Button } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { CustomModal, Translate } from '../components';
import { i18, translate } from '../utilities';

export const useServiceItemModal = () => {
    return useCallback((serviceCatalogId, serviceName, jobId, jobTitle, jobDescription) => {
        const valuesToCombine = [
            `${translate(i18.ServiceItemsCreate.Labels.InformModalServiceId)} ${serviceCatalogId}`,
            `${translate(i18.ServiceItemsCreate.Labels.InformModalService)} ${serviceName}`,
            `${translate(i18.ServiceItemsCreate.Labels.InformModalJobId)} ${jobId}`,
            `${translate(i18.ServiceItemsCreate.Labels.InformModalJobTitle)} ${jobTitle}`
        ];
        if (jobDescription !== undefined && jobDescription !== null) {
            valuesToCombine.push(`${translate(i18.ServiceItemsCreate.Labels.InformModalJobDescription)} ${jobDescription}`);
        }

        const combinedText = valuesToCombine.join('\n');

        const modalContent = (
            <div className="d-flex justify-content-center flex-column text-center align-items-center">
                <Translate
                    className="fs-18 font-weight-bold mb-4"
                    value={i18.ServiceItemsCreate.Labels.InformModalTitle}
                />
                <Translate
                    className="fs-16 font-weight-bold mb-4"
                    value={i18.ServiceItemsCreate.Labels.InformModalDescription}
                />
                <Translate
                    className="fs-16 font-weight-bold mb-4"
                    value={i18.ServiceItemsCreate.Labels.InformModalCopy}
                />
                <Button
                    shape="circle"
                    icon={<LinkOutlined />}
                    type="secondary"
                    className="mb-4"
                    onClick={() => navigator.clipboard.writeText(combinedText)}
                />
                {valuesToCombine.map((value, index) => (
                    <Translate key={index} className="mb-4" value={value} />
                ))}
            </div>
        );

        CustomModal.open({
            content: modalContent,
            okText: translate(i18.ServiceItemsCreate.Buttons.InformModalClose)
        });
    }, []);
};
