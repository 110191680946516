import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FilterOutlined, UserAddOutlined } from '@ant-design/icons';
import ScrollContainer from 'react-indiana-drag-scroll';

import { i18, oDataQuery, routerPaths } from '../../utilities';
import { history, odataActions } from '../../api';
import { ButtonGroup, ButtonWrapper, ContentHeader, Translate } from '../../components';
import { InternalClientsTable } from './components';

class InternalClientsOdataContainer extends React.Component {
    static defaultProps = {
        defaultFilter: {
            filters: [],
            orders: [
                {
                    name: 'CreationDateTime',
                    order: 'desc'
                }
            ],
            pagination: {
                current: 1,
                pageSize: 100
            }
        }
    }

    constructor (props) {
        super(props);

        this.state = this.props.history.location.state || this.props.defaultFilter;

        this.setFilter = this.setFilter.bind(this);
        this.setDefaultFilter = this.setDefaultFilter.bind(this);
        this.setOrderAndPagination = this.setOrderAndPagination.bind(this);
    }

    componentDidMount () {
        this.fetch();
    }

    fetch () {
        this.props.dispatch(odataActions.getInternalClientsGrid(oDataQuery(this.state)));
    }

    setFilter (filter, resetPagination) {
        this.setState(prevState => ({
            filters: [
                ...prevState.filters.filter(e => e.name !== filter.name),
                ...filter.value !== null ? [filter] : []
            ],
            ...(resetPagination && {
                pagination: this.props.defaultFilter.pagination
            })
        }), () => {
            this.fetch();
        });
    }

    setDefaultFilter () {
        this.setState({
            ...this.props.defaultFilter
        }, () => {
            history.replace();
            this.fetch();
        });
    }

    setOrderAndPagination (pagination, columns, order) {
        const orders = [];

        if (Array.isArray(order)) {
            orders.push(
                ...order.map(item => {
                    return {
                        name: item.columnKey,
                        order: item.order === 'ascend' ? 'asc' : 'desc'
                    };
                })
            );
        } else if (order.order) {
            orders.push({
                name: order.columnKey,
                order: order.order === 'ascend' ? 'asc' : 'desc'
            });
        }

        this.setState({
            orders: orders,
            pagination: {
                current: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.fetch();
        });
    }

    onOpenClient (clientId) {
        history.push(routerPaths.internalClients.index + '/' + clientId, this.state);
    }

    render () {
        return (
            <div>
                <ContentHeader
                    title={
                        <>
                            <Translate
                                className='mr-2'
                                value={i18.InternalClients.Titles.InternalClients}
                            />
                            <span className='fw_400 color-black-50'>({this.props.odataInternalClientsGrid['@odata.count']})</span>
                        </>
                    }
                    extra={
                        <ButtonGroup>
                            {this.state.filters.length > 0 || this.state.orders.length > 0 ? (
                                <ButtonWrapper
                                    icon={<FilterOutlined />}
                                    type='secondary'
                                    onClick={this.setDefaultFilter}
                                >
                                    <Translate value={i18.InternalClients.Buttons.Clear} />
                                </ButtonWrapper>
                            ) : null}
                            <ButtonWrapper
                                icon={<UserAddOutlined />}
                                type='primary'
                                onClick={() => history.push(routerPaths.internalClients.create, this.state)}
                            >
                                <Translate value={i18.InternalClients.Buttons.Add} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    }
                />
                <div className='content_block'>
                    <ScrollContainer
                        style={{
                            overflowX: 'auto'
                        }}
                    >
                        <InternalClientsTable
                            setFilter={this.setFilter}
                            setOrderAndPagination={this.setOrderAndPagination}
                            filters={this.state.filters}
                            orders={this.state.orders}
                            pagination={this.state.pagination}
                            onOpenClient={this.onOpenClient}
                        />
                    </ScrollContainer>
                </div>
            </div>
        );
    }
}

InternalClientsOdataContainer.propTypes = {
    defaultFilter: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    odataInternalClientsGrid: PropTypes.object.isRequired
};

function mapStateToProps (state) {
    const {
        odataInternalClientsGrid
    } = state.odataReducers;

    return {
        odataInternalClientsGrid
    };
}

const connectedInternalClientsOdataContainer = connect(mapStateToProps)(InternalClientsOdataContainer);
export { connectedInternalClientsOdataContainer as InternalClientsOdataContainer };
