export const fieldNames = {
    attachments: 'attachments',

    jobCode: 'jobCode',
    jobStatus: 'jobStatus',
    clientSegment: 'clientSegment',
    biddingType: 'biddingType',
    needApproval: 'bidConfirmationNeeded',
    vatType: 'vatType',
    vatPercentage: 'vatPercentage',
    materialsVatType: 'materialsVatType',
    vatName: 'vatName',
    materialsVatName: 'materialsVatName',

    clientPrice: 'fullPrice',
    partnerReward: 'partnerReward',
    administrationFee: 'administrationFee',
    materialExpenses: 'materialExpenses',
    jobType: 'jobType',
    biddingDateRange: 'biddingDateRange',
    hasJobAdExpired: 'hasJobAdExpired',
    showPriceDuringBidding: 'showPriceDuringBidding',

    serviceId: 'serviceId',
    serviceCode: 'serviceCode',
    serviceGroup: 'serviceGroupCode',
    jobDescription: 'description',
    jobDeadline: 'jobDeadline',
    isPreorderSentToPartners: 'isPreorderSentToPartners',
    isPreorderSentToClient: 'isPreorderSentToClient',

    latitude: 'latitude',
    longitude: 'longitude',
    address: 'objectAddress',
    additionalLocationInfo: 'additionalObjectInformation',

    managerPhone: 'workManagerTelephone',
    managerPhoneNumber: 'workManagerTelephoneNumber',
    managerPhoneNumberPrefix: 'workManagerTelephoneNumberPrefix',
    responsibleManager: 'responsiblePerson',
    contactPhoneNumber: 'contactPhoneNumber',
    contactNationalNumber: 'contactNationalNumber',
    contactNumberPrefix: 'contactTelephonePrefix',
    internalClientName: 'clientName',
    internalClientId: 'clientId',

    partnerId: 'partnerId',
    partnerName: 'partnerFullName',
    creationDate: 'createdAt',

    jobDateRange: 'jobPeriod',
    plannedStartDate: 'plannedStartDate',
    jobDuration: 'duration',
    administratorComment: 'administratorComment',
    jobTrackingUrl: 'jobTrackingUrl',

    client: {
        clientId: 'client.clientId',
        companyName: 'client.companyName',
        isCompany: 'client.isCompany',
        companyCode: 'client.companyCode',
        address: 'client.address',
        vatPayerCode: 'client.vatPayerCode',
        firstName: 'client.firstName',
        lastName: 'client.lastName',
        email: 'client.email',
        invoiceEmail: 'client.invoiceEmail',
        type: 'client.type'
    },
    factualHours: 'factualHours',
    partnerLink: 'partnerLink',

    mainClientName: 'MainClientName',
    mainClientId: 'MainClientId',

    callDate: 'adminCallInfo.clientCalledAt',
    callComment: 'adminCallInfo.comment',
    calledBy: 'adminCallInfo.fullName'
};
