import { requestApi, apiPaths, requestApiFormData, handleResponse, methods } from '../../config';

function putServiceItemsData(serviceItemId, data) {
    return requestApi(apiPaths.serviceItems + '/' + serviceItemId, {
        method: methods.put,
        data: data
    }).then(handleResponse);
}

function postServiceItems(data) {
    return requestApiFormData(apiPaths.serviceItems, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postServiceItemsSort(serviceGroupCode, data) {
    return requestApi(apiPaths.services + '/' + serviceGroupCode + '/service-items', {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function getServiceItems(serviceGroupCode) {
    return requestApi(apiPaths.services + '/' + serviceGroupCode + '/service-items').then(handleResponse);
}

function patchServiceItemStatus(serviceItemId, data) {
    return requestApi(apiPaths.serviceItems + '/' + serviceItemId + '/changeStatus', {
        method: methods.patch,
        data: data
    }).then(handleResponse);
}

function postServiceItemsV2(data) {
    return requestApi(apiPaths.serviceItemsV2, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

export const servicesServiceItems = {
    getServiceItems,
    postServiceItems,
    postServiceItemsSort,
    putServiceItemsData,
    patchServiceItemStatus,
    postServiceItemsV2
};
