export const countrySettingsConstants = {
    GET_COUNTRY_SETTINGS_REQUEST: 'GET_COUNTRY_SETTINGS_REQUEST',
    GET_COUNTRY_SETTINGS_SUCCESS: 'GET_COUNTRY_SETTINGS_SUCCESS',
    GET_COUNTRY_SETTINGS_ERROR: 'GET_COUNTRY_SETTINGS_ERROR',

    POST_COUNTRY_SETTINGS_REQUEST: 'POST_COUNTRY_SETTINGS_REQUEST',
    POST_COUNTRY_SETTINGS_SUCCESS: 'POST_COUNTRY_SETTINGS_SUCCESS',
    POST_COUNTRY_SETTINGS_ERROR: 'POST_COUNTRY_SETTINGS_ERROR',

    PUT_COUNTRY_SETTINGS_REQUEST: 'PUT_COUNTRY_SETTINGS_REQUEST',
    PUT_COUNTRY_SETTINGS_SUCCESS: 'PUT_COUNTRY_SETTINGS_SUCCESS',
    PUT_COUNTRY_SETTINGS_ERROR: 'PUT_COUNTRY_SETTINGS_ERROR',

    GET_COUNTRY_SETTINGS_BY_ID_REQUEST: 'GET_COUNTRY_SETTINGS_BY_ID_REQUEST',
    GET_COUNTRY_SETTINGS_BY_ID_SUCCESS: 'GET_COUNTRY_SETTINGS_BY_ID_SUCCESS',
    GET_COUNTRY_SETTINGS_BY_ID_ERROR: 'GET_COUNTRY_SETTINGS_BY_ID_ERROR'
};
