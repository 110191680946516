import { Environments } from './configs/environments';

const localEnv = {
    REACT_APP_API_URL: 'https://apitest.portalpro.lt/api/',
    REACT_APP_BFF_API_URL: 'https://adm-bff-api-test.portalpro.lt/api/',
    REACT_APP_ODATA_URL: 'https://apitest.portalpro.lt/odata/',
    // REACT_APP_API_URL: 'http://localhost:2001/api/',
    // REACT_APP_BFF_API_URL: 'http://localhost:7100/api/',
    // REACT_APP_ODATA_URL: 'http://localhost:2001/odata/',
    REACT_APP_DEFAULT_COUNTRY: 'LT',
    REACT_APP_DEFAULT_LANGUAGE: 'LT',
    REACT_APP_RECAPTCHA: '6Lf7wAsaAAAAAGcB2i29ffadJIb4QP5C1CtdSUQO',
    REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyDigR-ScWsSJAMV7o5-5K2-x5hq-OVPNZc',
    REACT_APP_USE_BFF_API: 'true'
};

if (process.env.NODE_ENV === Environments.Development) {
    window.env = localEnv;
}

if (process.env.REACT_APP_ENGINE === Environments.DockerCompose) {
    window.env = process.env;
}

export const env = {
    REACT_APP_API_URL: getEnvironmentVariable('REACT_APP_API_URL'),
    REACT_APP_BFF_API_URL: getEnvironmentVariable('REACT_APP_BFF_API_URL'),
    REACT_APP_ODATA_URL: getEnvironmentVariable('REACT_APP_ODATA_URL'),
    REACT_APP_DEFAULT_COUNTRY: getEnvironmentVariable('REACT_APP_DEFAULT_COUNTRY'),
    REACT_APP_DEFAULT_LANGUAGE: getEnvironmentVariable('REACT_APP_DEFAULT_LANGUAGE'),
    REACT_APP_RECAPTCHA: getEnvironmentVariable('REACT_APP_RECAPTCHA'),
    REACT_APP_GOOGLE_MAPS_API_KEY: getEnvironmentVariable('REACT_APP_GOOGLE_MAPS_API_KEY'),
    REACT_APP_USE_BFF_API: getEnvironmentVariable('REACT_APP_USE_BFF_API')
};

function getEnvironmentVariable(key) {
    const windowEnv = window.env[key];
    var windowEnvPlaceholder = windowEnv.match(/#{(.+)}#/);

    if (windowEnvPlaceholder) {
        console.error('Environment variable ' + windowEnvPlaceholder[1] + ' is not defined!');
        return;
    }

    return windowEnv;
}
