import React from 'react';
import PropTypes from 'prop-types';

import { i18 } from '../../../utilities';
import { JobFiles, JobImages } from './';
import { FormField, formFieldType, Translate } from '../../../components';
import { fieldNames } from '../fieldNames';

export const JobAttachments = props => {
    return (
        <>
            <div className="mb-5">
                <JobImages photos={props.photosBefore || []} title={i18.Job.Titles.PhotosBefore} />
            </div>
            <div className="mb-5">
                <JobImages photos={props.photosAfter || []} title={i18.Job.Titles.PhotosAfter} />
            </div>
            <div>
                <JobFiles files={props.files || []} title={i18.Job.Titles.Files} />
            </div>
            <div>
                <JobFiles files={props.partnerFiles || []} title={i18.Job.Titles.PartnerFiles} />
            </div>
            {props.fileUploadEnabled && (props.isJobEditable || props.isJobFilesEditable) && (
                <div>
                    <FormField
                        component={formFieldType.upload}
                        accept="image/jpeg, image/png, image/heic, application/pdf, .heic"
                        name={fieldNames.attachments}
                        maxFileSizeInBytes={props.fileUploadSettings.maxFileSizeInMb * 1024 * 1024}
                        multipleFiles
                        maxCount={props.fileUploadSettings.maxFileCount}
                        showUploadList
                    />
                </div>
            )}
            <div>
                <JobFiles
                    files={
                        props.jobScope?.fileName && props.jobScope?.fileUrl
                            ? [{ fileName: props.jobScope?.fileName, documentUrl: props.jobScope?.fileUrl }]
                            : []
                    }
                    title={i18.Job.Titles.JobScopeFile}
                />
            </div>
            {props.jobScope?.description && (
                <div>
                    <Translate className="fs-16 fw_600" value={i18.Job.Titles.JobScopeDescription} />
                    <div className="list_files mt-3">
                        <text>{props.jobScope.description}</text>
                    </div>
                </div>
            )}
            <div className="mt-3">
                <JobImages photos={props.materialsPhotos || []} title={i18.Job.Titles.MaterialFiles} />
            </div>
        </>
    );
};

JobAttachments.propTypes = {
    photosBefore: PropTypes.array,
    photosAfter: PropTypes.array,
    materialsPhotos: PropTypes.array,
    files: PropTypes.array,
    partnerFiles: PropTypes.array,
    fileUploadSettings: PropTypes.object,
    fileUploadEnabled: PropTypes.bool,
    isJobEditable: PropTypes.bool,
    isJobFilesEditable: PropTypes.bool,
    jobScope: PropTypes.object
};
