import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Popconfirm } from 'antd';

import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../components';
import {
    accountTypes,
    classificatorTranslatedArray,
    composeValidators,
    i18,
    i18clGroups,
    required,
    requiredEmail,
    requiredPhone
} from '../../../utilities';

const InternalClientsCreateForm = (props) => {
    return (
        <Form
            onSubmit={props.submit}
            render={({ handleSubmit, values, form }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <FormField
                        inline
                        title={i18.InternalClientsCreateForm.Labels.Type}
                        name='type'
                        component={formFieldType.select}
                        validate={required}
                        options={classificatorTranslatedArray(i18clGroups.TYPES_ACCOUNT)}
                        defaultValue={classificatorTranslatedArray(i18clGroups.TYPES_ACCOUNT)[0]?.value ||
                                      classificatorTranslatedArray(i18clGroups.TYPES_ACCOUNT)[0]?.label}
                        disabled={props.submitting}
                    />
                    {values.type === accountTypes.JURIDICAL && (
                        <>
                            <FormField
                                inline
                                title={i18.InternalClientsCreateForm.Labels.CompanyName}
                                name='companyName'
                                component={formFieldType.input}
                                validate={required}
                                maxLength={50}
                                disabled={props.submitting}
                            />
                            <FormField
                                inline
                                title={i18.InternalClientsCreateForm.Labels.CompanyCode}
                                name='companyCode'
                                component={formFieldType.input}
                                validate={required}
                                maxLength={30}
                                disabled={props.submitting}
                            />
                            <FormField
                                inline
                                title={i18.InternalClientsCreateForm.Labels.VatPayerCode}
                                name='vatPayerCode'
                                component={formFieldType.input}
                                validate={required}
                                maxLength={30}
                                disabled={props.submitting}
                            />
                        </>
                    )}
                    {values.type === accountTypes.PHYSICAL && (
                        <>
                            <FormField
                                inline
                                title={i18.InternalClientsCreateForm.Labels.FirstName}
                                name='firstName'
                                component={formFieldType.input}
                                validate={required}
                                maxLength={25}
                                disabled={props.submitting}
                            />
                            <FormField
                                inline
                                title={i18.InternalClientsCreateForm.Labels.LastName}
                                name='lastName'
                                component={formFieldType.input}
                                validate={required}
                                maxLength={25}
                                disabled={props.submitting}
                            />
                        </>
                    )}
                    <FormField
                        inline
                        title={i18.InternalClientsCreateForm.Labels.Address}
                        name='address'
                        component={formFieldType.input}
                        validate={required}
                        maxLength={100}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.InternalClientsCreateForm.Labels.Phone}
                        name="phone"
                        component={formFieldType.phone}
                        validate={requiredPhone({ nullable: true })}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.InternalClientsCreateForm.Labels.Email}
                        name='email'
                        component={formFieldType.input}
                        validate={requiredEmail}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.InternalClientsCreateForm.Labels.InvoiceEmail}
                        name='invoiceEmail'
                        component={formFieldType.input}
                        validate={composeValidators(required, requiredEmail)}
                        disabled={props.submitting}
                    />
                    <div className='text-right'>
                        <ButtonGroup>
                            <ButtonWrapper
                                type='text'
                                disabled={props.submitting}
                                onClick={() => props.cancel()}
                            >
                                <Translate value={i18.InternalClientsCreateForm.Buttons.Cancel} />
                            </ButtonWrapper>
                            <Popconfirm
                                key='create'
                                placement='left'
                                onConfirm={() => form.submit()}
                                title={<Translate value={i18.InternalClientsCreateForm.Labels.ConfirmationTitle} />}
                                okText={<Translate value={i18.InternalClientsCreateForm.Labels.ConfirmationOkText} />}
                                cancelText={<Translate value={i18.InternalClientsCreateForm.Labels.ConfirmationCancelText} />}
                            >
                                <ButtonWrapper
                                    type='primary'
                                    htmlType='submit'
                                    loading={props.submitting}
                                >
                                    <Translate value={i18.InternalClientsCreateForm.Buttons.Submit} />
                                </ButtonWrapper>
                            </Popconfirm>

                        </ButtonGroup>
                    </div>
                </form>
            )}
        />
    );
};

InternalClientsCreateForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};

const connectedInternalClientsCreateForm = connect(null)(InternalClientsCreateForm);
export { connectedInternalClientsCreateForm as InternalClientsCreateForm };
