import { countryVatConstants } from './constants';

export const countryVatReducers = function (state = {
    countryVats: []
}, action) {
    switch (action.type) {
    case countryVatConstants.GET_COUNTRY_VAT_REQUEST:
        return {
            ...state,
            send: true,
            sendCountryVats: true
        };
    case countryVatConstants.GET_COUNTRY_VAT_SUCCESS:
        return {
            ...state,
            countryVats: action.payload,
            send: false,
            sendCountryVats: false
        };
    case countryVatConstants.GET_COUNTRY_VAT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountryVats: false
        };

    case countryVatConstants.POST_COUNTRY_VAT_REQUEST:
        return {
            ...state,
            send: true,
            sendCountryVatPOST: true
        };
    case countryVatConstants.POST_COUNTRY_VAT_SUCCESS:
        return {
            ...state,
            send: false,
            sendCountryVatPOST: false
        };
    case countryVatConstants.POST_COUNTRY_VAT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountryVatPOST: false
        };

    case countryVatConstants.POST_VAT_REQUEST:
        return {
            ...state,
            send: true,
            sendCountryVatPOST: true
        };
    case countryVatConstants.POST_VAT_SUCCESS:
        return {
            ...state,
            send: false,
            sendCountryVatPOST: false
        };
    case countryVatConstants.POST_VAT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountryVatPOST: false
        };

    case countryVatConstants.PUT_VAT_REQUEST:
        return {
            ...state,
            send: true,
            sendCountryVatPUT: true
        };
    case countryVatConstants.PUT_VAT_SUCCESS:
        return {
            ...state,
            send: false,
            sendCountryVatPUT: false
        };
    case countryVatConstants.PUT_VAT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountryVatPUT: false
        };
    case countryVatConstants.DELETE_VAT_REQUEST:
        return {
            ...state,
            send: true,
            sendCountryVatDELETE: true
        };
    case countryVatConstants.DELETE_VAT_SUCCESS:
        return {
            ...state,
            send: false,
            sendCountryVatDELETE: false
        };
    case countryVatConstants.DELETE_VAT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendCountryVatDELETE: false
        };
    default:
        return state;
    }
};
