import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import { Error, Title } from './';

export const SwitchFormField = props => {
    let switchContainerStyles;

    if (props.justify) {
        switchContainerStyles = 'form_field col-3 d-flex justify-content-end';
    } else if (props.inline) {
        switchContainerStyles = 'form_field col-12 col-sm-12 col-md-4';
    } else {
        switchContainerStyles = 'form_field col-12';
    }

    return (
        <div className={props.inline ? 'form_field_wrapper row align-items-center' : 'form_field_wrapper row'}>
            <Title
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
                justify={props.justify}
            />
            <div className={switchContainerStyles}>
                <Switch defaultChecked={props.input.value} onChange={props.input.onChange} disabled={props.disabled} />
            </div>
            <Error touched={props.meta.touched} error={props.meta.error} />
        </div>
    );
};

SwitchFormField.propTypes = {
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    }),
    justify: PropTypes.bool
};
