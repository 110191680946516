import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, FormField, formFieldType } from '../../../../components';
import { fieldNames } from '../../fieldNames';
import { JobsStatusConstants, JobVatTypeConstants } from '../../../../api';
import { classificatorTranslatedArray, i18, i18cl, i18clGroups, getContentString } from '../../../../utilities';
class EntryFormSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedJobStatus: {
                jobStatuses: undefined,
                vatTypes: undefined,
                defaultJobStatus: undefined,
                defaultVatType: undefined
            }
        };

        this.loadVatTypesOptions = this.loadVatTypesOptions.bind(this);
    }

    componentDidMount() {
        this.loadVatTypesOptions();
        this.loadJobStatusOptions();
    }

    loadJobStatusOptions() {
        const work = classificatorTranslatedArray(i18clGroups.STATUSES_WORK);
        const order = classificatorTranslatedArray(i18clGroups.STATUSES_ORDER);
        const merged = [...work, ...order];
        const cleared = merged.filter((item, index, self) => index === self.findIndex(e => e.value === item.value));

        const defaultJobStatus = cleared.find(statusOption => {
            return statusOption.value === JobsStatusConstants.NEW;
        }).value;

        this.setState({
            jobStatuses: cleared,
            defaultJobStatus: defaultJobStatus
        });
    }

    loadVatTypesOptions() {
        const vatTypes = classificatorTranslatedArray(i18cl.JOB_VAT_TYPES.replace(/\.$/, ''));

        const defaultVatType = vatTypes.find(vatType => {
            return vatType.value === JobVatTypeConstants.STANDARD;
        }).value;

        this.setState({
            vatTypes: vatTypes,
            defaultVatType: defaultVatType
        });
    }

    render() {
        return (
            <>
                <div>
                    <div className="container-default mt-5">
                        <div className="row">
                            <div className="col-12">
                                <FormField
                                    title={i18.Job.Labels.JobCode}
                                    borderTitle
                                    name={fieldNames.jobCode}
                                    component={formFieldType.input}
                                    disabled
                                    dimmed={this.props.isJobEditable}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <FormField
                                    title={i18.Job.Labels.Status}
                                    borderTitle
                                    name={fieldNames.jobStatus}
                                    component={formFieldType.select}
                                    defaultValue={this.state.defaultJobStatus}
                                    options={this.state.jobStatuses}
                                    disabled
                                    dimmed={this.props.isJobEditable}
                                />
                            </div>
                        </div>
                        {!this.props.isJobEditable && (
                            <>
                                <div className="row">
                                    <div className="col-6 d-flex mt-4">
                                        <FormField
                                            name={fieldNames.isPreorderSentToPartners}
                                            component={formFieldType.checkbox}
                                            disabled
                                        />
                                        <Translate
                                            className="text-secondary"
                                            value={i18.Job.Labels.IsPreorderSentToPartners}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 d-flex mt-4">
                                        <FormField
                                            name={fieldNames.isPreorderSentToClient}
                                            component={formFieldType.checkbox}
                                            disabled
                                        />
                                        <Translate
                                            className="text-secondary"
                                            value={i18.Job.Labels.IsPreorderSentToClient}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="row">
                            <div className="col-12">
                                <FormField
                                    title={i18.Job.Labels.ClientSegment}
                                    borderTitle
                                    name={fieldNames.clientSegment}
                                    component={formFieldType.select}
                                    options={classificatorTranslatedArray(i18clGroups.CLIENT_SEGMENTS)}
                                    disabled
                                    dimmed={this.props.isJobEditable}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 d-flex mt-4">
                                <FormField name={fieldNames.needApproval} component={formFieldType.checkbox} disabled />
                                <Translate
                                    className={this.props.isJobEditable ? 'ant-input-dimmed' : 'text-secondary'}
                                    value={i18.Job.Labels.NeedJobApproval}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <FormField
                                    title={i18.Job.Labels.VatName}
                                    borderTitle
                                    component={formFieldType.select}
                                    name={fieldNames.vatName}
                                    disabled={!this.props.isJobEditable}
                                    highlighted={this.props.isJobEditable}
                                    options={
                                        this.props.job?.availableVats?.map(vat => ({
                                            value: vat.name,
                                            label: vat.name
                                        })) ?? []
                                    }
                                />
                            </div>
                            <div className="col-6">
                                <FormField
                                    title={i18.Job.Labels.VatType}
                                    borderTitle
                                    name={fieldNames.vatType}
                                    component={formFieldType.select}
                                    defaultValue={this.state.defaultVatType}
                                    options={this.state.vatTypes}
                                    disabled={!this.props.isJobEditable}
                                    highlighted={this.props.isJobEditable}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <FormField
                                    title={i18.Job.Labels.MaterialVatName}
                                    borderTitle
                                    component={formFieldType.select}
                                    name={fieldNames.materialsVatName}
                                    disabled={!this.props.isJobEditable}
                                    highlighted={this.props.isJobEditable}
                                    options={
                                        this.props.job?.availableMaterialVats?.map(vat => ({
                                            value: vat.name,
                                            label: vat.name
                                        })) ?? []
                                    }
                                />
                            </div>
                            <div className="col-6">
                                <FormField
                                    title={i18.Job.Labels.MaterialVatType}
                                    borderTitle
                                    name={fieldNames.materialsVatType}
                                    component={formFieldType.select}
                                    defaultValue={this.state.defaultVatType}
                                    options={this.state.vatTypes}
                                    disabled={!this.props.isJobEditable}
                                    highlighted={this.props.isJobEditable}
                                />
                            </div>
                        </div>
                        {this.props.job[fieldNames.biddingType] && (
                            <div className="row justify-content-end">
                                <div className="col-6">
                                    <FormField
                                        title={i18.Job.Labels.BiddingType}
                                        borderTitle
                                        name={fieldNames.biddingType}
                                        component={formFieldType.select}
                                        options={classificatorTranslatedArray(i18clGroups.BIDDING_TYPES)}
                                        disabled
                                        dimmed={this.props.isJobEditable}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

EntryFormSection.propTypes = {
    isJobEditable: PropTypes.bool.isRequired,
    job: PropTypes.object,
    vats: PropTypes.array
};

function mapStateToProps(state) {
    const { isJobEditable } = state.localJobReducers;
    const countryVats = state.countryVatReducers.countryVats;

    const { job } = state.jobReducers;

    return {
        isJobEditable,
        job: job?.value || {},
        vats: countryVats?.vats ?? []
    };
}

const connectedEntryFormSection = connect(mapStateToProps)(EntryFormSection);
export { connectedEntryFormSection as EntryFormSection };
