import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { i18, routerPaths } from '../../utilities';
import { history, partnerProfileActions, partnerProfileConstants } from '../../api';
import { ContentHeader, Translate } from '../../components';
import { PartnersCreateForm } from './';

class PartnersCreateContainer extends React.Component {
    constructor (props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    submit (values) {
        this.props.dispatch(partnerProfileActions.postProfile(values)).then(res => {
            if (res && res.type === partnerProfileConstants.POST_PARTNER_PROFILE_SUCCESS) {
                history.push(routerPaths.partners.index + '/' + res.payload);
            }
        });
    }

    render () {
        return (
            <div>
                <ContentHeader
                    title={<Translate value={i18.PartnersCreate.Titles.PartnersCreate} />}
                    back={routerPaths.partners.index}
                    backState={this.props.history.location.state}
                />
                <div className='content_block'>
                    <PartnersCreateForm
                        cancel={() => history.push(routerPaths.partners.index, this.props.history.location.state)}
                        submit={this.submit}
                        submitting={this.props.sendPartnerProfilePOST}
                    />
                </div>
            </div>
        );
    }
}

PartnersCreateContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    sendPartnerProfilePOST: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        sendPartnerProfilePOST
    } = state.partnerProfileReducers;

    return {
        sendPartnerProfilePOST
    };
}

const connectedPartnersCreateContainer = connect(mapStateToProps)(PartnersCreateContainer);
export { connectedPartnersCreateContainer as PartnersCreateContainer };
