import './polyfill';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { LoadScript } from '@react-google-maps/api';

import { store, history } from './api';
import { App } from './app';
import { env } from './configs';

import './style/libs/bootstrap.less';
import './style/helpers/fonts.less';
import './style/buttons.less';
import './style/content.less';
import './style/editor.less';
import './style/filters.less';
import './style/forms.less';
import './style/header.less';
import './style/images.less';
import './style/lists.less';
import './style/login.less';
import './style/main.less';
import './style/menu.less';
import './style/variables.less';
import './style/antd.less';
import './utilities/recaptcha';

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <LoadScript googleMapsApiKey={env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['places']}>
                <App />
            </LoadScript>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
