import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReCaptcha } from 'react-recaptcha-v3';
import { Form } from 'react-final-form';

import { i18, requiredEmail, routerPaths } from '../../../utilities';
import { history, requestApi, requestApiFormData, userActions, userConstants } from '../../../api';
import { ButtonPrimary, FormField, formFieldType, Translate } from '../../../components';
import { env } from '../../../configs';

class ResetPasswordForm extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            recaptchaLoaded: false
        };

        this.reCaptchaCallback = this.reCaptchaCallback.bind(this);
        this.submit = this.submit.bind(this);
    }

    reCaptchaCallback (token) {
        this.setState({
            recaptchaLoaded: true
        });

        requestApi.defaults.headers.common.captchaToken = token;
        requestApiFormData.defaults.headers.common.captchaToken = token;
    }

    submit (values) {
        this.props.dispatch(userActions.postResetPassword(values)).then(res => {
            if (res && res.type === userConstants.POST_RESET_PASSWORD_SUCCESS) {
                history.push(routerPaths.public.resetPasswordSuccess);
            } else {
                this.setState({
                    recaptchaLoaded: false
                });
            }
        });
    }

    render () {
        return (
            <Form
                onSubmit={this.submit}
                render={({ handleSubmit }) => (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete='off'
                    >
                        {!this.state.recaptchaLoaded && (
                            <ReCaptcha
                                sitekey={env.REACT_APP_RECAPTCHA}
                                verifyCallback={this.reCaptchaCallback}
                            />
                        )}
                        <div className='mb-4'>
                            <FormField
                                component={formFieldType.input}
                                title={i18.Reset.Labels.Email}
                                disabled={this.props.sendUserResetPasswordPOST}
                                name='email'
                                validate={requiredEmail}
                                size='large'
                            />
                        </div>
                        <div className='mb-4 text-center'>
                            <ButtonPrimary
                                block
                                htmlType='submit'
                                loading={this.props.sendUserResetPasswordPOST}
                                size='large'
                            >
                                <Translate value={i18.Reset.Buttons.Submit} />
                            </ButtonPrimary>
                        </div>
                        <div className='public_hint mb-4'>
                            <Translate value={i18.Reset.Labels.Submit} />
                        </div>
                    </form>
                )}
            />
        );
    };
}

ResetPasswordForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    sendUserResetPasswordPOST: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        sendUserResetPasswordPOST
    } = state.userReducers;

    return {
        sendUserResetPasswordPOST
    };
}

const connectedResetPasswordForm = connect(mapStateToProps)(ResetPasswordForm);
export { connectedResetPasswordForm as ResetPasswordForm };
