import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { countrySettingsActions, countrySettingsConstants } from '../../../api';
import { getCurrentDate, i18, i18cl, required, classificator } from '../../../utilities';
import { Classificator, FormField, formFieldType, Translate } from '../../../components';
import { settingInputType } from './fields';

class CountriesSettingsEditForm extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            visible: false
        };

        this.toggle = this.toggle.bind(this);
        this.submit = this.submit.bind(this);
    }

    toggle (state) {
        if (state) {
            this.props.dispatch(countrySettingsActions.getCountrySettingsById(this.props.countrySettingId)).then(res => {
                if (res && res.type === countrySettingsConstants.GET_COUNTRY_SETTINGS_BY_ID_SUCCESS) {
                    this.setState({ visible: true });
                }
            });
        } else {
            this.setState({ visible: false });
        }
    }

    submit (values) {
        const classificator = this.props.countrySettingsById?.classificatorList.find(e => e.classificatorCode === values.classificatorCode);

        values = {
            ...values,
            field: classificator?.field,
            classificatorCodeForValues: classificator?.classificatorCodeForValues
        };

        this.props.dispatch(countrySettingsActions.putCountrySettings(values)).then(res => {
            if (res && res.type === countrySettingsConstants.PUT_COUNTRY_SETTINGS_SUCCESS) {
                this.toggle();
                this.props.dispatch(countrySettingsActions.getCountrySettings());
            }
        });
    }

    title () {
        const { countrySettingsById } = this.props;

        if (countrySettingsById && countrySettingsById.classificatorList) {
            const classificatorItem = countrySettingsById.classificatorList.find(e => e.classificatorCode === countrySettingsById.classificatorCode);

            if (classificatorItem && classificatorItem.classificatorCodeForValues) {
                if (this.props.countrySettingsById.field === settingInputType.multiselect) {
                    const values = JSON.parse(countrySettingsById.value);

                    return values.map((value, index) => {
                        if (index > 0 && index < values.length) {
                            return `, ${classificator(classificatorItem.classificatorCodeForValues + '.' + value)}`;
                        }

                        return classificator(classificatorItem.classificatorCodeForValues + '.' + value);
                    });
                }

                return <Classificator value={classificatorItem.classificatorCodeForValues + '.' + countrySettingsById.value} />;
            }

            return countrySettingsById.value;
        }

        return countrySettingsById.value;
    }

    render () {
        return (
            <>
                <Button
                    type='link'
                    icon={<EditOutlined />}
                    loading={this.props.sendCountrySettingsId === this.props.countrySettingId}
                    disabled={this.props.sendCountrySettingsById}
                    onClick={() => this.toggle(true)}
                >
                    <Translate value={i18.CountrySettingsEditForm.Buttons.Edit} />
                </Button>
                <Modal
                    title={
                        this.props.countrySettingsById && (
                            <>
                                <div>{this.title()}</div>
                                <Classificator
                                    className='fs-14 color-secondary'
                                    value={i18cl.SETTINGS_COUNTRY + this.props.countrySettingsById.classificatorCode}
                                />
                            </>
                        )
                    }
                    visible={this.state.visible}
                    onCancel={() => this.toggle(false)}
                    footer={null}
                    maskClosable={false}
                >
                    <Form
                        onSubmit={this.submit}
                        initialValues={this.props.countrySettingsById}
                        render={({ handleSubmit, values }) => (
                            <form
                                onSubmit={handleSubmit}
                                autoComplete='off'
                            >
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.CountrySettingsEditForm.Labels.ValidFrom}
                                    name='validFrom'
                                    validate={required}
                                    disabled={getCurrentDate() > values.validFrom || this.props.sendCountrySettingsPUT}
                                    minDate={{
                                        date: getCurrentDate()
                                    }}
                                    maxDate={{
                                        date: values.validTo
                                    }}
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.CountrySettingsEditForm.Labels.ValidTo}
                                    name='validTo'
                                    disabled={this.props.sendCountrySettingsPUT}
                                    minDate={{
                                        date: values.validFrom || getCurrentDate()
                                    }}
                                />
                                <div className='text-right'>
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        loading={this.props.sendCountrySettingsPUT}
                                    >
                                        <Translate value={i18.CountrySettingsEditForm.Buttons.Submit} />
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </Modal>
            </>
        );
    }
}

CountriesSettingsEditForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    countrySettingId: PropTypes.number,
    countrySettingsById: PropTypes.object,
    sendCountrySettingsId: PropTypes.number,
    sendCountrySettingsById: PropTypes.bool,
    sendCountrySettingsPUT: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        countrySettingsById,
        sendCountrySettingsById,
        sendCountrySettingsPOST
    } = state.countrySettingsReducers;

    return {
        countrySettingsById,
        sendCountrySettingsById,
        sendCountrySettingsPOST
    };
}

const connectedCountriesSettingsEditForm = connect(mapStateToProps)(CountriesSettingsEditForm);
export { connectedCountriesSettingsEditForm as CountriesSettingsEditForm };
