import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Input, Table } from 'antd';

import { checkJsonProp, i18 } from '../../utilities';
import { translationActions } from '../../api';
import { ContentHeader, Translate } from '../../components';
import { TranslatesClassificatorsFilter } from './';
import { env } from '../../configs';

class TranslatesClassificatorsContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            filter: {},
            language: env.REACT_APP_DEFAULT_LANGUAGE,
            classificatorType: '',
            translates: [],
            translatesEN: []
        };

        this.filter = this.filter.bind(this);
        this.submit = this.submit.bind(this);
        this.change = this.change.bind(this);
    }

    componentDidMount () {
        this.props.dispatch(translationActions.getClassificationGroups());
    }

    filter (values) {
        this.setState({
            filter: values.filter,
            language: values.language,
            translate: []
        });

        this.props.dispatch(translationActions.getClassifications(values.language, values.filter)).then(res => {
            if (res && res.payload) {
                this.setState({
                    translates: res.payload.data[values.filter.group],
                    classificatorType: res.payload.type
                });
            }
        });

        this.props.dispatch(translationActions.getClassifications('EN', values.filter)).then(res => {
            if (res && res.payload) {
                this.setState({
                    translatesEN: res.payload.data[values.filter.group],
                    classificatorType: res.payload.type
                });
            }
        });
    }

    submit (data) {
        this.props.dispatch(translationActions.putClassification(this.state.language, data));
    }

    change (value, label) {
        this.setState(prevState => ({
            translates: {
                ...prevState.translates,
                [label]: value
            }
        }));
    }

    render () {
        const columns = [
            {
                title: <Translate value={i18.Translates.Labels.Key} />,
                dataIndex: 'label'
            }, {
                title: <Translate value={i18.Translates.Labels.Default} />,
                render: (index, item) => (
                    <div>{checkJsonProp(this.state.translatesEN, item.label)}</div>
                ),
                className: this.state.language === 'EN' ? 'd-none' : ''
            }, {
                title: <Translate value={i18.Translates.Labels.Translate} />,
                dataIndex: 'value',
                render: (index, item) => (
                    <Input
                        value={item.value}
                        onChange={({ target: { value } }) => this.change(value, item.label)}
                        onBlur={({ target: { value } }) => this.submit({
                            groupName: this.state.filter.group,
                            code: item.label,
                            translation: value,
                            type: this.state.classificatorType
                        })}
                    />
                )
            }
        ];
        const translates = Object.entries(this.state.translates).map(([label, value]) => ({
            label,
            value
        }));

        return (
            <>
                <ContentHeader
                    title={<Translate value={i18.Translates.Titles.Classificators} />}
                />
                <div className='content_block'>
                    <TranslatesClassificatorsFilter
                        filter={{
                            language: this.state.language,
                            filter: this.state.filter
                        }}
                        groups={this.props.translationClassificationsGroups}
                        languages={this.props.publicLanguages}
                        submit={this.filter}
                        submitting={this.props.sendTranslateClassificators}
                    />
                    <div className='content_box'>
                        <Table
                            columns={columns}
                            dataSource={translates}
                            rowKey='label'
                            size='small'
                            pagination={false}
                        />
                    </div>
                </div>
            </>
        );
    };
}

TranslatesClassificatorsContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    publicLanguages: PropTypes.array.isRequired,
    translationClassificationsGroups: PropTypes.array.isRequired,
    sendTranslateClassificators: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        publicLanguages
    } = state.publicReducers;
    const {
        translationClassificationsGroups,
        sendTranslationClassificationGroups
    } = state.translationReducers;

    return {
        publicLanguages,
        translationClassificationsGroups,
        sendTranslationClassificationGroups
    };
}

const connectedAdminTranslatesClassificatorsContainer = withRouter(connect(mapStateToProps)(TranslatesClassificatorsContainer));
export { connectedAdminTranslatesClassificatorsContainer as TranslatesClassificatorsContainer };
