import React from 'react';

import { Translate } from '../components';
import { i18, getTelephoneLength, personalCodeTypes } from './';
import { getPhoneNumberParts } from './telephoneSettings';

// const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const emailRegex = RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
const passwordRegex = RegExp(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/);
const emailAllowSpacesRegex = RegExp(/^[a-zA-Z0-9_.+-\s]+@[a-zA-Z0-9-\s]+\.[a-zA-Z0-9-.\s]+$/);

export const composeValidators =
    (...validators) =>
    value =>
        validators.reduce((error, validator) => error || validator(value), undefined);
    export const requiredEmailAllowSpaces = value => (emailAllowSpacesRegex.test(value) ? undefined : <Translate value={i18.Forms.Errors.RequiredEmail} />);
export const required = value => isValid(value, i18.Forms.Errors.Required);
export const requiredArray = value =>
    value && value.length > 0 ? undefined : <Translate value={i18.Forms.Errors.RequiredAtLeastOne} />;
export const requiredAvatar = value => (value ? undefined : <Translate value={i18.Forms.Errors.RequiredAvatar} />);
export const requiredEmail = value =>
    value ? emailRegex.test(value) ? undefined : <Translate value={i18.Forms.Errors.RequiredEmail} /> : undefined;
export const requiredIBAN = value =>
    value ? value && value.length > 0 ? undefined : <Translate value={i18.Forms.Errors.RequiredIBAN} /> : undefined;
export const requiredNotUndefined = value =>
    value !== undefined ? undefined : <Translate value={i18.Forms.Errors.Required} />;
export const requiredMeters = value => (value !== undefined ? undefined : true);
export const requiredSamePassword = same => value =>
    same === value ? undefined : <Translate value={i18.Forms.Errors.RequiredSamePassword} />;
export const requiredStrongPassword = value =>
    passwordRegex.test(value) ? undefined : <Translate value={i18.Forms.Errors.RequiredStrongPassword} />;
export const numberMax100 = value =>
    value ? value <= 100 ? undefined : <Translate value={i18.Forms.Errors.RequiredNumberMax100} /> : undefined;
export const nonRequiredPersonalCode = (personalCode, partnerAccount) =>
    isPersonalCodeValid(personalCode, partnerAccount.personalCodeType) ? undefined : (
        <Translate value={i18.Forms.Errors.InvalidPersonalCode} />
    );
export const checkCyrillicLetters = value => /[а-яА-ЯЁё|0-9]/gi.test(value);

export const requiredPhone = valueOrOptions => {
    const validate = (input, options) => {
        const parts = getPhoneNumberParts(input);
        const inputLength = String(input || '').length;

        if (!options?.nullable && (String(parts.prefix).length === inputLength || !inputLength)) {
            return <Translate value={i18.Forms.Errors.RequiredPhoneNumber} />;
        }

        const { min, max } = getTelephoneLength(input);

        if (inputLength > String(parts.prefix).length && inputLength < min) {
            return <Translate value={i18.Forms.Errors.PhoneNumberLength} />;
        }

        if (inputLength > max) {
            return <Translate value={i18.Forms.Errors.PhoneNumberMaxLength} />;
        }

        return undefined;
    };

    if (typeof valueOrOptions === 'object') {
        return input => validate(input, valueOrOptions);
    }

    return validate(valueOrOptions);
};

const isValid = (value, message) => {
    if (!(value === undefined || value === null) || value?.length) {
        return undefined;
    }
    return <Translate value={message} />;
};

function isPersonalCodeValid(personalCode, personalCodeType) {
    if (!personalCode && !personalCodeType) {
        return true;
    }

    const expectedCodeLength = 9;
    if (personalCode?.length !== expectedCodeLength) {
        return false;
    }

    const personalCodeCharArray = [...personalCode];
    if (personalCodeType === personalCodeTypes.DNI) {
        const dniStartsWithNumbers = personalCodeCharArray.slice(0, -1).every(isDigit);
        const dniEndsWithLetter = isLetter(personalCodeCharArray[expectedCodeLength - 1]);

        return dniStartsWithNumbers && dniEndsWithLetter;
    } else if (personalCodeType === personalCodeTypes.NIE) {
        var nieStartsWithLetter = isLetter(personalCodeCharArray[0]);
        var nieHasNumbersInMiddle = personalCodeCharArray.slice(1, -1).every(isDigit);
        var nieEndsWithLetter = isLetter(personalCodeCharArray[expectedCodeLength - 1]);

        return nieStartsWithLetter && nieHasNumbersInMiddle && nieEndsWithLetter;
    }

    return false;
}

const isLetter = char => char.toUpperCase() !== char.toLowerCase();
const isDigit = char => !isNaN(char);
