import React from 'react';
import { Tag } from 'antd';
import { colors } from '../colors';
import { Classificator } from '../../components';
import { i18cl } from '../i18';

export const statusesService = (status) => {
    const classificator = <Classificator value={i18cl.STATUSES_PROFILE_SERVICE + status} />;

    switch (status) {
    case 'VALID':
        return (
            <Tag color={colors.success}>{classificator}</Tag>
        );
    case 'WAITING_FOR_APPROVAL':
        return (
            <Tag color={colors.warning}>{classificator}</Tag>
        );
    case 'NOT_VALID':
        return (
            <Tag color={colors.error}>{classificator}</Tag>
        );
    default:
        return null;
    }
};
