import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import { i18 } from '../../../utilities';
import { Translate } from '../../translate';

export const TextFilterField = (props, confirm) => {
    const [value, setValue] = useState(props.data ? props.data.value : null);
    const reset = () => {
        setValue(null);

        props.setFilter({
            name: props.name,
            type: props.type,
            value: null
        });
    };
    const submit = () => {
        props.setFilter({
            name: props.name,
            type: props.type,
            value: value,
            operator: null
        }, true);
    };

    return (
        <>
            <div className='p-2'>
                <div className='filter_field_wrapper'>
                    <Input
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        onPressEnter={() => {
                            confirm({ closeDropdown: true });
                            submit();
                        }}
                    />
                </div>
            </div>
            <div className='ant-table-filter-dropdown-btns'>
                <Button
                    size='small'
                    type='text'
                    onClick={() => {
                        confirm({ closeDropdown: true });
                        reset();
                    }}
                >
                    <Translate value={i18.Filter.Buttons.Reset} />
                </Button>
                <Button
                    size='small'
                    type='primary'
                    onClick={() => {
                        confirm({ closeDropdown: true });
                        submit();
                    }}
                >
                    <Translate value={i18.Filter.Buttons.Submit} />
                </Button>
            </div>
        </>
    );
};

TextFilterField.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    confirm: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    data: PropTypes.object
};
