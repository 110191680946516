import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { servicesActions, serviceSettingsActions } from '../../api';
import { i18, i18cl, routerPaths } from '../../utilities';
import { Classificator, ContentHeader, Translate } from '../../components';
import { ServiceEditForm, ServiceInformation, ServiceSettingsList } from './components';

class ServiceContainer extends React.Component {
    componentDidMount() {
        this.props.dispatch(servicesActions.getServicesById(this.props.match.params.serviceCatalogId));
        this.props.dispatch(serviceSettingsActions.getServiceSettings(this.props.match.params.serviceCatalogId));
    }

    render() {
        if (!this.props.service) {
            return null;
        }

        return (
            <>
                <ContentHeader
                    back={routerPaths.services.services}
                    title={
                        <>
                            <span className="mr-5">{this.props.service.serviceCatalogCode}</span>
                            <Classificator value={i18cl.SERVICES_CATALOG + this.props.service.serviceCatalogCode} />
                        </>
                    }
                />
                <div className="content_block">
                    <div className="content_subtitle_wrapper">
                        <div className="content_subtitle">
                            <Translate value={i18.Service.Titles.Information} />
                        </div>
                        <div>
                            <ServiceEditForm data={this.props.service} />
                        </div>
                    </div>
                    <div className="content_box content_box_white p-3">
                        <ServiceInformation data={this.props.service} />
                    </div>
                    <ServiceSettingsList serviceCatalogId={this.props.match.params.serviceCatalogId} />
                </div>
            </>
        );
    }
}

ServiceContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }).isRequired,
    service: PropTypes.object
};

function mapStateToProps(state) {
    const { service, sendServiceGET } = state.servicesReducers;

    return {
        service,
        sendServiceGET
    };
}

const connectedServiceContainer = withRouter(connect(mapStateToProps)(ServiceContainer));
export { connectedServiceContainer as ServiceContainer };
