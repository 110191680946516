import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { FormField, formFieldType, InternalClientSelectionGrid } from '../../../../components';
import { i18 } from '../../../../utilities';

export class InternalClientSelectionField extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showClientsGrid: false
        };

        this.onToggleModal = this.onToggleModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onInternalClientSelected = this.onInternalClientSelected.bind(this);
    }

    onToggleModal (e) {
        e.preventDefault();

        this.toggleModal();
    }

    toggleModal () {
        this.setState(prevState => ({
            showClientsGrid: !prevState.showClientsGrid
        }));
    }

    onInternalClientSelected (internalClient) {
        this.props.updateKeyValue(this.props.nameId, internalClient.ClientId);
        this.props.updateKeyValue(this.props.nameValue, internalClient.ClientCompanyName || internalClient.ClientFirstName + ' ' + internalClient.ClientLastName);

        this.toggleModal();
    }

    render () {
        return (
            <div className={this.props.classNames}>
                <FormField
                    title={i18.Job.Labels.InternalClient}
                    borderTitle
                    name={this.props.nameValue}
                    onFocus={e => e.currentTarget.blur()}
                    onClick={this.onToggleModal}
                    component={formFieldType.input}
                    disabled={this.props.disabled}
                    dimmed={this.props.dimmed}
                />
                <Modal
                    visible={this.state.showClientsGrid}
                    onCancel={this.onToggleModal}
                    footer={null}
                    maskClosable={false}
                    width={'90%'}
                >
                    <InternalClientSelectionGrid
                        onInternalClientSelected={this.onInternalClientSelected}
                    />
                </Modal>
            </div>
        );
    }
};

InternalClientSelectionField.propTypes = {
    disabled: PropTypes.bool,
    dimmed: PropTypes.bool,
    nameValue: PropTypes.string.isRequired,
    nameId: PropTypes.string.isRequired,
    updateKeyValue: PropTypes.func.isRequired,
    classNames: PropTypes.string,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    })
};
