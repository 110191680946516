import { requestApi, apiPaths, handleResponse, methods } from '../../config';

export const countryVatServices = {
    getCountryVats,
    postCountryVat,
    postVat,
    putVat,
    deleteVat
};

function getCountryVats () {
    return requestApi(apiPaths.countriesVat).then(handleResponse);
}

function postCountryVat (data) {
    return requestApi(apiPaths.countriesVat, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postVat (countryVatId, data) {
    return requestApi(`${apiPaths.countriesVat}/${countryVatId}/vats`, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function putVat (countryVatId, data) {
    return requestApi(`${apiPaths.countriesVat}/${countryVatId}/vats/${data.id}`, {
        method: methods.put,
        data: data
    }).then(handleResponse);
}

function deleteVat (countryVatId, data) {
    return requestApi(`${apiPaths.countriesVat}/${countryVatId}/vats/${data.id}`, {
        method: methods.delete
    }).then(handleResponse);
}
