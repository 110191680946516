export const serviceSettingsConstants = {
    GET_SERVICE_SETTINGS_REQUEST: 'GET_SERVICE_SETTINGS_REQUEST',
    GET_SERVICE_SETTINGS_SUCCESS: 'GET_SERVICE_SETTINGS_SUCCESS',
    GET_SERVICE_SETTINGS_ERROR: 'GET_SERVICE_SETTINGS_ERROR',

    GET_SERVICE_SETTINGS_BY_ID_REQUEST: 'GET_SERVICE_SETTINGS_BY_ID_REQUEST',
    GET_SERVICE_SETTINGS_BY_ID_SUCCESS: 'GET_SERVICE_SETTINGS_BY_ID_SUCCESS',
    GET_SERVICE_SETTINGS_BY_ID_ERROR: 'GET_SERVICE_SETTINGS_BY_ID_ERROR',

    POST_SERVICE_SETTINGS_REQUEST: 'POST_SERVICE_SETTINGS_REQUEST',
    POST_SERVICE_SETTINGS_SUCCESS: 'POST_SERVICE_SETTINGS_SUCCESS',
    POST_SERVICE_SETTINGS_ERROR: 'POST_SERVICE_SETTINGS_ERROR',

    PUT_SERVICE_SETTINGS_REQUEST: 'PUT_SERVICE_SETTINGS_REQUEST',
    PUT_SERVICE_SETTINGS_SUCCESS: 'PUT_SERVICE_SETTINGS_SUCCESS',
    PUT_SERVICE_SETTINGS_ERROR: 'PUT_SERVICE_SETTINGS_ERROR'
};
