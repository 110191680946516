import { servicesServiceGroups } from './services';
import { serviceGroupsConstants } from './constants';

export const serviceGroupsActions = {
    getServiceGroup,
    getServiceGruops,
    postServiceGroupImage,
    putServiceGroupData
};

function putServiceGroupData (serviceGroupCode, data) {
    return dispatch => {
        dispatch(request());

        return servicesServiceGroups.putServiceGroupData(serviceGroupCode, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: serviceGroupsConstants.PUT_SERVICE_GROUP_DATA_REQUEST };
    }

    function success (data) {
        return {
            type: serviceGroupsConstants.PUT_SERVICE_GROUP_DATA_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: serviceGroupsConstants.PUT_SERVICE_GROUP_DATA_ERROR,
            error
        };
    }
}

function postServiceGroupImage (serviceGroupCode, image) {
    return dispatch => {
        dispatch(request());

        return servicesServiceGroups.postServiceGroupImage(serviceGroupCode, image)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: serviceGroupsConstants.POST_SERVICE_GROUP_IMAGE_REQUEST };
    }

    function success (data) {
        return {
            type: serviceGroupsConstants.POST_SERVICE_GROUP_IMAGE_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: serviceGroupsConstants.POST_SERVICE_GROUP_IMAGE_ERROR,
            error
        };
    }
}

function getServiceGroup (serviceGroupCode) {
    return dispatch => {
        dispatch(request());

        return servicesServiceGroups.getServiceGroup(serviceGroupCode)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: serviceGroupsConstants.GET_SERVICE_GROUP_REQUEST };
    }

    function success (data) {
        return {
            type: serviceGroupsConstants.GET_SERVICE_GROUP_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: serviceGroupsConstants.GET_SERVICE_GROUP_ERROR,
            error
        };
    }
}

function getServiceGruops () {
    return dispatch => {
        dispatch(request());

        return servicesServiceGroups.getServiceGruops()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: serviceGroupsConstants.GET_SERVICE_GROUPS_REQUEST };
    }

    function success (data) {
        return {
            type: serviceGroupsConstants.GET_SERVICE_GROUPS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: serviceGroupsConstants.GET_SERVICE_GROUPS_ERROR,
            error
        };
    }
}
