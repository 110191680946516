import React from 'react';
import PropTypes from 'prop-types';
import { Button, Upload } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { ErrorUpload } from './';
import { Translate } from '../../translate';
import { i18 } from '../../../utilities';

export const UploadAvatarFormField = (props) => {
    return (
        <div className='form_field_wrapper'>
            <div className='form_field'>
                <Upload
                    name={props.input.name}
                    showUploadList={false}
                    className='avatar-uploader'
                    action={false}
                    onChange={props.input.onChange}
                >
                    <div className='app_profile_avatar'>
                        <img
                            src='../../images/profile/profile.jpg'
                            alt='avatar'
                        />
                        <Button
                            block
                            icon={<EditOutlined />}
                            size='small'
                            type='text'
                        >
                            <Translate className='fs_14' value={i18.PartnerProfile.Buttons.ProfileAvatar} />
                        </Button>
                    </div>
                </Upload>
            </div>
            <ErrorUpload
                error={props.meta.error}
                touched={props.meta.touched}
            />
        </div>
    );
};

UploadAvatarFormField.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        value: PropTypes.any
    }),
    meta: PropTypes.shape({
        error: PropTypes.any,
        touched: PropTypes.bool
    })
};
