import { serviceGroupsConstants } from './constants';

export const serviceGroupsReducers = function (state = {
    serviceGroups: [],
    serviceGroup: {}
}, action) {
    switch (action.type) {
    case serviceGroupsConstants.GET_SERVICE_GROUPS_REQUEST:
        return {
            ...state,
            send: true,
            sendServiceGroupsGET: true
        };
    case serviceGroupsConstants.GET_SERVICE_GROUPS_SUCCESS:
        return {
            ...state,
            serviceGroups: action.payload,
            send: false,
            sendServiceGroupsGET: false
        };
    case serviceGroupsConstants.GET_SERVICE_GROUPS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceGroupsGET: false
        };
    case serviceGroupsConstants.GET_SERVICE_GROUP_REQUEST:
        return {
            ...state,
            send: true,
            sendServiceGroupGET: true
        };
    case serviceGroupsConstants.GET_SERVICE_GROUP_SUCCESS:
        return {
            ...state,
            serviceGroup: action.payload,
            send: false,
            sendServiceGroupGET: false
        };
    case serviceGroupsConstants.GET_SERVICE_GROUP_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceGroupGET: false
        };
    case serviceGroupsConstants.POST_SERVICE_GROUP_IMAGE_REQUEST:
        return {
            ...state,
            send: true,
            sendServiceGroupImagePOST: true
        };
    case serviceGroupsConstants.POST_SERVICE_GROUP_IMAGE_SUCCESS:
        return {
            ...state,
            send: false,
            sendServiceGroupImagePOST: false
        };
    case serviceGroupsConstants.POST_SERVICE_GROUP_IMAGE_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceGroupImagePOST: false
        };
    case serviceGroupsConstants.PUT_SERVICE_GROUP_DATA_REQUEST:
        return {
            ...state,
            send: true,
            sendServiceGroupDataPUT: true
        };
    case serviceGroupsConstants.PUT_SERVICE_GROUP_DATA_SUCCESS:
        return {
            ...state,
            send: false,
            sendServiceGroupDataPUT: false
        };
    case serviceGroupsConstants.PUT_SERVICE_GROUP_DATA_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendServiceGroupDataPUT: false
        };
    default:
        return state;
    }
};
