import React from 'react';
import PropTypes from 'prop-types';
import { i18 } from '../../../utilities';
import { Translate, ButtonWrapper } from '../../../components';
import { GoogleMap, Marker } from '@react-google-maps/api';

export class LocationSelectionMap extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            isLocationChanged: false,
            lat: this.props.defaultCoordinates.lat,
            lng: this.props.defaultCoordinates.lng,
            address: ''
        };

        this.onLocationChanged = this.onLocationChanged.bind(this);
        this.onLocationConfirmed = this.onLocationConfirmed.bind(this);
        this.getAddress = this.getAddress.bind(this);
    }

    async componentDidMount () {
        const position = { lat: this.state.lat, lng: this.state.lng };
        const address = await this.getAddress(position);
        this.setState({
            address
        });
    }

    async onLocationChanged (event) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const position = { lat, lng };
        const address = await this.getAddress(position);
        this.setState({
            isLocationChanged: true,
            lat: position.lat,
            lng: position.lng,
            address: address
        });
    }

    getAddress (position) {
        // Geocoder instance
        const geocoder = new window.google.maps.Geocoder();
        return new Promise((resolve) => {
            geocoder.geocode({ location: position }, (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    resolve(results?.[0]?.formatted_address ?? '');
                } else {
                    resolve('');
                }
            });
        });
    }

    onLocationConfirmed () {
        const { lat, lng, address } = this.state;
        this.props.onLocationSelected(lat, lng, address);
    }

    render () {
        return (
            <div>
                <div className="d-flex justify-content-between">
                    <div>{this.state.address}</div>
                    <ButtonWrapper
                        className="mr-4 mb-2"
                        type="primary"
                        onClick={this.onLocationConfirmed}
                        disabled={!this.state.isLocationChanged}
                    >
                        <Translate value={i18.JobCreateForm.Buttons.ConfirmLocation} />
                    </ButtonWrapper>
                </div>
                <GoogleMap mapContainerStyle={{
                    width: '100%',
                    height: '500px'
                }} center={this.props.defaultCoordinates} zoom={15}>
                    <Marker
                        position={this.props.defaultCoordinates}
                        draggable
                        onDragEnd={this.onLocationChanged}
                    />
                </GoogleMap>
            </div>
        );
    }
}

LocationSelectionMap.propTypes = {
    onLocationSelected: PropTypes.func.isRequired,
    defaultCoordinates: PropTypes.object
};
