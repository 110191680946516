import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Modal } from 'antd';
import { serialize } from 'object-to-formdata';
import { CloudUploadOutlined } from '@ant-design/icons';

import { getCurrentDate, i18, required } from '../../../../../utilities';
import { partnerProfileActions, partnerProfileConstants } from '../../../../../api';
import { FormField, formFieldType, Translate } from '../../../../../components';

class PartnerServiceDocumentsForm extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            visible: false
        };

        this.visibility = this.visibility.bind(this);
        this.submit = this.submit.bind(this);
    }

    visibility (e) {
        if (e) {
            e.stopPropagation();
        }

        this.setState(prevState => ({ visible: !prevState.visible }));
    }

    submit (values) {
        if (this.props.profileServiceId) {
            values.profileServiceId = this.props.profileServiceId;
        }
        const data = serialize(values);

        this.props.dispatch(partnerProfileActions.postProfileServiceDocument(this.props.profileId, this.props.profileServiceId, data)).then(res => {
            if (res && res.type === partnerProfileConstants.POST_PARTNER_SERVICE_DOCUMENT_SUCCESS) {
                this.visibility();
                this.props.update();
            }
        });
    }

    render () {
        return (
            <>
                <Button
                    type='primary'
                    icon={<CloudUploadOutlined />}
                    onClick={(e) => this.visibility(e)}
                >
                    <Translate value={i18.Files.Buttons.Upload} />
                </Button>
                <Modal
                    destroyOnClose
                    title={this.props.title}
                    visible={this.state.visible}
                    onCancel={(e) => this.visibility(e)}
                    footer={null}
                    maskClosable={false}
                >
                    <Form
                        onSubmit={this.submit}
                        initialValues={this.props.initialData}
                        render={({ handleSubmit, values }) => (
                            <form
                                onSubmit={handleSubmit}
                                autoComplete='off'
                            >
                                <FormField
                                    title={i18.Files.Labels.ValidFrom}
                                    component={formFieldType.date}
                                    name='validFrom'
                                    disabled={this.props.sendPartnerServiceDocumentPOST}
                                    validate={required}
                                />
                                <FormField
                                    title={i18.Files.Labels.ValidTo}
                                    component={formFieldType.date}
                                    name='validTo'
                                    disabled={this.props.sendPartnerServiceDocumentPOST}
                                    minDate={{
                                        date: values.validFrom || getCurrentDate()
                                    }}
                                />
                                <FormField
                                    title={i18.Files.Labels.File}
                                    component={formFieldType.upload}
                                    name='file'
                                    disabled={this.props.sendPartnerServiceDocumentPOST}
                                    validate={required}
                                />
                                <div className='text-right'>
                                    <Button
                                        htmlType='submit'
                                        type='primary'
                                        onClick={(e) => e.stopPropagation()}
                                        loading={this.props.sendPartnerServiceDocumentPOST}
                                    >
                                        <Translate value={i18.Files.Buttons.Submit} />
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </Modal>
            </>
        );
    };
}

PartnerServiceDocumentsForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    initialData: PropTypes.object,
    title: PropTypes.object,
    profileId: PropTypes.string.isRequired,
    profileServiceId: PropTypes.string.isRequired,
    sendPartnerServiceDocumentPOST: PropTypes.bool,
    update: PropTypes.func
};

function mapStateToProps (state) {
    const {
        sendPartnerServiceDocumentPOST
    } = state.partnerProfileReducers;

    return {
        sendPartnerServiceDocumentPOST
    };
}

const connectedPartnerServiceDocumentsForm = connect(mapStateToProps)(PartnerServiceDocumentsForm);
export { connectedPartnerServiceDocumentsForm as PartnerServiceDocumentsForm };
