import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Input } from 'antd';
import { Field } from 'react-final-form';
import { EnvironmentTwoTone } from '@ant-design/icons';
import { Translate } from '../../../components';
import { i18, translate, getCountry } from '../../../utilities';

export const LocationAutoComplete = (props) => {
    function onAddressSelected (address) {
        geocodeByAddress(address)
            .then(results => results[0])
            .then(result =>
                getLatLng(result)
                    .then(latLng => { return { location: latLng, formattedAddress: result.formatted_address }; })
            )
            .then(({ location, formattedAddress }) => props.onAddressSelected(location.lat, location.lng, formattedAddress));
    };

    function getSearchOptions () {
        return {
            componentRestrictions: {
                country: [getCountry()]
            },
            types: ['address']
        };
    }

    return (
        <Field
            name={props.name}
        >
            {fieldProps => (
                <PlacesAutocomplete
                    value={fieldProps.input.value}
                    name={fieldProps.input.name}
                    onChange={fieldProps.input.onChange}
                    searchOptions={getSearchOptions()}
                    onSelect={onAddressSelected}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <div>
                                <Input
                                    style={{ width: '100%' }}
                                    suffix={<EnvironmentTwoTone onClick={props.onOpenMap} />}
                                    maxLength={150}
                                    {...getInputProps({
                                        placeholder: translate(i18.JobCreateForm.Labels.ObjectAddress)
                                    })}
                                />
                            </div>

                            <div>
                                {loading &&
                                    <Translate value={i18.Loader.Titles.Default} />
                                }
                                {suggestions.map((suggestion) => {
                                    const className = 'suggestion-item--active';
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                            key={suggestion.placeId}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            )}
        </Field>
    );
};

LocationAutoComplete.propTypes = {
    onAddressSelected: PropTypes.func.isRequired,
    onOpenMap: PropTypes.func.isRequired,
    name: PropTypes.string
};
