import qs from 'qs';

import { requestApi, apiPaths, handleResponse, methods } from '../../config';

export const translationServices = {
    getClassificationGroups,
    getClassifications,
    putClassification,
    getContent,
    putContent,
    getErrorMessages,
    putErrorMessage,
    getLabels,
    putLabel
};

function getClassificationGroups () {
    return requestApi(apiPaths.translation + 'classificationGroups').then(handleResponse);
}

function getClassifications (language, query) {
    return requestApi(apiPaths.translation + language + '/classifications?' + qs.stringify(query)).then(handleResponse);
}

function putClassification (language, data) {
    return requestApi(apiPaths.translation + language + '/classification', {
        method: methods.put,
        data: data
    }).then(handleResponse);
}

function getContent (language, title) {
    return requestApi(apiPaths.translation + language + '/content/' + title).then(handleResponse);
}

function putContent (language, data) {
    return requestApi(apiPaths.translation + language + '/content', {
        method: methods.put,
        data: data
    }).then(handleResponse);
}

function getErrorMessages (language) {
    return requestApi(apiPaths.translation + language + '/errorMessages').then(handleResponse);
}

function putErrorMessage (language, data) {
    return requestApi(apiPaths.translation + language + '/errorMessage', {
        method: methods.put,
        data: data
    }).then(handleResponse);
}

function getLabels (language, query) {
    return requestApi(apiPaths.translation + language + '/labels?' + qs.stringify(query)).then(handleResponse);
}

function putLabel (language, data) {
    return requestApi(apiPaths.translation + language + '/label', {
        method: methods.put,
        data: data
    }).then(handleResponse);
}
