import { i18 } from '../../utilities/i18';
import { ClientSegmentConstants } from './clientSegmentConstants';

export const clientSegments = {
    APARTMENT: {
        value: ClientSegmentConstants.APARTMENT,
        label: i18.ServicesFormEdit.Labels.ServiceDetailsApartmentSegment
    },
    ADMINISTRATOR: {
        value: ClientSegmentConstants.ADMINISTRATOR,
        label: i18.ServicesFormEdit.Labels.ServiceDetailsAdministratorSegment
    }
};
