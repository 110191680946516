import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Table } from 'antd';
import { Translate } from '../../../components';
import { i18, translate } from '../../../utilities';
import { jobActions } from '../../../api';
import { FilePdfOutlined } from '@ant-design/icons';

const columns = [
    {
        key: 'InvoiceType',
        title: <Translate value={i18.Job.Labels.InvoiceType} />,
        render: (_, item) => `${translate(i18.Job.Invoices[item.invoiceType])} - ${item.invoiceCode}`
    },
    {
        key: 'InvoiceLink',
        title: <Translate value={i18.Job.Labels.InvoicePDF} />,
        render: () => <FilePdfOutlined />
    }
];

const JobInvoices = props => {
    useEffect(() => {
        const { dispatch, job } = props;
        dispatch(jobActions.getJobInvoices(job.jobId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRowClick = invoice => {
        const openedWindow = window.open(invoice.urlToFile, '_blank', 'noopener,noreferrer');
        if (openedWindow) {
            openedWindow.opener = null;
        }
    };

    return (
        <div className="content_block">
            <Table
                rowKey="invoiceCode"
                onRow={invoice => ({
                    onClick: () => handleRowClick(invoice)
                })}
                style={{ minWidth: 1000 }}
                columns={columns}
                rowClassName="cursor_pointer"
                dataSource={props.jobInvoices}
                loading={props.sendJobInvoicesGET}
            />
        </div>
    );
};

JobInvoices.propTypes = {
    job: PropTypes.object,
    jobInvoices: PropTypes.array,
    sendJobInvoicesGET: PropTypes.bool,
    dispatch: PropTypes.func
};

function mapStateToProps(state) {
    const { job, jobInvoices, sendJobInvoicesGET } = state.jobReducers;

    return {
        job: job?.value || {},
        jobInvoices,
        sendJobInvoicesGET
    };
}

const connectedJobInvoices = withRouter(connect(mapStateToProps)(JobInvoices));
export { connectedJobInvoices as JobInvoices };
