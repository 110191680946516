import { publicServices } from './services';
import { publicConstants } from './constants';

export const publicActions = {
    getBankName,
    getLanguages,
    getLocalization,
    getServiceAreas,
    getContent,
    getConfigurations
};

function getBankName (iban) {
    return dispatch => {
        dispatch(request());

        return publicServices.getBankName(iban)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: publicConstants.GET_PUBLIC_BANK_NAME_REQUEST };
    }

    function success (data) {
        return {
            type: publicConstants.GET_PUBLIC_BANK_NAME_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: publicConstants.GET_PUBLIC_BANK_NAME_ERROR,
            error
        };
    }
}

function getLanguages () {
    return dispatch => {
        dispatch(request());

        return publicServices.getLanguages()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: publicConstants.GET_PUBLIC_LANGUAGES_REQUEST };
    }

    function success (data) {
        return {
            type: publicConstants.GET_PUBLIC_LANGUAGES_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: publicConstants.GET_PUBLIC_LANGUAGES_ERROR,
            error
        };
    }
}

function getLocalization () {
    return dispatch => {
        dispatch(request());

        return publicServices.getLocalization()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: publicConstants.GET_PUBLIC_LOCALIZATION_REQUEST };
    }

    function success (data) {
        return {
            type: publicConstants.GET_PUBLIC_LOCALIZATION_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: publicConstants.GET_PUBLIC_LOCALIZATION_ERROR,
            error
        };
    }
}

function getServiceAreas () {
    return dispatch => {
        dispatch(request());

        return publicServices.getServiceAreas()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: publicConstants.GET_PUBLIC_SERVICE_AREAS_REQUEST };
    }

    function success (data) {
        return {
            type: publicConstants.GET_PUBLIC_SERVICE_AREAS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: publicConstants.GET_PUBLIC_SERVICE_AREAS_ERROR,
            error
        };
    }
}

function getContent () {
    return dispatch => {
        dispatch(request());
        return publicServices.getContent()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };
    function request () {
        return { type: publicConstants.GET_PUBLIC_CONTENT_REQUEST };
    }
    function success (data) {
        return {
            type: publicConstants.GET_PUBLIC_CONTENT_SUCCESS,
            payload: data
        };
    }
    function failure (error) {
        return {
            type: publicConstants.GET_PUBLIC_CONTENT_ERROR,
            error
        };
    }
}

function getConfigurations () {
    return dispatch => {
        dispatch(request());
        return publicServices.getConfigurations()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };
    function request () {
        return { type: publicConstants.GET_PUBLIC_CONFIGURATIONS_REQUEST };
    }
    function success (data) {
        return {
            type: publicConstants.GET_PUBLIC_CONFIGURATIONS_SUCCESS,
            payload: data
        };
    }
    function failure (error) {
        return {
            type: publicConstants.GET_PUBLIC_CONFIGURATIONS_ERROR,
            error
        };
    }
}
