export const publicConstants = {
    GET_PUBLIC_BANK_NAME_REQUEST: 'GET_PUBLIC_BANK_NAME_REQUEST',
    GET_PUBLIC_BANK_NAME_SUCCESS: 'GET_PUBLIC_BANK_NAME_SUCCESS',
    GET_PUBLIC_BANK_NAME_ERROR: 'GET_PUBLIC_BANK_NAME_ERROR',

    GET_PUBLIC_CITIES_REQUEST: 'GET_PUBLIC_CITIES_REQUEST',
    GET_PUBLIC_CITIES_SUCCESS: 'GET_PUBLIC_CITIES_SUCCESS',
    GET_PUBLIC_CITIES_ERROR: 'GET_PUBLIC_CITIES_ERROR',

    GET_PUBLIC_LANGUAGES_REQUEST: 'GET_PUBLIC_LANGUAGES_REQUEST',
    GET_PUBLIC_LANGUAGES_SUCCESS: 'GET_PUBLIC_LANGUAGES_SUCCESS',
    GET_PUBLIC_LANGUAGES_ERROR: 'GET_PUBLIC_LANGUAGES_ERROR',

    GET_PUBLIC_LOCALIZATION_REQUEST: 'GET_PUBLIC_LOCALIZATION_REQUEST',
    GET_PUBLIC_LOCALIZATION_SUCCESS: 'GET_PUBLIC_LOCALIZATION_SUCCESS',
    GET_PUBLIC_LOCALIZATION_ERROR: 'GET_PUBLIC_LOCALIZATION_ERROR',

    GET_PUBLIC_SERVICE_AREAS_REQUEST: 'GET_PUBLIC_SERVICE_AREAS_REQUEST',
    GET_PUBLIC_SERVICE_AREAS_SUCCESS: 'GET_PUBLIC_SERVICE_AREAS_SUCCESS',
    GET_PUBLIC_SERVICE_AREAS_ERROR: 'GET_PUBLIC_SERVICE_AREAS_ERROR',

    GET_PUBLIC_CONTENT_REQUEST: 'GET_PUBLIC_CONTENT_REQUEST',
    GET_PUBLIC_CONTENT_SUCCESS: 'GET_PUBLIC_CONTENT_SUCCESS',
    GET_PUBLIC_CONTENT_ERROR: 'GET_PUBLIC_CONTENT_ERROR',

    GET_PUBLIC_CONFIGURATIONS_REQUEST: 'GET_PUBLIC_CONFIGURATIONS_REQUEST',
    GET_PUBLIC_CONFIGURATIONS_SUCCESS: 'GET_PUBLIC_CONFIGURATIONS_SUCCESS',
    GET_PUBLIC_CONFIGURATIONS_ERROR: 'GET_PUBLIC_CONFIGURATIONS_ERROR'
};
