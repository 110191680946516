import { requestApi, apiPaths, handleResponse, methods } from '../../config';

export const serviceSettingsServices = {
    getServiceSettings,
    getServiceSettingsById,
    postServiceSettings,
    putServiceSettings
};

function getServiceSettings (serviceCatalogId) {
    return requestApi(apiPaths.serviceSettings + '/' + serviceCatalogId).then(handleResponse);
}

function getServiceSettingsById (serviceCatalogId, serviceSettingId) {
    return requestApi(apiPaths.serviceSettings + '/' + serviceCatalogId + '/settings/' + serviceSettingId).then(handleResponse);
}

function postServiceSettings (serviceCatalogId, data) {
    return requestApi(apiPaths.serviceSettings + '/' + serviceCatalogId, {
        method: methods.post,
        data: data
    });
}

function putServiceSettings (serviceCatalogId, data) {
    return requestApi(apiPaths.serviceSettings + '/' + serviceCatalogId, {
        method: methods.put,
        data: data
    });
}
