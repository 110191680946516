export const checkJsonProp = (object, key) => {
    try {
        const keys = key.split('.');

        object = object[keys[0]];

        for (let i = 1; i < keys.length; i++) {
            object = object[keys[i]];
        }

        if (object === undefined) {
            return undefined;
        }

        return object;
    } catch (e) {
        return undefined;
    }
};

export const checkJsonExist = (object, key) => {
    try {
        const keys = key.split('.');

        object = object[keys[0]];

        for (let i = 1; i < keys.length; i++) {
            object = object[keys[i]];
        }

        return object !== undefined;
    } catch (e) {
        return undefined;
    }
};
