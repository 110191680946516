import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';

import { countrySettingsActions } from '../../../api';
import { formatDate, getCurrentDate, i18, i18cl, statusesService, classificator } from '../../../utilities';
import { Classificator, Translate } from '../../../components';
import { CountriesSettingsEditForm } from './';
import { settingInputType } from './fields';

class CountriesSettingsList extends React.Component {
    componentDidMount () {
        this.props.dispatch(countrySettingsActions.getCountrySettings());
    }

    render () {
        const columns = [
            {
                title: <Translate value={i18.CountriesSettings.Labels.Name} />,
                render: (item) => <Classificator value={i18cl.SETTINGS_COUNTRY + item.classificatorCode} />
            }, {
                title: <Translate value={i18.CountriesSettings.Labels.Count} />,
                render: (item) => item.data.length,
                className: 'text-right'
            }
        ];
        const columnsInner = [
            {
                title: <Translate value={i18.CountriesSettings.Labels.Status} />,
                render: (item) => statusesService(item.status)
            }, {
                title: <Translate value={i18.CountriesSettings.Labels.Value} />,
                render: (item) => {
                    if (!item.valueClassificatorCode) {
                        return item.value;
                    }

                    if (item.field === settingInputType.multiselect) {
                        const values = JSON.parse(item.value);

                        return values.map((value, index) => {
                            if (index > 0 && index < values.length) {
                                return `, ${classificator(item.valueClassificatorCode + '.' + value)}`;
                            }

                            return classificator(item.valueClassificatorCode + '.' + value);
                        });
                    }

                    return <Classificator value={item.valueClassificatorCode + '.' + item.value} />;
                }
            }, {
                title: <Translate value={i18.CountriesSettings.Labels.ValidFrom} />,
                render: (item) => formatDate(item.validFrom)
            }, {
                title: <Translate value={i18.CountriesSettings.Labels.ValidTo} />,
                render: (item) => formatDate(item.validTo)
            }, {
                title: <Translate value={i18.CountriesSettings.Labels.Actions} />,
                render: (item) => (
                    item.validTo > getCurrentDate() || !item.validTo ? (
                        <CountriesSettingsEditForm
                            countrySettingId={item.countrySettingId}
                        />
                    ) : null
                ),
                className: 'text-right'
            }
        ];

        return (
            <div className='content_box'>
                <Table
                    rowKey='classificatorCode'
                    columns={columns}
                    size='large'
                    dataSource={this.props.countrySettings}
                    pagination={false}
                    loading={this.props.sendCountrySettings}
                    expandable={{
                        expandedRowRender: item =>
                            <Table
                                rowKey='countrySettingId'
                                columns={columnsInner}
                                size='large'
                                dataSource={item.data}
                                pagination={false}
                            />
                    }}
                />
            </div>
        );
    }
}

CountriesSettingsList.propTypes = {
    dispatch: PropTypes.func.isRequired,
    countrySettings: PropTypes.array.isRequired,
    sendCountrySettings: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        countrySettings,
        sendCountrySettings
    } = state.countrySettingsReducers;

    return {
        countrySettings,
        sendCountrySettings
    };
}

const connectedCountriesSettingsList = connect(mapStateToProps)(CountriesSettingsList);
export { connectedCountriesSettingsList as CountriesSettingsList };
