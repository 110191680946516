import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { i18, routerPaths } from '../../utilities';
import { history, internalClientActions, internalClientConstants } from '../../api';
import { ContentHeader, Translate } from '../../components';
import { InternalClientForm } from './components';

class InternalClientContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            client: null
        };

        this.submit = this.submit.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount () {
        const { dispatch } = this.props;
        dispatch(internalClientActions.getInternalClient(this.props.match.params.clientId)).then(res => {
            if (res.type === internalClientConstants.GET_INTERNAL_CLIENT_SUCCESS) {
                this.setState({
                    client: res.payload
                });
            }
        });
    }

    submit (values) {
        const { dispatch } = this.props;

        dispatch(
            internalClientActions.putInternalClient(
                this.props.match.params.clientId,
                values
            )
        ).then(res => {
            if (res && res.type === internalClientConstants.PUT_INTERNAL_CLIENT_SUCCESS) {
                history.push(routerPaths.internalClients.index, this.props.history.location.state);
            }
        });
    }

    cancel () {
        history.push(routerPaths.internalClients.index, this.props.history.location.state);
    }

    render () {
        return (
            <div>
                <ContentHeader
                    title={<Translate className='mr-2' value={i18.InternalClient.Titles.InternalClient} />}
                    back={routerPaths.internalClients.index}
                    backState={this.props.history.location.state}
                />
                {this.state.client && (
                    <div className='content_block'>
                        <InternalClientForm
                            client={this.state.client}
                            onCancel={this.cancel}
                            onClientUpdate={this.submit}
                            submitting={this.props.sendInternalClientPut}
                        />
                    </div>
                )}
            </div>
        );
    }
}

InternalClientContainer.propTypes = {
    sendInternalClientPut: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    })
};

function mapStateToProps (state) {
    const {
        sendInternalClientPut
    } = state.internalClientReducers;

    return {
        sendInternalClientPut
    };
}

const connectedInternalClientContainer = connect(mapStateToProps)(InternalClientContainer);
export { connectedInternalClientContainer as InternalClientContainer };
