import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { countrySettingsActions, countrySettingsConstants } from '../../../api';
import { classificatorArray, getCurrentDate, i18, i18cl, required } from '../../../utilities';
import { FormField, formFieldType, Translate } from '../../../components';
import { SettingValueField } from './settingValueField';

class CountriesSettingsForm extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            visible: false
        };

        this.toggle = this.toggle.bind(this);
        this.submit = this.submit.bind(this);
    }

    toggle (state) {
        if (state) {
            this.props.dispatch(countrySettingsActions.getCountrySettingsById(0)).then(res => {
                if (res && res.type === countrySettingsConstants.GET_COUNTRY_SETTINGS_BY_ID_SUCCESS) {
                    this.setState({ visible: true });
                }
            });
        } else {
            this.setState({ visible: false });
        }
    }

    submit (values) {
        const classificator = this.props.countrySettingsById?.classificatorList.find(e => e.classificatorCode === values.classificatorCode);

        values = {
            ...values,
            field: classificator?.field,
            classificatorCodeForValues: classificator?.classificatorCodeForValues
        };

        if (Array.isArray(values.value)) {
            values.value = JSON.stringify(values.value);
        }

        this.props.dispatch(countrySettingsActions.postCountrySettings(values)).then(res => {
            if (res && res.type === countrySettingsConstants.POST_COUNTRY_SETTINGS_SUCCESS) {
                this.toggle();
                this.props.dispatch(countrySettingsActions.getCountrySettings());
            }
        });
    }

    render () {
        return (
            <>
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    loading={this.props.sendCountrySettingsId === 0}
                    disabled={this.props.sendCountrySettingsById}
                    onClick={() => this.toggle(true)}
                >
                    <Translate value={i18.CountrySettingsForm.Buttons.Add} />
                </Button>
                <Modal
                    destroyOnClose
                    title={<Translate value={i18.CountrySettingsForm.Titles.Add} />}
                    visible={this.state.visible}
                    onCancel={() => this.toggle(false)}
                    footer={null}
                    maskClosable={false}
                >
                    <Form
                        onSubmit={this.submit}
                        render={({ handleSubmit, values }) => (
                            <form
                                onSubmit={handleSubmit}
                                autoComplete='off'
                            >
                                <FormField
                                    component={formFieldType.select}
                                    title={i18.CountrySettingsForm.Labels.Parameter}
                                    options={classificatorArray(i18cl.SETTINGS_COUNTRY, this.props.countrySettingsById.classificatorList, 'classificatorCode')}
                                    disabled={this.props.sendCountrySettingsPOST}
                                    name='classificatorCode'
                                    validate={required}
                                />
                                <SettingValueField
                                    values={values}
                                    classificatorList={this.props.countrySettingsById.classificatorList}
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.CountrySettingsForm.Labels.ValidFrom}
                                    name='validFrom'
                                    validate={required}
                                    disabled={this.props.sendCountrySettingsPOST}
                                    minDate={{
                                        date: getCurrentDate(0)
                                    }}
                                    maxDate={{
                                        date: values.validTo
                                    }}
                                />
                                <FormField
                                    component={formFieldType.date}
                                    title={i18.CountrySettingsForm.Labels.ValidTo}
                                    name='validTo'
                                    disabled={this.props.sendCountrySettingsPOST}
                                    minDate={{
                                        date: values.validFrom || getCurrentDate(0)
                                    }}
                                />
                                <div className='text-right'>
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        loading={this.props.sendCountrySettingsPOST}
                                    >
                                        <Translate value={i18.CountrySettingsForm.Buttons.Submit} />
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </Modal>
            </>
        );
    }
}

CountriesSettingsForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    countrySettingsById: PropTypes.object,
    sendCountrySettingsId: PropTypes.number,
    sendCountrySettingsById: PropTypes.bool,
    sendCountrySettingsPOST: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        countrySettingsById,
        sendCountrySettingsById,
        sendCountrySettingsPOST
    } = state.countrySettingsReducers;

    return {
        countrySettingsById,
        sendCountrySettingsById,
        sendCountrySettingsPOST
    };
}

const connectedCountriesSettingsForm = connect(mapStateToProps)(CountriesSettingsForm);
export { connectedCountriesSettingsForm as CountriesSettingsForm };
