import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Input, Form } from 'antd';

export const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...rest }) => {
    const valueOrNull = dataIndex ? record[dataIndex] : null;
    const minimalValue = dataIndex === 'coefficient' || dataIndex === 'priceReduction' ? 0 : 1;
    const inputNode =
        inputType === 'number' ? (
            <InputNumber defaultValue={valueOrNull} min={minimalValue} />
        ) : (
            <Input defaultValue={valueOrNull} />
        );

    return (
        <td {...rest}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: dataIndex !== 'description',
                        }
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

EditableCell.propTypes = {
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.object,
    inputType: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
};
