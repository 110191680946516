import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import {
    formFieldType,
    CheckboxFormField,
    DateFormField,
    InputFormField,
    MultipleCheckboxFormField,
    MultipleSelectFormField,
    NumberFormField,
    PasswordFormField,
    PhoneFormField,
    DateRangePickerFormField,
    RadioFormField,
    SelectFormField,
    SwitchFormField,
    TextareaFormField,
    UploadAvatarFormField,
    UploadFormField,
    LocationAutoComplete
} from './';
import { ClickLinkField } from './fields/clickLink';

export const FormField = props => {
    const componentSwitch = component => {
        switch (component) {
            case formFieldType.checkbox:
                return CheckboxFormField;
            case formFieldType.date:
                return DateFormField;
            case formFieldType.dateRangePicker:
                return DateRangePickerFormField;
            case formFieldType.input:
                return InputFormField;
            case formFieldType.multiCheckbox:
                return MultipleCheckboxFormField;
            case formFieldType.multiSelect:
                return MultipleSelectFormField;
            case formFieldType.number:
                return NumberFormField;
            case formFieldType.password:
                return PasswordFormField;
            case formFieldType.phone:
                return PhoneFormField;
            case formFieldType.radio:
                return RadioFormField;
            case formFieldType.select:
                return SelectFormField;
            case formFieldType.textarea:
                return TextareaFormField;
            case formFieldType.uploadAvatar:
                return UploadAvatarFormField;
            case formFieldType.upload:
                return UploadFormField;
            case formFieldType.switch:
                return SwitchFormField;
            case formFieldType.locationAutoComplete:
                return LocationAutoComplete;
            case formFieldType.clickLink:
                return ClickLinkField;
            default:
                console.log(`Component not found ${component}`);
                return null;
        }
    };

    if (props.hidden) {
        return null;
    }

    return (
        <Field
            {...props}
            type={props.component === formFieldType.checkbox ? 'checkbox' : null}
            component={componentSwitch(props.component)}
        />
    );
};

FormField.propTypes = {
    addonAfter: PropTypes.any,
    addonBefore: PropTypes.any,
    autoFocus: PropTypes.bool,
    inline: PropTypes.bool,
    component: PropTypes.string.isRequired,
    format: PropTypes.func,
    formatDate: PropTypes.oneOf(['date', 'week', 'month', 'year']),
    formatNumber: PropTypes.object,
    formatNumberType: PropTypes.oneOf(['floatValue', 'formattedValue', 'value']),
    formatNumberPrefix: PropTypes.string,
    hidden: PropTypes.bool,
    input: PropTypes.object,
    inputType: PropTypes.string,
    label: PropTypes.string,
    labelTranslated: PropTypes.bool,
    loading: PropTypes.bool,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    options: PropTypes.array,
    optionsClassificator: PropTypes.string,
    optionsLabel: PropTypes.string,
    optionsValue: PropTypes.string,
    placeholder: PropTypes.string,
    placeholderFixed: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
    style: PropTypes.object,
    suffix: PropTypes.any,
    numberText: PropTypes.bool,
    required: PropTypes.bool,
    title: PropTypes.string,
    time: PropTypes.bool,
    titleTranslated: PropTypes.bool,
    validate: PropTypes.func,
    justify: PropTypes.bool,
    onSearch: PropTypes.func
};
