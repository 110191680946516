import React from 'react';
import { FormField, formFieldType, Translate } from '../../../../components';
import { fieldNames } from '../../fieldNames';
import { i18 } from '../../../../utilities';

export const CallInformationFormSection = () => (
    <>
        <Translate className="fs-16 fw_600" value={i18.Job.Labels.CallSectionTitle} />
        <div className="container-default mt-2">
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.CallDate}
                        borderTitle
                        name={fieldNames.callDate}
                        component={formFieldType.date}
                        time
                        disabled
                        dimmed
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.CallComment}
                        borderTitle
                        name={fieldNames.callComment}
                        component={formFieldType.textarea}
                        maxLength={1000}
                        disabled
                        dimmed
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.CalledBy}
                        borderTitle
                        name={fieldNames.calledBy}
                        component={formFieldType.input}
                        disabled
                        dimmed
                    />
                </div>
            </div>
        </div>
    </>
);
