import { odataServices } from './services';
import { odataConstants } from './constants';

export const odataActions = {
    getInternalClientsGrid,
    getJobGridQuery,
    getJobBiddingsGrid,
    getJobPartnersByClientSegmentGrid,
    getPartnersGrid,
    getJobPartnersGridForServiceAndClientSegment,
    getJobPartnersGridForService
};

function getInternalClientsGrid(query) {
    return dispatch => {
        dispatch(request());

        return odataServices.getInternalClientsGrid(query).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: odataConstants.GET_ODATA_INTERNAL_CLIENTS_GRID_REQUEST };
    }

    function success(data) {
        return {
            type: odataConstants.GET_ODATA_INTERNAL_CLIENTS_GRID_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: odataConstants.GET_ODATA_INTERNAL_CLIENTS_GRID_ERROR,
            error
        };
    }
}

function getPartnersGrid(query) {
    return dispatch => {
        dispatch(request());

        return odataServices.getPartnersGrid(query).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: odataConstants.GET_ODATA_PARTNERS_GRID_REQUEST };
    }

    function success(data) {
        return {
            type: odataConstants.GET_ODATA_PARTNERS_GRID_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: odataConstants.GET_ODATA_PARTNERS_GRID_ERROR,
            error
        };
    }
}

function getJobPartnersGridForServiceAndClientSegment(clientSegment, serviceId, query) {
    return dispatch => {
        dispatch(request());

        return odataServices.getJobPartnersGridForServiceAndClientSegment(clientSegment, serviceId, query).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: odataConstants.GET_ODATA_JOB_PARTNERS_GRID_REQUEST };
    }

    function success(data) {
        return {
            type: odataConstants.GET_ODATA_JOB_PARTNERS_GRID_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: odataConstants.GET_ODATA_JOB_PARTNERS_GRID_ERROR,
            error
        };
    }
}

function getJobPartnersGridForService(serviceId, query) {
    return dispatch => {
        dispatch(request());

        return odataServices.getJobPartnersGridForService(serviceId, query).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: odataConstants.GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_REQUEST };
    }

    function success(data) {
        return {
            type: odataConstants.GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: odataConstants.GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_ERROR,
            error
        };
    }
}

function getJobPartnersByClientSegmentGrid(clientSegment, query) {
    return dispatch => {
        dispatch(request());

        return odataServices.getJobPartnersByClientSegmentGrid(clientSegment, query).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: odataConstants.GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_REQUEST };
    }

    function success(data) {
        return {
            type: odataConstants.GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: odataConstants.GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_ERROR,
            error
        };
    }
}
function getJobGridQuery(query) {
    return dispatch => {
        dispatch(request());

        return odataServices.getJobGridQuery(query).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: odataConstants.GET_ODATA_JOBS_GRID_REQUEST };
    }

    function success(data) {
        return {
            type: odataConstants.GET_ODATA_JOBS_GRID_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: odataConstants.GET_ODATA_JOBS_GRID_ERROR,
            error
        };
    }
}

function getJobBiddingsGrid(jobId, query) {
    return dispatch => {
        dispatch(request());

        return odataServices.getJobBiddingsGrid(jobId, query).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: odataConstants.GET_ODATA_JOB_BIDDINGS_GRID_REQUEST };
    }

    function success(data) {
        return {
            type: odataConstants.GET_ODATA_JOB_BIDDINGS_GRID_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: odataConstants.GET_ODATA_JOB_BIDDINGS_GRID_ERROR,
            error
        };
    }
}
