import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ContentHeader, Classificator } from '../../components';
import { ServiceGroupInformation } from '.';
import { serviceGroupsActions, serviceGroupsConstants } from '../../api';
import { i18cl, routerPaths } from '../../utilities';

const ServiceGroupContainer = (props) => {
    useEffect(() => {
        getServiceGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getServiceGroup = () => {
        props.dispatch(serviceGroupsActions.getServiceGroup(props.match.params.serviceGroupCode));
    };

    const updateServiceGroupImage = (image) => {
        props.dispatch(serviceGroupsActions.postServiceGroupImage(props.match.params.serviceGroupCode, image)).then(res => {
            if (res && res.type === serviceGroupsConstants.POST_SERVICE_GROUP_IMAGE_SUCCESS) {
                getServiceGroup();
            }
        });
    };

    const updateServiceGroupData = (data, onSuccess) => {
        props.dispatch(serviceGroupsActions.putServiceGroupData(props.match.params.serviceGroupCode, data)).then(res => {
            if (res && res.type === serviceGroupsConstants.PUT_SERVICE_GROUP_DATA_SUCCESS) {
                getServiceGroup();
                onSuccess();
            }
        });
    };

    return (
        <>
            <ContentHeader
                back={routerPaths.serviceGroups.serviceGroups}
                title={
                    <>
                        <Classificator value={i18cl.GROUPS_SERVICE + props.serviceGroup.serviceGroupCode} />
                    </>
                }
            />
            <div className='content_block'>
                <ServiceGroupInformation
                    data={props.serviceGroup}
                    getServiceGroup={getServiceGroup}
                    updateServiceGroupImage={updateServiceGroupImage}
                    updateServiceGroupData={updateServiceGroupData}
                    loading={props.send}
                />
            </div>
        </>
    );
};

ServiceGroupContainer.propTypes = {
    dispatch: PropTypes.func,
    serviceGroup: PropTypes.object,
    send: PropTypes.bool,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }).isRequired
};

function mapStateToProps (state) {
    const {
        send,
        serviceGroup,
        serviceGroupConstants
    } = state.serviceGroupsReducers;

    return {
        send,
        serviceGroup,
        serviceGroupConstants
    };
}

const connectedServiceGroupContainer = connect(mapStateToProps)(ServiceGroupContainer);
export { connectedServiceGroupContainer as ServiceGroupContainer };
