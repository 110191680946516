import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Modal } from 'antd';
import { Field } from 'react-final-form';
import { Translate, FormField, formFieldType } from '../../../components';
import { getCountryStartingCoordinates, i18, translate } from '../../../utilities';
import { LocationSelectionMap } from './locationSelectionMap';
import { LocationAutoComplete } from './locationAutoComplete';
import { fieldNames } from './fieldNames';

export class ObjectLocationFormSection extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            latitude: undefined,
            longitude: undefined,
            address: '',
            showMap: false,
            isSelected: false
        };

        this.onAddressSelected = this.onAddressSelected.bind(this);
        this.onToggleModal = this.onToggleModal.bind(this);
    }

    onAddressSelected (latitude, longitude, address) {
        this.setState({
            latitude: latitude,
            longitude: longitude,
            address: address,
            showMap: false,
            isSelected: true
        });

        this.props.updateKeyValue(fieldNames.latitude, latitude);
        this.props.updateKeyValue(fieldNames.longitude, longitude);
        this.props.updateKeyValue(fieldNames.address, address);
    };

    getDefaultCoordinates () {
        return this.state.isSelected
            ? { lat: this.state.latitude, lng: this.state.longitude }
            : getCountryStartingCoordinates();
    }

    onToggleModal (e) {
        e.preventDefault();

        this.setState(prevState => ({
            showMap: !prevState.showMap
        }));
    }

    render () {
        return (
            <>
                <Translate className='fs-16 fw_600' value={i18.JobCreateForm.Titles.ObjectLocation} />
                <div className='container-default mt-4'>
                    <div className='row mb-3'>
                        <div className='col-12'>
                            <LocationAutoComplete
                                name={fieldNames.address}
                                onAddressSelected={this.onAddressSelected}
                                onOpenMap={this.onToggleModal}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-6'>
                            <Field
                                name={fieldNames.latitude}
                            >
                                {props => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        placeholder={translate(i18.JobCreateForm.Labels.ObjectLatitude)}
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className='col-6'>
                            <Field
                                name={fieldNames.longitude}
                            >
                                {props => (
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        placeholder={translate(i18.JobCreateForm.Labels.ObjectLongitude)}
                                        name={props.input.name}
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                    />
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <FormField
                                placeholder={translate(i18.JobCreateForm.Labels.ObjectAdditionalInfo)}
                                name={fieldNames.additionalLocationInfo}
                                component={formFieldType.textarea}
                                maxLength={200}
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    visible={this.state.showMap}
                    onCancel={this.onToggleModal}
                    footer={null}
                    maskClosable={false}
                    width={'90%'}
                >
                    <LocationSelectionMap
                        onLocationSelected={this.onAddressSelected}
                        defaultCoordinates={this.getDefaultCoordinates()}
                    />
                </Modal>
            </>
        );
    }
}

ObjectLocationFormSection.propTypes = {
    updateKeyValue: PropTypes.func.isRequired
};
