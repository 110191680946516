import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { history, userActions } from '../../api';
import { routerPaths } from '../../utilities';

class LogoutContainer extends React.Component {
    componentDidMount () {
        this.props.dispatch(userActions.logout());
        history.replace(routerPaths.login);
    }

    render () {
        return null;
    };
}

LogoutContainer.propTypes = {
    dispatch: PropTypes.func.isRequired
};

const connectedLogoutContainer = connect(null)(LogoutContainer);
export { connectedLogoutContainer as LogoutContainer };
