import { servicesServiceItems } from './services';
import { serviceItemsConstants } from './constants';
import { Message } from '../../../components/message';
import { i18, translate } from '../../../utilities';

function putServiceItemsData(serviceItemId, data) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await servicesServiceItems.putServiceItemsData(serviceItemId, data);
            dispatch(success(response));
            return response;
        } catch (error) {
            dispatch(failure(error));
            throw error;
        }
    };

    function request() {
        return { type: serviceItemsConstants.POST_SERVICE_ITEMS_REQUEST };
    }

    function success(data) {
        Message({
            message: translate(i18.API.Messages.ServiceItemUpdated)
        });

        return {
            type: serviceItemsConstants.PUT_SERVICE_ITEMS_DATA_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: serviceItemsConstants.PUT_SERVICE_ITEMS_DATA_ERROR,
            error
        };
    }
}

function postServiceItems(data) {
    return dispatch => {
        dispatch(request());

        return servicesServiceItems.postServiceItems(data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: serviceItemsConstants.POST_SERVICE_ITEMS_REQUEST };
    }

    function success(data) {
        return {
            type: serviceItemsConstants.POST_SERVICE_ITEMS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: serviceItemsConstants.POST_SERVICE_ITEMS_ERROR,
            error
        };
    }
}

function postServiceItemsSort(serviceGroupCode, data) {
    return dispatch => {
        dispatch(request());

        return servicesServiceItems.postServiceItemsSort(serviceGroupCode, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: serviceItemsConstants.POST_SERVICE_ITEMS_SORT_REQUEST };
    }

    function success(data) {
        return {
            type: serviceItemsConstants.POST_SERVICE_ITEMS_SORT_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: serviceItemsConstants.POST_SERVICE_ITEMS_SORT_ERROR,
            error
        };
    }
}

function getServiceItems(serviceGroupCode) {
    return dispatch => {
        dispatch(request());

        return servicesServiceItems.getServiceItems(serviceGroupCode).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: serviceItemsConstants.GET_SERVICE_ITEMS_REQUEST };
    }

    function success(data) {
        return {
            type: serviceItemsConstants.GET_SERVICE_ITEMS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: serviceItemsConstants.GET_SERVICE_ITEMS_ERROR,
            error
        };
    }
}

function patchServiceItemStatus(serviceItemId, data) {
    return dispatch => {
        dispatch(request());

        return servicesServiceItems.patchServiceItemStatus(serviceItemId, data).then(
            data => dispatch(success(data)),
            error => dispatch(failure(error))
        );
    };

    function request() {
        return { type: serviceItemsConstants.PATCH_SERVICE_ITEM_STATUS_REQUEST };
    }

    function success() {
        Message({
            message: translate(i18.API.Messages.ServiceItemStatusUpdated)
        });

        return {
            type: serviceItemsConstants.PATCH_SERVICE_ITEM_STATUS_SUCCESS,
            payload: {
                id: serviceItemId,
                isDisabled: data.IsDisabled
            }
        };
    }

    function failure(error) {
        return {
            type: serviceItemsConstants.PATCH_SERVICE_ITEM_STATUS_ERROR,
            error
        };
    }
}

function postServiceItemsV2(data) {
    return async dispatch => {
        dispatch(request());

        try {
            const response = await servicesServiceItems.postServiceItemsV2(data);
            dispatch(success(response));
            return response;
        } catch (error) {
            dispatch(failure(error));
            throw error;
        }
    };

    function request() {
        return { type: serviceItemsConstants.POST_SERVICE_ITEMS_REQUEST_V2 };
    }

    function success(data) {
        return {
            type: serviceItemsConstants.POST_SERVICE_ITEMS_SUCCESS_V2,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: serviceItemsConstants.POST_SERVICE_ITEMS_ERROR_V2,
            error
        };
    }
}

export const serviceItemsActions = {
    getServiceItems,
    putServiceItemsData,
    postServiceItems,
    postServiceItemsSort,
    patchServiceItemStatus,
    postServiceItemsV2
};
