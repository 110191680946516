import { internalClientConstants } from './constants';

export const internalClientReducers = function (state = {
    sendInternalClientPost: false
}, action) {
    switch (action.type) {
    case internalClientConstants.POST_INTERNAL_CLIENT_REQUEST:
        return {
            ...state,
            send: true,
            sendInternalClientPost: true
        };
    case internalClientConstants.POST_INTERNAL_CLIENT_SUCCESS:
        return {
            ...state,
            send: false,
            sendInternalClientPost: false
        };
    case internalClientConstants.POST_INTERNAL_CLIENT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendInternalClientPost: false
        };

    case internalClientConstants.PUT_INTERNAL_CLIENT_REQUEST:
        return {
            ...state,
            send: true,
            sendInternalClientPut: true
        };
    case internalClientConstants.PUT_INTERNAL_CLIENT_SUCCESS:
        return {
            ...state,
            send: false,
            sendInternalClientPut: false
        };
    case internalClientConstants.PUT_INTERNAL_CLIENT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendInternalClientPut: false
        };

    case internalClientConstants.GET_INTERNAL_CLIENT_REQUEST:
        return {
            ...state,

            send: true,
            sendInternalClientGet: true
        };
    case internalClientConstants.GET_INTERNAL_CLIENT_SUCCESS:
        return {
            ...state,
            client: action.payload,
            send: false,
            sendInternalClientGet: false
        };
    case internalClientConstants.GET_INTERNAL_CLIENT_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendInternalClientGet: false
        };
    default:
        return state;
    }
};
