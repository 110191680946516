import React from 'react';
import PropTypes from 'prop-types';
import { Result } from 'antd';

import { routerPaths } from '../../../utilities';
import { ButtonPrimary } from '../../../components';
import { history } from '../../../api';

export const ResetPasswordInformation = ({ title, buttonText }) => {
    return (
        <div className='public_inner_sm'>
            <Result
                status='success'
                title={title}
                extra={[
                    <ButtonPrimary
                        type='primary'
                        key='login'
                        onClick={() => history.replace(routerPaths.login)}
                    >
                        {buttonText}
                    </ButtonPrimary>
                ]}
            />
        </div>
    );
};

ResetPasswordInformation.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};
