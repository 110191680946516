import { getCountryCallingCode, isSupportedCountry, AsYouType, Metadata, getCountries } from 'libphonenumber-js/min';
import { getLanguage, getCountrySettingValue, countrySettings } from '.';

const metadata = new Metadata();

export const addPlusSign = (input) => `+${input}`;

const getDefaultCountry = () => {
    const lng = getLanguage();
    return isSupportedCountry(lng) ? lng : 'LT';
};

export const getPhoneNumberParts = (phoneNumber) => {
    const parsed = parsePhoneNumber(phoneNumber);
    const number = parsed.getNumber()?.nationalNumber || '';
    const countryCode = parsed.getCallingCode() || '';

    if (!phoneNumber || !number) {
        return {
            prefix: countryCode ? addPlusSign(countryCode) : getDefaultPhonePrefix(),
            number: ''
        };
    }

    return {
        prefix: addPlusSign(countryCode),
        number: number
    };
};

export const getUniqueCallingCodes = () => {
    const allPosibbleCountries = getCountries();

    return [...new Set(allPosibbleCountries.map(callingCode =>
        getCountryCallingCode(callingCode)
    ))].sort();
};

export const getSelectableCallingCodes = () => getUniqueCallingCodes().map(callingCode => {
    const formatted = addPlusSign(callingCode);

    return {
        value: formatted,
        label: formatted
    };
});

export const getDefaultPhonePrefix = () => {
    const countrySettingValue = getCountrySettingValue(countrySettings.PhonePlaceholder);

    if (countrySettingValue) {
        return countrySettingValue;
    }

    const country = getDefaultCountry();
    return addPlusSign(getCountryCallingCode(country));
};

export const parsePhoneNumber = (phoneNumber) => {
    const country = getDefaultCountry();
    const asYouType = new AsYouType(country);

    if (phoneNumber) {
        asYouType.input(phoneNumber);
    }

    return asYouType;
};

export const getTelephoneLength = (phoneNumber) => {
    const parsed = parsePhoneNumber(phoneNumber);
    const country = parsed.getCountry();
    const callingCode = parsed.getCallingCode();

    metadata.selectNumberingPlan(country);
    const possibleLengths = metadata.numberingPlan?.possibleLengths()?.filter(len => len <= 13) || [7, 13];
    const prefixLength = 1 + String(callingCode || '370').length;

    const min = Math.min(...possibleLengths);
    const max = Math.max(...possibleLengths);

    return {
        min: min + prefixLength,
        max: max + prefixLength,
        withoutPrefix: {
            min,
            max
        }
    };
};
