import { countryVatServices } from './services';
import { countryVatConstants } from './constants';
import { Message } from '../../../components/message';
import { i18, translate } from '../../../utilities';

export const countryVatActions = {
    getCountryVats,
    postCountryVat,
    postVat,
    putVat,
    deleteVat
};

function getCountryVats () {
    return dispatch => {
        dispatch(request());

        return countryVatServices.getCountryVats()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: countryVatConstants.GET_COUNTRY_VAT_REQUEST };
    }

    function success (data) {
        return {
            type: countryVatConstants.GET_COUNTRY_VAT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: countryVatConstants.GET_COUNTRY_VAT_ERROR,
            error
        };
    }
}

function postCountryVat (data) {
    return dispatch => {
        dispatch(request());

        return countryVatServices.postCountryVat(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: countryVatConstants.POST_COUNTRY_VAT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.CountryVatAdded)
        });

        return {
            type: countryVatConstants.POST_COUNTRY_VAT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: countryVatConstants.POST_COUNTRY_VAT_ERROR,
            error
        };
    }
}

function postVat (countryVatId, data) {
    return dispatch => {
        dispatch(request());

        return countryVatServices.postVat(countryVatId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: countryVatConstants.POST_VAT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.CountryVatAdded)
        });

        return {
            type: countryVatConstants.POST_VAT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: countryVatConstants.POST_VAT_ERROR,
            error
        };
    }
}

function putVat (countryVatId, data) {
    return dispatch => {
        dispatch(request());

        return countryVatServices.putVat(countryVatId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: countryVatConstants.PUT_VAT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.CountryVatUpdated)
        });

        return {
            type: countryVatConstants.PUT_VAT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: countryVatConstants.PUT_VAT_ERROR,
            error
        };
    }
}

function deleteVat (countryVatId, data) {
    return dispatch => {
        dispatch(request());

        return countryVatServices.deleteVat(countryVatId, data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: countryVatConstants.DELETE_VAT_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.CountryVatDeleted)
        });

        return {
            type: countryVatConstants.DELETE_VAT_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: countryVatConstants.DELETE_VAT_ERROR,
            error
        };
    }
}
