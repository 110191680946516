import React from 'react';

import { FormField, formFieldType } from '../../../components';
import { fieldNames } from '../fieldNames';
import {
    i18
} from '../../../utilities';

export const CompanyClient = () => {
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <FormField
                        title={i18.Job.Labels.ClientName}
                        borderTitle
                        name={fieldNames.client.companyName}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <FormField
                        title={i18.Job.Labels.ClientCompanyCode}
                        borderTitle
                        name={fieldNames.client.companyCode}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <FormField
                        title={i18.Job.Labels.ClientCompanyVatCode}
                        borderTitle
                        name={fieldNames.client.vatPayerCode}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
        </>
    );
};
