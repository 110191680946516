import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Table, Input } from 'antd';
import { i18, translate } from '../../../utilities';
import { FilterField, filterFieldTypes, Translate, ContentHeader, ButtonWrapper, ButtonGroup } from '../../../components';
import { SearchOutlined } from '@ant-design/icons';

export class PartnersSelectionGrid extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            selectedPartnerIds: this.props.selectedRowKeys,
            selectedPartnersData: [],
            ...this.props.defaultFilter
        };

        this.setFilter = this.setFilter.bind(this);
        this.setOrderAndPagination = this.setOrderAndPagination.bind(this);
        this.onSelectPartner = this.onSelectPartner.bind(this);
        this.onConfirmPartnersSelection = this.onConfirmPartnersSelection.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount () {
        this.props.fetchOdata(this.state);
    }

    setFilter (filter, resetPagination) {
        this.setState(prevState => ({
            filters: [
                ...prevState.filters.filter(e => e.name !== filter.name),
                ...filter.value !== null ? [filter] : []
            ],
            ...(resetPagination && {
                pagination: this.props.defaultFilter.pagination
            })
        }), () => {
            this.props.fetchOdata(this.state);
        });
    }

    setOrderAndPagination (pagination, columns, order) {
        const orders = [];

        if (Array.isArray(order)) {
            orders.push(
                ...order.map(item => {
                    return {
                        name: item.columnKey,
                        order: item.order === 'ascend' ? 'asc' : 'desc'
                    };
                })
            );
        } else if (order.order) {
            orders.push({
                name: order.columnKey,
                order: order.order === 'ascend' ? 'asc' : 'desc'
            });
        }

        this.setState({
            orders: orders,
            pagination: {
                current: pagination.current,
                pageSize: pagination.pageSize
            }
        }, () => {
            this.props.fetchOdata(this.state);
        });
    }

    onSelectPartner (selectedRowKeys, selectedRowData) {
        this.setState({
            selectedPartnerIds: [...selectedRowKeys],
            selectedPartnersData: [...selectedRowData]
        });
    }

    onConfirmPartnersSelection () {
        if (this.props.multiplePartners) {
            this.props.onPartnersSelected(this.state.selectedPartnerIds, this.state.selectedPartnersData);
        } else if (this.state.selectedPartnerIds && this.state.selectedPartnerIds.length > 0 &&
                   this.state.selectedPartnersData && this.state.selectedPartnerIds.length > 0) {
            this.props.onPartnersSelected(this.state.selectedPartnerIds[0], this.state.selectedPartnersData[0]);
        }
    }

    onClose (e) {
        this.setState({
            selectedPartnerIds: this.props.selectedRowKeys
        });

        this.props.onCloseForm(e);
    }

    render () {
        const columns = [
            {
                dataIndex: 'FirstName',
                key: 'FirstName',
                title: <Translate value={i18.PartnerSelection.Labels.FirstName} />,
                ...FilterField({
                    name: 'FirstName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'FirstName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'FirstName')
                })
            }, {
                dataIndex: 'LastName',
                key: 'LastName',
                title: <Translate value={i18.PartnerSelection.Labels.LastName} />,
                ...FilterField({
                    name: 'LastName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'LastName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'LastName')
                })
            }, {
                dataIndex: 'CompanyName',
                key: 'CompanyName',
                title: <Translate value={i18.PartnerSelection.Labels.CompanyName} />,
                ...FilterField({
                    name: 'CompanyName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'CompanyName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'CompanyName')
                })
            }, {
                dataIndex: 'Email',
                key: 'Email',
                title: <Translate value={i18.PartnerSelection.Labels.Email} />,
                ...FilterField({
                    name: 'Email',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'Email'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'Email')
                })
            }
        ];

        return (
            <>
                <div className='content_block'>
                    <ContentHeader
                        title={
                            <>
                                <Translate className='mr-2' value={i18.PartnerSelection.Titles.PartnerList} />
                                <span className='fw_400 color-black-50'>({this.props.odataPartnerGrid['@odata.count']})</span>
                            </>
                        }
                        extra={
                            <ButtonGroup>
                                <Input
                                    style={{
                                        marginRight: 20
                                    }}
                                    placeholder={ translate(i18.PartnerSelection.Labels.SearchPlaceholder) }
                                    prefix={<SearchOutlined/>}
                                    onPressEnter={({ target }) => {
                                        this.setFilter({
                                            name: this.props.searchAggregateName,
                                            type: filterFieldTypes.text,
                                            value: target.value,
                                            operator: null
                                        });
                                    }}
                                />
                                <ButtonWrapper
                                    type='secondary'
                                    onClick={this.onClose}
                                >
                                    <Translate value={i18.PartnerSelection.Buttons.Cancel} />
                                </ButtonWrapper>
                                <ButtonWrapper
                                    type='primary'
                                    onClick={this.onConfirmPartnersSelection}
                                >
                                    <Translate value={i18.PartnerSelection.Buttons.Select} />
                                </ButtonWrapper>
                            </ButtonGroup>
                        }
                    />
                    <ScrollContainer
                        style={{
                            overflowX: 'auto'
                        }}
                    >
                        <Table
                            rowKey='PartnerId'
                            onChange={(pagination, columns, order) => this.setOrderAndPagination(pagination, columns, order)}
                            rowClassName='cursor_pointer'
                            columns={columns}
                            dataSource={this.props.odataPartnerGrid.value || []}
                            loading={this.props.odataPartnerGridLoading}
                            rowSelection={{
                                selectedRowKeys: this.state.selectedPartnerIds || this.props.selectedRowKeys,
                                type: this.props.multiplePartners ? 'check' : 'radio',
                                preserveSelectedRowKeys: false,
                                onChange: this.onSelectPartner
                            }}
                            pagination={{
                                total: this.props.odataPartnerGrid['@odata.count'],
                                pageSize: this.state.pagination.pageSize,
                                current: this.state.pagination.current
                            }}
                        />
                    </ScrollContainer>
                </div>
            </>
        );
    }
};

PartnersSelectionGrid.propTypes = {
    multiplePartners: PropTypes.bool.isRequired,
    odataPartnerGrid: PropTypes.object,
    odataPartnerGridLoading: PropTypes.bool,
    searchAggregateName: PropTypes.string.isRequired,
    onOpenProfile: PropTypes.func.isRequired,
    fetchOdata: PropTypes.func.isRequired,

    selectedRowKeys: PropTypes.array,
    onPartnersSelected: PropTypes.func.isRequired,
    onCloseForm: PropTypes.func.isRequired,
    defaultFilter: PropTypes.object.isRequired
};
