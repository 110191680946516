import React from 'react';
import PropTypes from 'prop-types';

import { dateFormat, formatDate, i18, i18cl } from '../../../../../utilities';
import { Classificator, Translate } from '../../../../../components';

export const PartnerCommunicationsData = (props) => {
    const data = [
        {
            label: <Translate value={i18.PartnerCommunications.Labels.LastCommunication} />,
            value: formatDate(props.data.lastCommunication, dateFormat().dateTime)
        }, {
            label: <Translate value={i18.PartnerCommunications.Labels.NextScheduledCall} />,
            value: <span>{formatDate(props.data.nextScheduledCall, dateFormat().dateTime)}</span>
        }, {
            label: <Translate value={i18.PartnerCommunications.Labels.LastSentInformation} />,
            value: props.data.lastSentInformation
        }, {
            label: <Translate value={i18.PartnerCommunications.Labels.DateOfLastSentInformation} />,
            value: formatDate(props.data.dateOfLastSentInformation, dateFormat().dateTime)
        }, {
            label: <Translate value={i18.PartnerCommunications.Labels.NonApprovalReason} />,
            value: props.data.nonApprovalReason && <Classificator value={i18cl.NON_APPROVAL_REASON + props.data.nonApprovalReason} />
        }, {
            label: <Translate value={i18.PartnerCommunications.Labels.Comments} />,
            value: props.data.comments
        }
    ];

    return (
        <div className='list_info_wrapper'>
            {data.map((item, index) =>
                <div
                    key={index}
                    className={'list_info row ' + item.className || ''}
                >
                    <div className='list_info_label col-12 col-xl-5'>{item.label}</div>
                    <div className='list_info_value col-12 col-xl'>{item.value}</div>
                </div>
            )}
        </div>
    );
};

PartnerCommunicationsData.propTypes = {
    block: PropTypes.any,
    blockForm: PropTypes.any,
    data: PropTypes.object.isRequired,
    status: PropTypes.any,
    statusForm: PropTypes.any,
    unlock: PropTypes.object
};
