import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import moment from 'moment';

export const parseToken = (token) => {
    var decoded = jwtDecode(token);
    return decoded;
};

class Cookie {
    setCookie (token) {
        Cookies.set('_admin', token, { secure: true, sameSite: 'Lax', expires: moment(parseToken(token).exp * 1000).toDate() });
    }

    getCookie () {
        return Cookies.get('_admin');
    }

    removeCookie () {
        Cookies.remove('_admin');
    }
}

export default new Cookie();
