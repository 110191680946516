import { jobCreationConstants } from './constants';

export const jobCreationReducers = function (state = {
    fileUploadSettings: {},
    services: [],
    vatTypes: [],
    workStatuses: [],
    jobTypes: [],
    orderStatuses: []
}, action) {
    switch (action.type) {
    case jobCreationConstants.GET_JOB_CREATION_INPUTS_REQUEST:
        return {
            ...state,
            send: true,
            sendJobCreationInputsGET: true
        };
    case jobCreationConstants.GET_JOB_CREATION_INPUTS_SUCCESS:
        return {
            ...state,
            ...action.payload,
            send: false,
            sendJobCreationInputsGET: false
        };
    case jobCreationConstants.GET_JOB_CREATION_INPUTS_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendJobCreationInputsGET: false
        };
    case jobCreationConstants.POST_JOB_REQUEST:
        return {
            ...state,
            send: true,
            sendJobPOST: true
        };
    case jobCreationConstants.POST_JOB_SUCCESS:
        return {
            ...state,
            send: false,
            sendJobPOST: false
        };
    case jobCreationConstants.POST_JOB_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendJobPOST: false
        };
    default:
        return state;
    }
};
