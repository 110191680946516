import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Form, Typography, Popconfirm } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MenuOutlined } from '@ant-design/icons';

import { Translate, ContentHeader } from '../../components';
import { serviceItemsActions, servicesActions } from '../../api';
import { classificator, i18, i18cl } from '../../utilities';
import { ServiceFilterForm, DraggableBodyRow, EditableCell } from './components';

import './index.less';
import { useServiceItemModal } from '../../hooks/useServiceItemModal';
import ScrollContainer from 'react-indiana-drag-scroll';

const ServiceItemsContainer = props => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [serviceCatalogId, setServiceCatalogId] = useState(
        props.history?.location?.state?.initialServiceCatalogId || null
    );
    const handleShowServiceItemModal = useServiceItemModal();

    useEffect(() => {
        props.dispatch(servicesActions.getServices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setData(props.serviceItems);
    }, [props.serviceItems]);

    const getRowClassName = record => (record.isDisabled ? 'bg-lightgrey cursor_pointer' : 'cursor_pointer');

    const handleStatusChange = async record => {
        const newStatus = !record.isDisabled;
        await props.dispatch(serviceItemsActions.patchServiceItemStatus(record.id, { IsDisabled: newStatus }));

        const serviceGroupCode = props.services.find(
            service => service.serviceCatalogId === serviceCatalogId
        )?.serviceCatalogCode;
        const serviceName = classificator(i18cl.SERVICES_CATALOG + serviceGroupCode);

        handleShowServiceItemModal(serviceCatalogId, serviceName, record.id, record.title, record.description);
    };

    const handleDataCopy = record => {
        const copiedTextArray = [serviceCatalogId, record.id, record.title];

        if (record.description !== undefined && record.description !== null) {
            copiedTextArray.push(record.description);
        }
        copiedTextArray.push(record.unitOfMeasure);
        const copiedText = copiedTextArray.join(', ');

        navigator.clipboard.writeText(copiedText);
    };

    const handleSort = useCallback((serviceGroupCode, sortedData) => {
        if (!serviceGroupCode) {
            return;
        }

        const mappedDataIds = sortedData.map(item => item?.id || '');

        props.dispatch(serviceItemsActions.postServiceItemsSort(serviceGroupCode, mappedDataIds));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMoveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dataCopy = [...data];
            const removed = dataCopy.splice(dragIndex, 1);
            dataCopy.splice(hoverIndex, 0, ...removed);
            setData(dataCopy);
            handleSort(serviceCatalogId, dataCopy);
        },
        [serviceCatalogId, handleSort, data]
    );

    const isEditing = record => record.id === editingKey;

    const handleCellEdit = record => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id);
    };

    const handleCellSave = async key => {
        try {
            const row = await form.validateFields([
                form.jobTitle,
                form.unitOfMeasure,
                form.Price,
                form.MinimalPrice,
                form.Margin,
                form.Coefficient,
                form.PriceReduction
            ]);

            const newData = [...data];
            const index = newData.findIndex(item => key === item.id);
            if (index > -1) {
                const item = newData[index];
                const newItem = { ...item, ...row, jobTitle: row.title };
                newData.splice(index, 1, newItem);
                await props.dispatch(serviceItemsActions.putServiceItemsData(item.id, newItem));
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch {}
    };

    const handleCellCancel = () => {
        setEditingKey('');
    };

    const columns = [
        {
            width: '1%',
            render: (ctx, data, idx) => {
                return (
                    <DraggableBodyRow index={idx} handleMoveRow={handleMoveRow}>
                        <MenuOutlined />
                    </DraggableBodyRow>
                );
            }
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.ServiceId} />,
            render: () => serviceCatalogId,
            editable: false
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.JobId} />,
            dataIndex: 'id',
            editable: false
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.JobTitle} />,
            dataIndex: 'title',
            editable: true
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.JobDescription} />,
            dataIndex: 'description',
            onHeaderCell: () => ({
                style: {
                    width: 400,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }
            }),
            render: text => <div style={{ width: 400 }}>{text}</div>,
            editable: true
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.UnitOfMeasure} />,
            dataIndex: 'unitOfMeasure',
            editable: true
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.Price} />,
            dataIndex: 'price',
            editable: true
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.MinimalPrice} />,
            dataIndex: 'minimalPrice',
            editable: true
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.Margin} />,
            dataIndex: 'margin',
            editable: true
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.Coefficient} />,
            dataIndex: 'coefficient',
            editable: true
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.PriceReduction} />,
            dataIndex: 'priceReduction',
            editable: true
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.Code} />,
            dataIndex: 'code',
            editable: false
        },
        {
            title: <Translate value={i18.ServiceItems.Labels.Actions} />,
            dataIndex: 'actions',
            render: (_, record) => {
                const editable = isEditing(record);
                const statusIsDisabled = record.isDisabled;

                return editable ? (
                    <span>
                        <Typography.Link onClick={() => handleCellSave(record.id)} className="mr-3">
                            <Translate value={i18.ServiceItems.Labels.Save} />
                        </Typography.Link>
                        <Typography.Link onClick={handleCellCancel}>
                            <Translate value={i18.ServiceItems.Labels.Cancel} />
                        </Typography.Link>
                    </span>
                ) : (
                    <span>
                        <Typography.Link
                            disabled={editingKey !== ''}
                            onClick={() => handleCellEdit(record)}
                            className="mr-3"
                        >
                            <Translate value={i18.ServiceItems.Labels.Edit} />
                        </Typography.Link>
                        <Popconfirm
                            title={
                                statusIsDisabled ? (
                                    <Translate value={i18.ServiceItems.Labels.ConfirmationUnhide} />
                                ) : (
                                    <Translate value={i18.ServiceItems.Labels.ConfirmationHide} />
                                )
                            }
                            onConfirm={() => handleStatusChange(record)}
                            okText={<Translate value={i18.ServiceItems.Labels.ConfirmationOkText} />}
                            cancelText={<Translate value={i18.ServiceItems.Labels.ConfirmationCancelText} />}
                        >
                            <Typography.Link>
                                <Translate
                                    disabled={editingKey !== ''}
                                    value={
                                        statusIsDisabled ? i18.ServiceItems.Labels.Unhide : i18.ServiceItems.Labels.Hide
                                    }
                                    className="mr-3"
                                />
                            </Typography.Link>
                        </Popconfirm>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => handleDataCopy(record)}>
                            <Translate value={i18.ServiceItems.Labels.Copy} />
                        </Typography.Link>
                    </span>
                );
            }
        }
    ];

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: ['serviceId', 'price', 'margin', 'coefficient', 'priceReduction', 'minimalPrice'].includes(
                    col.dataIndex
                )
                    ? 'number'
                    : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const handleSubmit = ({ serviceGroupCode }) => {
        setServiceCatalogId(serviceGroupCode);
        props.dispatch(serviceItemsActions.getServiceItems(serviceGroupCode));
    };

    return (
        <>
            <ContentHeader title={<Translate value={i18.ServiceItems.Titles.ServiceItems} />} />
            <div className="content_block">
                <ServiceFilterForm
                    services={props.services}
                    submit={handleSubmit}
                    serviceCatalogId={serviceCatalogId}
                    submitting={props.sendServiceItemsGET}
                />
                <div className="my-5">
                    <Translate className="color-danger" value={i18.ServiceItems.Labels.Info} />
                </div>
                <Form form={form} component={false} validateMessages={false}>
                    <ScrollContainer
                        style={{
                            overflowX: 'auto'
                        }}
                        ignoreElements="#draggable, input"
                    >
                        <DndProvider backend={HTML5Backend}>
                            <Table
                                className="price-list-table"
                                rowKey="id"
                                rowClassName={getRowClassName}
                                columns={mergedColumns}
                                dataSource={data}
                                pagination={false}
                                loading={props.sendServiceItemsGET || props.sendServiceItemStatusPATCH}
                                style={{ minWidth: 1400 }}
                                components={{
                                    body: {
                                        cell: EditableCell
                                    }
                                }}
                            />
                        </DndProvider>
                    </ScrollContainer>
                </Form>
            </div>
        </>
    );
};

ServiceItemsContainer.propTypes = {
    dispatch: PropTypes.func,
    services: PropTypes.array,
    serviceItems: PropTypes.array,
    sendServiceItemsGET: PropTypes.bool,
    sendServiceItemStatusPATCH: PropTypes.bool,
    history: PropTypes.shape({
        location: PropTypes.object
    })
};

function mapStateToProps(state) {
    const { services } = state.servicesReducers;

    const { serviceItems, sendServiceItemStatusPATCH, sendServiceItemsGET } = state.serviceItemsReducers;

    return {
        services,
        serviceItems,
        sendServiceItemStatusPATCH,
        sendServiceItemsGET
    };
}

const connectedServiceItemsContainer = connect(mapStateToProps)(ServiceItemsContainer);
export { connectedServiceItemsContainer as ServiceItemsContainer };
