import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InternalClientSelectionField } from './internalClientSelectionField';
import { FormField, formFieldType, Translate } from '../../../components';
import { fieldNames } from './fieldNames';
import {
    getUser,
    i18,
    requiredPhone
} from '../../../utilities';

const OtherSettingsFormSection = (props) => {
    useEffect(() => {
        props.updateKeyValue(fieldNames.responsibleManager, `${getUser().firstName} ${getUser().lastName}`);
    });

    return (
        <>
            <Translate className='fs-16 fw_600' value={i18.JobCreateForm.Titles.OtherSettings} />
            <div className='container-default mt-4'>
                <div className='row mb-2'>
                    <div className='col-12'>
                        <FormField
                            placeholder={i18.JobCreateForm.Labels.ManagerPhone}
                            name={fieldNames.managerPhone}
                            component={formFieldType.phone}
                            validate={requiredPhone({ nullable: true })}
                        />
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-12'>
                        <FormField
                            placeholder={i18.JobCreateForm.Labels.ResponsibleManager}
                            name={fieldNames.responsibleManager}
                            component={formFieldType.input}
                            maxLength={50}
                        />
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-12'>
                        <FormField
                            placeholder={i18.JobCreateForm.Labels.ContactNumber}
                            name={fieldNames.contactNumber}
                            component={formFieldType.phone}
                            validate={requiredPhone}
                        />
                    </div>
                </div>
                <div className='row mb-4'>
                    <InternalClientSelectionField
                        classNames='col-12'
                        nameValue={fieldNames.internalClientName}
                        nameId={fieldNames.internalClientId}
                        updateKeyValue={props.updateKeyValue}
                    />
                </div>
            </div>
        </>
    );
};

OtherSettingsFormSection.propTypes = {
    updateKeyValue: PropTypes.func.isRequired
};

const connectedOtherSettingsFormSection = connect(null)(OtherSettingsFormSection);
export { connectedOtherSettingsFormSection as OtherSettingsFormSection };
