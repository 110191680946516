import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { classificatorTranslatedArray, i18, i18cl, i18clGroups, routerPaths } from '../../../../utilities';
import { history, partnerProfileActions, partnerProfileConstants } from '../../../../api';
import { ButtonGroup, ButtonWrapper, Classificator, PopConfirmWrapper, Translate } from '../../../../components';
import { PartnerServiceDocumentsList, PartnerServiceData, PartnerServiceForm } from './';

const { Option } = Select;

class PartnerServiceContainer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            editing: false
        };

        this.change = this.change.bind(this);
        this.update = this.update.bind(this);
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount () {
        this.props.dispatch(partnerProfileActions.getProfileService(this.props.match.params.partnerId, this.props.match.params.partnerServiceId));
        this.props.dispatch(partnerProfileActions.getProfileServiceDocuments(this.props.match.params.partnerId, this.props.match.params.partnerServiceId));
    }

    change (value) {
        this.props.dispatch(partnerProfileActions.pathProfileService(this.props.match.params.partnerId, {
            profileServiceId: parseInt(this.props.match.params.partnerServiceId),
            profileServiceStatus: value
        })).then(() => {
            this.props.dispatch(partnerProfileActions.getProfileService(this.props.match.params.partnerId, this.props.match.params.partnerServiceId, true));
        });
    }

    update () {
        this.props.dispatch(partnerProfileActions.getProfileServiceDocuments(this.props.match.params.partnerId, this.props.match.params.partnerServiceId, true));
    }

    submit (values) {
        this.props.dispatch(partnerProfileActions.putProfileService(this.props.match.params.partnerId, values)).then(() => {
            this.props.dispatch(partnerProfileActions.getProfileService(this.props.match.params.partnerId, this.props.match.params.partnerServiceId, true)).then(() => {
                this.setState({ editing: false });
            });
        });
    }

    delete () {
        this.props.dispatch(partnerProfileActions.deleteProfileService(this.props.match.params.partnerId, this.props.match.params.partnerServiceId)).then(res => {
            if (res && res.type === partnerProfileConstants.DELETE_PARTNER_PROFILE_SERVICE_SUCCESS) {
                history.replace(routerPaths.partners.index + '/' + this.props.match.params.partnerId + '/services');
            }
        });
    }

    render () {
        if (!this.props.partnerProfileService) {
            return null;
        }

        return (
            <div className='content_block'>
                <div className='content_subtitle_wrapper'>
                    <div className='content_subtitle fs-20 fw_600'>
                        <span className='mr-5'>{this.props.partnerProfileService.serviceCode}</span>
                        <Classificator value={i18cl.SERVICES_CATALOG + this.props.partnerProfileService.serviceCode} />
                    </div>
                    <div>
                        <ButtonGroup>
                            <PopConfirmWrapper
                                placement='top'
                                title={<Translate value={i18.PartnerService.Messages.Delete} />}
                                onConfirm={() => this.delete()}
                            >
                                <ButtonWrapper
                                    danger
                                    type='text'
                                    icon={<DeleteOutlined />}
                                    loading={this.props.sendPartnerServiceDELETE}
                                >
                                    <Translate value={i18.PartnerService.Buttons.Delete} />
                                </ButtonWrapper>
                            </PopConfirmWrapper>
                            {!this.state.editing && (
                                <ButtonWrapper
                                    type='secondary'
                                    icon={<EditOutlined />}
                                    onClick={() => this.setState({ editing: true })}
                                >
                                    <Translate value={i18.PartnerService.Buttons.Edit} />
                                </ButtonWrapper>
                            )}
                        </ButtonGroup>
                    </div>
                </div>
                <div className='mb-4'>
                    {this.state.editing ? (
                        <PartnerServiceForm
                            values={this.props.partnerProfileService}
                            areas={this.props.publicServiceAreas}
                            cancel={() => this.setState({ editing: false })}
                            submit={this.submit}
                            submitting={this.props.sendPartnerProfileServicePATCH || this.props.sendPartnerProfileServiceGET}
                            currency={this.props.currency}
                        />
                    ) : (
                        <PartnerServiceData
                            data={this.props.partnerProfileService}
                            currency={this.props.currency}
                            statusChanger={(
                                <Select
                                    style={{
                                        width: 200
                                    }}
                                    value={this.props.partnerProfileService.profileServiceStatus}
                                    onChange={(e) => this.change(e)}
                                    disabled={this.props.sendPartnerProfileServiceGET}
                                    loading={this.props.sendPartnerProfileServicePATCH}
                                >
                                    {classificatorTranslatedArray(i18clGroups.STATUSES_PROFILE_SERVICE).map((item, index) =>
                                        <Option
                                            key={index}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                    )}
                                </Select>
                            )}
                        />
                    )}
                </div>
                {this.props.partnerProfileServiceDocuments.length > 0 && (
                    <div className='mb-4'>
                        <div className='content_subtitle_wrapper'>
                            <div className='content_subtitle'>
                                <Translate value={i18.PartnerService.Titles.Documents} />
                            </div>
                        </div>
                        <PartnerServiceDocumentsList
                            data={this.props.partnerProfileServiceDocuments}
                            dispatch={this.props.dispatch}
                            loading={this.props.sendPartnerProfileServiceDocumentsGET}
                            profileId={this.props.match.params.partnerId}
                            profileServiceId={this.props.match.params.partnerServiceId}
                            update={this.update}
                        />
                    </div>
                )}
            </div>
        );
    }
}

PartnerServiceContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    partnerProfileService: PropTypes.object,
    publicServiceAreas: PropTypes.array.isRequired,
    partnerProfileServiceDocuments: PropTypes.array.isRequired,
    sendPartnerProfileServiceGET: PropTypes.bool,
    sendPartnerProfileServicePATCH: PropTypes.bool,
    sendPartnerProfileServiceDocumentsGET: PropTypes.bool,
    sendPartnerServiceDELETE: PropTypes.bool,
    currency: PropTypes.string
};

function mapStateToProps (state) {
    const {
        partnerProfileService,
        partnerProfileServiceDocuments,
        sendPartnerServiceDELETE,
        sendPartnerProfileServiceGET,
        sendPartnerProfileServicePATCH,
        sendPartnerProfileServiceDocumentsGET
    } = state.partnerProfileReducers;
    const {
        publicServiceAreas,
        publicLocalization: {
            countrySettings: { CURRENCY: currency }
        }
    } = state.publicReducers;

    return {
        partnerProfileService,
        partnerProfileServiceDocuments,
        publicServiceAreas,
        sendPartnerProfileServiceGET,
        sendPartnerProfileServicePATCH,
        sendPartnerProfileServiceDocumentsGET,
        sendPartnerServiceDELETE,
        currency
    };
}

const connectedPartnerServiceContainer = withRouter(connect(mapStateToProps)(PartnerServiceContainer));
export { connectedPartnerServiceContainer as PartnerServiceContainer };
