import { partnerReadConstants } from './constants';

export const partnerReadReducers = function (state = {
    partnerReadActivityLog: [],
    partnerReadCommunicationLog: {},
    partnerMarketingAgreed: {},
    partnerMarketingAgreedAndApproved: {}
}, action) {
    switch (action.type) {
    case partnerReadConstants.GET_PARTNER_READ_ACTIVITY_LOG_REQUEST:
        return {
            ...state,
            partnerReadActivityLog: [],
            send: true,
            sendPartnerReadActivityLogGET: true
        };
    case partnerReadConstants.GET_PARTNER_READ_ACTIVITY_LOG_SUCCESS:
        return {
            ...state,
            partnerReadActivityLog: action.payload,
            send: false,
            sendPartnerReadActivityLogGET: false
        };
    case partnerReadConstants.GET_PARTNER_READ_ACTIVITY_LOG_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerReadActivityLogGET: false
        };
    case partnerReadConstants.GET_PARTNER_READ_COMMUNICATION_LOG_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerReadCommunicationLogGET: true
        };
    case partnerReadConstants.GET_PARTNER_READ_COMMUNICATION_LOG_SUCCESS:
        return {
            ...state,
            partnerReadCommunicationLog: action.payload,
            send: false,
            sendPartnerReadCommunicationLogGET: false
        };
    case partnerReadConstants.GET_PARTNER_READ_COMMUNICATION_LOG_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerReadCommunicationLogGET: false
        };
    case partnerReadConstants.GET_PARTNER_MARKETING_AGREED_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerMarketingAgreedGET: true
        };
    case partnerReadConstants.GET_PARTNER_MARKETING_AGREED_SUCCESS:
        return {
            ...state,
            partnerMarketingAgreed: action.payload,
            send: false,
            sendPartnerMarketingAgreedGET: false
        };
    case partnerReadConstants.GET_PARTNER_MARKETING_AGREED_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerMarketingAgreedGET: false
        };
    case partnerReadConstants.GET_PARTNER_MARKETING_AGREED_AND_APPROVED_REQUEST:
        return {
            ...state,
            send: true,
            sendPartnerMarketingAgreedAndApprovedGET: true
        };
    case partnerReadConstants.GET_PARTNER_MARKETING_AGREED_AND_APPROVED_SUCCESS:
        return {
            ...state,
            partnerMarketingAgreedAndApproved: action.payload,
            send: false,
            sendPartnerMarketingAgreedAndApprovedGET: false
        };
    case partnerReadConstants.GET_PARTNER_MARKETING_AGREED_AND_APPROVED_ERROR:
        return {
            ...state,
            errors: action.error,
            send: false,
            sendPartnerMarketingAgreedAndApprovedGET: false
        };
    default:
        return state;
    }
};
