import { requestApi, apiPaths, requestApiFormData, handleResponse, methods } from '../../config';

export const servicesServiceGroups = {
    getServiceGroup,
    getServiceGruops,
    postServiceGroupImage,
    putServiceGroupData
};

function putServiceGroupData (serviceGroupCode, data) {
    return requestApi(apiPaths.serviceGroups.serviceGroups + '/' + serviceGroupCode, {
        method: methods.put,
        data: data
    }).then(handleResponse);
}

function postServiceGroupImage (serviceGroupCode, image) {
    return requestApiFormData(apiPaths.serviceGroups.serviceGroups + '/' + serviceGroupCode + '/image', {
        method: methods.post,
        data: image
    }).then(handleResponse);
}

function getServiceGroup (serviceGroupCode) {
    return requestApi(apiPaths.serviceGroups.serviceGroups + '/' + serviceGroupCode).then(handleResponse);
}

function getServiceGruops () {
    return requestApi(apiPaths.serviceGroups.serviceGroups).then(handleResponse);
}
