import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';

import { Translate } from '../../components';
import { history, serviceGroupsActions } from '../../api';
import { i18, routerPaths } from '../../utilities';

const ServiceGroupsContainer = (props) => {
    useEffect(() => {
        props.dispatch(serviceGroupsActions.getServiceGruops());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: <Translate value={i18.ServiceGroups.Labels.Code} />,
            render: (item) => item.serviceGroupCode,
            width: 1
        }, {
            title: <Translate value={i18.ServiceGroups.Labels.Description} />,
            render: (item) => item.description
        }
    ];

    return (
        <Table
            rowKey='serviceGroupCode'
            rowClassName='cursor_pointer'
            columns={columns}
            dataSource={props.serviceGroups}
            pagination={false}
            loading={props.sendServiceGroupsGET}
            onRow={item => {
                return {
                    onClick: () => history.push(routerPaths.serviceGroups.serviceGroup + item.serviceGroupCode)
                };
            }}
        />
    );
};

ServiceGroupsContainer.propTypes = {
    dispatch: PropTypes.func,
    serviceGroups: PropTypes.array,
    sendServiceGroupsGET: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        serviceGroups
    } = state.serviceGroupsReducers;

    return {
        serviceGroups
    };
}

const connectedServiceGroupsContainer = connect(mapStateToProps)(ServiceGroupsContainer);
export { connectedServiceGroupsContainer as ServiceGroupsContainer };
