import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, FormField, formFieldType } from '../../../../components';
import { getCountryStartingCoordinates, i18 } from '../../../../utilities';
import { LocationAutoComplete } from './locationAutoComplete';
import { fieldNames } from '../../fieldNames';

class ObjectLocationFormSection extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            latitude: undefined,
            longitude: undefined,
            address: '',
            isSelected: false
        };

        this.onAddressSelected = this.onAddressSelected.bind(this);
    }

    onAddressSelected (latitude, longitude, address) {
        this.setState({
            latitude: latitude,
            longitude: longitude,
            address: address,
            isSelected: true
        });

        this.props.updateKeyValue(fieldNames.latitude, latitude);
        this.props.updateKeyValue(fieldNames.longitude, longitude);
        this.props.updateKeyValue(fieldNames.address, address);
    };

    getDefaultCoordinates () {
        return this.state.isSelected
            ? { lat: this.state.latitude, lng: this.state.longitude }
            : getCountryStartingCoordinates();
    }

    render () {
        return (
            <>
                <Translate className='fs-16 fw_600' value={i18.Job.Titles.ObjectLocation} />
                <div className='container-default mt-4'>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <LocationAutoComplete
                                name={fieldNames.address}
                                onAddressSelected={this.onAddressSelected}
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <FormField
                                title={i18.Job.Labels.ObjectLatitude}
                                borderTitle
                                name={fieldNames.latitude}
                                component={formFieldType.input}
                                disabled
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                        <div className='col-6'>
                            <FormField
                                title={i18.Job.Labels.ObjectLongitude}
                                borderTitle
                                name={fieldNames.longitude}
                                component={formFieldType.input}
                                disabled
                                dimmed={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <FormField
                                title={i18.Job.Labels.ObjectAdditionalInfo}
                                borderTitle
                                name={fieldNames.additionalLocationInfo}
                                component={formFieldType.textarea}
                                maxLength={200}
                                disabled={!this.props.isJobEditable}
                                highlighted={this.props.isJobEditable}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

ObjectLocationFormSection.propTypes = {
    isJobEditable: PropTypes.bool.isRequired,
    updateKeyValue: PropTypes.func.isRequired
};

function mapStateToProps (state) {
    const {
        isJobEditable
    } = state.localJobReducers;

    return {
        isJobEditable
    };
}

const connectedObjectLocationFormSection = connect(mapStateToProps)(ObjectLocationFormSection);
export { connectedObjectLocationFormSection as ObjectLocationFormSection };
