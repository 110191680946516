import { jobCreateServices } from './services';
import { jobCreationConstants } from './constants';
import { i18, translate } from '../../../utilities';
import { Message } from '../../../components';

export const jobCreateActions = {
    getJobCreationInputs,
    postJob
};

function getJobCreationInputs () {
    return dispatch => {
        dispatch(request());

        return jobCreateServices.getJobCreationInputs()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: jobCreationConstants.GET_JOB_CREATION_INPUTS_REQUEST };
    }

    function success (data) {
        return {
            type: jobCreationConstants.GET_JOB_CREATION_INPUTS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: jobCreationConstants.GET_JOB_CREATION_INPUTS_ERROR,
            error
        };
    }
}

function postJob (data) {
    return dispatch => {
        dispatch(request());

        return jobCreateServices.postJob(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: jobCreationConstants.POST_JOB_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.JobCreated)
        });

        return {
            type: jobCreationConstants.POST_JOB_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: jobCreationConstants.POST_JOB_ERROR,
            error
        };
    }
}
