import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { formFieldType, FormField, Translate } from '../../../components';
import { fieldNames } from '../fieldNames';
import { i18, translate } from '../../../utilities';
import { PersonClient } from './personClient';
import { CompanyClient } from './companyClient';

const JobClient = props => {
    const {
        job: {
            client: { isCompany }
        }
    } = props;
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.ClientType}
                        borderTitle
                        name={fieldNames.client.type}
                        input={{
                            value: isCompany
                                ? translate(i18.Job.Labels.ClientJuridical)
                                : translate(i18.Job.Labels.ClientPhysical)
                        }}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.ClientId}
                        borderTitle
                        name={fieldNames.client.clientId}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className="mb-3">
                <Translate className="fs-16 fw_600" value={i18.Job.Titles.ClientInfo} />
            </div>
            {isCompany ? <CompanyClient /> : <PersonClient />}
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.ClientAddress}
                        borderTitle
                        name={fieldNames.client.address}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.ContactPhone}
                        borderTitle
                        name={fieldNames.contactPhoneNumber}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.ClientEmail}
                        borderTitle
                        name={fieldNames.client.email}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.ClientInvoiceEmail}
                        borderTitle
                        name={fieldNames.client.invoiceEmail}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.MainClientId}
                        borderTitle
                        name={fieldNames.mainClientId}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FormField
                        title={i18.Job.Labels.MainClientName}
                        borderTitle
                        name={fieldNames.mainClientName}
                        component={formFieldType.input}
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

JobClient.propTypes = {
    job: PropTypes.object,
    dispatch: PropTypes.func
};

function mapStateToProps(state) {
    const { job } = state.jobReducers;

    return {
        job: job?.value || {}
    };
}

const connectedJobClient = connect(mapStateToProps)(JobClient);
export { connectedJobClient as JobClient };
