import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Input } from 'antd';
import {
    CheckOutlined,
    ExclamationCircleFilled,
    FilterOutlined,
    SearchOutlined,
    UserAddOutlined
} from '@ant-design/icons';
import ScrollContainer from 'react-indiana-drag-scroll';

import {
    classificatorTranslatedArray,
    dateFormat,
    formatDate,
    i18,
    i18cl,
    i18clGroups,
    oDataQuery,
    routerPaths,
    statusesJob,
    translate
} from '../../utilities';
import { history, odataActions } from '../../api';
import {
    ButtonGroup,
    ButtonWrapper,
    Classificator,
    ContentHeader,
    FilterField,
    filterFieldTypes,
    Translate
} from '../../components';

class JobsContainer extends React.Component {
    static defaultProps = {
        defaultFilter: {
            filters: [],
            orders: [
                {
                    name: 'CreatedAt',
                    order: 'desc'
                }
            ],
            pagination: {
                current: 1,
                pageSize: 100
            },
            searchInputValue: ''
        }
    };

    constructor(props) {
        super(props);

        this.state = this.props.history.location.state || this.props.defaultFilter;

        this.setFilter = this.setFilter.bind(this);
        this.setOrderAndPagination = this.setOrderAndPagination.bind(this);
        this.onOpenJob = this.onOpenJob.bind(this);
        this.sorter = this.sorter.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        this.props.dispatch(odataActions.getJobGridQuery(oDataQuery(this.state)));
    }

    setFilter(filter, resetPagination) {
        this.setState(
            prevState => ({
                filters: [
                    ...prevState.filters.filter(e => e.name !== filter.name),
                    ...(filter.value !== null ? [filter] : [])
                ],
                ...(resetPagination && {
                    pagination: this.props.defaultFilter.pagination
                })
            }),
            () => {
                this.fetch();
            }
        );
    }

    setOrderAndPagination(pagination, columns, order) {
        const getOrderObject = item => ({
            name: item.columnKey,
            order: item.order === 'ascend' ? 'asc' : 'desc'
        });

        let orders = Array.isArray(order) ? order.map(getOrderObject) : [getOrderObject(order)];

        if (orders.length) {
            orders = [orders.pop(), ...orders];
        }

        this.setState(
            {
                orders,
                pagination: {
                    current: pagination.current,
                    pageSize: pagination.pageSize
                }
            },
            this.fetch
        );
    }

    onOpenJob(jobId) {
        history.push(routerPaths.jobs.index + '/' + jobId, {
            ...this.state,
            prevRoute: this.props.history.location.pathname
        });
    }

    sorter(name, type) {
        return {
            type: type,
            name: name,
            setFilter: this.setFilter,
            sortOrder: this.state.orders.find(e => e.name === name),
            data: this.state.filters.find(e => e.name === name)
        };
    }

    render() {
        const workStatuses = classificatorTranslatedArray(i18clGroups.STATUSES_WORK);
        const orderStatuses = classificatorTranslatedArray(i18clGroups.STATUSES_ORDER);
        const mergedStatuses = [...workStatuses, ...orderStatuses];
        const clearedStatuses = mergedStatuses.filter(
            (item, index, self) => index === self.findIndex(e => e.value === item.value)
        );
        const columns = [
            {
                key: 'HasNewActivities',
                title: <Translate value={i18.Jobs.Labels.HasNewActivities} />,
                render: item => (item.HasNewActivities ? <ExclamationCircleFilled className="color-warning" /> : null),
                ...FilterField({
                    ...this.sorter('HasNewActivities', filterFieldTypes.bool)
                })
            },
            {
                key: 'StatusName',
                title: <Translate value={i18.Jobs.Labels.StatusName} />,
                render: item => statusesJob(item.StatusName),
                ...FilterField({
                    options: clearedStatuses,
                    ...this.sorter('StatusName', filterFieldTypes.select)
                })
            },
            {
                key: 'IsPreorder',
                render: item => (item.IsPreorder ? <CheckOutlined /> : null),
                title: <Translate value={i18.Jobs.Labels.IsPreorder} />,
                ...FilterField({
                    ...this.sorter('IsPreorder', filterFieldTypes.bool)
                })
            },
            {
                key: 'IsPreorderSentToPartners',
                render: item => (item.IsPreorderSentToPartners ? <CheckOutlined /> : null),
                title: <Translate value={i18.Jobs.Labels.IsPreorderSentToPartners} />,
                ...FilterField({
                    ...this.sorter('IsPreorderSentToPartners', filterFieldTypes.bool)
                })
            },
            {
                key: 'IsPreorderSentToClient',
                render: item => (item.IsPreorderSentToClient ? <CheckOutlined /> : null),
                title: <Translate value={i18.Jobs.Labels.IsPreorderSentToClient} />,
                ...FilterField({
                    ...this.sorter('IsPreorderSentToClient', filterFieldTypes.bool)
                })
            },
            {
                key: 'JobCode',
                dataIndex: 'JobCode',
                title: <Translate value={i18.Jobs.Labels.JobCode} />,
                ...FilterField({
                    ...this.sorter('JobCode', filterFieldTypes.text)
                })
            },
            {
                key: 'Address',
                dataIndex: 'Address',
                title: <Translate value={i18.Jobs.Labels.Address} />,
                ...FilterField({
                    ...this.sorter('Address', filterFieldTypes.text)
                })
            },
            {
                key: 'Description',
                dataIndex: 'Description',
                title: <Translate value={i18.Jobs.Labels.Description} />,
                render: item => <p style={{ width: '400px' }}>{item}</p>,
                ...FilterField({
                    ...this.sorter('Description', filterFieldTypes.text)
                })
            },
            {
                key: 'NumberOfBids',
                dataIndex: 'NumberOfBids',
                title: <Translate value={i18.Jobs.Labels.NumberOfBids} />,
                ...FilterField({
                    ...this.sorter('NumberOfBids', filterFieldTypes.number)
                })
            },
            {
                key: 'BiddingType',
                render: item => <Classificator value={i18cl.BIDDING_TYPES + item.BiddingType} />,
                title: <Translate value={i18.Jobs.Labels.BiddingType} />,
                ...FilterField({
                    options: classificatorTranslatedArray(i18clGroups.BIDDING_TYPES),
                    ...this.sorter('BiddingType', filterFieldTypes.select)
                })
            },
            {
                key: 'PartnerIsExpert',
                render: item => (item.PartnerIsExpert ? <CheckOutlined /> : null),
                title: <Translate value={i18.Jobs.Labels.PartnerIsExpert} />,
                ...FilterField({
                    ...this.sorter('PartnerIsExpert', filterFieldTypes.bool)
                })
            },
            {
                key: 'ClientSegment',
                render: item => <Classificator value={i18cl.CLIENT_SEGMENTS + item.ClientSegment} />,
                title: <Translate value={i18.Jobs.Labels.ClientSegment} />,
                ...FilterField({
                    options: classificatorTranslatedArray(i18clGroups.CLIENT_SEGMENTS),
                    ...this.sorter('ClientSegment', filterFieldTypes.select)
                })
            },
            {
                key: 'BiddingExpiration',
                render: item => formatDate(item.BiddingExpiration, dateFormat().dateTime),
                title: <Translate value={i18.Jobs.Labels.BiddingExpiration} />,
                ...FilterField({
                    ...this.sorter('BiddingExpiration', filterFieldTypes.date)
                })
            },
            {
                key: 'Deadline',
                render: item => formatDate(item.Deadline, dateFormat().dateTime),
                title: <Translate value={i18.Jobs.Labels.Deadline} />,
                ...FilterField({
                    ...this.sorter('Deadline', filterFieldTypes.date)
                })
            },
            {
                key: 'ClientName',
                dataIndex: 'ClientName',
                title: <Translate value={i18.Jobs.Labels.ClientName} />,
                ...FilterField({
                    ...this.sorter('ClientName', filterFieldTypes.text)
                })
            },
            {
                key: 'MainClientName',
                dataIndex: 'MainClientName',
                title: <Translate value={i18.Jobs.Labels.MainClientName} />,
                ...FilterField({
                    ...this.sorter('MainClientName', filterFieldTypes.text)
                })
            },
            {
                key: 'ClientPhoneNumber',
                dataIndex: 'ClientPhoneNumber',
                title: <Translate value={i18.Jobs.Labels.ClientPhoneNumber} />,
                ...FilterField({
                    ...this.sorter('ClientPhoneNumber', filterFieldTypes.text)
                })
            },
            {
                key: 'FullPrice',
                dataIndex: 'FullPrice',
                title: <Translate value={i18.Jobs.Labels.FullPrice} />,
                ...FilterField({
                    ...this.sorter('FullPrice', filterFieldTypes.number)
                })
            },
            {
                key: 'ClientInvoiceNumber',
                dataIndex: 'ClientInvoiceNumber',
                title: <Translate value={i18.Jobs.Labels.ClientInvoiceNumber} />,
                ...FilterField({
                    ...this.sorter('ClientInvoiceNumber', filterFieldTypes.text)
                })
            },
            {
                key: 'ServiceName',
                render: item => <Classificator value={i18cl.SERVICES_CATALOG + item.ServiceName} />,
                title: <Translate value={i18.Jobs.Labels.Service} />,
                ...FilterField({
                    options: classificatorTranslatedArray(i18clGroups.SERVICES_CATALOG),
                    ...this.sorter('ServiceName', filterFieldTypes.select)
                })
            },
            {
                key: 'StartedAt',
                render: item => formatDate(item.StartedAt, dateFormat().dateTime),
                title: <Translate value={i18.Jobs.Labels.Started} />,
                ...FilterField({
                    ...this.sorter('StartedAt', filterFieldTypes.date)
                })
            },
            {
                key: 'FinishedAt',
                render: item => formatDate(item.FinishedAt, dateFormat().dateTime),
                title: <Translate value={i18.Jobs.Labels.Finished} />,
                ...FilterField({
                    ...this.sorter('FinishedAt', filterFieldTypes.date)
                })
            },
            {
                key: 'OwnerName',
                dataIndex: 'OwnerName',
                title: <Translate value={i18.Jobs.Labels.OwnerName} />,
                ...FilterField({
                    ...this.sorter('OwnerName', filterFieldTypes.text)
                })
            },
            {
                key: 'JobTypeName',
                render: item => <Classificator value={i18cl.TYPES_WORK + item.JobTypeName} />,
                title: <Translate value={i18.Jobs.Labels.JobType} />,
                ...FilterField({
                    options: classificatorTranslatedArray(i18clGroups.TYPES_WORK),
                    ...this.sorter('JobTypeName', filterFieldTypes.select)
                })
            },
            {
                key: 'JobId',
                dataIndex: 'JobId',
                title: <Translate value={i18.Jobs.Labels.JobId} />,
                ...FilterField({
                    ...this.sorter('JobId', filterFieldTypes.text)
                })
            },
            {
                key: 'PartnerFullName',
                dataIndex: 'PartnerFullName',
                title: <Translate value={i18.Jobs.Labels.Partner} />,
                ...FilterField({
                    ...this.sorter('PartnerFullName', filterFieldTypes.text)
                })
            },
            {
                key: 'CompanyName',
                dataIndex: 'CompanyName',
                title: <Translate value={i18.Jobs.Labels.Company} />,
                ...FilterField({
                    ...this.sorter('CompanyName', filterFieldTypes.text)
                })
            },
            {
                key: 'CreatedAt',
                render: item => formatDate(item.CreatedAt, dateFormat().dateTime),
                title: <Translate value={i18.Jobs.Labels.Created} />,
                ...FilterField({
                    ...this.sorter('CreatedAt', filterFieldTypes.date)
                })
            },
            {
                key: 'IsReviewNeeded',
                render: item => (item.IsReviewNeeded ? <CheckOutlined /> : null),
                title: <Translate value={i18.Jobs.Labels.IsReviewNeeded} />,
                ...FilterField({
                    ...this.sorter('IsReviewNeeded', filterFieldTypes.bool)
                })
            },
            {
                key: 'IsBidConfirmationNeeded',
                render: item => (item.IsBidConfirmationNeeded ? <CheckOutlined /> : null),
                title: <Translate value={i18.Jobs.Labels.IsBidConfirmationNeeded} />,
                ...FilterField({
                    ...this.sorter('IsBidConfirmationNeeded', filterFieldTypes.bool)
                })
            }
        ];

        return (
            <div>
                <ContentHeader
                    title={
                        <>
                            <Translate className="mr-2" value={i18.Jobs.Titles.Jobs} />
                            <span className="fw_400 color-black-50">({this.props.odataJobsGrid['@odata.count']})</span>
                        </>
                    }
                    extra={
                        <ButtonGroup>
                            <Input
                                className="mr-3"
                                placeholder={translate(i18.PartnersList.Labels.SearchPlaceholder)}
                                prefix={<SearchOutlined />}
                                onChange={({ target }) => this.setState({ searchInputValue: target.value })}
                                value={this.state.searchInputValue}
                                onPressEnter={({ target }) => {
                                    this.setFilter({
                                        name: 'SearchAggregate',
                                        type: filterFieldTypes.text,
                                        value: target.value,
                                        operator: null
                                    });
                                }}
                            />
                            {this.state.filters.length > 0 || this.state.orders.length > 0 ? (
                                <ButtonWrapper
                                    icon={<FilterOutlined />}
                                    type="secondary"
                                    onClick={() => {
                                        this.setState(
                                            (_, props) => ({
                                                ...props.defaultFilter
                                            }),
                                            () => {
                                                history.replace();
                                                this.fetch();
                                            }
                                        );
                                    }}
                                >
                                    <Translate value={i18.Jobs.Buttons.Clear} />
                                </ButtonWrapper>
                            ) : null}
                            <ButtonWrapper
                                icon={<UserAddOutlined />}
                                type="primary"
                                onClick={() => history.push(routerPaths.jobs.create, this.state)}
                            >
                                <Translate value={i18.Jobs.Buttons.Add} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    }
                />
                <div className="content_block">
                    <ScrollContainer style={{ overflowX: 'auto' }}>
                        <Table
                            rowKey="JobId"
                            onChange={(pagination, columns, order) =>
                                this.setOrderAndPagination(pagination, columns, order)
                            }
                            rowClassName="cursor_pointer"
                            style={{ minWidth: 1400 }}
                            columns={columns}
                            dataSource={this.props.odataJobsGrid.value || []}
                            loading={this.props.sendOdataJobsGrid}
                            onRow={item => {
                                return {
                                    onClick: () => this.onOpenJob(item.JobId)
                                };
                            }}
                            pagination={{
                                total: this.props.odataJobsGrid['@odata.count'],
                                pageSize: this.state.pagination.pageSize,
                                current: this.state.pagination.current
                            }}
                        />
                    </ScrollContainer>
                </div>
            </div>
        );
    }
}

JobsContainer.propTypes = {
    defaultFilter: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    odataJobsGrid: PropTypes.object.isRequired,
    sendOdataJobsGrid: PropTypes.bool
};

function mapStateToProps(state) {
    const { odataJobsGrid, sendOdataJobsGrid } = state.odataReducers;

    return {
        odataJobsGrid,
        sendOdataJobsGrid
    };
}

const connectedJobsContainer = connect(mapStateToProps)(JobsContainer);
export { connectedJobsContainer as JobsContainer };
