import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { Error, Title } from './';
import { Translate } from '../../translate';
import { classificatorArray } from '../../../utilities';

const Option = Select.Option;

export const SelectFormField = props => {
    const getOptionValue = item => {
        if (props.optionsArray) return item;

        return item[props.optionsValue || 'value'];
    };

    const getOptionLabel = item => {
        if (props.optionsClassificator) {
            const label = item[props.optionsLabel || 'label'];
            return classificatorArray(props.optionsClassificator + label);
        }

        if (props.optionsArray) return item;

        return item[props.optionsLabel || 'label'];
    };

    const generateOptionsUI = () => {
        if (props.options && props.options.length > 0) {
            return props.options.map((item, index) => (
                <Option key={index} value={getOptionValue(item)} disabled={item.disabled}>
                    {getOptionLabel(item)}
                </Option>
            ));
        }

        return null;
    };

    const optionsUi = generateOptionsUI();

    let allowClear = props.allowClear;
    if (allowClear === undefined) {
        allowClear = true;
    }

    const selectClassName = [];
    if (props.borderTitle) selectClassName.push('border-title');
    if (props.highlighted) selectClassName.push('ant-input-highlighted');
    if (props.dimmed) selectClassName.push('ant-input-dimmed');

    return (
        <div className={props.inline ? 'form_field_wrapper row align-items-center' : 'form_field_wrapper row'}>
            <Title
                center={props.center}
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
                required={props.required}
                borderTitle={props.borderTitle}
                dimmed={props.dimmed}
            />
            <div className={props.inline ? 'form_field col-12 col-sm-12 col-md-4' : 'form_field col-12'}>
                <Select
                    allowClear={allowClear}
                    className={selectClassName.join(' ')}
                    defaultValue={props.defaultValue}
                    showSearch
                    placeholder={props.placeholder ? <Translate value={props.placeholder} /> : null}
                    disabled={props.disabled}
                    loading={props.loading}
                    name={props.input.name}
                    onChange={value => {
                        props.input.onChange(value);
                        if (props.onChange) props.onChange(value);
                    }}
                    value={props.input.value || undefined}
                    size={props.size}
                    onSelect={props.onSelect}
                    style={{ width: '100%', textAlign: props.centerText ? 'center' : undefined }}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) > -1}
                    onSearch={props.onSearch}
                >
                    {optionsUi}
                </Select>
            </div>
            <Error inline={props.inline} touched={props.meta.touched} error={props.meta.error} />
        </div>
    );
};

SelectFormField.propTypes = {
    allowClear: PropTypes.bool,
    borderTitle: PropTypes.bool,
    centerText: PropTypes.bool,
    highlighted: PropTypes.bool,
    disabled: PropTypes.bool,
    dimmed: PropTypes.bool,
    defaultValue: PropTypes.string,
    inline: PropTypes.bool,
    input: PropTypes.shape({
        value: PropTypes.any,
        name: PropTypes.string,
        onChange: PropTypes.func
    }),
    loading: PropTypes.bool,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    }),
    options: PropTypes.array,
    optionsArray: PropTypes.bool,
    optionsClassificator: PropTypes.string,
    optionsLabel: PropTypes.string,
    optionsValue: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'default', 'small']),
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    center: PropTypes.bool,
    onSearch: PropTypes.func
};
