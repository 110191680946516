import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { publicActions } from '../../../../../api';
import {
    accountTypes,
    personalCodeTypes,
    classificatorTranslatedArray,
    countrySettings,
    formatNumber,
    getBooleanCountrySettingValue,
    i18,
    i18clGroups,
    numberMax100,
    required,
    requiredEmail,
    requiredIBAN,
    nonRequiredPersonalCode,
    getCountry,
    countryCodes,
    getContentString,
    i18cl,
    requiredPhone
} from '../../../../../utilities';
import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../../../components';

const PartnerAccountForm = (props) => {
    const country = getCountry();
    const isPolandCountryCode = country === countryCodes.PL;
    const isSpainCountryCode = country === countryCodes.ES;

    const getBankName = (iban, form) => {
        if (!iban) {
            return;
        }
        props.dispatch(publicActions.getBankName(iban)).then(res => {
            if (res && res.payload) {
                form.mutators.updateBankName(res.payload);
            }
        });
    };

    const constructVatOptions = (vatPercentages) => (
        vatPercentages.map(vat => ({
            value: vat.name,
            label: getContentString(`${i18cl.INVOICING_VAT}${vat.name}`)
        }))
    );

    const showCompanyCode = (accountType) => {
        if (isSpainCountryCode && accountType === accountTypes.PHYSICAL) {
            return false;
        }
        return true;
    };

    return (
        <Form
            mutators={{
                updateBankName: (args, state, utils) => {
                    utils.changeValue(state, 'bankName', () => args[0]);
                }
            }}
            onSubmit={props.submit}
            initialValues={{
                accountType: props.data.account.typeCode,
                companyName: props.data.account.company.name,
                companyCode: props.data.account.company.code,
                insurancePolicyNumber: props.data.account.insurancePolicyNumber,
                isVatPayer: props.data.account.company.isVatPayer,
                vatCode: props.data.account.company.vatCode,
                vatName: props.data.account.company.vatName,
                accountingEmail: props.data.account.accountingEmail,
                administrationMargin: props.data.account.administrationMargin,
                phoneNumber: props.data.account.phoneNumber,
                email: props.data.account.email,
                fullAddress: props.data.account.fullAddress,
                addressAdditionalInfo: props.data.account.addressAdditionalInfo,
                bankAccountNo: props.data.account.bankAccountNo,
                bankName: props.data.account.bankName,
                position: props.data.account.position,
                personalCodeType: props.data.account.personalCodeType,
                personalCode: props.data.account.personalCode,
                vatStatusChangedDate: props.data.account.company.vatStatusChangedDate
            }}
            render={({ handleSubmit, values, form }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.AccountType}
                        name='accountType'
                        component={formFieldType.select}
                        validate={required}
                        options={classificatorTranslatedArray(i18clGroups.TYPES_ACCOUNT)}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.CompanyName}
                        name='companyName'
                        component={formFieldType.input}
                        validate={values.accountType === accountTypes.JURIDICAL && required}
                        disabled={props.submitting}
                    />
                    {
                        showCompanyCode(values.accountType) && (
                            <FormField
                                inline
                                title={getCountry() === countryCodes.PL ? i18.PartnerAccountForm.Labels.NipCode : i18.PartnerAccountForm.Labels.CompanyCode}
                                name='companyCode'
                                maxLength={20}
                                component={formFieldType.input}
                                disabled={props.submitting}
                            />
                        )
                    }
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.InsurancePolicyNumber}
                        name='insurancePolicyNumber'
                        component={formFieldType.input}
                        disabled={props.submitting}
                    />
                    {
                        getBooleanCountrySettingValue(countrySettings.ShowVatCode) && (
                            <>
                                <FormField
                                    inline
                                    title={i18.PartnerAccountForm.Labels.isVatPayer}
                                    name='isVatPayer'
                                    component={formFieldType.switch}
                                    disabled={props.submitting}
                                />
                                {values.isVatPayer && (
                                    <FormField
                                        inline
                                        title={i18.PartnerAccountForm.Labels.VATCode}
                                        name='vatCode'
                                        component={formFieldType.input}
                                        validate={required}
                                        disabled={props.submitting}
                                    />
                                )}
                                <FormField
                                    inline
                                    title={i18.PartnerAccountForm.Labels.VatStatusChangedDate}
                                    name='vatStatusChangedDate'
                                    component={formFieldType.date}
                                    disabled={props.submitting}
                                />
                            </>
                        )
                    }

                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.VatName}
                        name='vatName'
                        options={constructVatOptions(props.countryVats.vats)}
                        component={formFieldType.select}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.AccountingEmail}
                        name='accountingEmail'
                        component={formFieldType.input}
                        validate={requiredEmail}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.AdministrationMargin}
                        name='administrationMargin'
                        formatNumber={formatNumber().percentPositive}
                        component={formFieldType.number}
                        validate={numberMax100}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.Email}
                        name='email'
                        component={formFieldType.input}
                        validate={requiredEmail}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.Phone}
                        name="phoneNumber"
                        component={formFieldType.phone}
                        validate={requiredPhone}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.Address}
                        name='fullAddress'
                        component={formFieldType.locationAutoComplete}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.AddressAdditionalInfo}
                        name='addressAdditionalInfo'
                        component={formFieldType.textarea}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.BankAccount}
                        name='bankAccountNo'
                        component={formFieldType.input}
                        onBlur={({ target: { value } }) => getBankName(value, form)}
                        validate={isPolandCountryCode ? undefined : requiredIBAN}
                        disabled={props.submitting}
                        format={e => e?.replace(/ /ig, '')}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.BankName}
                        name='bankName'
                        component={formFieldType.input}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        title={i18.PartnerAccountForm.Labels.Position}
                        name='position'
                        component={formFieldType.input}
                        disabled={props.submitting}
                        hidden={values.accountType === accountTypes.PHYSICAL}
                    />
                    {values.accountType === accountTypes.PHYSICAL && getCountry() === countryCodes.ES && (
                        <>
                            <FormField
                                inline
                                component={formFieldType.select}
                                name='personalCodeType'
                                title={i18.PartnerAccountForm.Labels.PersonalCodeType}
                                disabled={props.submitting}
                                options={[
                                    { value: personalCodeTypes.NIE },
                                    { value: personalCodeTypes.DNI }
                                ]}
                                optionsLabel='value'
                                optionsValue='value'
                            />
                            <FormField
                                inline
                                component={formFieldType.input}
                                name='personalCode'
                                title={i18.PartnerAccountForm.Labels.PersonalCode}
                                disabled={props.submitting}
                                validate={nonRequiredPersonalCode}
                            />
                        </>
                    )}
                    <div className='text-right'>
                        <ButtonGroup>
                            <ButtonWrapper
                                type='text'
                                disabled={props.submitting}
                                onClick={() => props.cancel()}
                            >
                                <Translate value={i18.PartnerAccountForm.Buttons.Cancel} />
                            </ButtonWrapper>
                            <ButtonWrapper
                                type='primary'
                                htmlType='submit'
                                loading={props.submitting}
                            >
                                <Translate value={i18.PartnerAccountForm.Buttons.Submit} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    </div>
                </form>
            )}
        />
    );
};

PartnerAccountForm.propTypes = {
    cancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    vatPercentages: PropTypes.array,
    countryVats: PropTypes.arrayOf(PropTypes.object)
};

const connectedPartnerAccountForm = connect(null)(PartnerAccountForm);
export { connectedPartnerAccountForm as PartnerAccountForm };
