import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Modal, Checkbox, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { jobCreateFormActions } from '../../../api';
import { PartnerSelectionForm } from './partnerSelectionForm';
import { i18 } from '../../../utilities';
import { Translate } from '../../../components';

class PartnerSelectionFormSection extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            manualPartnersSelection: true,
            showPartnersGrid: false
        };

        this.onToggleModal = this.onToggleModal.bind(this);
        this.onPartnersSelected = this.onPartnersSelected.bind(this);
        this.onManualPartnersSelectionChanged = this.onManualPartnersSelectionChanged.bind(this);
    }

    onToggleModal (e) {
        e.preventDefault();

        this.setState(prevState => ({
            showPartnersGrid: !prevState.showPartnersGrid
        }));
    }

    componentWillUnmount () {
        this.props.dispatch(jobCreateFormActions.setBiddingPartnerIds([]));
        this.props.updateKeyValue(this.props.name, []);
    }

    componentDidUpdate (prevProps) {
        if (prevProps.selectedServiceGroup !== undefined && prevProps.selectedServiceGroup !== this.props.selectedServiceGroup) {
            this.onPartnersSelected([]);
        }

        if (Object.keys(prevProps.selectedService).length && prevProps.selectedService.serviceId !== this.props.selectedService.serviceId) {
            this.onPartnersSelected([]);
        }
    }

    onPartnersSelected (partnerIds) {
        this.setState({
            showPartnersGrid: false
        });

        this.props.dispatch(jobCreateFormActions.setBiddingPartnerIds(partnerIds));
        this.props.updateKeyValue(this.props.name, partnerIds);
    }

    onManualPartnersSelectionChanged (e) {
        const manualSelection = e.target.checked;

        if (manualSelection) {
            this.props.updateKeyValue(this.props.name, this.props.biddingPartnerIds);
        } else {
            this.props.updateKeyValue(this.props.name, []);
        }

        this.setState({
            manualPartnersSelection: manualSelection
        });
    }

    render () {
        const { selectedService: { serviceId }, selectedClientSegment } = this.props;

        return (
            <>
                <Translate className='fs-16 fw_600' value={i18.PartnerSelection.Titles.PartnerSelection} />
                <div className='container-default mt-4'>
                    <div className='row mb-3'>
                        <div className='col-12'>
                            <Checkbox
                                checked={this.state.manualPartnersSelection}
                                onChange={this.onManualPartnersSelectionChanged}
                                disabled={!serviceId}
                            >
                                <Translate value={i18.PartnerSelection.Labels.ManualPartnerSelection} />
                            </Checkbox>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mb-4'>
                            {this.state.manualPartnersSelection &&
                                <Dropdown
                                    className='border-base'
                                    trigger={['click']}
                                    visible={false}
                                    disabled={!serviceId}
                                >
                                    <Button
                                        type='text'
                                        style={{ width: '100%' }}
                                        onClick={this.onToggleModal}
                                    >
                                        <Translate value={i18.PartnerSelection.Labels.SelectedPartnersIds} /> : {this.props.biddingPartnerIds.join()} <DownOutlined />
                                    </Button>
                                </Dropdown>}
                        </div>
                    </div>
                </div>
                <Modal
                    visible={this.state.showPartnersGrid}
                    onCancel={this.onToggleModal}
                    destroyOnClose={true}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                    width={'90%'}
                >
                    <PartnerSelectionForm
                        serviceId={serviceId}
                        clientSegment={selectedClientSegment}
                        onPartnersSelected={this.onPartnersSelected}
                        onCloseForm={this.onToggleModal}
                        selectedRowKeys={this.props.biddingPartnerIds}
                        formValues={this.props.formValues}
                    />
                </Modal>
            </>
        );
    }
};

PartnerSelectionFormSection.propTypes = {
    name: PropTypes.string.isRequired,
    updateKeyValue: PropTypes.func.isRequired,
    biddingPartnerIds: PropTypes.array,
    formValues: PropTypes.object.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    dispatch: PropTypes.func,
    selectedService: PropTypes.object,
    selectedClientSegment: PropTypes.string,
    selectedServiceGroup: PropTypes.string
};

function mapStateToProps (state) {
    const {
        selectedService,
        selectedServiceGroup,
        biddingPartnerIds,
        selectedClientSegment
    } = state.jobCreateFormReducers;

    return {
        biddingPartnerIds,
        selectedServiceGroup,
        selectedService,
        selectedClientSegment
    };
}

const connectedPartnerSelectionFormSection = connect(mapStateToProps)(PartnerSelectionFormSection);
export { connectedPartnerSelectionFormSection as PartnerSelectionFormSection };
