import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

const type = 'DraggableBodyRow';

export const DraggableBodyRow = ({ index, handleMoveRow, ...restProps }) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
            };
        },
        drop: item => {
            handleMoveRow(item.index, index);
        }
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });
    drop(drag(ref));

    return <div id={"draggable"} ref={ref} className={isOver ? dropClassName : ''} style={{ cursor: 'move' }} {...restProps} />;
};

DraggableBodyRow.propTypes = {
    handleMoveRow: PropTypes.func,
    index: PropTypes.number
};
