import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal } from 'antd';
import { history, odataActions } from '../../../api';
import { oDataQuery, routerPaths } from '../../../utilities';
import { PartnersSelectionGrid } from '../../jobsCreate/components/partnersSelectionGrid';

class PartnerReassignForm extends React.Component {
    static defaultProps = {
        defaultFilter: {
            filters: [],
            orders: [],
            pagination: {
                current: 1,
                pageSize: 20
            }
        }
    }

    constructor (props) {
        super(props);

        this.fetch = this.fetch.bind(this);
        this.onOpenProfile = this.onOpenProfile.bind(this);
    }

    componentDidMount () {
        this.fetch(this.props.defaultFilter);
    }

    fetch (filters) {
        const { serviceId, dispatch } = this.props;

        dispatch(odataActions.getJobPartnersGridForService(serviceId, oDataQuery(filters)));
    }

    onOpenProfile (profileId) {
        history.push(routerPaths.partners.index + '/' + profileId + '/profile', {
            prevRoute: this.props.history.location.pathname
        });
    }

    render () {
        return (
            <Modal
                visible={this.props.showPartnersGrid}
                onCancel={this.props.onFormToogle}
                destroyOnClose={true}
                footer={null}
                closable={false}
                maskClosable={false}
                width={'90%'}
            >
                <PartnersSelectionGrid
                    multiplePartners={false}
                    odataPartnerGrid={this.props.odataJobReassignPartnersGrid}
                    odataPartnerGridLoading={this.props.sendOdataJobReassignPartnersGrid}
                    searchAggregateName={'SearchAggregate'}
                    onOpenProfile={this.onOpenProfile}
                    fetchOdata={this.fetch}
                    onPartnersSelected={this.props.onPartnersSelected}
                    onCloseForm={this.props.onFormToogle}
                    selectedRowKeys={this.props.selectedRowKeys}
                    defaultFilter={this.props.defaultFilter}
                />
            </Modal>
        );
    }
};

PartnerReassignForm.propTypes = {
    serviceId: PropTypes.number.isRequired,
    showPartnersGrid: PropTypes.bool.isRequired,
    onFormToogle: PropTypes.func.isRequired,
    onPartnersSelected: PropTypes.func.isRequired,
    selectedRowKeys: PropTypes.array,
    defaultFilter: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    odataJobReassignPartnersGrid: PropTypes.object.isRequired,
    sendOdataJobReassignPartnersGrid: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        odataJobReassignPartnersGrid,
        sendOdataJobReassignPartnersGrid
    } = state.odataReducers;

    return {
        odataJobReassignPartnersGrid,
        sendOdataJobReassignPartnersGrid
    };
}

const connectedPartnerReassignForm = withRouter(connect(mapStateToProps)(PartnerReassignForm));
export { connectedPartnerReassignForm as PartnerReassignForm };
