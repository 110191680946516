import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { dateFormat, formatDate, i18, i18cl } from '../../../../../utilities';
import { Classificator, Translate } from '../../../../../components';

export const PartnerAdminActionsList = (props) => {
    const columns = [
        {
            title: <Translate value={i18.PartnerAdminActions.Labels.Date} />,
            render: (item) => formatDate(item.timestamp, dateFormat().dateTimeSecond)
        }, {
            title: <Translate value={i18.PartnerAdminActions.Labels.Action} />,
            render: (item) => (
                <>
                    <Classificator value={i18cl.ACTIVITY_LOG + item.activityCode} />
                    {item.reason && (
                        <>: <Classificator value={i18cl.NON_APPROVAL_REASON + item.reason} /></>
                    )}
                </>
            )
        }, {
            title: <Translate value={i18.PartnerAdminActions.Labels.Author} />,
            dataIndex: 'author'
        }, {
            title: <Translate value={i18.PartnerAdminActions.Labels.Description} />,
            dataIndex: 'freeText'
        }
    ];

    return (
        <Table
            rowKey='timestamp'
            dataSource={props.data}
            columns={columns}
            pagination={false}
            loading={props.loading}
        />
    );
};

PartnerAdminActionsList.propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool
};
