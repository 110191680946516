import React from 'react';
import PropTypes from 'prop-types';

import { accountTypes, classificator, countryCodes, dateFormat, formatDate, getCountry, i18, i18cl } from '../../../../../utilities';
import { Classificator, Translate } from '../../../../../components';
import { ProfileStatusConstants } from '../../../../../api';

export const PartnerProfileData = props => {
    const formatClientSegmentValue = clientSegments => {
        if (clientSegments?.length > 1) {
            return clientSegments.map((clientSegment, index) => {
                const comma = index === 0 ? '' : ', ';

                return `${comma}${classificator(i18cl.CLIENT_SEGMENTS + clientSegment)}`;
            });
        } else if (clientSegments?.length === 1) {
            return classificator(i18cl.CLIENT_SEGMENTS + clientSegments[0]);
        }
    };

    const profile = [
        {
            label: <Translate value={i18.PartnerProfile.Labels.Manager} />,
            value: props.data.responsibleAdminEmailAddress,
            actions: props.assignManager
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.Status} />,
            value: <Classificator className="fw_600" value={i18cl.STATUSES_PROFILE + props.data.status} />,
            actions: props.data.status === ProfileStatusConstants.APPROVED ? null : props.status,
            form: props.data.status === ProfileStatusConstants.APPROVED ? null : props.statusForm
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.Block} />,
            value: (
                <>
                    <div className="fw_600">
                        {props.data.isBlocked ? (
                            <Translate value={i18.PartnerProfile.Statuses.BlockedTrue} />
                        ) : (
                            <Translate value={i18.PartnerProfile.Statuses.BlockedFalse} />
                        )}
                    </div>
                    <div className="color-black-75">{props.data.blockReason}</div>
                </>
            ),
            actions: props.block,
            form: props.blockForm
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.Lock} />,
            value: (
                <div className="fw_600">
                    {props.data.isLocked ? (
                        <Translate value={i18.PartnerProfile.Statuses.LockedTrue} />
                    ) : (
                        <Translate value={i18.PartnerProfile.Statuses.LockedFalse} />
                    )}
                </div>
            ),
            actions: props.unlock
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.KycVerification} />,
            value: props.data.kycVerified ? (
                <Translate value={i18.PartnerProfile.Labels.KycVerified} />
            ) : (
                <Translate value={i18.PartnerProfile.Labels.KycNotVerified} />
            ),
            actions: props.kycVerifiedAction
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.SyncLabbis} />,
            value: formatDate(props.data.lastSyncWithLabbis, dateFormat().dateTimeSecond)
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.Name} />,
            value: props.data.firstName
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.Surname} />,
            value: props.data.lastName
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.AboutMe} />,
            value: props.data.aboutMeInfo
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.Email} />,
            value: props.data.email
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.Telephone} />,
            value: props.data.telephone
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.IsAutoBidEnabled} />,
            value: props.data.isAutoBidEnabled ? (
                <Translate value={i18.PartnerProfile.Labels.AutoBidEnabled} />
            ) : (
                <Translate value={i18.PartnerProfile.Labels.AutoBidDisabled} />
            ),
            actions: props.isAutoBidEnabled
        },
        ...(props.data.account.typeCode === accountTypes.PHYSICAL
            ? [
                  {
                      label: <Translate value={i18.PartnerProfile.Labels.Birthday} />,
                      value: formatDate(props.data.birthday, dateFormat().date)
                  }
              ]
            : []),
        {
            label: <Translate value={i18.PartnerProfile.Labels.PrivacyPolicy} />,
            value: props.data.acceptedPrivacyPolicy ? (
                <Translate value={i18.PartnerProfile.Labels.AcceptedPrivacyPolicy} />
            ) : (
                <Translate value={i18.PartnerProfile.Labels.RejectedPrivacyPolicy} />
            )
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.TermsAndConditions} />,
            value: props.data.acceptedTermsAndConditions ? (
                <Translate value={i18.PartnerProfile.Labels.AcceptedTermsAndConditions} />
            ) : (
                <Translate value={i18.PartnerProfile.Labels.RejectedTermsAndConditions} />
            )
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.ToBeUsedForMarketingPurpose} />,
            value: props.data.acceptedToBeUsedForMarketingPurpose ? (
                <Translate value={i18.PartnerProfile.Labels.AcceptedToBeUsedForMarketingPurpose} />
            ) : (
                <Translate value={i18.PartnerProfile.Labels.RejectedToBeUsedForMarketingPurpose} />
            )
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.Source} />,
            value: props.data.learnedAboutUsFrom && (
                <Classificator value={i18cl.INFO_ABOUT_PORTAL_FOUND_IN + props.data.learnedAboutUsFrom} />
            )
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.JobSources} />,
            value: formatClientSegmentValue(props.data.jobSources),
            actions: props.jobSourcesAction,
            form: props.jobSourcesForm
        },
        {
            label: <Translate value={i18.PartnerProfile.Labels.CanSellMaterials} />,
            actions: props.mtcAction,
            value: props.data.allowedSellMTCMaterials ? (
                <Translate value={i18.PartnerProfile.Labels.CanSellMaterialsAccepted} />
            ) : (
                <Translate value={i18.PartnerProfile.Labels.CanSellMaterialsRejected} />
            )
        },
        props.greenCardAction && {
            label: <Translate value={i18.PartnerProfile.Labels.hasGreenCard} />,
            actions: props.greenCardAction,
            value: props.data.hasGreenCard ? (
                <Translate value={i18.PartnerProfile.Labels.hasGreenCardAccepted} />
            ) : (
                <Translate value={i18.PartnerProfile.Labels.hasGreenCardRejected} />
            )
        }
    ].filter(Boolean);
    return (
        <div className="list_info_wrapper">
            {profile
                .filter(e => e.visible !== false)
                .map((item, index) => (
                    <div key={index} className="list_info row">
                        {!item.form && (
                            <>
                                <div className="list_info_label col-12 col-xl-5">{item.label}</div>
                                <div className="list_info_value col-12 col-xl">{item.value}</div>
                                <div className="list_info_actions col-auto">{item.actions}</div>
                            </>
                        )}
                        {item.form && <div className="list_info_content col-12">{item.form}</div>}
                    </div>
                ))}
        </div>
    );
};

PartnerProfileData.propTypes = {
    assignManager: PropTypes.any,
    jobSourcesAction: PropTypes.any,
    jobSourcesForm: PropTypes.any,
    kycVerifiedAction: PropTypes.any,
    mtcAction: PropTypes.any,
    greenCardAction: PropTypes.any,
    block: PropTypes.any,
    blockForm: PropTypes.any,
    data: PropTypes.object.isRequired,
    status: PropTypes.any,
    statusForm: PropTypes.any,
    unlock: PropTypes.any,
    isAutoBidEnabled: PropTypes.any
};
