export const countryVatConstants = {
    GET_COUNTRY_VAT_REQUEST: 'GET_COUNTRY_VAT_REQUEST',
    GET_COUNTRY_VAT_SUCCESS: 'GET_COUNTRY_VAT_SUCCESS',
    GET_COUNTRY_VAT_ERROR: 'GET_COUNTRY_VAT_ERROR',

    POST_COUNTRY_VAT_REQUEST: 'POST_COUNTRY_VAT_REQUEST',
    POST_COUNTRY_VAT_SUCCESS: 'POST_COUNTRY_VAT_SUCCESS',
    POST_COUNTRY_VAT_ERROR: 'POST_COUNTRY_VAT_ERROR',

    POST_VAT_REQUEST: 'POST_VAT_REQUEST',
    POST_VAT_SUCCESS: 'POST_VAT_SUCCESS',
    POST_VAT_ERROR: 'POST_VAT_ERROR',

    PUT_VAT_REQUEST: 'PUT_VAT_REQUEST',
    PUT_VAT_SUCCESS: 'PUT_VAT_SUCCESS',
    PUT_VAT_ERROR: 'PUT_VAT_ERROR',

    DELETE_VAT_REQUEST: 'DELETE_VAT_REQUEST',
    DELETE_VAT_SUCCESS: 'DELETE_VAT_SUCCESS',
    DELETE_VAT_ERROR: 'DELETE_VAT_ERROR'
};
