import { countrySettingsServices } from './services';
import { countrySettingsConstants } from './constants';
import { Message } from '../../../components/message';
import { i18, translate } from '../../../utilities';

export const countrySettingsActions = {
    getCountrySettings,
    postCountrySettings,
    putCountrySettings,
    getCountrySettingsById
};

function getCountrySettings () {
    return dispatch => {
        dispatch(request());

        return countrySettingsServices.getCountrySettings()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: countrySettingsConstants.GET_COUNTRY_SETTINGS_REQUEST };
    }

    function success (data) {
        return {
            type: countrySettingsConstants.GET_COUNTRY_SETTINGS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: countrySettingsConstants.GET_COUNTRY_SETTINGS_ERROR,
            error
        };
    }
}

function postCountrySettings (data) {
    return dispatch => {
        dispatch(request());

        return countrySettingsServices.postCountrySettings(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: countrySettingsConstants.POST_COUNTRY_SETTINGS_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.CountrySettingAdded)
        });

        return {
            type: countrySettingsConstants.POST_COUNTRY_SETTINGS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: countrySettingsConstants.POST_COUNTRY_SETTINGS_ERROR,
            error
        };
    }
}

function putCountrySettings (data) {
    return dispatch => {
        dispatch(request());

        return countrySettingsServices.putCountrySettings(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: countrySettingsConstants.PUT_COUNTRY_SETTINGS_REQUEST };
    }

    function success (data) {
        Message({
            message: translate(i18.API.Messages.CountrySettingUpdated)
        });

        return {
            type: countrySettingsConstants.PUT_COUNTRY_SETTINGS_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: countrySettingsConstants.PUT_COUNTRY_SETTINGS_ERROR,
            error
        };
    }
}

function getCountrySettingsById (countrySettingId) {
    return dispatch => {
        dispatch(request());

        return countrySettingsServices.getCountrySettingsById(countrySettingId)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return {
            type: countrySettingsConstants.GET_COUNTRY_SETTINGS_BY_ID_REQUEST,
            sendCountrySettingId: countrySettingId
        };
    }

    function success (data) {
        return {
            type: countrySettingsConstants.GET_COUNTRY_SETTINGS_BY_ID_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: countrySettingsConstants.GET_COUNTRY_SETTINGS_BY_ID_ERROR,
            error
        };
    }
}
