import { jobCreateFormConstants } from './constants';

export const jobCreateFormActions = {
    setSelectedJobStatus,
    setSelectedClientSegment,
    setSelectedVatType,
    setIsJobApprovalNeeded,
    setIsJobTypeEmergency,
    setSelectedService,
    setSelectedServiceGroup,
    setJobDurationTime,
    setJobDurationDays,
    setBiddingPartnerIds,
    setJobPartnerId,
    setJobDefaultValues,
    setJobReducerState
};

function setSelectedJobStatus (statusType, statusCode) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_SELECTED_JOB_STATUS,
            payload: {
                statusType: statusType,
                statusCode: statusCode
            }
        };
    }
}

function setSelectedClientSegment (clientSegment) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_SELECTED_CLIENT_SEGMENT,
            payload: clientSegment
        };
    }
}

function setSelectedVatType (vatType) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_SELECTED_VAT_TYPE,
            payload: vatType
        };
    }
}

function setIsJobApprovalNeeded (isJobApprovalNeeded) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_IS_JOB_APPROVAL_NEEDED,
            payload: isJobApprovalNeeded
        };
    }
}

function setIsJobTypeEmergency (isJobTypeEmergency) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_IS_JOB_TYPE_EMERGENCY,
            payload: isJobTypeEmergency
        };
    }
}

function setSelectedService (service) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_SELECTED_SERVICE,
            payload: service
        };
    }
}

function setSelectedServiceGroup (serviceGroup) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_SELECTED_SERVICE_GROUP,
            payload: serviceGroup
        };
    }
}

function setJobDurationTime (jobDurationTime) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_JOB_DURATION_TIME,
            payload: jobDurationTime
        };
    }
}

function setJobDurationDays (jobDurationDays) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_JOB_DURATION_DAYS,
            payload: jobDurationDays
        };
    }
}

function setJobDefaultValues () {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_DEFAULT_VALUES
        };
    }
}

function setJobReducerState (state) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_REDUCER_STATE,
            payload: state
        };
    }
}

function setBiddingPartnerIds (biddingPartnerIds) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_BIDDING_PARTNER_IDS,
            payload: biddingPartnerIds
        };
    }
}

function setJobPartnerId (jobPartnerId) {
    return dispatch => {
        dispatch(request());
    };

    function request () {
        return {
            type: jobCreateFormConstants.SET_JOB_PARTNER_ID,
            payload: jobPartnerId
        };
    }
}
