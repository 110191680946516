import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

import { Error, Title } from './';

export const RadioFormField = (props) => {
    return (
        <div className='form_field_wrapper'>
            <Title
                inline={props.inline}
                title={props.title}
                titleTranslated={props.titleTranslated}
            />
            <div className='form_field'>
                <Radio.Group onChange={props.input.onChange} value={props.input.value} disabled={props.disabled}>
                    {props.options && props.options.map((item, index) =>
                        <Radio key={index} value={item[props.optionsValue]} disabled={item.disabled}>{item[props.optionsLabel]}</Radio>
                    )}
                </Radio.Group>
            </div>
            <Error
                touched={props.meta.touched}
                error={props.meta.error}
            />
        </div>
    );
};

RadioFormField.propTypes = {
    title: PropTypes.string,
    titleTranslated: PropTypes.bool,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    options: PropTypes.array,
    optionsLabel: PropTypes.string,
    optionsValue: PropTypes.string,
    input: PropTypes.shape({
        value: PropTypes.any,
        onChange: PropTypes.func
    }),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.any
    })
};
