import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { checkJsonProp } from '../../utilities';

class Classificator extends React.Component {
    render () {
        if (!this.props.value) {
            return <span className='color_error'>No value</span>;
        }

        const value = checkJsonProp(this.props.publicLocalization.classificators, this.props.value);

        return (
            <span className={this.props.className}>{value || this.props.value}</span>
        );
    }
}

Classificator.propTypes = {
    className: PropTypes.string,
    publicLocalization: PropTypes.object,
    value: PropTypes.string.isRequired
};

function mapStateToProps (state) {
    const { publicLocalization } = state.publicReducers;

    return {
        publicLocalization
    };
}

const connectedClassificator = connect(mapStateToProps)(Classificator);
export { connectedClassificator as Classificator };
