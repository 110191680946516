export const odataConstants = {
    GET_ODATA_INTERNAL_CLIENTS_GRID_REQUEST: 'GET_ODATA_INTERNAL_CLIENTS_GRID_REQUEST',
    GET_ODATA_INTERNAL_CLIENTS_GRID_SUCCESS: 'GET_ODATA_INTERNAL_CLIENTS_GRID_SUCCESS',
    GET_ODATA_INTERNAL_CLIENTS_GRID_ERROR: 'GET_ODATA_INTERNAL_CLIENTS_GRID_ERROR',

    GET_ODATA_JOBS_GRID_REQUEST: 'GET_ODATA_JOBS_GRID_REQUEST',
    GET_ODATA_JOBS_GRID_SUCCESS: 'GET_ODATA_JOBS_GRID_SUCCESS',
    GET_ODATA_JOBS_GRID_ERROR: 'GET_ODATA_JOBS_GRID_ERROR',

    GET_ODATA_JOB_PARTNERS_GRID_REQUEST: 'GET_ODATA_JOB_PARTNERS_GRID_REQUEST',
    GET_ODATA_JOB_PARTNERS_GRID_SUCCESS: 'GET_ODATA_JOB_PARTNERS_GRID_SUCCESS',
    GET_ODATA_JOB_PARTNERS_GRID_ERROR: 'GET_ODATA_JOB_PARTNERS_GRID_ERROR',

    GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_REQUEST: 'GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_REQUEST',
    GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_SUCCESS: 'GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_SUCCESS',
    GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_ERROR: 'GET_ODATA_JOB_REASSIGN_PARTNERS_GRID_ERROR',

    GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_REQUEST: 'GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_REQUEST',
    GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_SUCCESS: 'GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_SUCCESS',
    GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_ERROR: 'GET_ODATA_JOB_PARTNERS_BY_CLIENTSEGMENT_GRID_ERROR',

    GET_ODATA_PARTNERS_GRID_REQUEST: 'GET_ODATA_PARTNERS_GRID_REQUEST',
    GET_ODATA_PARTNERS_GRID_SUCCESS: 'GET_ODATA_PARTNERS_GRID_SUCCESS',
    GET_ODATA_PARTNERS_GRID_ERROR: 'GET_ODATA_PARTNERS_GRID_ERROR',

    GET_ODATA_JOB_BIDDINGS_GRID_REQUEST: 'GET_ODATA_JOB_BIDDINGS_GRID_REQUEST',
    GET_ODATA_JOB_BIDDINGS_GRID_SUCCESS: 'GET_ODATA_JOB_BIDDINGS_GRID_SUCCESS',
    GET_ODATA_JOB_BIDDINGS_GRID_ERROR: 'GET_ODATA_JOB_BIDDINGS_GRID_ERROR'
};
