import React from 'react';
import PropTypes from 'prop-types';
import { ContentState, EditorState, Modifier } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import './editorLinks.css';

class EditorLinks extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            open: false
        };

        this.openPlaceholderDropdown = this.openPlaceholderDropdown.bind(this);
        this.addLinks = this.addLinks.bind(this);
    }

    openPlaceholderDropdown () {
        this.setState({ open: !this.state.open });
    }

    addLinks (value) {
        let {
            contentState
        } = this.props;
        const {
            editorState,
            onChange
        } = this.props;
        const {
            contentBlocks,
            entityMap
        } = htmlToDraft(value);
        contentState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
        );

        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    }

    render () {
        return (
            <div className='rdw-block-wrapper' onClick={this.openPlaceholderDropdown}>
                <div className='rdw-dropdown-wrapper rdw-block-dropdown' aria-expanded={this.state.open ? 'true' : 'false'} style={{ width: 200 }}>
                    <a className='rdw-dropdown-selectedtext'>
                        <span>Custom links</span>
                        <div className='rdw-dropdown-carettoopen' />
                    </a>
                    {this.state.open && (
                        <ul className='rdw-dropdown-optionwrapper'>
                            <li className="rdw-dropdownoption-default highlight-on-hover" onClick={() => this.addLinks('<a href="{EDITOR_BLOCKED_USER_LINK}">EDITOR_BLOCKED_USER_TEXT</a>')}>Blocked user link</li>
                            {this.props.customLinks && this.props.customLinks.map((customLink, i) => {
                                return (
                                    <li
                                        key={i}
                                        className='rdw-dropdownoption-default highlight-on-hover'
                                        onClick={() => this.addLinks(`<a href="${customLink.href}">${customLink.value}</a>`)}
                                    >
                                        {customLink.value}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        );
    }
}

EditorLinks.propTypes = {
    contentState: PropTypes.object,
    editorState: PropTypes.object,
    customLinks: PropTypes.array,
    onChange: PropTypes.func
};

export { EditorLinks };
