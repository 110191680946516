import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import { translate } from '../../utilities';

export const StatusTooltip = ({ children, translation }) => {
    const titleTranslation = translate(translation);
    return (
        <Tooltip title={titleTranslation}>
            {children}
        </Tooltip>
    );
};

StatusTooltip.propTypes = {
    translation: PropTypes.string,
    children: PropTypes.element
};
