export const jobConstants = {
    GET_JOB_REQUEST: 'GET_JOB_REQUEST',
    GET_JOB_SUCCESS: 'GET_JOB_SUCCESS',
    GET_JOB_ERROR: 'GET_JOB_ERROR',

    GET_JOB_OFFERS_REQUEST: 'GET_JOB_OFFERS_REQUEST',
    GET_JOB_OFFERS_SUCCESS: 'GET_JOB_OFFERS_SUCCESS',
    GET_JOB_OFFERS_ERROR: 'GET_JOB_OFFERS_ERROR',

    POST_JOB_OFFERS_REQUEST: 'POST_JOB_OFFERS_REQUEST',
    POST_JOB_OFFERS_SUCCESS: 'POST_JOB_OFFERS_SUCCESS',
    POST_JOB_OFFERS_ERROR: 'POST_JOB_OFFERS_ERROR',

    GET_JOB_INVOICES_REQUEST: 'GET_JOB_INVOICES_REQUEST',
    GET_JOB_INVOICES_SUCCESS: 'GET_JOB_INVOICES_SUCCESS',
    GET_JOB_INVOICES_ERROR: 'GET_JOB_INVOICES_ERROR',

    GET_JOB_SCOPE_FILE_URL_REQUEST: 'GET_JOB_SCOPE_FILE_URL_REQUEST',
    GET_JOB_SCOPE_FILE_URL_SUCCESS: 'GET_JOB_SCOPE_FILE_URL_SUCCESS',
    GET_JOB_SCOPE_FILE_URL_ERROR: 'GET_JOB_SCOPE_FILE_URL_ERROR',

    GET_JOB_VALIDATION_STATUSES_REQUEST: 'GET_JOB_VALIDATION_STATUSES_REQUEST',
    GET_JOB_VALIDATION_STATUSES_SUCCESS: 'GET_JOB_VALIDATION_STATUSES_SUCCESS',
    GET_JOB_VALIDATION_STATUSES_ERROR: 'GET_JOB_VALIDATION_STATUSES_ERROR',

    PUT_JOB_REQUEST: 'PUT_JOB_REQUEST',
    PUT_JOB_SUCCESS: 'PUT_JOB_SUCCESS',
    PUT_JOB_ERROR: 'PUT_JOB_ERROR',

    PUT_ASSIGN_NEW_PARTNER_REQUEST: 'PUT_ASSIGN_NEW_PARTNER_REQUEST',
    PUT_ASSIGN_NEW_PARTNER_SUCCESS: 'PUT_ASSIGN_NEW_PARTNER_SUCCESS',
    PUT_ASSIGN_NEW_PARTNER_ERROR: 'PUT_ASSIGN_NEW_PARTNER_ERROR',

    PUT_ASSIGN_NEW_PARTNER_STANDARD_REQUEST: 'PUT_ASSIGN_NEW_PARTNER_STANDARD_REQUEST',
    PUT_ASSIGN_NEW_PARTNER_STANDARD_SUCCESS: 'PUT_ASSIGN_NEW_PARTNER_STANDARD_SUCCESS',
    PUT_ASSIGN_NEW_PARTNER_STANDARD_ERROR: 'PUT_ASSIGN_NEW_PARTNER_STANDARD_ERROR',

    POST_CANCEL_JOB_REQUEST: 'POST_CANCEL_JOB_REQUEST',
    POST_CANCEL_JOB_SUCCESS: 'POST_CANCEL_JOB_SUCCESS',
    POST_CANCEL_JOB_ERROR: 'POST_CANCEL_JOB_ERROR',

    POST_COMPLETE_JOB_REQUEST: 'POST_COMPLETE_JOB_REQUEST',
    POST_COMPLETE_JOB_SUCCESS: 'POST_COMPLETE_JOB_SUCCESS',
    POST_COMPLETE_JOB_ERROR: 'POST_COMPLETE_JOB_ERROR',

    POST_APPROVE_JOB_REQUEST: 'POST_APPROVE_JOB_REQUEST',
    POST_APPROVE_JOB_SUCCESS: 'POST_APPROVE_JOB_SUCCESS',
    POST_APPROVE_JOB_ERROR: 'POST_APPROVE_JOB_ERROR',

    POST_SET_CALLED_REQUEST: 'POST_SET_CALLED_REQUEST',
    POST_SET_CALLED_SUCCESS: 'POST_SET_CALLED_SUCCESS',
    POST_SET_CALLED_ERROR: 'POST_SET_CALLED_ERROR',

    POST_NEW_COMPETITOR_OFFER: 'POST_NEW_COMPETITOR_OFFER',
    POST_NEW_COMPETITOR_OFFER_SUCCESS: 'POST_NEW_COMPETITOR_OFFER_SUCCESS',
    POST_NEW_COMPETITOR_OFFER_ERROR: 'POST_NEW_COMPETITOR_OFFER_ERROR',

    PUT_COMPETITOR_OFFER: 'PUT_COMPETITOR_OFFER',
    PUT_COMPETITOR_OFFER_SUCCESS: 'PUT_COMPETITOR_OFFER_SUCCESS',
    PUT_COMPETITOR_OFFER_ERROR: 'PUT_COMPETITOR_OFFER_ERROR',

    DELETE_COMPETITOR_OFFER: 'DELETE_COMPETITOR_OFFER',
    DELETE_COMPETITOR_OFFER_SUCCESS: 'DELETE_COMPETITOR_OFFER_SUCCESS',
    DELETE_COMPETITOR_OFFER_ERROR: 'DELETE_COMPETITOR_OFFER_ERROR'
};
