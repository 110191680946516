import React from 'react';

import { AvatarPicker, LanguagePicker, LogoutButton } from './components';

export const PartHeader = () => {
    return (
        <div
            className='d-flex flex-row-reverse'
            style={{ height: 64 }}
        >
            <div className='d-flex align-items-center'>
                <LanguagePicker />
                <AvatarPicker />
                <LogoutButton />
            </div>
        </div>
    );
};
