import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { responsiveBreakpoint } from '../../utilities';

export const ButtonPrimary = (props) => {
    const block = props.block ? responsiveBreakpoint(props.block) : false;

    return (
        <Button
            type='primary'
            {...props}
            block={block}
        >
            {props.children}
        </Button>
    );
};

ButtonPrimary.propTypes = {
    children: PropTypes.any,
    block: PropTypes.oneOf([true, 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
    className: PropTypes.string
};
