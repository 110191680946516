import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { dateFormat, formatDate, i18, i18cl } from '../../../../../utilities';
import { Classificator, Translate } from '../../../../../components';

export const PartnerLogsList = (props) => {
    const columns = [
        {
            title: <Translate value={i18.PartnerLogs.Labels.Date} />,
            render: (item) => formatDate(item.timestamp, dateFormat().dateTimeSecond)
        }, {
            title: <Translate value={i18.PartnerLogs.Labels.Action} />,
            render: (item) => <Classificator value={i18cl.ACTIVITY_LOG + item.activityCode} />
        }, {
            title: <Translate value={i18.PartnerLogs.Labels.Description} />,
            dataIndex: 'freeText'
        }
    ];

    return (
        <Table
            rowKey='timestamp'
            dataSource={props.data}
            columns={columns}
            pagination={false}
            loading={props.loading}
        />
    );
};

PartnerLogsList.propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool
};
