import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Table, Input } from 'antd';
import {
    ExclamationCircleFilled,
    FilterOutlined,
    SearchOutlined,
    SyncOutlined,
    UserAddOutlined
} from '@ant-design/icons';
import ScrollContainer from 'react-indiana-drag-scroll';

import {
    classificatorTranslatedArray,
    dateFormat,
    formatDate,
    i18,
    i18clGroups,
    oDataQuery,
    routerPaths,
    statusesProfile,
    translate
} from '../../utilities';
import { history, odataActions, partnerProfileActions, partnerProfileConstants } from '../../api';
import {
    ButtonGroup,
    ButtonWrapper,
    ContentHeader,
    FilterField,
    filterFieldTypes,
    Translate,
    StatusTooltip
} from '../../components';

class PartnersOdataContainer extends React.Component {
    static defaultProps = {
        defaultFilter: {
            filters: [],
            orders: [
                {
                    name: 'HasNewActivities',
                    order: 'desc'
                },
                {
                    name: 'RegistrationDate',
                    order: 'desc'
                }
            ],
            pagination: {
                current: 1,
                pageSize: 100
            },
            searchInputValue: ''
        }
    };

    constructor(props) {
        super(props);

        this.state = this.props.history.location.state?.filter || this.props.defaultFilter;

        this.setFilter = this.setFilter.bind(this);
        this.setOrderAndPagination = this.setOrderAndPagination.bind(this);
        this.click = this.click.bind(this);
        this.sync = this.sync.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        this.props.dispatch(odataActions.getPartnersGrid(oDataQuery(this.state)));
    }

    setFilter(filter, resetPagination) {
        this.setState(
            prevState => ({
                filters: [
                    ...prevState.filters.filter(e => e.name !== filter.name),
                    ...(filter.value !== null ? [filter] : [])
                ],
                ...(resetPagination && {
                    pagination: this.props.defaultFilter.pagination
                })
            }),
            () => {
                this.fetch();
            }
        );
    }

    setOrderAndPagination(pagination, columns, order) {
        const orders = [];

        if (Array.isArray(order)) {
            orders.push(
                ...order.map(item => {
                    return {
                        name: item.columnKey,
                        order: item.order === 'ascend' ? 'asc' : 'desc'
                    };
                })
            );
        } else if (order.order) {
            orders.push({
                name: order.columnKey,
                order: order.order === 'ascend' ? 'asc' : 'desc'
            });
        }

        this.setState(
            {
                orders: orders,
                pagination: {
                    current: pagination.current,
                    pageSize: pagination.pageSize
                }
            },
            () => {
                this.fetch();
            }
        );
    }

    click(profileId) {
        history.push(routerPaths.partners.index + '/' + profileId + '/profile', {
            filter: this.state,
            prevRoute: this.props.history.location.pathname
        });
    }

    sync(profileId) {
        this.props.dispatch(partnerProfileActions.postSynchronization(profileId)).then(res => {
            if (res && res.type === partnerProfileConstants.POST_PARTNER_PROFILE_SYNCHRONIZATION_SUCCESS) {
                this.fetch();
            }
        });
    }

    render() {
        const columns = [
            {
                key: 'HasNewActivities',
                title: <Translate value={i18.PartnersList.Labels.HasNewActivities} />,
                render: item => (item.HasNewActivities ? <ExclamationCircleFilled className="color-warning" /> : null),
                ...FilterField({
                    name: 'HasNewActivities',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'HasNewActivities'),
                    type: filterFieldTypes.bool,
                    data: this.state.filters.find(e => e.name === 'HasNewActivities')
                })
            },
            {
                key: 'StatusName',
                title: <Translate value={i18.PartnersList.Labels.StatusName} />,
                render: item => (
                    <StatusTooltip translation={i18.Partners.Tooltip[item.StatusName]}>
                        {statusesProfile(item.StatusName)}
                    </StatusTooltip>
                ),
                ...FilterField({
                    name: 'StatusName',
                    options: classificatorTranslatedArray(i18clGroups.STATUSES_PROFILE),
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'StatusName'),
                    type: filterFieldTypes.select,
                    data: this.state.filters.find(e => e.name === 'StatusName')
                })
            },
            {
                dataIndex: 'FirstName',
                key: 'FirstName',
                title: <Translate value={i18.PartnersList.Labels.FirstName} />,
                ...FilterField({
                    name: 'FirstName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'FirstName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'FirstName')
                })
            },
            {
                dataIndex: 'LastName',
                key: 'LastName',
                title: <Translate value={i18.PartnersList.Labels.LastName} />,
                ...FilterField({
                    name: 'LastName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'LastName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'LastName')
                })
            },
            {
                dataIndex: 'CompanyName',
                key: 'CompanyName',
                title: <Translate value={i18.PartnersList.Labels.CompanyName} />,
                ...FilterField({
                    name: 'CompanyName',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'CompanyName'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'CompanyName')
                })
            },
            {
                dataIndex: 'PhoneNumber',
                key: 'PhoneNumber',
                title: <Translate value={i18.PartnersList.Labels.PhoneNumber} />,
                ...FilterField({
                    name: 'PhoneNumber',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'PhoneNumber'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'PhoneNumber')
                })
            },
            {
                dataIndex: 'Email',
                key: 'Email',
                title: <Translate value={i18.PartnersList.Labels.Email} />,
                ...FilterField({
                    name: 'Email',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'Email'),
                    type: filterFieldTypes.text,
                    data: this.state.filters.find(e => e.name === 'Email')
                })
            },
            {
                key: 'RegistrationDate',
                title: <Translate value={i18.PartnersList.Labels.RegistrationDate} />,
                render: item => formatDate(item.RegistrationDate),
                ...FilterField({
                    name: 'RegistrationDate',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'RegistrationDate'),
                    type: filterFieldTypes.date,
                    data: this.state.filters.find(e => e.name === 'RegistrationDate')
                })
            },
            {
                key: 'LastLoginDate',
                title: <Translate value={i18.PartnersList.Labels.LastLoginDate} />,
                render: item => formatDate(item.LastLoginDate),
                ...FilterField({
                    name: 'LastLoginDate',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'LastLoginDate'),
                    type: filterFieldTypes.date,
                    data: this.state.filters.find(e => e.name === 'LastLoginDate')
                })
            },
            {
                key: 'TotalServiceCount',
                title: <Translate value={i18.PartnersList.Labels.TotalServiceCount} />,
                render: item => item.TotalServiceCount,
                ...FilterField({
                    name: 'TotalServiceCount',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'TotalServiceCount'),
                    type: filterFieldTypes.number,
                    data: this.state.filters.find(e => e.name === 'TotalServiceCount')
                })
            },
            {
                key: 'CompletedServiceCount',
                title: <Translate value={i18.PartnersList.Labels.CompletedServiceCount} />,
                render: item => item.CompletedServiceCount,
                ...FilterField({
                    name: 'CompletedServiceCount',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'CompletedServiceCount'),
                    type: filterFieldTypes.number,
                    data: this.state.filters.find(e => e.name === 'CompletedServiceCount')
                })
            },
            {
                key: 'IncompleteServiceCount',
                title: <Translate value={i18.PartnersList.Labels.IncompleteServiceCount} />,
                render: item => item.IncompleteServiceCount,
                ...FilterField({
                    name: 'IncompleteServiceCount',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'IncompleteServiceCount'),
                    type: filterFieldTypes.number,
                    data: this.state.filters.find(e => e.name === 'IncompleteServiceCount')
                })
            },
            {
              key: 'HasDocuments',
              title: <Translate value={i18.PartnersList.Labels.HasDocuments} />,
              render: item =>
                  item.HasDocuments ? (
                      <Translate value={i18.PartnersList.Values.DocumentsTrue} />
                  ) : (
                      <Translate value={i18.PartnersList.Values.DocumentsFalse} />
                  ),
              ...FilterField({
                  name: 'HasDocuments',
                  setFilter: this.setFilter,
                  sortOrder: this.state.orders.find(e => e.name === 'HasDocuments'),
                  type: filterFieldTypes.bool,
                  data: this.state.filters.find(e => e.name === 'HasDocuments')
              })
            },
            {
                key: 'PendingServiceCount',
                title: <Translate value={i18.PartnersList.Labels.PendingServiceCount} />,
                render: item => item.PendingServiceCount,
                ...FilterField({
                    name: 'PendingServiceCount',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'PendingServiceCount'),
                    type: filterFieldTypes.number,
                    data: this.state.filters.find(e => e.name === 'PendingServiceCount')
                })
            },
            {
                key: 'HasAgreedToReceiveMarketingInformation',
                title: <Translate value={i18.PartnersList.Labels.HasAgreedToReceiveMarketingInformation} />,
                render: item =>
                    item.HasAgreedToReceiveMarketingInformation ? (
                        <Translate value={i18.PartnersList.Values.MarketingTrue} />
                    ) : (
                        <Translate value={i18.PartnersList.Values.MarketingFalse} />
                    ),
                ...FilterField({
                    name: 'HasAgreedToReceiveMarketingInformation',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'HasAgreedToReceiveMarketingInformation'),
                    type: filterFieldTypes.bool,
                    data: this.state.filters.find(e => e.name === 'HasAgreedToReceiveMarketingInformation')
                })
            },
            {
                key: 'SynchronizationDate',
                title: <Translate value={i18.PartnersList.Labels.SynchronizationDate} />,
                render: item => (
                    <div className="text-right text-nowrap">
                        {formatDate(item.SynchronizationDate, dateFormat().dateTimeSecond)}
                        <ButtonWrapper
                            icon={<SyncOutlined />}
                            type="link"
                            onClick={e => {
                                e.stopPropagation();
                                this.sync(item.PartnerId);
                            }}
                            loading={this.props.sendPartnerProfileSynchronization.includes(item.PartnerId)}
                        />
                    </div>
                ),
                ...FilterField({
                    name: 'SynchronizationDate',
                    setFilter: this.setFilter,
                    sortOrder: this.state.orders.find(e => e.name === 'SynchronizationDate'),
                    type: filterFieldTypes.date,
                    data: this.state.filters.find(e => e.name === 'SynchronizationDate')
                })
            }
        ];

        return (
            <div>
                <ContentHeader
                    title={
                        <>
                            <Translate className="mr-2" value={i18.Partners.Titles.Partners} />
                            <span className="fw_400 color-black-50">
                                ({this.props.odataPartnersGrid['@odata.count']})
                            </span>
                        </>
                    }
                    extra={
                        <ButtonGroup>
                            <Input
                                style={{
                                    marginRight: 20
                                }}
                                placeholder={translate(i18.PartnersList.Labels.SearchPlaceholder)}
                                prefix={<SearchOutlined />}
                                onChange={({ target }) => this.setState({ searchInputValue: target.value })}
                                value={this.state.searchInputValue}
                                onPressEnter={({ target }) => {
                                    this.setFilter({
                                        name: 'SearchAggregate',
                                        type: filterFieldTypes.text,
                                        value: target.value,
                                        operator: null
                                    });
                                }}
                            />
                            {this.state.filters.length > 0 || this.state.orders.length > 0 ? (
                                <ButtonWrapper
                                    icon={<FilterOutlined />}
                                    type="secondary"
                                    onClick={() => {
                                        this.setState(
                                            (_, props) => ({
                                                ...props.defaultFilter
                                            }),
                                            () => {
                                                history.replace();
                                                this.fetch();
                                            }
                                        );
                                    }}
                                >
                                    <Translate value={i18.Partners.Buttons.Clear} />
                                </ButtonWrapper>
                            ) : null}
                            <ButtonWrapper
                                icon={<UserAddOutlined />}
                                type="primary"
                                onClick={() => history.push(routerPaths.partners.create, this.state)}
                            >
                                <Translate value={i18.Partners.Buttons.Add} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    }
                />
                <div className="content_block">
                    <ScrollContainer
                        style={{
                            overflowX: 'auto'
                        }}
                    >
                        <Table
                            rowKey="Key"
                            onChange={(pagination, columns, order) =>
                                this.setOrderAndPagination(pagination, columns, order)
                            }
                            rowClassName="cursor_pointer"
                            style={{ minWidth: 1400 }}
                            columns={columns}
                            dataSource={this.props.odataPartnersGrid.value || []}
                            loading={this.props.sendOdataPartnersGrid}
                            onRow={item => {
                                return {
                                    onClick: () => this.click(item.PartnerId)
                                };
                            }}
                            pagination={{
                                total: this.props.odataPartnersGrid['@odata.count'],
                                pageSize: this.state.pagination.pageSize,
                                current: this.state.pagination.current
                            }}
                        />
                    </ScrollContainer>
                </div>
            </div>
        );
    }
}

PartnersOdataContainer.propTypes = {
    defaultFilter: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    odataPartnersGrid: PropTypes.object.isRequired,
    sendOdataPartnersGrid: PropTypes.bool,
    sendPartnerProfileSynchronization: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    const { odataPartnersGrid, sendOdataPartnersGrid } = state.odataReducers;
    const { sendPartnerProfileSynchronization } = state.partnerProfileReducers;

    return {
        odataPartnersGrid,
        sendOdataPartnersGrid,
        sendPartnerProfileSynchronization
    };
}

const connectedPartnersOdataContainer = withRouter(connect(mapStateToProps)(PartnersOdataContainer));
export { connectedPartnersOdataContainer as PartnersOdataContainer };
