import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    CheckCircleTwoTone
} from '@ant-design/icons';

import { countryVatActions, countryVatConstants } from '../../api';
import { colors, getContentString, i18, i18clGroups } from '../../utilities';
import { CountryVatForm } from './components';
import { ContentHeader, Translate } from '../../components';

const actionTypes = {
    create: 'create',
    update: 'update',
    delete: 'delete'
};

class CountryVatContainer extends React.Component {
    constructor (props) {
        super(props);

        this.openCreateForm = this.openCreateForm.bind(this);
        this.openUpdateForm = this.openUpdateForm.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.closeFormAndRefetchIf = this.closeFormAndRefetchIf.bind(this);
        this.submitCreateVat = this.submitCreateVat.bind(this);
        this.submitUpdateVat = this.submitUpdateVat.bind(this);
        this.selectSubmitType = this.selectSubmitType.bind(this);
        this.fetchVats = this.fetchVats.bind(this);

        this.state = {
            selectedVat: null,
            actionType: null
        };
    }

    componentDidMount () {
        this.fetchVats();
    }

    openCreateForm () {
        this.setState({ actionType: actionTypes.create });
    }

    openUpdateForm (value) {
        this.setState({
            actionType: actionTypes.update,
            selectedVat: value
        });
    }

    fetchVats () {
        this.props.dispatch(countryVatActions.getCountryVats());
    }

    closeForm () {
        this.setState({
            actionType: null,
            selectedVat: null
        });
    }

    closeFormAndRefetchIf (condition) {
        return (response) => {
            if (response?.type === condition) {
                this.fetchVats();
                this.closeForm();
            }
        };
    }

    submitCreateVat (values) {
        const { countryVats } = this.props;

        if (countryVats.id) {
            this.props
                .dispatch(countryVatActions.postVat(countryVats.id, values))
                .then(this.closeFormAndRefetchIf(countryVatConstants.POST_VAT_SUCCESS));
        } else {
            this.props
                .dispatch(countryVatActions.postCountryVat(values))
                .then(
                    this.closeFormAndRefetchIf(
                        countryVatConstants.POST_COUNTRY_VAT_SUCCESS
                    )
                );
        }
    }

    submitUpdateVat (values) {
        this.props
            .dispatch(countryVatActions.putVat(this.props.countryVats.id, values))
            .then(this.closeFormAndRefetchIf(countryVatConstants.PUT_VAT_SUCCESS));
    }

    submitDeleteVat (values) {
        this.props
            .dispatch(countryVatActions.deleteVat(this.props.countryVats.id, values))
            .then(this.fetchVats);
    }

    selectSubmitType (type) {
        if (type === actionTypes.create) {
            return this.submitCreateVat;
        }
        return this.submitUpdateVat;
    }

    render () {
        const columns = [
            {
                title: <Translate value={i18.CountryVat.Labels.VatDefault} />,
                render: (item) => (
                    <div>{item?.isDefault && <CheckCircleTwoTone style={{ fontSize: 25 }} twoToneColor={colors.success} />}</div>
                ),
                width: '15%'
            },
            {
                title: <Translate value={i18.CountryVat.Labels.VatName} />,
                render: (item) => item.name,
                width: '25%'
            },
            {
                title: <Translate value={i18.CountryVat.Labels.VatPercentage} />,
                render: (item) => `${item.percentage}`,
                width: '15%'
            },
            {
                title: <Translate value={i18.CountryVat.Labels.VatTranslation} />,
                render: (item) =>
                    getContentString(`${i18clGroups.INVOICING_VAT}${item.name}`),
                width: '25%'
            },
            {
                title: <Translate value={i18.CountryVat.Labels.VatAction} />,
                className: 'text-right',
                render: (item) => (
                    <div>
                        <Button
                            className="mr-4"
                            type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                            loading={this.props.sendCountryVatById}
                            onClick={() => this.openUpdateForm(item)}
                        />
                        <Button
                            danger
                            type="primary"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            loading={this.props.sendCountryVatDELETE}
                            onClick={() => this.submitDeleteVat(item)}
                            disabled={item.isDefault}
                        />
                    </div>
                )
            }
        ];

        return (
            <>
                <ContentHeader
                    title={<Translate value={i18.CountryVat.Titles.Main} />}
                    extra={
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            loading={false}
                            disabled={false}
                            onClick={this.openCreateForm}
                        >
                            <Translate value={i18.CountryVat.Buttons.Add} />
                        </Button>
                    }
                />
                <CountryVatForm
                    initialValues={this.state.selectedVat}
                    isCountryVatExist={this.props.countryVats.id !== undefined}
                    actionType={this.state.actionType}
                    onCancel={this.closeForm}
                    submit={this.selectSubmitType(this.state.actionType)}
                    loading={
                        this.props.sendCountryVatPUT || this.props.sendCountryVatPOST
                    }
                />
                <div className="content_block">
                    <div className="content_box">
                        <Table
                            columns={columns}
                            dataSource={this.props.countryVats.vats}
                            loading={this.props.sendCountryVats}
                            rowKey="name"
                            pagination={false}
                        />
                    </div>
                </div>
            </>
        );
    }
}

CountryVatContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    countryVats: PropTypes.object.isRequired,
    sendCountryVats: PropTypes.bool,
    sendCountryVatPUT: PropTypes.bool,
    sendCountryVatById: PropTypes.bool,
    sendCountryVatPOST: PropTypes.bool,
    sendCountryVatDELETE: PropTypes.bool
};

function mapStateToProps (state) {
    const {
        countryVats,
        sendCountryVats,
        sendCountryVatPUT,
        sendCountryVatById,
        sendCountryVatPOST,
        sendCountryVatDELETE
    } = state.countryVatReducers;

    const { sendPublicContentGET } = state.publicReducers;

    return {
        countryVats,
        sendCountryVats,
        sendCountryVatPUT,
        sendCountryVatById,
        sendCountryVatPOST,
        sendCountryVatDELETE,
        sendPublicContentGET
    };
}

const connectedVatContainer = connect(mapStateToProps)(CountryVatContainer);
export { connectedVatContainer as CountryVatContainer, actionTypes };
