import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { MailOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons';

import { accountTypes, i18, i18cl, routerPaths } from '../../utilities';
import { partnerManagementActions, partnerProfileActions, partnerProfileConstants, ProfileStatusConstants } from '../../api';
import { ButtonGroup, ButtonWrapper, Classificator, ContentHeader, ContentLoader, ProfileImage, Translate } from '../../components';
import {
    PartnerMenu,
    PartnerAccountContainer,
    PartnerAdminActionsContainer,
    PartnerCommunicationsContainer,
    PartnerDocumentsContainer,
    PartnerLogsContainer,
    PartnerProfileContainer,
    PartnerServiceContainer,
    PartnerServiceItemsContainer,
    PartnerServicesContainer,
    PartnerDevicesContainer
} from './';

class PartnerContainer extends React.Component {
    componentDidMount () {
        this.props.dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId));
    }

    render () {
        if (this.props.sendPartnerProfileGET) {
            return <ContentLoader />;
        }

        if (!this.props.partnerProfile) {
            return null;
        }

        return (
            <>
                <ContentHeader
                    back={this.props.history.location.state?.prevRoute ?? routerPaths.partners.index}
                    backState={this.props.history.location.state}
                    avatar={<ProfileImage profileId={parseInt(this.props.match.params.partnerId)} />}
                    title={this.props.partnerProfile.account.typeCode === accountTypes.JURIDICAL
                        ? <div>{this.props.partnerProfile.account.company.name}</div>
                        : <div>{this.props.partnerProfile.firstName + ' ' + this.props.partnerProfile.lastName}</div>
                    }
                    description={
                        <>
                            <div>{this.props.partnerProfile.email}</div>
                            <div><Classificator value={i18cl.TYPES_ACCOUNT + this.props.partnerProfile.account.typeCode} /></div>
                        </>
                    }
                    extra={
                        <ButtonGroup>
                            <ButtonWrapper
                                type='secondary'
                                icon={<SyncOutlined />}
                                loading={this.props.sendPartnerProfileSynchronization.includes(this.props.match.params.partnerId)}
                                onClick={() => {
                                    this.props.dispatch(partnerProfileActions.postProfileSynchronization(this.props.match.params.partnerId)).then(res => {
                                        if (res && res.type === partnerProfileConstants.POST_PARTNER_PROFILE_SYNCHRONIZATION_SUCCESS) {
                                            this.props.dispatch(partnerProfileActions.getProfile(this.props.match.params.partnerId, true));
                                        }
                                    });
                                }}
                            >
                                <Translate value={i18.Partner.Buttons.SyncLabbis} />
                            </ButtonWrapper>
                            <ButtonWrapper
                                type='secondary'
                                icon={<MailOutlined />}
                                loading={this.props.sendPartnerPasswordReset}
                                onClick={() => this.props.dispatch(partnerProfileActions.postResetPassword(this.props.match.params.partnerId))}
                            >
                                <Translate value={i18.Partner.Buttons.PasswordReset} />
                            </ButtonWrapper>
                            {this.props.partnerProfile.status === ProfileStatusConstants.EMAIL_NOT_APPROVED && (
                                <ButtonWrapper
                                    type='secondary'
                                    icon={<ReloadOutlined />}
                                    loading={this.props.sendPartnerManagementResetEmailConfirmationPOST}
                                    onClick={() => this.props.dispatch(partnerManagementActions.postResendEmailConfirmation(this.props.match.params.partnerId))}
                                >
                                    <Translate value={i18.Partner.Buttons.ResendEmailConfirmation} />
                                </ButtonWrapper>
                            )}
                        </ButtonGroup>
                    }
                />
                <PartnerMenu
                    backState={this.props.history.location.state}
                />
                <Switch>
                    <Route
                        component={PartnerProfileContainer}
                        exact
                        path={routerPaths.partners.profile}
                    />
                    <Route
                        component={PartnerAccountContainer}
                        exact
                        path={routerPaths.partners.account}
                    />
                    <Route
                        component={PartnerAdminActionsContainer}
                        exact
                        path={routerPaths.partners.adminActions}
                    />
                    <Route
                        component={PartnerDocumentsContainer}
                        exact
                        path={routerPaths.partners.documents}
                    />
                    <Route
                        component={PartnerServiceItemsContainer}
                        exact
                        path={routerPaths.partners.serviceItems}
                    />
                    <Route
                        component={PartnerServicesContainer}
                        exact
                        path={routerPaths.partners.services}
                    />
                    <Route
                        component={PartnerLogsContainer}
                        exact
                        path={routerPaths.partners.logs}
                    />
                    <Route
                        component={PartnerServiceContainer}
                        exact
                        path={routerPaths.partners.service}
                    />
                    <Route
                        component={PartnerCommunicationsContainer}
                        exact
                        path={routerPaths.partners.communications}
                    />
                    <Route
                        component={PartnerDevicesContainer}
                        exact
                        path={routerPaths.partners.devices}
                    />
                </Switch>
            </>
        );
    };
}

PartnerContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        location: PropTypes.object.isRequired
    }),
    match: PropTypes.shape({
        params: PropTypes.object.isRequired
    }),
    partnerProfile: PropTypes.object,
    sendPartnerManagementResetEmailConfirmationPOST: PropTypes.bool,
    sendPartnerProfileGET: PropTypes.bool.isRequired,
    sendPartnerPasswordReset: PropTypes.bool,
    sendPartnerProfileSynchronization: PropTypes.array.isRequired
};

function mapStateToProps (state) {
    const {
        partnerProfile,
        sendPartnerProfileGET,
        sendPartnerPasswordReset,
        sendPartnerProfileSynchronization
    } = state.partnerProfileReducers;
    const {
        sendPartnerManagementResetEmailConfirmationPOST
    } = state.partnerManagementReducers;

    return {
        partnerProfile,
        sendPartnerManagementResetEmailConfirmationPOST,
        sendPartnerProfileGET,
        sendPartnerPasswordReset,
        sendPartnerProfileSynchronization
    };
}

const connectedPartnerContainer = connect(mapStateToProps)(PartnerContainer);
export { connectedPartnerContainer as PartnerContainer };
