import { featureTogglesServices } from './services';
import { featureTogglesConstants } from './constants';

export const featureTogglesActions = {
    getFeatureToggles
};

function getFeatureToggles () {
    return dispatch => {
        dispatch(request());

        return featureTogglesServices.getFeatureToggles()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request () {
        return { type: featureTogglesConstants.GET_FEATURE_TOGGLES_REQUEST };
    }

    function success (data) {
        return {
            type: featureTogglesConstants.GET_FEATURE_TOGGLES_SUCCESS,
            payload: data
        };
    }

    function failure (error) {
        return {
            type: featureTogglesConstants.GET_FEATURE_TOGGLES_ERROR,
            error
        };
    }
}
