import axios from 'axios';

import { getCountry, getLanguage, getToken } from '../../../utilities';
import { env } from '../../../configs';

const api = env.REACT_APP_BFF_API_URL;

export const methods = {
    get: 'GET',
    patch: 'PATCH',
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE'
};

export const requestBffApi = axios.create({
    baseURL: api,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
        function (data) {
            return JSON.stringify(data);
        }
    ]
});

requestBffApi.interceptors.request.use(config => {
    config.headers.Authorization = getToken();
    config.headers.CountryCode = getCountry();
    config.headers.LangCode = getLanguage();

    return config;
});
