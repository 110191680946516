import React from 'react';
import PropTypes from 'prop-types';

import {
    i18,
    i18cl,
    countrySettings,
    getBooleanCountrySettingValue,
    getCountry,
    countryCodes,
    getContentString,
    accountTypes,
    formatDate
} from '../../../../../utilities';

import { Classificator, Translate } from '../../../../../components';

export const PartnerAccountData = (props) => {
    const showCompanyCode = (accountType) => {
        if (getCountry() === countryCodes.ES && accountType === accountTypes.PHYSICAL) {
            return false;
        }
        return true;
    };

    const account = [
        {
            label: <Translate value={i18.PartnerAccount.Labels.AccountType} />,
            value: <Classificator value={i18cl.TYPES_ACCOUNT + props.data.account.typeCode} />
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.CompanyName} />,
            value: props.data.account.company.name
        }, {
            label: <Translate value={getCountry() === countryCodes.PL ? i18.PartnerAccount.Labels.NipCode : i18.PartnerAccount.Labels.CompanyCode} />,
            value: props.data.account.company.code,
            visible: showCompanyCode(props.data.account.typeCode)
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.InsurancePolicyNumber} />,
            value: props.data.account.insurancePolicyNumber
        }, ...(props.data.account.company.isVatPayer && getBooleanCountrySettingValue(countrySettings.ShowVatCode) ? [{
            label: <Translate value={i18.PartnerAccount.Labels.VATCode} />,
            value: props.data.account.company.vatCode
        }] : []), ...(getBooleanCountrySettingValue(countrySettings.ShowVatCode) ? [{
            label: <Translate value={i18.PartnerAccount.Labels.VatStatusChangedDate} />,
            value: formatDate(props.data.account.company.vatStatusChangedDate)
        }] : []), {
            label: <Translate value={i18.PartnerAccount.Labels.VatName} />,
            value: props.data.account.company.vatName ? getContentString(`${i18cl.INVOICING_VAT}${props.data.account.company.vatName}`) : ''
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.AccountingEmail} />,
            value: props.data.account.accountingEmail
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.AdministrationMargin} />,
            value: props.data.account.administrationMargin
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.Email} />,
            value: props.data.account.email
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.Phone} />,
            value: props.data.account.phoneNumber
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.Address} />,
            value: props.data.account.fullAddress
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.AddressAdditionalInfo} />,
            value: props.data.account.addressAdditionalInfo
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.BankAccount} />,
            value: props.data.account.bankAccountNo
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.BankName} />,
            value: props.data.account.bankName
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.Position} />,
            value: props.data.account.position,
            visible: props.data.account.isCompany
        }, {
            label: <Translate value={i18.PartnerAccount.Labels.PersonalCode} />,
            value: props.data.account.personalCode,
            visible: !props.data.account.isCompany && getCountry() === countryCodes.ES
        }
    ];

    return (
        <div className='list_info_wrapper'>
            {account.filter(e => e.visible !== false).map((item, index) =>
                <div
                    key={index}
                    className='list_info row'
                >
                    <div className='list_info_label col-12 col-xl-5'>{item.label}</div>
                    <div className='list_info_value col-12 col-xl-7'>{item.value}</div>
                </div>
            )}
        </div>
    );
};

PartnerAccountData.propTypes = {
    data: PropTypes.object.isRequired
};
