import { filterFieldTypes } from '../components';
import moment from 'moment';

export const oDataQuery = filters => {
    let query = `?$count=true&$top=${filters.pagination.pageSize}&$skip=${
        (filters.pagination.current - 1) * filters.pagination.pageSize
    }`;

    if (filters.filters.length) {
        query = query + '&$filter=';

        filters.filters.map((item, index) => {
            switch (item.type) {
                case filterFieldTypes.text:
                    query =
                        query +
                        `contains(tolower(${item.name}),tolower(%27${urlEncode(item.value)}%27))` +
                        (filters.filters.length > index + 1 ? ' and ' : '');
                    break;
                case filterFieldTypes.bool:
                case filterFieldTypes.number:
                    query =
                        query +
                        `${item.name}%20eq%20${item.value}` +
                        (filters.filters.length > index + 1 ? ' and ' : '');
                    break;
                case filterFieldTypes.date: {
                    const { minDate, maxDate } = extractDates(item);
                    query =
                        query +
                        `${item.name}%20ge%20${urlEncode(minDate)}` +
                        '%20and%20' +
                        `${item.name}%20le%20${urlEncode(maxDate)}` +
                        (filters.filters.length > index + 1 ? ' and ' : '');
                    break;
                }
                case filterFieldTypes.select:
                    query =
                        query +
                        `${item.name}%20eq%20'${item.value}'` +
                        (filters.filters.length > index + 1 ? ' and ' : '');
                    break;
            }
        });
    }

    if (filters.orders.length) {
        query = query + '&$orderby=';

        filters.orders.map((item, index) => {
            query = query + `${item.name} ${item.order}` + (filters.orders.length > index + 1 ? ', ' : '');
        });
    }

    if (filters.select && filters.select.length) {
        query = query + '&$select=';

        filters.select.map((item, index) => {
            const commaBefore = index === 0 ? '' : ',';
            query = query + `${commaBefore}${item}`;
        });
    }

    return query;
};

function extractDates(item) {
    if (Array.isArray(item.value)) {
        return {
            minDate: localBeginningOfDay(item.value[0]),
            maxDate: localEndOfDay(item.value[1])
        };
    } else {
        return {
            minDate: localBeginningOfDay(item.value),
            maxDate: localEndOfDay(item.value)
        };
    }
}

function localEndOfDay(dateTimeOffset) {
    return moment(dateTimeOffset).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).utc().toISOString();
}

function localBeginningOfDay(dateTimeOffset) {
    return moment(dateTimeOffset).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).utc().toISOString();
}

function urlEncode(value) {
    return encodeURIComponent(value);
}
