import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from '../translate/translate';
import { getFormattedStopJobsEntries } from '../../utilities';
import { clientSegments, stopTypes } from '../../api';

export const TranslateStopJobs = ({ jobsList }) => {
    const formattedStopJobsEntries = getFormattedStopJobsEntries(jobsList);

    return formattedStopJobsEntries.map(([stoppedJobType, segments], i) => {
        const translatedSegments =
            segments.length > 0 ? (
                <>
                    :{' '}
                    {segments.flatMap((segment, ii, arr) => {
                        const isLastSegment = ii === arr.length - 1;

                        return [
                            <Translate key={ii} value={clientSegments[segment].label} />,
                            !isLastSegment && <span key={`${ii}-span`}>, </span>
                        ];
                    })}
                </>
            ) : null;
        const isLastItem = i !== formattedStopJobsEntries.length - 1;
        const separator = isLastItem ? <span>, </span> : null;

        return (
            <React.Fragment key={i}>
                <Translate value={stopTypes[stoppedJobType].label} />
                {translatedSegments}
                {separator}
            </React.Fragment>
        );
    });
};

TranslateStopJobs.propTypes = {
    jobsList: PropTypes.arrayOf(
        PropTypes.shape({
            stoppedJobType: PropTypes.string.isRequired,
            clientSegment: PropTypes.string.isRequired
        })
    )
};
