import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import { classificatorArray, formatNumber, i18, i18cl, required } from '../../../../../utilities';
import { ButtonGroup, ButtonWrapper, FormField, formFieldType, Translate } from '../../../../../components';

export const PartnerServicesForm = (props) => {
    return (
        <Form
            onSubmit={props.submit}
            render={({ handleSubmit }) => (
                <form
                    onSubmit={handleSubmit}
                    autoComplete='off'
                >
                    <FormField
                        inline
                        title={i18.PartnerServicesForm.Labels.Service}
                        name='serviceId'
                        component={formFieldType.select}
                        options={classificatorArray(i18cl.SERVICES_CATALOG, props.services, 'serviceCode', 'profileServiceId')}
                        validate={required}
                        loading={props.servicesLoading}
                        disabled={props.submitting}
                    />
                    <FormField
                        inline
                        component={formFieldType.multiSelect}
                        title={i18.PartnerServicesForm.Labels.Areas}
                        options={props.areas}
                        optionsLabel='name'
                        optionsValue='serviceAreaId'
                        name='areas'
                        loading={props.areasLoading}
                        disabled={props.submitting}
                        validate={required}
                    />
                    <FormField
                        inline
                        component={formFieldType.number}
                        formatNumber={formatNumber().numberPositive}
                        disabled={props.submitting}
                        name='radius'
                        title={i18.PartnerServicesForm.Labels.AreasRadius}
                        validate={required}
                    />
                    <FormField
                        inline
                        component={formFieldType.number}
                        title={`${i18.PartnerServicesForm.Labels.HourlyRate}${props.currency}`}
                        name='hourlyRate'
                        disabled={props.submitting}
                    />
                    <div className='text-right'>
                        <ButtonGroup>
                            <ButtonWrapper
                                disabled={props.submitting}
                                onClick={() => props.cancel()}
                            >
                                <Translate value={i18.PartnerServicesForm.Buttons.Cancel} />
                            </ButtonWrapper>
                            <ButtonWrapper
                                type='primary'
                                htmlType='submit'
                                loading={props.submitting}
                            >
                                <Translate value={i18.PartnerServicesForm.Buttons.Submit} />
                            </ButtonWrapper>
                        </ButtonGroup>
                    </div>
                </form>
            )}
        />
    );
};

PartnerServicesForm.propTypes = {
    areas: PropTypes.array.isRequired,
    areasLoading: PropTypes.bool,
    services: PropTypes.array.isRequired,
    servicesLoading: PropTypes.bool,
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    currency: PropTypes.string
};
